<template>
    <el-dialog
      :title="title"
      :visible.sync="centerDialogVisible"
      :width="moduleWidth"
      :show-close="false"
      @close="hide"
      top="35vh"
      center
    >
      <component :is="isModules" @hide="hide"></component>
    </el-dialog>
  </template>
  <script>
  import farmsModel from "./index";
  export default {
    props: {
      isShow: {
        type: Boolean,
      },
      modules: {
        type: String,
        default: "Recharge",
      },
      title: {
        type: String,
        default: "提示",
      },
    },
    data() {
      return {
        isModules: "",
        centerDialogVisible: this.isShow,
        viewportWidth: null,
        viewportHeight: null,
      };
    },
    computed: {
      moduleWidth() {
        return this.viewportWidth > 700 ? "30%" : "80%";
      },
    },
  
    created() {
      this.isModules = farmsModel[this.modules];
      this.updateViewportSize(); // 初始化获取视口尺寸
      window.addEventListener("resize", this.updateViewportSize);
    },
    watch: {
      isShow() {
        this.centerDialogVisible = this.isShow;
      },
      modules() {
        this.isModules = farmsModel[this.modules];
      },
    },
    methods: {
      //视口监听
      updateViewportSize() {
        this.viewportWidth = window.innerWidth;
        this.viewportHeight = window.innerHeight;
      },
      hide() {
        this.$emit("hide");
      },
    },
  };
  </script>
  <style lang="scss" scoped>
.el-dialog--center{
    border-radius:10px
}
</style>
  