const en_ES = {
    Datajson:{
        language_json:{
            zh_json:'中文简体',
            zh_FT_json: '中文繁體',
            eh_json:'English',
            ja_JP_json:'日本語',  
            ko_KR_json:'한국어' ,
            en_TH_json:'ภาษาไทย',
            fr_FR_json:'En français',
            ru_RU_json:'русск',
            en_IN_json:'हिंदीName',
            en_ID_json:'Bahasa indonesia',
            en_BS_json:'فارسی',
            en_YN_json:'Việt nam',
            en_ALB_json:' بالعربية ',
            en_TEQ_json:'Türkçe',
            en_ES_json:'Español',
            en_PTY_json:'Português',
            en_YDL_json:'Italiano'
        },
    },
    home: {
        language: 'idioma',
        fduse_desc: 'FDUSD es una Stablecoin respaldada por USD en una proporción de 1:1.',
        introduce_desc: 'Revolucionamos las finanzas globales con una Stablecoin digital de vanguardia, brindando seguridad, velocidad e innovación a empresas y constructores en todo el mundo.',
        getfduse_desc: 'Obtén FDUSD',
        stability_desc: 'Estabilidad y Innovación',
        experience_desc: 'Experimenta velocidades rápidas y costos mínimos. Da forma al futuro de las finanzas con nuestra solución innovadora y confiable.',
        whitepaper_desc: 'Libro Blanco',
        redeemable_desc: 'Canjeable 1:1 por dólares estadounidenses',
        dollars_desc: 'FDUSD tiene la intención de estar respaldado por completo por efectivo y activos equivalentes en efectivo. Se pretende que los tokens sean canjeables 1:1 por dólares estadounidenses.',
        backed_desc: 'Respaldado por activos totalmente reservados',
        deserved_desc: 'Los activos reservados se mantienen en estructuras de custodia totalmente segregadas y protegidas contra la bancarrota.',
        programmable_desc: 'Totalmente programable',
        innovative_desc: 'Con nuestra solución innovadora de Stablecoin respaldada por tecnología avanzada, buscamos proporcionar una alternativa segura y confiable que brinde tranquilidad y tenga un impacto positivo en el mundo de las finanzas.',
        settle_desc: 'Liquidación en minutos',
        nearZero_desc: 'Costo cercano a cero',
        always_desc: 'Siempre activo, 24/7',
        fully_desc: "Totalmente programable",
        rewrite_desc: 'Reescribe el futuro con First Digital Labs',
        combining_desc: 'Combinando estabilidad con innovación en First Digital Labs, nos comprometemos a ganar confianza ofreciendo transparencia inquebrantable, cumplimiento normativo y seguridad respaldados por tecnología de vanguardia.',
        mission_desc: 'Tenemos la misión de impulsar la industria financiera hacia una nueva era de las finanzas y remodelar el panorama financiero global.',
        should_desc: '¿Por qué deberías usar First Digital USD?',
        collateral_desc: 'La garantía está protegida por un custodio de primer nivel y calificado con una licencia fiduciaria para una protección de activos sin problemas y cumplimiento normativo.',
        transparent_desc: 'Transparente',
        independent_desc: 'Atestación independiente: la garantía es validada por un tercero independiente, garantizando la integridad de las reservas y los informes financieros.',
        examined_by_desc: 'Examinado por',
        attestation_Reports_desc: 'Informes de Attestation',
        January2024_desc: 'Enero 2024',
        Report_desc: 'Informe',
        December_desc: 'Diciembre 2023',
        November_desc: 'Noviembre 2023',
        Networks_desc: '¿Qué redes son compatibles?',
        available_desc: 'FDUSD está disponible en',
        Ethereum_desc: 'Ethereum',
        and_desc: 'y',
        BNB_Chain_desc: 'BNB Chain',
        planned_desc: 'con soporte planificado para un número creciente de blockchains.',
        Audited_desc: 'Auditado por',
        Frequently_desc: 'Preguntas Frecuentes',
        How_FDUSD_desc: '¿Cómo puedo obtener FDUSD?',
        Acquiring_desc: 'Puedes obtener FDUSD de varias formas. Si eres un actor importante de la industria, un intermediario financiero o un inversionista profesional de cierto estatus y cumples con los requisitos necesarios, puedes adquirir FDUSD directamente de First Digital Labs. Ten en cuenta que First Digital Labs no vende tokens directamente a clientes minoristas:',
        canstill_desc: 'Todavía puedes obtener FDUSD a través de mercados secundarios sin convertirte en cliente de First Digital Labs. Muchos exchanges líderes de criptomonedas listan FDUSD y ofrecen una liquidez sustancial. Esto proporciona una forma accesible y conveniente para que las personas interesadas adquieran FDUSD. Sin embargo, siempre es esencial realizar una investigación exhaustiva y asegurarse de la credibilidad del exchange que estés utilizando.',
        equivalent_desc: '¿Cómo puedo confiar en que FDUSD siempre será equivalente a un dólar?',
       // stablecoin_desc: 'Tu confianza en el valor de la Stablecoin FDUSD está bien fundamentada. Se pretende que cada FDUSD esté respaldado por completo por un dólar estadounidense o un activo de valor justo equivalente. Estos activos se mantienen de manera segura por uncustodio calificado en cuentas con instituciones depositarias reguladas, lo que proporciona una base sólida para el valor de la moneda. Este sistema es lo que brinda a FDUSD su estabilidad inherente y por qué puedes confiar en su equivalencia con el dólar estadounidense.',
        redeem_FDUSD: '¿Cómo puedo canjear FDUSD?',
       // stablecoin_desc: 'Para canjear tu Stablecoin FDUSD, primero debes convertirte en cliente de First Digital Labs y cumplir con requisitos específicos, incluyendo verificación contra el lavado de dinero (AML) y financiamiento del terrorismo (CTF). Una vez completada exitosamente esta verificación, puedes intercambiar tus FDUSD por su equivalente en moneda fiduciaria, sacándolo de circulación. Alternativamente, puedes vender tus tokens FDUSD en el mercado secundario a través de un exchange de criptomonedas o un proveedor de venta libre (OTC) que admita las Stablecoins de FD121. Por favor, ten en cuenta todas las regulaciones legales y financieras en tu jurisdicción antes de proceder.',
        Explore_desc: "Explora nuestro Ecosistema",
        Experience_desc: 'Experimenta la estabilidad con nuestra solución de Stablecoin confiable.',
        slide_desc: 'diapositiva',
        _1to6_desc: '1 al 6',
        of7_desc: 'de 7',
        Transparency_desc: 'Transparencia',
        Legal_desc: 'Legal',
        Compliance_desc: 'Cumplimiento',
        Policy_desc: 'Política de Privacidad',
        Company_desc: 'Empresa',
        About_Us_desc: 'Sobre Nosotros',
        Social_Media_desc: 'Redes Sociales',
        Twitter_desc: 'Twitter',
        Disclosures_desc: 'Divulgaciones Importantes:',
        Limited_desc: 'FD121 Limited es una compañía de tecnología financiera, no un banco. Los servicios de custodia de los activos están proporcionados por ',
        First_desc: "First Digital Trust Limited",
        product_desc: 'Todos los nombres de productos y compañías son marcas comerciales™ o marcas registradas® de sus respectivos titulares. Todos los nombres de compañías, productos y servicios utilizados en este sitio web son solo para fines de identificación. El uso de estos nombres, marcas comerciales y marcas no implica ninguna afiliación o respaldo por parte de ellos.',
        additional_desc: 'Para riesgos, divulgaciones e información adicional importante, visita',
        Copyright_desc: 'Derechos de Autor © 2024 FD121 Limited. Todos los Derechos Reservados.'
    },
    newhome_json: {
        Exchanges_json: "Intercambios",
        DeFi_json: "Finanzas Descentralizadas",
        Payments_json: "Pagos",
        Accessibility_json: "Accesibilidad",
        listed_json: "TUSD está listado en múltiples intercambios líderes en la industria, proporcionando liquidez y accesibilidad para todos los inversores.",
        stablecoin_json: "Como una stablecoin ampliamente utilizada, TUSD ha sido adoptada significativamente en varios protocolos de finanzas descentralizadas (DeFi), incluyendo creadores de mercado automatizados (AMM), plataformas de préstamos y proyectos de cultivo de rendimiento (YIF).",
        leading_json: "Como la principal stablecoin en yuan, TUSD es un medio ideal para transacciones transfronterizas. Con las bajas tarifas de transacción y la velocidad instantánea de TUSD, tanto individuos como instituciones pueden realizar transacciones sin los desafíos financieros tradicionales.",
        available_json: "TUSD también está disponible en las principales blockchains como Ethereum, BNB Smart Chain, TRON y Avalanche. La red de puentes de TUSD incluye Polygon, Fantom, Arbitrum, Cronos y más.",
        error1_json: "No se puede conectar al nodo. Cambie de nodo y vuelva a intentarlo",
        error2_json: "Error de cadena",
        error3s_json: "Por favor, conecte su dirección",
        Loading1_json: "Cargando",
        Loading2_json: "Cargando información del proyecto",
        error3_json: "Error de red",
        error4_json: "Conexión de la cartera rechazada",
        error5_json: "Versión antigua de la cartera",
        error6_json: "Conexión de la cartera rechazada",
        Loading3_json: "Iniciando sesión",
        error7_json: "Error de cuenta",
        error8_json: "No se puede conectar al nodo. Cambie de nodo y vuelva a intentarlo",
        error9_json: "Error de red",
        Loading4_json: "Staking",
        error10_json: "Ha sido bloqueado",
        error11_json: "Error de proyecto",
        error12_json: "Gas insuficiente",
        success1_json: "Éxito",
        error13_json: "Error al obtener la cuenta",
        error14_json: "Error desconocido",
        error15_json: "No se puede conectar al nodo. Cambie de nodo y vuelva a intentarlo",
       
      },
      newhome_desc: {
        title_desc: "Moneda digital construida para el nuevo sistema de pagos global",
        desc_desc: "TrueUSD es la primera stablecoin vinculada al dólar que realiza auditorías diarias de sus reservas por una institución independiente de terceros.",
        Started_desc: "Comenzar",
        Contact_desc: "Contáctenos",
        Circulating_desc: "Suministro en circulación",
        Trading_desc: "Volumen de comercio",
        Transaction_desc: "Número total de transacciones",
        Support_desc1: "Total de cadenas soportadas",
        Trustworthy_desc: "Confiable",
        utilizes_desc: "TUSD utiliza mecanismos avanzados de auditoría y certificación para aumentar la transparencia. Sus informes de auditoría diarios están disponibles en el sitio web, permitiendo a los usuarios monitorear las reservas en cualquier momento.",
        Popularity_desc: "Popularidad",
        convenience_desc: "TUSD ofrece conveniencia y utilidad en varios escenarios financieros como el comercio y las transferencias. Actualmente, TUSD se puede utilizar en más de 80 intercambios de criptomonedas y protocolos DeFi, proporcionando flexibilidad y accesibilidad a los usuarios.",
        Liquidity_desc: "Liquidez",
        During_desc: "En el tercer trimestre de 2023, el volumen de comercio diario de TUSD superó los mil millones de dólares, mostrando su asombrosa liquidez. Además, TUSD no cobra ninguna tarifa por acuñación y redención.",
        Trusted_desc: "Confiado por líderes mundiales",
        Using_desc: "Usando TUSD",
        LearnMore_desc: "Aprenda más",
        Goes_desc: "TUSD se dirige a múltiples cadenas",
        Natively_desc1: "Red de despliegue nativa",
        Ethereum_desc: "Ethereum",
        TRON_desc: "TRON",
        Avalanche_desc: "Avalanche",
        Smart_desc: "BNB Smart Chain",
        Bridged_desc: "Red de puentes",
        Fantom_desc: "Fantom",
        Arbitrum_desc: "Arbitrum",
        Cronos_desc: "Cronos",
        Optimism_desc: "Optimism",
        Aurora_desc: "Aurora",
        FAQs_desc: "Preguntas frecuentes",
        TrueUSD_desc: "P1. ¿Qué es TrueUSD?",
        Banking_desc: "TrueUSD utiliza instituciones financieras globales y una infraestructura de blockchain robusta. Es la primera stablecoin que utiliza contratos inteligentes para acuñación/redención, con soporte para verificación de cobertura de reservas en tiempo real en la cadena, respaldada por la firma de contabilidad mundial Moore Hong Kong y Chainlink Proof of Reserve.",
        started_desc: "P2. ¿Cómo comenzar a usar TrueUSD?",
        stepsbelow_desc: "Para comenzar a usar TrueUSD, siga los siguientes pasos:",
        verify_desc: "Después de crear y verificar su cuenta a través del sitio web de TrueUSD, recibirá un correo electrónico de",
        website_desc: "sitio web de TrueUSD",
       
        verification_desc: "Verifique a través del enlace de verificación. Haga clic en este enlace para verificar su dirección de correo electrónico, luego configure",
        information_desc: "Complete la información sobre usted y/o su organización. Para una lista de los documentos requeridos, haga clic",
        here_desc: "aquí",
        application_desc: "Envíe su solicitud. Revisaremos su solicitud.",
        Redeem_desc: "Acuñación y redención de nuestra stablecoin sin tarifas en un solo clic",
        Trade_desc: "Comercie TrueUSD en más de 160 mercados en múltiples intercambios y más de 20 mesas OTC en 5 continentes",
        Send_desc: "Envíe TrueUSD a cualquier persona, en cualquier momento, de manera 100 veces más barata y 1000 veces más rápida que una transferencia bancaria",
        mint_desc: "P3. ¿Cómo acuñar y redimir TrueUSD?",
        partner_desc1: "Envíe una transferencia bancaria a nuestros socios bancarios, utilizando la información que se muestra en",
        Trueapp_desc: "la aplicación TrueUSD",
        Please_desc: "Tenga en cuenta: actualmente no aceptamos ACH u otros servicios de transferencia de moneda digital como Venmo, TransferWise, PayPal, Revolut, etc.",
        account_desc: "El nombre en su cuenta bancaria debe coincidir con el nombre en su cuenta TrueUSD.",
        creating_desc: "Después de crear su cuenta, recibirá un correo electrónico de",
        generally_desc: "Generalmente, las transferencias bancarias entre bancos suelen tardar entre 1 y 5 días hábiles en completarse.",
        email_desc: "Una vez que recibamos su transferencia bancaria, le enviaremos un correo electrónico de confirmación.",
        minted_desc: "Una vez que recibamos sus fondos, TrueUSD será acuñado y transferido a la dirección de la cartera proporcionada en la aplicación dentro de un día hábil.",
        redeem4_desc: "P4. ¿Cómo redimir TrueUSD?",
        Visit_desc: "Visite",
        Polygon_desc1: "la aplicación TrueUSD",
        receive_desc: "para ingresar su información bancaria y obtener su dirección de redención única. Las transferencias de moneda fiduciaria están sujetas a los requisitos de KYC y AML de nuestros socios bancarios.",
        redemption_desc: "Esta dirección de redención es única para cada usuario de TrueUSD. No comparta su dirección de redención única con otros.",
        simple_desc: "¿Por qué mi dirección de redención es tan simple?",
        notice_desc: "Notará que esta dirección es muy simple, con muchos '0'. Esto es intencional. Puede redimir desde cualquier cartera (incluidas las carteras de intercambio) a esta dirección, y todas las redenciones recibidas en esta dirección estarán vinculadas a su cuenta TrueUSD.",
        unique_desc: "Envíe TrueUSD a su dirección de redención única (mínimo $1,000).",
        partner_desc: "Una vez que los tokens se envíen con éxito a su dirección de redención, nuestros socios bancarios generalmente emitirán una transferencia bancaria a su cuenta bancaria dentro de un día hábil.",
        exchanges_desc: "P5. ¿En qué intercambios está listado TrueUSD (TUSD)?",
        Binance_desc: "Actualmente, TrueUSD está listado en varios intercambios, incluyendo Binance, Bittrex y Upbit.",
        here2_desc: "aquí",
        markets_desc: "para obtener la lista completa de intercambios y mercados activos de TrueUSD.",
        contract_desc: "P6. ¿Cuál es la dirección del contrato inteligente de TrueUSD?",
        tokenSmart_desc: "Nuestros contratos inteligentes se pueden encontrar a través de la red de despliegue nativa de TUSD y la red de puentes de TUSD o el explorador de la cadena Binance. Los enlaces están a continuación. También puede obtener más información sobre nuestros contratos inteligentes y las actualizaciones de contratos inteligentes en nuestro",
        blog_desc: "blog",
        related_desc: ".",
        Natively_desc: "Red de despliegue nativa de TUSD",
        Ethererum2_desc: "Ethereum",
        SmartChain_desc: "BNB Smart Chain:",
       
        Avalanche2_desc: "Avalanche",
        Beacon_desc: "BNB Beacon Chain:",
        Networks2_desc: "Red de puentes de TUSD",
        Polygon2_desc: "Polygon",
        Fantom2_desc: "Fantom",
       
        Polygon_desc2: "Optimism",
        ChainOld_desc: "BNB Smart Chain (antiguo):",
        AboutMore_desc: "Obtenga más información sobre TUSD",
        Digital_desc: "Moneda digital para el nuevo sistema de pagos global",
        first_desc: "TrueUSD es la primera stablecoin vinculada al dólar que utiliza una institución independiente de terceros para verificar diariamente sus reservas.",
        Polygon_desc: "Polygon",
        Follow_desc: "Síganos",
        Resources_desc: "Recursos",
        Stablecoin_desc: "TrueUSD se convierte en la primera stablecoin respaldada por el dólar que garantiza la acuñación a través de 'Proof of Reserves'",
        Engages_desc: "TUSD colabora con la firma de contabilidad líder MooreHK para proporcionar servicios de verificación de sus reservas fiduciarias",
        Becomes_desc: "TUSD (TRC-20) se convierte en la moneda oficial de Dominica",
        Blog_desc: "Blog",
        Support_desc: "Soporte",
        TermsOf_desc: "Términos de uso",
        Privacy_desc: "Política de privacidad",
        TrueUsd_desc: "TrueUSD",
        Home_desc: "Inicio",
        Transparency_desc: "Transparencia",
        Ecosystem_desc: "Ecosistema",
        About_desc: "Acerca de",
        Supported_desc: "Cadenas soportadas",
        Other_desc: "Otras cadenas",
        rights_desc: "© 2023 TrueUSD. Todos los derechos reservados.",
        Back_desc: "Volver arriba"
      },
      project_desc: {
        Pool_desc: "Fondo",
        participating_desc: "Al participar en tokens, recibirá",
        rewards_desc: "como recompensa.",
        EndTime_desc: "Hora de finalización del proyecto",
        duration_desc: "Duración del staking",
        days_desc: "días",
        calculation_desc: "Cálculo periódico",
        Invite_desc: "Invitar",
        level_desc: "Comisión de nivel",
        withdrawal_desc: "Monto mínimo de retiro",
        supply_desc: "Monto mínimo de suministro",
        period_desc: "Periodo mínimo de retiro",
        Invitations_desc: "Nivel de invitación",
        People_desc: "Número de personas"
      },
      capitaInfoView_desc: {
        Please_desc: "Por favor, conecte la dirección",
        Pool_desc: "Fondo",
        receive_desc: "Al participar en tokens, recibirá",
        rewards_desc: "como recompensa.",
        level_desc: "Fondo de nivel",
        Expired_desc: "Caducado",
        Unexpired_desc: "No caducado",
        Participated_desc: "Participado",
        uncommitted_desc: "No comprometido",
        Withdraw_desc: "Retirar",
        Redeem_desc: "Canjear",
        Status_desc: "Estado",
        Principal_desc: "Principal",
        Redeemed_desc: "Canjeado",
        subaccounts_desc: "Subcuentas",
        Supplied_desc: "Suministrado",
        Redeemable_desc: "Canjeable",
        Commission_desc: "Comisión",
        Available_desc: "Disponible para retiro",
        Withdrawed_desc: "Retirado",
        Lock_desc: "Comisión bloqueada",
        All_desc: "Todas las comisiones",
        interest_desc: "Intereses",
        Settled_desc: "Liquidado",
        Loading_desc: "Cargando",
        amount_desc: "Monto disponible para retiro"
      },
      elModal_desc: {
        Please_desc: "Ingrese el monto del depósito",
        Recharge_desc: "Recargar",
        Cancel_desc: "Cancelar",
        withdrawal_desc: "Ingrese el monto del retiro",
        Withdraw_desc: "Retirar",
        redemption_desc: "Ingrese el monto del canje",
        Redeem_desc: "Canjear",
        Withdrawable_desc: "Monto disponible para retiro (comisión + intereses):",
        balance_desc: "Saldo canjeable:",
        input_desc: "Ingrese el monto",
        Transaction_desc: "¡Transacción exitosa!",
        failed_desc: "Transacción fallida",
        funds_desc: "Fondos insuficientes en la cuenta",
        Loading_desc: "Cargando"
      },
    modal: {
        hold_desc: 'Si tienes',
        Smart_desc: 'de BNB Smart Chain, obtendrás una recompensa adicional,',
        Rewards_desc: 'Las recompensas solo están disponibles para cantidades superiores a',
        address_desc: 'La dirección tiene',
        hours_desc: 'durante 24 horas, y el mínimo de retención se basa en la captura aleatoria del sistema. El monto se asigna una vez cada 24 horas. La asignación real a la cuenta prevalecerá.'
    },
    UserInfo: {
        Wallets_desc: 'Carteras',
        Reward_desc: 'Recompensa',
        half_month: '15 días',
        one_month: '1 mes',
        three_month: '3 meses',
        Invitees: 'Invitados',
        no_data: 'No hay datos',
        walletNo_support: 'Tu billetera no es compatible. Por favor, usa otra aplicación de billetera.',
        network_wrong: 'Por favor, selecciona la red correcta',
      },
}
export default en_ES

// Datajson:{
//     language_json:{
//         zh_json:'Chino simplificado',
//         zh_FT_json: 'Chino tradicional',
//         eh_json:'Inglés',
//         ja_JP_json:'Japonés',  
//         ko_KR_json:'Coreano' ,
//         en_TH_json:'Tailandés',
//         fr_FR_json:'Francés',
//         ru_RU_json:'Ruso',
//         en_IN_json:'Hindi',
//         en_ID_json:'Indonesio',
//         en_BS_json:'Persa',
//         en_YN_json:'Vietnamita',
//         en_ALB_json:'árabe',
//         en_TEQ_json:'Turco',
//         en_ES_json:'Español',
//         en_PTY_json:'Portugués',
//         en_YDL_json:'Italianos'
//     },
// },