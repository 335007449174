//法语
const fr_FR = {
    Datajson: {
        language_json: {
            zh_json: '中文简体',
            zh_FT_json: '中文繁體',
            eh_json: 'English',
            ja_JP_json: '日本語',
            ko_KR_json: '한국어',
            en_TH_json: 'ภาษาไทย',
            fr_FR_json: 'En français',
            ru_RU_json: 'русск',
            en_IN_json: 'हिंदीName',
            en_ID_json: 'Bahasa indonesia',
            en_BS_json: 'فارسی',
            en_YN_json: 'Việt nam',
            en_ALB_json: ' بالعربية ',
            en_TEQ_json: 'Türkçe',
            en_ES_json: 'Español',
            en_PTY_json: 'Português',
            en_YDL_json: 'Italiano'
        },
    },
    home: {
        language: 'langue',
        fduse_desc: 'FDUSD est une stablecoin adossée au dollar américain à un taux de change de 1:1.',
        introduce_desc: 'Révolutionnez la finance mondiale avec une stablecoin numérique de pointe, offrant aux entreprises et aux développeurs une sécurité, une rapidité et une innovation sans précédent dans le monde entier.',
        getfduse_desc: 'Obtenez FDUSD',
        stability_desc: 'Stabilité et innovation',
        experience_desc: "Profitez de vitesses ultra-rapides et de frais minimes. Façonnez l'avenir de la finance avec notre solution innovante et fiable.",
        whitepaper_desc: 'Livre blanc',
        redeemable_desc: 'Échangeable 1:1 contre des dollars américains',
        dollars_desc: 'FDUSD est conçu pour être entièrement adossé à des espèces et à des actifs équivalents en espèces. Les jetons peuvent être échangés 1:1 contre des dollars américains.',
        backed_desc: 'Adossé à des actifs entièrement réservés',
        deserved_desc: 'Les actifs réservés sont détenus dans des structures de détention entièrement séparées et protégées contre les faillites.',
        programmable_desc: 'Entièrement programmable',
        innovative_desc: "Avec notre solution de stablecoin innovante - soutenue par une technologie de pointe, nous visons à fournir une alternative sûre et fiable qui apporte la tranquillité d'esprit et qui a un impact positif dans le monde de la finance.",
        settle_desc: 'Règlement en quelques minutes',
        nearZero_desc: 'Coût proche de zéro',
        always_desc: 'Toujours actif, 24/7',
        fully_desc: "Entièrement programmable",
        rewrite_desc: "Réécrivez l'avenir avec First Digital Labs",
        combining_desc: 'En combinant stabilité et innovation chez First Digital Labs, nous nous engageons à gagner la confiance en offrant une transparence, une conformité et une sécurité inébranlables soutenues par une technologie de pointe.',
        mission_desc: "Nous avons pour mission de propulser l'industrie financière dans une nouvelle ère de la finance et de remodeler le paysage financier mondial.",
        should_desc: 'Pourquoi devriez-vous utiliser le First Digital USD?',
        collateral_desc: "Les garanties sont protégées par un dépositaire de premier ordre qualifié disposant d'une licence de fiducie pour une protection transparente des actifs et une conformité réglementaire.",
        transparent_desc: 'Transparent',
        independent_desc: "Attestation indépendante - les garanties sont validées par un tiers indépendant, garantissant l'intégrité des réserves et des rapports financiers.",
        examined_by_desc: 'Examiné par',
        attestation_Reports_desc: "Rapports d'attestation",
        January2024_desc: 'Janvier 2024',
        Report_desc: 'Rapport',
        December_desc: 'Décembre 2023',
        November_desc: 'Novembre 2023',
        Networks_desc: 'Quels réseaux sont pris en charge?',
        available_desc: 'FDUSD est disponible sur',
        Ethereum_desc: 'Ethereum',
        and_desc: 'et',
        BNB_Chain_desc: 'BNB Chain',
        planned_desc: 'avec une prise en charge prévue pour un nombre croissant de blockchains.',
        Audited_desc: 'Vérifié par',
        Frequently_desc: 'Questions fréquemment posées',
        How_FDUSD_desc: 'Comment puis-je obtenir FDUSD?',
        Acquiring_desc: "L'acquisition de FDUSD peut être réalisée de plusieurs manières. Si vous êtes un acteur clé de l'industrie, un intermédiaire financier ou un investisseur professionnel d'un certain statut et que vous répondez aux critères requis, vous pouvez acheter directement FDUSD auprès de First Digital Labs. Veuillez noter que First Digital Labs ne vend pas directement de jetons aux clients de détail.:",
        canstill_desc: "Vous pouvez toujours obtenir FDUSD par le biais de marchés secondaires sans devenir client de First Digital Labs. De nombreuses principales plateformes d'échange de cryptomonnaies répertorient FDUSD et offrent une liquidité importante. Cela offre un moyen accessible et pratique aux personnes intéressées d'acquérir FDUSD. Il est cependant essentiel de mener des recherches approfondies et de garantir la crédibilité de l'échange que vous utilisez.",
        equivalent_desc: 'Comment puis-je avoir confiance que FDUSD sera toujours équivalent à un dollar?',
      //  stablecoin_desc: "Votre confiance dans la valeur stable de FDUSD est bien fondée. Chaque FDUSD est censé être entièrement adossé à un dollar américain ou à un actif de valeur équivalente. Ces actifs sont en sécurité chez un dépositaire qualifié dans des comptes auprès d'institutions de dépôt réglementées, ce qui garantit une base solide pour la valeur de la pièce. C'est ce système qui confère à FDUSD sa stabilité intrinsèque et vous permet de faire confiance à sa valeur équivalente au dollar américain.",
        redeem_FDUSD: 'Comment puis-je échanger FDUSD?',
       // stablecoin_desc: "Pour échanger votre stablecoin FDUSD, vous devez d'abord devenir client de First Digital Labs et remplir des critères spécifiques, notamment des vérifications de lutte contre le blanchiment d'argent (AML) et de financement du terrorisme (FTC). Après avoir réussi ces vérifications, vous pouvez échanger vos FDUSD contre l'équivalent en monnaie fiduciaire, ce qui le retire de la circulation. Alternativement, vous pouvez vendre vos jetons FDUSD sur le marché secondaire via une plateforme d'échange de cryptomonnaies ou un fournisseur de gré à gré (OTC) qui prend en charge les stablecoins FD121. Veuillez prendre connaissance de toutes les réglementations légales et financières de votre juridiction avant de procéder.",
        Explore_desc: "Explorez notre écosystème",
        Experience_desc: "Faites l'expérience de la stabilité avec notre solution de stablecoin fiable.",
        slide_desc: 'diapositive',
        _1to6_desc: '1 à 6',
        of7_desc: 'sur 7',
        Transparency_desc: 'Transparence',
        Legal_desc: 'Légal',
        Compliance_desc: 'Conformité',
        Policy_desc: 'Politique de confidentialité',
        Company_desc: 'Société',
        About_Us_desc: 'À propos de nous',
        Social_Media_desc: 'Réseaux sociaux',
        Twitter_desc: 'Twitter',
        Disclosures_desc: 'Divulgations importantes:',
        Limited_desc: 'FD121 Limited est une société de technologie financière, pas une banque. Les services de garde des réserves sont fournis par ',
        First_desc: "First Digital Trust Limited",
        product_desc: "Tous les noms de produits et d'entreprises sont des marques de commerce™ ou des marques déposées® de leurs détenteurs respectifs. Tous les noms de sociétés, de produits et de services utilisés sur ce site Web sont uniquement à des fins d'identification. L'utilisation de ces noms, marques de commerce et marques ne sous-entend aucune affiliation ou approbation de leur part. ",
        additional_desc: 'Pour des risques, divulgations et informations importants supplémentaires, veuillez visiter',
        Copyright_desc: 'Copyright © 2024 FD121 Limited. Tous droits réservés.'
    },
    newhome_json: {
        Exchanges_json: "Bourses",
        DeFi_json: "Finance décentralisée",
        Payments_json: "Paiements",
        Accessibility_json: "Accessibilité",
        listed_json: "TUSD est coté sur plusieurs bourses leader de l'industrie, offrant liquidité et accessibilité à tous les investisseurs.",
        stablecoin_json: "En tant que stablecoin largement utilisé, TUSD a été adopté de manière significative dans divers protocoles de finance décentralisée (DeFi), y compris les teneurs de marché automatisés (AMM), les plateformes de prêt et les projets de yield farming (YIF).",
        leading_json: "En tant que principal stablecoin en yuan, TUSD est un moyen idéal pour les transactions transfrontalières. Avec les faibles frais de transaction et la vitesse instantanée de TUSD, les particuliers et les institutions peuvent effectuer des transactions sans les défis financiers traditionnels.",
        available_json: "TUSD est également disponible sur les principales blockchains telles qu'Ethereum, BNB Smart Chain, TRON et Avalanche. Le réseau de ponts de TUSD comprend Polygon, Fantom, Arbitrum, Cronos et plus encore.",
        error1_json: "Impossible de se connecter au nœud. Essayez de changer de nœud et réessayez.",
        error2_json: "Erreur de chaîne",
        error3s_json: "Veuillez connecter votre adresse",
        Loading1_json: "Chargement en cours",
        Loading2_json: "Chargement des informations du projet",
        error3_json: "Erreur réseau",
        error4_json: "Connexion au portefeuille refusée",
        error5_json: "Ancienne version du portefeuille",
        error6_json: "Connexion au portefeuille refusée",
        Loading3_json: "Connexion en cours",
        error7_json: "Erreur de compte",
        error8_json: "Impossible de se connecter au nœud. Essayez de changer de nœud et réessayez.",
        error9_json: "Erreur réseau",
        Loading4_json: "Mise en gage en cours",
        error10_json: "Vous avez été bloqué",
        error11_json: "Erreur de projet",
        error12_json: "Gaz insuffisant",
        success1_json: "Succès",
        error13_json: "Échec de récupération du compte",
        error14_json: "Erreur inconnue",
        error15_json: "Impossible de se connecter au nœud. Essayez de changer de nœud et réessayez.",
       
      },
      newhome_desc: {
        title_desc: "Une monnaie numérique conçue pour le nouveau système de paiement mondial",
        desc_desc: "TrueUSD est le premier stablecoin adossé au dollar à effectuer des audits quotidiens de ses réserves par une institution tierce indépendante.",
        Started_desc: "Commencer",
        Contact_desc: "Contactez-nous",
        Circulating_desc: "Fourniture en circulation",
        Trading_desc: "Volume de trading",
        Transaction_desc: "Nombre total de transactions",
        Support_desc1: "Total des chaînes supportées",
        Trustworthy_desc: "Fiable",
        utilizes_desc: "TUSD utilise des mécanismes avancés d'audit et de certification pour améliorer la transparence. Ses rapports d'audit quotidiens sont disponibles sur le site Web, permettant aux utilisateurs de surveiller les réserves à tout moment.",
        Popularity_desc: "Popularité",
        convenience_desc: "TUSD offre commodité et utilité dans divers scénarios financiers tels que les transactions et les transferts. Actuellement, TUSD est disponible sur plus de 80 bourses de cryptomonnaies et protocoles DeFi, offrant flexibilité et accessibilité aux utilisateurs.",
        Liquidity_desc: "Liquidité",
        During_desc: "Au troisième trimestre de 2023, le volume de transactions quotidien de TUSD a dépassé 1 milliard de dollars, montrant une liquidité impressionnante. De plus, TUSD ne facture aucun frais pour le minting et le rachat.",
        Trusted_desc: "Fiable par les leaders mondiaux",
        Using_desc: "Utiliser TUSD",
        LearnMore_desc: "En savoir plus",
        Goes_desc: "TUSD se dirige vers le multi-chaînes",
        Natively_desc1: "Réseau de déploiement natif",
        Ethereum_desc: "Ethereum",
        TRON_desc: "TRON",
        Avalanche_desc: "Avalanche",
        Smart_desc: "BNB Smart Chain",
        Bridged_desc: "Réseau de pont",
        Fantom_desc: "Fantom",
        Arbitrum_desc: "Arbitrum",
        Cronos_desc: "Cronos",
        Optimism_desc: "Optimism",
        Aurora_desc: "Aurora",
        FAQs_desc: "Questions fréquentes",
        TrueUSD_desc: "Q1. Qu'est-ce que TrueUSD ?",
        Banking_desc: "TrueUSD utilise des institutions financières mondiales et une infrastructure blockchain robuste. C'est le premier stablecoin à utiliser des contrats intelligents pour le minting et le rachat, avec une vérification en temps réel de la couverture des réserves sur la chaîne, soutenue par la société de comptabilité mondiale Moore Hong Kong et Chainlink Proof of Reserve.",
        started_desc: "Q2. Comment commencer à utiliser TrueUSD ?",
        stepsbelow_desc: "Pour commencer à utiliser TrueUSD, suivez les étapes ci-dessous :",
        verify_desc: "Après avoir créé et vérifié votre compte via le site Web de TrueUSD, vous recevrez un e-mail de",
        website_desc: "Site Web de TrueUSD",
       
        verification_desc: "Vérifiez via le lien de vérification. Cliquez sur ce lien pour vérifier votre adresse e-mail, puis configurez",
        information_desc: "Remplissez les informations sur vous-même et/ou votre organisation. Pour une liste des documents requis, cliquez sur",
        here_desc: "ici",
        application_desc: "Soumettez votre candidature. Nous examinerons votre demande.",
        Redeem_desc: "Minting et rachat de notre stablecoin sans frais en un clic",
        Trade_desc: "Échangez TrueUSD sur plus de 160 marchés dans plusieurs bourses et plus de 20 bureaux OTC sur 5 continents",
        Send_desc: "Envoyez TrueUSD à n'importe qui, n'importe où, à tout moment pour un coût 100 fois inférieur et une vitesse 1000 fois supérieure à celle du virement bancaire",
        mint_desc: "Q3. Comment mint et racheter TrueUSD ?",
        partner_desc1: "Envoyez un virement bancaire à nos partenaires bancaires en utilisant les informations affichées dans",
        Trueapp_desc: "Application TrueUSD",
        Please_desc: "Remarque : nous n'acceptons actuellement pas les transferts ACH ou d'autres services de transfert de devises numériques tels que Venmo, TransferWise, PayPal, Revolut, etc.",
        account_desc: "Le nom sur votre compte bancaire doit correspondre au nom sur votre compte TrueUSD.",
        creating_desc: "Après avoir créé votre compte, vous recevrez un e-mail de",
        generally_desc: "En général, les virements bancaires entre les banques prennent généralement 1 à 5 jours ouvrables.",
        email_desc: "Une fois que nous aurons reçu votre virement, nous vous enverrons un e-mail de confirmation.",
        minted_desc: "Une fois que nous aurons reçu vos fonds, TrueUSD sera minté et transféré à l'adresse de votre portefeuille fournie dans l'application dans un délai d'un jour ouvrable.",
        redeem4_desc: "Q4. Comment racheter TrueUSD ?",
        Visit_desc: "Visitez",
        Polygon_desc1: "Application TrueUSD",
        receive_desc: "pour entrer vos informations bancaires et obtenir votre adresse de rachat unique. Les transferts de devises fiduciaires sont soumis aux exigences KYC et AML de nos partenaires bancaires.",
        redemption_desc: "Cette adresse de rachat est unique pour chaque utilisateur de TrueUSD. Ne partagez pas votre adresse de rachat unique avec d'autres.",
        simple_desc: "Pourquoi mon adresse de rachat est-elle si simple ?",
        notice_desc: "Vous remarquerez que cette adresse est très simple, avec de nombreux '0'. C'est intentionnel. Vous pouvez racheter depuis n'importe quel portefeuille (y compris les portefeuilles d'échange) à cette adresse, et tous les rachats reçus à cette adresse seront liés à votre compte TrueUSD.",
        unique_desc: "Envoyez TrueUSD à votre adresse de rachat unique (minimum $1,000).",
        partner_desc: "Une fois que les jetons ont été envoyés avec succès à votre adresse de rachat, nos partenaires bancaires émettront généralement un virement bancaire à votre compte bancaire dans un délai d'un jour ouvrable.",
        exchanges_desc: "Q5. Sur quelles bourses TrueUSD (TUSD) est-il coté ?",
        Binance_desc: "TrueUSD est actuellement coté sur plusieurs bourses, y compris Binance, Bittrex et Upbit.",
        here2_desc: "ici",
        markets_desc: "pour obtenir la liste complète des bourses et des marchés actifs de TrueUSD.",
        contract_desc: "Q6. Quelle est l'adresse du contrat intelligent de TrueUSD ?",
        tokenSmart_desc: "Nos contrats intelligents peuvent être trouvés via le réseau de déploiement natif de TUSD et le réseau de pont de TUSD ou le navigateur Binance Chain. Les liens sont ci-dessous. Vous pouvez également en savoir plus sur nos contrats intelligents et les mises à jour des contrats intelligents sur notre",
        blog_desc: "blog",
        related_desc: ".",
        Natively_desc: "Réseau de déploiement natif de TUSD",
        Ethererum2_desc: "Ethereum",
        SmartChain_desc: "BNB Smart Chain :",
       
        Avalanche2_desc: "Avalanche",
        Beacon_desc: "BNB Beacon Chain :",
        Networks2_desc: "Réseau de pont de TUSD",
        Polygon2_desc: "Polygon",
        Fantom2_desc: "Fantom",
       
        Polygon_desc2: "Optimism",
        ChainOld_desc: "BNB Smart Chain (ancien) :",
        AboutMore_desc: "En savoir plus sur TUSD",
        Digital_desc: "Monnaie numérique pour le nouveau système de paiement mondial",
        first_desc: "TrueUSD est le premier stablecoin adossé au dollar à effectuer des vérifications quotidiennes des réserves par une institution tierce indépendante.",
        Polygon_desc: "Polygon",
        Follow_desc: "Suivez-nous",
        Resources_desc: "Ressources",
        Stablecoin_desc: "TrueUSD devient le premier stablecoin soutenu par le dollar à garantir le minting grâce à la 'Preuve de Réserves'",
        Engages_desc: "TUSD collabore avec la société de comptabilité leader MooreHK pour fournir des services de vérification de ses réserves fiduciaires",
        Becomes_desc: "TUSD (TRC-20) devient la monnaie légale en Dominique",
        Blog_desc: "Blog",
        Support_desc: "Support",
        TermsOf_desc: "Conditions d'utilisation",
        Privacy_desc: "Politique de confidentialité",
        TrueUsd_desc: "TrueUSD",
        Home_desc: "Accueil",
        Transparency_desc: "Transparence",
        Ecosystem_desc: "Écosystème",
        About_desc: "À propos",
        Supported_desc: "Chaînes supportées",
        Other_desc: "Autres chaînes",
        rights_desc: "© 2023 TrueUSD. Tous droits réservés.",
        Back_desc: "Retour en haut"
      },
      project_desc: {
        Pool_desc: "Pool de fonds",
        participating_desc: "En participant avec des jetons, vous recevrez",
        rewards_desc: "en récompense.",
        EndTime_desc: "Heure de fin du projet",
        duration_desc: "Durée de staking",
        days_desc: "jours",
        calculation_desc: "Calcul périodique",
        Invite_desc: "Inviter",
        level_desc: "Commission de niveau",
        withdrawal_desc: "Montant minimum de retrait",
        supply_desc: "Montant minimum de fourniture",
        period_desc: "Période minimum de retrait",
        Invitations_desc: "Niveau d'invitations",
        People_desc: "Nombre de personnes"
      },
      capitaInfoView_desc: {
        Please_desc: "Veuillez connecter l'adresse",
        Pool_desc: "Pool de fonds",
        receive_desc: "En participant avec des jetons, vous recevrez",
        rewards_desc: "en récompense.",
        level_desc: "Pool de fonds de niveau",
        Expired_desc: "Expiré",
        Unexpired_desc: "Non expiré",
        Participated_desc: "Participé",
        uncommitted_desc: "Non engagé",
        Withdraw_desc: "Retirer",
        Redeem_desc: "Racheter",
        Status_desc: "Statut",
        Principal_desc: "Principal",
        Redeemed_desc: "Racheté",
        subaccounts_desc: "Sous-comptes",
        Supplied_desc: "Fourni",
        Redeemable_desc: "Rachetable",
        Commission_desc: "Commission",
        Available_desc: "Disponible pour retrait",
        Withdrawed_desc: "Retiré",
        Lock_desc: "Commission verrouillée",
        All_desc: "Toutes les commissions",
        interest_desc: "Intérêt",
        Settled_desc: "Réglé",
        Loading_desc: "Chargement en cours",
        amount_desc: "Montant disponible pour retrait"
      },
      elModal_desc: {
        Please_desc: "Veuillez entrer le montant du dépôt",
        Recharge_desc: "Recharger",
        Cancel_desc: "Annuler",
        withdrawal_desc: "Veuillez entrer le montant du retrait",
        Withdraw_desc: "Retirer",
        redemption_desc: "Veuillez entrer le montant du rachat",
        Redeem_desc: "Racheter",
        Withdrawable_desc: "Montant disponible pour retrait (commission + intérêt) :",
        balance_desc: "Solde rachetable :",
        input_desc: "Veuillez entrer le montant",
        Transaction_desc: "Transaction réussie !",
        failed_desc: "Échec de la transaction",
        funds_desc: "Fonds insuffisants",
        Loading_desc: "Chargement en cours"
      },
    modal: {
        hold_desc: 'Si vous détenez',
        Smart_desc: 'du BNB Smart Chain, vous recevrez une récompense bonus,',
        Rewards_desc: 'Les récompenses ne sont disponibles que pour les montants supérieurs à',
        address_desc: "L'adresse détient",
        hours_desc: "pendant 24 heures, et la détention minimale est basée sur le cliché aléatoire du système. Le montant est attribué une fois toutes les 24 heures. L'allocation réelle sur le compte prévaudra.",
    },
    UserInfo: {
        Wallets_desc: 'Portefeuilles',
        Reward_desc: 'Récompense',
        half_month: '15 jours',
        one_month: '1 mois',
        three_month: '3 mois',
        Invitees: 'Invités',
        no_data: 'Aucune donnée',
        walletNo_support: 'Votre portefeuille n\'est pas pris en charge. Veuillez utiliser une autre application de portefeuille.',
        network_wrong: 'Veuillez sélectionner le réseau correct',
      },
}
export default fr_FR

// Datajson:{
//     language_json:{
//         zh_json:'Le chinois simplifié',
//         zh_FT_json: 'Chinois traditionnel',
//         eh_json:'En anglais',
//         ja_JP_json:'japonais',  
//         ko_KR_json:'coréen' ,
//         en_TH_json:'Le thaï',
//         fr_FR_json:'En français',
//         ru_RU_json:'Le russe',
//         en_IN_json:'Langue hindi',
//         en_ID_json:'Langue indonésienne',
//         en_BS_json:'Persan persan',
//         en_YN_json:'Langue vietnamienne',
//         en_ALB_json:'Langue arabe',
//         en_TEQ_json:'La langue turque',
//         en_ES_json:'Langue espagnole',
//         en_PTY_json:'En portugais',
//         en_YDL_json:'Langue italienne'
//     },
// },