//英语
const en = {
  Datajson: {
    language_json: {
      zh_json: "中文简体",
      zh_FT_json: "中文繁體",
      eh_json: "English",
      ja_JP_json: "日本語",
      ko_KR_json: "한국어",
      en_TH_json: "ภาษาไทย",
      fr_FR_json: "En français",
      ru_RU_json: "русск",
      en_IN_json: "हिंदीName",
      en_ID_json: "Bahasa indonesia",
      en_BS_json: "فارسی",
      en_YN_json: "Việt nam",
      en_ALB_json: " بالعربية ",
      en_TEQ_json: "Türkçe",
      en_ES_json: "Español",
      en_PTY_json: "Português",
      en_YDL_json: "Italiano",
    },
  },
  language_join: {
    language_join: "English",
  },
  home: {
    language: "language",
    fduse_desc: "FDUSD is a 1:1 USD-backed Stablecoin.",
    introduce_desc:
      "Revolutionize global finance with a cutting-edge digital stablecoin, providing businesses and builders with security, speed, and innovation worldwide.",
    getfduse_desc: "Get FDUSD",
    stability_desc: "Stability Meets Innovation",
    experience_desc:
      "Experience lightning-fast speeds and minimal costs. Shape the future of finance withour innovative and trusted solution.",
    whitepaper_desc: "Whitepaper",
    redeemable_desc: "Redeemable 1:1 for U.S. dollars",
    dollars_desc:
      "FDUSD is intended to be a fully backed by cash and cash equivalent assets. Tokens are intended to be redeemable 1:1 for U.S. dollars.",
    backed_desc: "Backed by fully reserved assets",
    deserved_desc:
      "Reserved assets are held in fully segregated bankruptcy-remote holding structures.",
    programmable_desc: "Fully Programmable",
    innovative_desc:
      "With our innovative stablecoin solution - backed by advanced technology, we aim to provide a safe and reliable alternative that brings peace of mind, making a positive impact in the world of finance.",
    settle_desc: "Settle in minutes",
    nearZero_desc: "Near-zero Cost",
    always_desc: "Always-on, 24/7",
    fully_desc: "Fully Programmable",
    rewrite_desc: "Rewrite Future with First Digital Labs",
    combining_desc:
      "Combining stability with innovation at First Digital Labs, we are committed to gaining trust by offering unwavering transparency, compliance and security backed by cutting-edge technology.",
    mission_desc:
      "We are on a mission to propel the financial industry into a new era of finance and reshape the global financial landscape.",
    should_desc: "Why Should You Use First Digital USD?",
    collateral_desc:
      "Collateral is safeguarded by a top-tier, qualified custodian with a trust license for seamless asset protection and regulatory compliance.",
    transparent_desc: "Transparent",
    independent_desc:
      "Independent attestation - the collateral is validated by an independent third-party, ensuring the integrity of the reserves and financial reporting.",
    examined_by_desc: "Examined by",
    attestation_Reports_desc: "Attestation Reports",
    January2024_desc: "January 2024",
    Report_desc: "Report",
    December_desc: "December 2023",
    November_desc: "November 2023",
    Networks_desc: "Which Networks Are Supported?",
    available_desc: "FDUSD is available on",
    Ethereum_desc: "Ethereum",
    and_desc: "and",
    BNB_Chain_desc: "BNB Chain",
    planned_desc:
      "with planned support for an increasing number of blockchains.",
    Audited_desc: "Audited by",
    Frequently_desc: "Frequently Asked Questions",
    How_FDUSD_desc: "How can I get FDUSD?",
    Acquiring_desc:
      "Acquiring FDUSD can be achieved in a couple of ways. If you are a key industry player, a financial intermediary, or a professional investor of a certain stature and meet the requisite criteria, you can directly purchase FDUSD from First Digital Labs. Please note that First Digital Labs does not sell tokens directly to retail customers.:",
    canstill_desc:
      "You can still obtain FDUSD through secondary markets without becoming a client of First Digital Labs. Many leading cryptocurrency exchanges list FDUSD and offer substantial liquidity. This provides an accessible and convenient way for interested individuals to acquire FDUSD. It" +
      "s always essential, however, to conduct thorough research and ensure the credibility of the exchange youre using.",
    equivalent_desc:
      "How can I trust that FDUSD will always be equivalent to a dollar?",
    // stablecoin_desc:
    //   "Your trust in the FDUSD stablecoin value is well-grounded. Each FDUSD is intended to be fully backed by one US dollar or an asset of equivalent fair value. These assets are safely held by aqualified custodian in accounts with regulated depository institutions, providing a solidfoundation for the coin" +
    //   "s value. This system is what provides FDUSD with its inherent stabilityand why you can trust its equivalent value to the US dollar.",
    redeem_FDUSD: "How can I redeem FDUSD?",
    // stablecoin_desc:
    //   "To redeem your FDUSD stablecoin, you must first become a client of First Digital Labs and meet specific requirements, including Anti-Money Laundering (AML) and Counter-Terrorism Financing (CTF) checks. Upon successful completion of these checks, you can exchange your FDUSD for its equivalent in fiat currency, thereby taking it out of circulation. Alternatively, you can sell your FDUSD tokens on the secondary market through a cryptocurrency exchange or an Over-the-Counter (OTC) provider that supports FD121" +
    //   "s stablecoins. Please be aware of all legal and financial regulations in your jurisdiction before proceeding.",
    Explore_desc: "Explore our Ecosystem",
    Experience_desc:
      "Experience the stability with our reliable stablecoin solution.",
    slide_desc: "slide",
    _1to6_desc: "1 to 6",
    of7_desc: "of 7",
    Transparency_desc: "Transparency",
    Legal_desc: "Legal",
    Compliance_desc: "Compliance",
    Policy_desc: "Privacy Policy",
    Company_desc: "Company",
    About_Us_desc: "About Us",
    Social_Media_desc: "Social Media",
    Twitter_desc: "Twitter",
    Disclosures_desc: "Important Disclosures:",
    Limited_desc:
      "FD121 Limited is a financial technology company, not a bank. Custody services of the reserves are provided by ",
    First_desc: "First Digital Trust Limited",
    product_desc:
      "All product and company names are trademarks™ or registered® trademarks of their respective holders. All company, product and service names used in this website are for identification purposes only. Use of these names, trademarks and brands does not imply any affiliation with or endorsement by them.",
    additional_desc:
      "For additional important risks, disclosures, and information, please visit",
    Copyright_desc: "Copyright © 2024 FD121 Limited. All Rights Reserved.",
  },
  newhome_json: {
    Exchanges_json: "Exchanges",
    DeFi_json: "DeFi",
    Payments_json: "Payments",
    Accessibility_json: "Accessibility",
    listed_json:
      "TUSD is listed on multiple industry-leading exchanges, providing liquidity and accessibility to all investors worldwide.",
    stablecoin_json:
      "As a widely-used stablecoin, TUSD has gained significant adoption across various DeFi protocols, encompassing Automated Market Makers (AMM), lending platforms, and Yield Farming (YIF) projects. ",
    leading_json:
      "As a leading USD-pegged stablecoin,TUSD is the ideal medium for cross-border transactions. With TUSD’s low transaction fees and instantaneous speed, individuals and institutions alike can trade without the challenges associated with traditional finance.",
    available_json:
      "TUSD is also available on the top leading blockchains such as Ethereum, BNB Smart Chain, TRON, and Avalanche. Some of TUSD’s bridged networks include Polygon, Fantom, Arbitrum, Cronos, and more.",
    error1_json: "The node cannot be connected. Replace the node and try again",
    error2_json: "Chain error",
    error3s_json: "Please connect your address",
    Loading1_json: "Loading",
    Loading2_json: "Loading project information",
    error3_json: "network error",
    error4_json: "wallet connect deny",
    error5_json: "old wallet version",
    error6_json: "wallet_connect_deny",
    Loading3_json: "Login loading",
    error7_json: "account error",
    error8_json: "The node cannot be connected. Replace the node and try again",
    error9_json: "network wrong",
    Loading4_json: "Stake loading",
    error10_json: "You are blocked",
    error11_json: "project error",
    error12_json: "no enough gas",
    success1_json: "success",
    error13_json: "Account acquisition failed",
    error14_json: "unknown error",
    error15_json:
      "The node cannot be connected. Replace the node and try again",
  },
  newhome_desc: {
    title_desc: "Digital Currency Built for the New Global Payment System",
    desc_desc:
      "TrueUSD is the first USD-pegged stablecoin to deploy dailyattestations for its underlying reserves by independent third-party institutions.",
    Started_desc: "Get Started",
    Contact_desc: "Contact Us",
    Circulating_desc: "Circulating Supply",
    Trading_desc: "Trading Volume",

    Transaction_desc: "Total Transaction Counts",
    Support_desc1: "Total Support Chains",
    Trustworthy_desc: "Trustworthy",
    utilizes_desc:
      " TUSD utilizes advanced auditing and attestation mechanisms to enhance the transparency. Its daily audit reports are available on the website, allowing users to monitor the reserves at any time.",
    Popularity_desc: "Popularity",
    convenience_desc:
      "TUSD offers convenience and utility in various financial contexts, such as trading and transfers. Currently, TUSD is available on over 80 cryptocurrency exchanges and DeFi protocols, providing users with flexibility and accessibility.",
    Liquidity_desc: "Liquidity",
    During_desc:
      " During Q3 2023, TUSD recorded a daily trading volume surpassing $1 billion, underlying its impressive liquidity. Additionally, TUSD does not charge any fees for its minting and redemption.",
    Trusted_desc: "Trusted By Global Leaders",
    Using_desc: "Using TUSD",
    LearnMore_desc: "Learn more",
    Goes_desc: "TUSD Goes Multi-Chain",
    Natively_desc1: "Natively Deployed Networks",
    Ethereum_desc: "Ethereum",
    TRON_desc: "TRON",
    Avalanche_desc: "Avalanche",

    Smart_desc: "BNB Smart Chain",
    Bridged_desc: "Bridged Networks",
    Fantom_desc: "Fantom",
    Arbitrum_desc: "Arbitrum",
    Cronos_desc: "Cronos",
    Optimism_desc: "Optimism",
    Aurora_desc: "Aurora",
    FAQs_desc: "FAQs",
    TrueUSD_desc: "Q1.What is TrueUSD?",
    Banking_desc:
      " Banking on a well-established global network of financial    institutions and robust blockchain infrastructure, TUSD is the    first stablecoin to utilize smart contracts for    minting/redemption via real time on-chain verification of   underlying reserve coverage, supported by Moore Hong Kong, a   global leading accounting firm, and Chainlink Proof of  Reserve.",
    started_desc: "Q2. How to get started with TrueUSD?",
    stepsbelow_desc: "To get started with TrueUSD, follow the steps below:",
    verify_desc: "Create and verify your account via the",
    website_desc: "TrueUSD website",
    creating_desc: " After creating your account, you'll receive an email from",
    verification_desc:
      "with a verification link. Click this link to  verify your email address and then set up",
    information_desc:
      "Fill in information about yourself and/or your   organization. For a list of the documents   required, please click",
    here_desc: "here",
    application_desc:
      "Submit your application. We will review your  application.",
    Redeem_desc:
      " Mint and Redeem our stablecoins with one-click and  without fees",
    Trade_desc:
      "Trade TrueUSD on , multiple exchanges 160+   markets, and 20+ OTC desks across 5 continents",
    Send_desc:
      " Send TrueUSD to anyone, anytime, anywhere, up to   100x cheaper and 1000x faster than a wire transfer",
    mint_desc: "Q3. How do I mint and redeem TrueUSD?",
    partner_desc1:
      " Send a wire to our banking partner, using the mint  instructions displayed in the",
    Trueapp_desc: "TrueUSD app",
    Please_desc:
      " Please note: We DO NOT currently accept ACH or other   digital currency transfer services, such as Venmo,    TransferWise, PayPal, Revolut, etc.",
    account_desc:
      " The name on your bank account must match the name on   your TrueUSD account.",
    generally_desc:
      " It generally takes anywhere from 1-5 business days for  a wire transfer to complete among banks.",
    email_desc:
      " We will send an email confirmation to you once we have    received your wire.",
    minted_desc:
      " TrueUSD is minted and transferred to the wallet  address provided in the app within 1 business day after we receive your funds.",
    redeem4_desc: "Q4. How do I redeem TrueUSD?",
    Visit_desc: " Visit the",
    Polygon_desc1: "TrueUSD app",
    receive_desc:
      "to input your bank info and receive your unique redemption  address. The fiat transfer is subject to our banking   partners’ KYC and AML requirements.",
    redemption_desc:
      " This redemption address is unique to each TrueUSD   user. Please do not share your unique redemption   address with anyone else.",
    simple_desc: "Why is my redemption address so simple?",

    notice_desc:
      "You will notice that this address is very simple   and contains many '0's. This is intentional. You can   redeem from any wallet (including exchange wallets) to   this address, and all redemptions received by this   address will be associated with your TrueUSD account.",
    unique_desc:
      " Send TrueUSD (minimum $1,000) to your unique redemption address.",
    partner_desc:
      " Our banking partner generally issues a wire to your bank  account within 1 business day after the tokens are   successfully sent to your redemption address.",
    exchanges_desc: " Q5. Which exchanges is TrueUSD (TUSD) listed on?",
    Binance_desc:
      " TrueUSD is currently listed on multiple exchanges,  including Binance, Bittrex, and Upbit.",
    here2_desc: "here",
    markets_desc: "for a full list of active TrueUSD exchanges and markets.",
    contract_desc: "Q6. What’s the smart contract address for TrueUSD?",
    tokenSmart_desc:
      " Our token smart contracts can be found through TUSD Natively   Deployed networks and TUSD Bridged Networks or Binance Chain   Explorer. Below are links. You can also read more about our   smart contracts and upgrades to our smart contract on our",
    blog_desc: "blog",
    related_desc:
      " ERC-20 smart contract address and TUSD related smart  contract links as follows:",
    Natively_desc: "TUSD Natively Deployed Networks",
    Ethererum2_desc: "Ethererum",
    SmartChain_desc: "BNB Smart Chain:",

    Avalanche2_desc: "Avalanche",
    Beacon_desc: " BNB Beacon Chain:",
    Networks2_desc: "TUSD Bridged Networks",
    Polygon2_desc: "Polygon",
    Fantom2_desc: "Fantom",

    Polygon_desc2: "Optimism",

    ChainOld_desc: "BNB Smart Chain (Old):",
    AboutMore_desc: "Learn More About TUSD",
    Digital_desc: "Digital Currency Built for the New Global Payment System",
    first_desc:
      "TrueUSD is the first USD-pegged stablecoin to deploy daily   attestations for its underlying reserves by independent   third-party institutions.",
    Polygon_desc: "Polygon",
    Follow_desc: "Follow Us",
    Resources_desc: "Resources",
    Stablecoin_desc:
      "TrueUSD Becomes First USD-Backed Stablecoin to Secure  Minting with “Proof of Reserves”",
    Engages_desc:
      "TUSD Engages Leading Accounting Firm MooreHK to Provide  Attestation Service on its Fiat Reserve",
    Becomes_desc: "TUSD (TRC-20) Becomes Legal Tender in Dominica",
    Blog_desc: "Blog",
    Support_desc: "Support",
    TermsOf_desc: "Terms Of Use",
    Privacy_desc: "Privacy Policy",
    TrueUsd_desc: "TrueUSD",
    Home_desc: "Home",
    Transparency_desc: "Transparency",
    Ecosystem_desc: "Ecosystem",

    About_desc: "About",
    Supported_desc: "Supported Chains",
    Other_desc: "Other Chains",
    rights_desc: "© 2023 TrueUSD. All rights reserved.",
    Back_desc: "Back to the top",
  },
  project_desc: {
    Pool_desc: "Pool",
    participating_desc: "as a participating token, you will receive",
    rewards_desc: "as rewards.",
    EndTime_desc: "Project End Time",
    duration_desc: "Pledge duration",
    days_desc: "days",
    calculation_desc: "Periodic calculation",
    Invite_desc: "Invite",
    level_desc: "level commission",

    withdrawal_desc: "Min withdrawal amount",
    supply_desc: "Min supply amount",

    period_desc: "Min withdrawal period",
    Invitations_desc: "level of Invitations",
    People_desc: "People",
  },
  capitaInfoView_desc: {
    Please_desc: "Please link the address",
    Pool_desc: "Pool",
    receive_desc: "as a participating token, you will receive",
    rewards_desc: "as rewards.",
    level_desc: "level pool",
    Expired_desc: "Expired",
    Unexpired_desc: "Unexpired",
    Participated_desc: "Participated",
    uncommitted_desc: "uncommitted",
    Withdraw_desc: "Withdraw",
    Redeem_desc: "Redeem",
    Status_desc: "Status",

    Principal_desc: "Principal",
    Redeemed_desc: "Redeemed",
    subaccounts_desc: "sub accounts",
    Supplied_desc: "Supplied",
    Redeemable_desc: "Redeemable",
    Commission_desc: "Commission",
    Available_desc: "Available withdrawn",
    Withdrawed_desc: "Withdrawed",
    Lock_desc: "Lock commission",
    All_desc: "All commissions",
    interest_desc: "interest",
    Settled_desc: "Settled",
    Loading_desc: "Loading",
    amount_desc: "Withdrawable amount",
  },
  elModal_desc: {
    Please_desc: "Please enter the recharge amount",
    Recharge_desc: "Recharge",
    Cancel_desc: "Cancel",
    withdrawal_desc: "Please enter the withdrawal amount",
    Withdraw_desc: "Withdraw",
    redemption_desc: "Please enter the redemption amount",
    Redeem_desc: "Redeem",
    Withdrawable_desc: "Withdrawable amount (commission+interest) :",
    balance_desc: "Redeemable balance:",
    input_desc: "Please enter the amount",
    Transaction_desc: "Transaction successful!",
    failed_desc: "Transaction failed",
    funds_desc: "Insufficient account funds",
    Loading_desc: "Loading",
  },
  modal: {
    hold_desc: "If you hold",
    Smart_desc: "of the BNB Smart Chain, you will have a bonus reward,",
    Rewards_desc: "Rewards are only available for amounts exceeding",
    address_desc: "The address holds",
    hours_desc:
      "for 24 hours, and the minimum holding is based on the random snapshot of the system. Amount is allocated, once every 24 hours。 The actual allocation to the account shall prevail。",
    copyLink_desc: "Copy Link ",
  },
  UserInfo: {
    Wallets_desc: "Wallets",
    Reward_desc: "Reward",
    half_month: "15 Days",
    one_month: "1 month",
    three_month: "3 months",
    Invitees: "Invitees",
    no_data: "No Data",
    walletNo_support:
      "Your wallet is not supported. Please use another wallet app",
    network_wrong: "Please select the correct network",
  },
};
export default en;

// Datajson:{
//     language_json:{
//         zh_json:'Simplified Chinese',
//         zh_FT_json: 'traditional Chinese',
//         eh_json:'English',
//         ja_JP_json:'Japanese',
//         ko_KR_json:'Korean' ,
//         en_TH_json:'Thai',
//         fr_FR_json:'French',
//         ru_RU_json:'Russian',
//         en_IN_json:'Hindi',
//         en_ID_json:'Indonesian',
//         en_BS_json:'farsi',
//         en_YN_json:'Vietnamese',
//         en_ALB_json:'Arabic',
//         en_TEQ_json:'Turkish',
//         en_ES_json:'Spanish',
//         en_PTY_json:'Portuguese',
//         en_YDL_json:'Italian'
//     },
// },
