//韩语
const ko_KR = {
    Datajson: {
        language_json: {
            zh_json: '中文简体',
            zh_FT_json: '中文繁體',
            eh_json: 'English',
            ja_JP_json: '日本語',
            ko_KR_json: '한국어',
            en_TH_json: 'ภาษาไทย',
            fr_FR_json: 'En français',
            ru_RU_json: 'русск',
            en_IN_json: 'हिंदीName',
            en_ID_json: 'Bahasa indonesia',
            en_BS_json: 'فارسی',
            en_YN_json: 'Việt nam',
            en_ALB_json: ' بالعربية ',
            en_TEQ_json: 'Türkçe',
            en_ES_json: 'Español',
            en_PTY_json: 'Português',
            en_YDL_json: 'Italiano'
        },
    },
    home: {
        language: '언어',
        fduse_desc: 'FDUSD는 1:1로 달러에 지원되는 안정적인 암호화폐입니다.',
        introduce_desc: '혁신적인 디지털 안정화폐를 통해 글로벌 금융을 혁신하고, 보안, 속도 및 혁신을 제공하여 전 세계의 기업과 건설자들에게 혁신적인 솔루션을 제공합니다.',
        getfduse_desc: 'FDUSD 받기',
        stability_desc: '안정성과 혁신의 만남',
        experience_desc: '초고속 거래속도와 최소한의 비용을 경험해 보세요. 혁신적이고 신뢰할 수 있는 솔루션으로 금융의 미래를 함께 만들어 보세요.',
        whitepaper_desc: '화이트페이퍼',
        redeemable_desc: '1:1로 미국 달러로 교환 가능',
        dollars_desc: 'FDUSD는 현금 및 현금 등가 자산으로 완전히 지원될 것으로 예상됩니다. 토큰은 1:1로 미국 달러로 교환 가능하도록 되어 있습니다.',
        backed_desc: '완전히 예약된 자산으로 지원됨',
        deserved_desc: '예약된 자산은 완전히 분리된 파산 방지 보유 구조에 보관됩니다.',
        programmable_desc: '완전히 프로그래밍 가능',
        innovative_desc: '진보적인 기술로 지원되는 혁신적인 안정화폐 솔루션으로, 안심과 신뢰를 제공하여 금융 분야에 긍정적인 영향을 주고자 합니다.',
        settle_desc: '몇 분 안에 체결',
        nearZero_desc: '거의 없는 비용',
        always_desc: '항상 켜져 있음, 24시간 7일',
        fully_desc: "완전히 프로그래밍 가능",
        rewrite_desc: 'First Digital Labs와 함께 미래를 새롭게 쓰다',
        combining_desc: 'First Digital Labs에서 안정성과 혁신을 결합하여, 우리는 철저한 투명성, 준수 및 최첨단 기술로 지원되는 신뢰를 얻기 위해 노력하고 있습니다.',
        mission_desc: '금융 산업을 새로운 금융 시대로 이끌고 글로벌 금융 풍토를 재편하고자 합니다.',
        should_desc: '왜 First Digital USD를 사용해야 할까요?',
        collateral_desc: '담보물은 최고 수준의 자격을 갖춘 보관인이 신뢰라이선스로 보호합니다.',
        transparent_desc: '투명성',
        independent_desc: '동일한 제 3자에 의해 담보물이 검증되어 예비 자금 및 재무 보고서의 무결성이 보장됩니다.',
        examined_by_desc: '검토자',
        attestation_Reports_desc: '확인 보고서',
        January2024_desc: '2024년 1월',
        Report_desc: '보고서',
        December_desc: '2023년 12월',
        November_desc: '2023년 11월',
        Networks_desc: '지원되는 네트워크는 어떤 것이 있나요?',
        available_desc: 'FDUSD는 다음에서 사용 가능합니다.',
        Ethereum_desc: '이더리움',
        and_desc: '그리고',
        BNB_Chain_desc: 'BNB 체인',
        planned_desc: '더 많은 블록체인을 지원할 예정입니다.',
        Audited_desc: '검사자는',
        Frequently_desc: '자주 묻는 질문',
        How_FDUSD_desc: '어떻게 FDUSD를 얻을 수 있을까요?',
        Acquiring_desc: 'FDUSD를 얻는 방법은 몇 가지가 있습니다. 주요 산업 참가자, 금융 중개인 또는 특정 지위의 전문 투자자인 경우 First Digital Labs에서 직접 FDUSD를 구매할 수 있습니다. First Digital Labs는 소매 고객에게 토큰을 직접 판매하지 않습니다.:',
        canstill_desc: 'First Digital Labs의 고객이 되지 않고도 보조 시장을 통해 여전히 FDUSD를 얻을 수 있습니다. 많은 주요 암호화폐 거래소에서 FDUSD를 상장하고 상당한 유동성을 제공합니다. 이는 관심 있는 개인들이 FDUSD를 얻기 위한 접근 가능하고 편리한 방법을 제공합니다. 그러나 사용 중인 거래소의 신뢰성을 확실히 조사하고 확인하는 것이 항상 중요합니다.',
        equivalent_desc: 'FDUSD가 항상 달러와 동일한 가치를 갖는 것에 대해 어떻게 신뢰할 수 있을까요?',
      //  stablecoin_desc: 'FDUSD의 안정적인 가치에 대한 신뢰는 충분히 근거가 있습니다. 각 FDUSD는 미국 달러 하나 또는 동등한 공정 가치의 자산으로 완전히 지원될 것으로 예상됩니다. 이러한 자산은 신뢰할 수 있는 보관인이 규제된 예금 기관의 계정에서 안전하게 보관되어 코인의 가치에 견고한 기반이 마련됩니다. 이 시스템은 FDUSD에 고유한 안정성을 제공하고 미국 달러와 동등한 가치에 대한 신뢰를 보장하는 이유입니다.',
        redeem_FDUSD: 'FDUSD를 어떻게 교환할 수 있을까요?',
     //   stablecoin_desc: 'FDUSD 안정화폐를 교환하려면 먼저 First Digital Labs의 고객이 되어야 하며, 특정 요구 사항을 충족해야 합니다. 이에는 미래자금세탁방지(AML) 및 테러자금조달(CTF) 점검을 포함합니다. 이러한 점검을 성공적으로 완료한 후에는 FDUSD를 현금과 동등한 가치로 환전하여 순환에서 제외할 수 있습니다. 또는 암호화폐 거래소나 OTC 공급자에서 FD121의 안정화폐를 지원하는 보조 시장에서 FDUSD 토큰을 판매할 수도 있습니다. 진행하기 전에 귀하의 관할권에서의 모든 법적 및 금융 규정을 숙지해야 합니다.',
        Explore_desc: "생태계 탐색",
        Experience_desc: '신뢰할 수 있는 안정화폐 솔루션으로 안정성을 경험해 보세요.',
        slide_desc: '슬라이드',
        _1to6_desc: '1에서 6까지',
        of7_desc: '7 중',
        Transparency_desc: '투명성',
        Legal_desc: '법적 사항',
        Compliance_desc: '준수',
        Policy_desc: '개인정보 처리방침',
        Company_desc: '회사',
        About_Us_desc: '회사 소개',
        Social_Media_desc: '소셜 미디어',
        Twitter_desc: '트위터',
        Disclosures_desc: '중요',
    },
    newhome_json: {
        Exchanges_json: "거래소",
        DeFi_json: "탈중앙 금융",
        Payments_json: "지불",
        Accessibility_json: "접근성",
        listed_json: "TUSD는 여러 업계 선도 거래소에 상장되어 모든 투자자에게 유동성과 접근성을 제공합니다.",
        stablecoin_json: "널리 사용되는 스테이블코인으로서, TUSD는 자동화 마켓 메이커(AMM), 대출 플랫폼, 수익 농업(YIF) 프로젝트를 포함한 다양한 탈중앙 금융(DeFi) 프로토콜에서 크게 채택되었습니다.",
        leading_json: "선도적인 위안화 스테이블코인으로서, TUSD는 국경 간 거래의 이상적인 매체입니다. TUSD의 저렴한 거래 수수료와 즉시 속도를 통해 개인과 기관 모두 전통적인 금융 도전 없이 거래를 수행할 수 있습니다.",
        available_json: "TUSD는 Ethereum, BNB 스마트 체인, TRON, Avalanche와 같은 주요 블록체인에서도 사용할 수 있습니다. TUSD의 브릿지 네트워크에는 Polygon, Fantom, Arbitrum, Cronos 등이 포함됩니다.",
        error1_json: "노드에 연결할 수 없습니다. 노드를 변경한 후 다시 시도하세요",
        error2_json: "체인 오류",
        error3s_json: "주소를 연결하세요",
        Loading1_json: "로딩 중",
        Loading2_json: "프로젝트 정보를 로딩 중",
        error3_json: "네트워크 오류",
        error4_json: "지갑 연결이 거부되었습니다",
        error5_json: "지갑 버전이 오래되었습니다",
        error6_json: "지갑 연결 거부",
        Loading3_json: "로그인 중",
        error7_json: "계정 오류",
        error8_json: "노드에 연결할 수 없습니다. 노드를 변경한 후 다시 시도하세요",
        error9_json: "네트워크 오류",
        Loading4_json: "스테이킹 중",
        error10_json: "차단되었습니다",
        error11_json: "프로젝트 오류",
        error12_json: "가스가 부족합니다",
        success1_json: "성공",
        error13_json: "계정 가져오기 실패",
        error14_json: "알 수 없는 오류",
        error15_json: "노드에 연결할 수 없습니다. 노드를 변경한 후 다시 시도하세요",
      
      },
      newhome_desc: {
        title_desc: "새로운 글로벌 결제 시스템을 위한 디지털 화폐",
        desc_desc: "TrueUSD는 독립된 제3자 기관이 일상적으로 기본 준비금을 감사하는 최초의 달러 페그 스테이블코인입니다.",
        Started_desc: "시작",
        Contact_desc: "문의하기",
        Circulating_desc: "유통 공급",
        Trading_desc: "거래량",
        Transaction_desc: "총 거래 횟수",
        Support_desc1: "총 지원 체인",
        Trustworthy_desc: "신뢰할 수 있음",
        utilizes_desc: "TUSD는 투명성을 높이기 위해 고급 감사 및 인증 메커니즘을 활용합니다. 일상적인 감사 보고서는 웹사이트에서 확인할 수 있으며, 사용자는 언제든지 준비금을 모니터링할 수 있습니다.",
        Popularity_desc: "인기도",
        convenience_desc: "TUSD는 거래와 송금을 비롯한 다양한 금융 시나리오에서 편리성과 실용성을 제공합니다. 현재 TUSD는 80개 이상의 암호화폐 거래소 및 DeFi 프로토콜에서 사용 가능하여 사용자에게 유연성과 접근성을 제공합니다.",
        Liquidity_desc: "유동성",
        During_desc: "2023년 3분기 동안 TUSD의 일일 거래량은 10억 달러를 초과하여 놀라운 유동성을 보여주었습니다. 또한 TUSD는 주조 및 상환에 대해 어떠한 수수료도 부과하지 않습니다.",
        Trusted_desc: "전 세계 리더들의 신뢰를 받음",
        Using_desc: "TUSD 사용",
        LearnMore_desc: "자세히 알아보기",
        Goes_desc: "TUSD는 다중 체인으로 이동",
        Natively_desc1: "네이티브 배포 네트워크",
        Ethereum_desc: "이더리움",
        TRON_desc: "트론",
        Avalanche_desc: "아발란체",
        Smart_desc: "BNB 스마트 체인",
        Bridged_desc: "브릿지 네트워크",
        Fantom_desc: "판톰",
        Arbitrum_desc: "아비트럼",
        Cronos_desc: "크로노스",
        Optimism_desc: "옵티미즘",
        Aurora_desc: "오로라",
        FAQs_desc: "자주 묻는 질문",
        TrueUSD_desc: "Q1. TrueUSD란 무엇입니까?",
        Banking_desc: "TrueUSD는 전 세계 금융 기관과 강력한 블록체인 인프라를 활용하여 실시간 온체인 검증을 통해 기본 준비금의 커버리지를 보장하는 최초의 스테이블코인입니다. Moore Hong Kong 및 Chainlink Proof of Reserve의 지원을 받았습니다.",
        started_desc: "Q2. TrueUSD 사용을 시작하려면 어떻게 해야 합니까?",
        stepsbelow_desc: "TrueUSD 사용을 시작하려면 다음 단계를 따르십시오:",
        verify_desc: "TrueUSD 웹사이트에서 계정을 생성하고 인증한 후 다음 이메일을 받게 됩니다:",
        website_desc: "TrueUSD 웹사이트",
       
        verification_desc: "인증 링크를 통해 인증을 완료하세요. 이 링크를 클릭하여 이메일 주소를 확인하고 설정을 진행하십시오.",
        information_desc: "자신 또는 조직에 대한 정보를 입력하세요. 필요한 서류 목록은",
        here_desc: "여기",
        application_desc: "신청서를 제출하세요. 신청서를 검토하겠습니다.",
        Redeem_desc: "수수료 없이 한 번의 클릭으로 스테이블코인 주조 및 상환",
        Trade_desc: "여러 거래소의 160개 이상의 시장과 5개 대륙의 20개 이상의 OTC 데스크에서 TrueUSD 거래",
        Send_desc: "전신 송금보다 100배 저렴하고 1000배 빠른 방식으로 언제 어디서나 누구에게나 TrueUSD 송금",
        mint_desc: "Q3. TrueUSD를 주조 및 상환하려면 어떻게 해야 합니까?",
        partner_desc1: "은행 파트너에게 전신 송금을 보내고,",
        Trueapp_desc: "TrueUSD 애플리케이션",
        Please_desc: "참고: 현재 ACH 또는 Venmo, TransferWise, PayPal, Revolut와 같은 기타 디지털 통화 송금 서비스를 수락하지 않습니다.",
        account_desc: "은행 계좌의 이름은 TrueUSD 계정의 이름과 일치해야 합니다.",
        creating_desc: "계정을 생성한 후 다음 이메일을 받게 됩니다:",
        generally_desc: "일반적으로 은행 간 전신 송금은 1~5 영업일이 소요됩니다.",
        email_desc: "전신 송금을 받은 후 확인 이메일을 발송합니다.",
        minted_desc: "자금을 받은 후 TrueUSD는 1 영업일 이내에 주조되어 애플리케이션 내의 지갑 주소로 전송됩니다.",
        redeem4_desc: "Q4. TrueUSD를 상환하려면 어떻게 해야 합니까?",
        Visit_desc: "방문:",
        Polygon_desc1: "TrueUSD 애플리케이션",
        receive_desc: "은행 정보를 입력하고 고유한 상환 주소를 받으세요. 법정 화폐 송금은 은행 파트너의 KYC 및 AML 요구 사항을 준수합니다.",
        redemption_desc: "이 상환 주소는 각 TrueUSD 사용자에게 고유합니다. 고유한 상환 주소를 다른 사람과 공유하지 마세요.",
        simple_desc: "왜 내 상환 주소가 이렇게 간단합니까?",
        notice_desc: "이 주소가 매우 간단하고 많은 '0'이 포함된 것을 알 수 있습니다. 이는 의도된 것입니다. 이 주소를 통해 모든 지갑(거래소 지갑 포함)에서 상환할 수 있으며, 이 주소로 수신된 모든 상환은 TrueUSD 계정과 연결됩니다.",
        unique_desc: "고유한 상환 주소로 TrueUSD 전송(최소 $1,000).",
        partner_desc: "토큰이 성공적으로 상환 주소로 전송된 후, 은행 파트너는 일반적으로 1 영업일 이내에 은행 계좌로 전신 송금을 발송합니다.",
        exchanges_desc: "Q5. TrueUSD(TUSD)는 어떤 거래소에 상장되어 있습니까?",
        Binance_desc: "TrueUSD는 현재 Binance, Bittrex, Upbit를 포함한 여러 거래소에 상장되어 있습니다.",
        here2_desc: "여기",
        markets_desc: "활성 TrueUSD 거래소 및 시장의 전체 목록을 확인하세요.",
        contract_desc: "Q6. TrueUSD의 스마트 계약 주소는 무엇입니까?",
        tokenSmart_desc: "당사의 토큰 스마트 계약은 TUSD 네이티브 배포 네트워크 및 TUSD 브릿지 네트워크 또는 Binance 체인 브라우저를 통해 찾을 수 있습니다. 링크는 아래에 있습니다. 스마트 계약 및 스마트 계약 업그레이드에 대한 자세한 내용은",
        blog_desc: "블로그",
        related_desc: "에 나와 있습니다.",
        Natively_desc: "TUSD 네이티브 배포 네트워크",
        Ethererum2_desc: "이더리움",
        SmartChain_desc: "BNB 스마트 체인:",
       
        Avalanche2_desc: "아발란체",
        Beacon_desc: "BNB 비콘 체인:",
        Networks2_desc: "TUSD 브릿지 네트워크",
        Polygon2_desc: "폴리곤",
        Fantom2_desc: "판톰",
       
        Polygon_desc2: "옵티미즘",
        ChainOld_desc: "구 BNB 스마트 체인:",
        AboutMore_desc: "TUSD에 대해 자세히 알아보기",
        Digital_desc: "새로운 글로벌 결제 시스템을 위한 디지털 화폐",
        first_desc: "TrueUSD는 독립된 제3자 기관이 일일 준비금 검증을 수행하는 최초의 달러 페그 스테이블코인입니다.",
        Polygon_desc: "폴리곤",
        Follow_desc: "팔로우하기",
        Resources_desc: "자원",
        Stablecoin_desc: "TrueUSD는 'Proof of Reserves'를 통해 주조를 보장하는 최초의 달러 지원 스테이블코인이 되었습니다",
        Engages_desc: "TUSD는 법정 화폐 준비금 검증 서비스를 제공하기 위해 주요 회계 회사인 MooreHK와 협력합니다",
        Becomes_desc: "TUSD(TRC-20)는 도미니카 공화국의 법정 화폐가 됩니다",
        Blog_desc: "블로그",
        Support_desc: "지원",
        TermsOf_desc: "이용 약관",
        Privacy_desc: "개인정보 보호정책",
        TrueUsd_desc: "TrueUSD",
        Home_desc: "홈",
        Transparency_desc: "투명성",
        Ecosystem_desc: "생태계",
        About_desc: "소개",
        Supported_desc: "지원되는 체인",
        Other_desc: "기타 체인",
        rights_desc: "© 2023 TrueUSD. 모든 권리 보유.",
        Back_desc: "맨 위로"
      },
      project_desc: {
        Pool_desc: "자금풀",
        participating_desc: "토큰에 참여하면,",
        rewards_desc: "보상으로 받게 됩니다.",
        EndTime_desc: "프로젝트 종료 시간",
        duration_desc: "스테이킹 기간",
        days_desc: "일",
        calculation_desc: "주기적 계산",
        Invite_desc: "초대",
        level_desc: "레벨 커미션",
        withdrawal_desc: "최소 출금 금액",
        supply_desc: "최소 공급 금액",
        period_desc: "최소 출금 주기",
        Invitations_desc: "초대 레벨",
        People_desc: "인원 수"
      },
      capitaInfoView_desc: {
        Please_desc: "주소를 연결하세요",
        Pool_desc: "자금풀",
        receive_desc: "토큰에 참여하면,",
        rewards_desc: "보상으로 받게 됩니다.",
        level_desc: "레벨 자금풀",
        Expired_desc: "만료됨",
        Unexpired_desc: "만료되지 않음",
        Participated_desc: "참여됨",
        uncommitted_desc: "미약정",
        Withdraw_desc: "출금",
        Redeem_desc: "상환",
        Status_desc: "상태",
        Principal_desc: "원금",
        Redeemed_desc: "상환됨",
        subaccounts_desc: "서브 계정",
        Supplied_desc: "공급됨",
        Redeemable_desc: "상환 가능",
        Commission_desc: "커미션",
        Available_desc: "출금 가능",
        Withdrawed_desc: "출금됨",
        Lock_desc: "커미션 잠금",
        All_desc: "모든 커미션",
        interest_desc: "이자",
        Settled_desc: "정산됨",
        Loading_desc: "로딩 중",
        amount_desc: "출금 가능 금액"
      },
      elModal_desc: {
        Please_desc: "입금 금액을 입력하세요",
        Recharge_desc: "입금",
        Cancel_desc: "취소",
        withdrawal_desc: "출금 금액을 입력하세요",
        Withdraw_desc: "출금",
        redemption_desc: "상환 금액을 입력하세요",
        Redeem_desc: "상환",
        Withdrawable_desc: "출금 가능 금액 (커미션 + 이자):",
        balance_desc: "상환 가능 잔액:",
        input_desc: "금액을 입력하세요",
        Transaction_desc: "거래 성공!",
        failed_desc: "거래 실패",
        funds_desc: "계좌 잔액이 부족합니다",
        Loading_desc: "로딩 중"
      },
    modal: {
        hold_desc: '보유하고 있다면',
        Smart_desc: 'BNB Smart Chain의 경우 보너스 보상을 받을 수 있습니다.',
        Rewards_desc: '보상은',
        address_desc: '주소는',
        hours_desc: '24시간 동안 유지되며, 최소 보유량은 시스템의 무작위 스냅샷을 기준으로 합니다. 실제 할당은 매 24시간마다 이루어집니다. 계정에 실제로 할당되는 금액이 우선합니다.',
    },
    UserInfo: {
        Wallets_desc: '지갑',
        Reward_desc: '보상',
        half_month: '15일',
        one_month: '1개월',
        three_month: '3개월',
        Invitees: '초대자',
        no_data: '데이터 없음',
        walletNo_support: '지원되지 않는 지갑입니다. 다른 지갑 앱을 사용해주세요.',
        network_wrong: '올바른 네트워크를 선택하세요',
      },
}
export default ko_KR

// Datajson:{
//     language_json:{
//         zh_json:'중국어 간체',
//         zh_FT_json: '중국어 번체',
//         eh_json:'영어',
//         ja_JP_json:'일본어',  
//         ko_KR_json:'한국어' ,
//         en_TH_json:'타이 어로',
//         fr_FR_json:'프랑스어',
//         ru_RU_json:'러시아어',
//         en_IN_json:'힌디어',
//         en_ID_json:'인도네시아어',
//         en_BS_json:'페르시아어',
//         en_YN_json:'베트남어',
//         en_ALB_json:'아랍어로',
//         en_TEQ_json:'터키어로',
//         en_ES_json:'스페인어',
//         en_PTY_json:'포르투갈어로',
//         en_YDL_json:'이탈리아어로'
//     },
// },