//日语
const ja_JP = {
    Datajson: {
        language_json: {
            zh_json: '中文简体',
            zh_FT_json: '中文繁體',
            eh_json: 'English',
            ja_JP_json: '日本語',
            ko_KR_json: '한국어',
            en_TH_json: 'ภาษาไทย',
            fr_FR_json: 'En français',
            ru_RU_json: 'русск',
            en_IN_json: 'हिंदीName',
            en_ID_json: 'Bahasa indonesia',
            en_BS_json: 'فارسی',
            en_YN_json: 'Việt nam',
            en_ALB_json: ' بالعربية ',
            en_TEQ_json: 'Türkçe',
            en_ES_json: 'Español',
            en_PTY_json: 'Português',
            en_YDL_json: 'Italiano'
        },
    },
    home: {
        language:'言語',
        fduse_desc: 'FDUSDは1:1の米ドルに裏付けられたステーブルコインです。',
        introduce_desc: '革新的なデジタルステーブルコインによって、ビジネスとビルダーにセキュリティ、スピード、革新を提供し、世界中で金融を革新します。',
        getfduse_desc: 'FDUSDを入手する',
        stability_desc: '安定性とイノベーション',
        experience_desc: '高速かつ低コストな取引を体験してください。革新的で信頼性の高いソリューションで、金融の未来を形作りましょう。',
        whitepaper_desc: 'ホワイトペーパー',
        redeemable_desc: '1:1で米ドルに交換可能',
        dollars_desc: 'FDUSDは現金および現金同等の資産に完全に裏付けられることを目指しています。トークンは1:1で米ドルに交換可能です。',
        backed_desc: '完全に準備された資産に裏付けられています',
        deserved_desc: '準備された資産は、破産から隔離された保全構造に保持されています。',
        programmable_desc: '完全にプログラム可能',
        innovative_desc: '革新的なステーブルコインソリューションにより、先進技術に裏打ちされた安全で信頼性の高い選択肢を提供し、金融界にポジティブな影響を与えることを目指しています。',
        settle_desc: '数分で決済',
        nearZero_desc: 'ほぼゼロのコスト',
        always_desc: '常に稼働、24時間365日',
        fully_desc: "完全にプログラム可能",
        rewrite_desc: 'First Digital Labsで未来を書き換える',
        combining_desc: 'First Digital Labsでは安定性と革新を融合し、徹底的な透明性、コンプライアンス、セキュリティを提供することで信頼を獲得することに取り組んでいます。',
        mission_desc: '私たちは金融業界を新たな時代に推進し、世界の金融ランドスケープを再構築する使命を持っています。',
        should_desc: 'なぜFirst Digital USDを使用する必要があるのですか？',
        collateral_desc: '担保はトップクラスの信託ライセンスを持つ信頼性のある保管業者によって保護されています。',
        transparent_desc: '透明性',
        independent_desc: '独立した監査-担保は独立した第三者によって検証され、準備金および財務報告の信頼性が保証されています。',
        examined_by_desc: '監査：',
        attestation_Reports_desc: '監査報告書',
        January2024_desc: '2024年1月',
        Report_desc: '報告書',
        December_desc: '2023年12月',
        November_desc: '2023年11月',
        Networks_desc: '対応しているネットワークは？',
        available_desc: 'FDUSDは次のネットワークで利用可能です：',
        Ethereum_desc: 'Ethereum',
        and_desc: 'および',
        BNB_Chain_desc: 'BNB Chain',
        planned_desc: '今後、さらに多くのブロックチェーンをサポートする予定です。',
        Audited_desc: '監査：',
        Frequently_desc: 'よくある質問',
        How_FDUSD_desc: 'FDUSDを入手する方法は？',
        Acquiring_desc: 'FDUSDを入手する方法はいくつかあります。業界の主要プレーヤーや金融機関、一定の地位を持つプロフェッショナル投資家である場合、First Digital Labsから直接FDUSDを購入することができます。ただし、First Digital Labsは一般の個人顧客にトークンを直接販売していません。',
        canstill_desc: 'First Digital Labsのクライアントにならずに、二次市場でFDUSDを入手することもできます。多くの主要な仮想通貨取引所がFDUSDをリストしており、流動性が豊富です。これにより、興味のある個人がFDUSDを取得するためのアクセスしやすく便利な方法が提供されています。ただし、利用する取引所の信頼性を徹底的に調査し、確認することが常に重要です。',
        equivalent_desc: 'FDUSDが常に1ドルと同等であることを信頼するにはどうすればよいですか？',
       // stablecoin_desc: 'FDUSDステーブルコインの価値に対する信頼は、十分に根拠があります。各FDUSDは、1米ドルまたは同等の公正な価値の資産に完全に裏付けられることを意図しています。これらの資産は、信託ライセンスを持つ信頼性のある保管業者によって安全に保持され、規制された預金機関の口座に保管されています。これにより、FDUSDの価値は確固たる基盤が提供されており、米ドルと同等の価値に信頼性があります。',
        redeem_FDUSD: 'FDUSDを交換するにはどうすればよいですか？',
      //  stablecoin_desc: 'FDUSDステーブルコインを交換するには、まずFirst Digital Labsのクライアントになり、特定の要件を満たす必要があります。これには、マネーロンダリング防止（AML）およびテロ資金供与防止（CTF）のチェックなどが含まれます。これらのチェックを成功裏に完了すると、FDUSDを法定通貨と交換することができ、それによってFDUSDを流通から外すことができます。また、FDUSDトークンをサポートする仮想通貨取引所やカウンター取引（OTC）プロバイダーを介して二次市場でFDUSDトークンを売却することもできます。手続きを進める前に、所在地のすべての法的および金融規制について認識してください。',
        Explore_desc: "エコシステムを探索する",
        Experience_desc: '信頼性のあるステーブルコインソリューションで安定性を体験してください。',
        slide_desc: 'スライド',
        _1to6_desc: '1から6まで',
        of7_desc: '全7つ中',
        Transparency_desc: '透明性',
        Legal_desc: '法的',
        Compliance_desc: 'コンプライアンス',
        Policy_desc: 'プライバシーポリシー',
        Company_desc: '会社',
        About_Us_desc: '私たちについて',
        Social_Media_desc: 'ソーシャルメディア',
        Twitter_desc: 'Twitter',
        Disclosures_desc: '重要な開示事項：',
        Limited_desc: 'FD121 Limitedは金融テクノ',
    },
    newhome_json: {
        Exchanges_json: "取引所",
        DeFi_json: "分散型金融",
        Payments_json: "支払い",
        Accessibility_json: "アクセシビリティ",
        listed_json: "TUSDは複数の業界をリードする取引所に上場しており、すべての投資家に流動性とアクセス可能性を提供します。",
        stablecoin_json: "広く使用されているステーブルコインとして、TUSDはさまざまな分散型金融（DeFi）プロトコルで著しく採用されています。これには、自動マーケットメーカー（AMM）、貸付プラットフォーム、および収益農業（YIF）プロジェクトが含まれます。",
        leading_json: "主要な人民元ステーブルコインとして、TUSDは国境を越えた取引の理想的な手段です。TUSDの低い取引手数料と即時速度を利用して、個人および機関の両方が従来の金融上の課題なしに取引を行うことができます。",
        available_json: "TUSDは、Ethereum、BNBスマートチェーン、TRON、Avalancheなどの主要なブロックチェーンでも使用できます。TUSDのブリッジネットワークには、Polygon、Fantom、Arbitrum、Cronosなどが含まれます。",
        error1_json: "ノードに接続できません。ノードを変更して再試行してください",
        error2_json: "チェーンエラー",
        error3s_json: "アドレスを接続してください",
        Loading1_json: "読み込み中",
        Loading2_json: "プロジェクト情報を読み込んでいます",
        error3_json: "ネットワークエラー",
        error4_json: "ウォレット接続が拒否されました",
        error5_json: "古いウォレットバージョン",
        error6_json: "ウォレット接続拒否",
        Loading3_json: "ログイン中",
        error7_json: "アカウントエラー",
        error8_json: "ノードに接続できません。ノードを変更して再試行してください",
        error9_json: "ネットワークエラー",
        Loading4_json: "ステーキング中",
        error10_json: "あなたはブロックされました",
        error11_json: "プロジェクトエラー",
        error12_json: "ガスが不足しています",
        success1_json: "成功",
        error13_json: "アカウント取得に失敗しました",
        error14_json: "不明なエラー",
        error15_json: "ノードに接続できません。ノードを変更して再試行してください",
      
      },
      newhome_desc: {
        title_desc: "新しいグローバル支払いシステムのために構築されたデジタル通貨",
        desc_desc: "TrueUSDは、独立した第三者機関が日常的にその基本準備金を監査する最初のドルにペッグされたステーブルコインです。",
        Started_desc: "開始",
        Contact_desc: "お問い合わせ",
        Circulating_desc: "流通供給",
        Trading_desc: "取引量",
        Transaction_desc: "総取引回数",
        Support_desc1: "総サポートチェーン",
        Trustworthy_desc: "信頼性",
        utilizes_desc: "TUSDは、透明性を高めるために高度な監査および認証メカニズムを利用しています。日常的な監査報告書はウェブサイトで確認でき、ユーザーはいつでも準備金を監視できます。",
        Popularity_desc: "人気度",
        convenience_desc: "TUSDは、取引や送金などのさまざまな金融シナリオで利便性と実用性を提供します。現在、TUSDは80以上の暗号通貨取引所およびDeFiプロトコルで使用でき、ユーザーに柔軟性とアクセス可能性を提供します。",
        Liquidity_desc: "流動性",
        During_desc: "2023年第3四半期には、TUSDの日次取引量が10億ドルを超え、その驚異的な流動性を示しました。さらに、TUSDは鋳造および償還に対して一切の手数料を請求しません。",
        Trusted_desc: "世界のリーダーに信頼されている",
        Using_desc: "TUSDの使用",
        LearnMore_desc: "もっと詳しく知る",
        Goes_desc: "TUSDはマルチチェーンに向かう",
        Natively_desc1: "ネイティブデプロイメントネットワーク",
        Ethereum_desc: "イーサリアム",
        TRON_desc: "トロン",
        Avalanche_desc: "アバランチ",
        Smart_desc: "BNBスマートチェーン",
        Bridged_desc: "ブリッジネットワーク",
        Fantom_desc: "ファントム",
        Arbitrum_desc: "アービトラム",
        Cronos_desc: "クロノス",
        Optimism_desc: "オプティミズム",
        Aurora_desc: "オーロラ",
        FAQs_desc: "よくある質問",
        TrueUSD_desc: "Q1. TrueUSDとは何ですか？",
        Banking_desc: "TrueUSDは、世界中の金融機関と強力なブロックチェーンインフラストラクチャを利用して、リアルタイムのオンチェーン検証を通じてその準備金のカバレッジを証明する最初のステーブルコインです。Moore Hong KongとChainlink Proof of Reserveによってサポートされています。",
        started_desc: "Q2. TrueUSDの使用を開始するにはどうすればよいですか？",
        stepsbelow_desc: "TrueUSDの使用を開始するには、次の手順に従ってください：",
        verify_desc: "TrueUSDのウェブサイトでアカウントを作成して認証を行った後、次のメールを受け取ります：",
        website_desc: "TrueUSDウェブサイト",
       
        verification_desc: "認証リンクを使用して認証してください。このリンクをクリックしてメールアドレスを確認し、次に設定を行います。",
        information_desc: "ご自身や/または組織の情報を入力してください。必要な書類のリストについては、",
        here_desc: "こちら",
        application_desc: "申請を送信してください。申請を審査します。",
        Redeem_desc: "手数料なしでワンクリックでステーブルコインの鋳造および償還",
        Trade_desc: "複数の取引所の160以上の市場および5大陸の20以上のOTCデスクでTrueUSDを取引",
        Send_desc: "電信送金よりも100倍安く、1000倍速く、いつでもどこでも誰にでもTrueUSDを送信",
        mint_desc: "Q3. TrueUSDを鋳造および償還するにはどうすればよいですか？",
        partner_desc1: "銀行パートナーに電信送金を行い、",
        Trueapp_desc: "TrueUSDアプリ",
        Please_desc: "注意：現在、ACHまたはVenmo、TransferWise、PayPal、Revolutなどの他のデジタル通貨送金サービスは受け付けていません。",
        account_desc: "銀行口座の名義はTrueUSDアカウントの名義と一致する必要があります。",
        creating_desc: "アカウントを作成後、次のメールを受け取ります：",
        generally_desc: "通常、銀行間の電信送金は1〜5営業日かかります。",
        email_desc: "電信送金を受け取った後、確認メールを送信します。",
        minted_desc: "資金を受け取った後、TrueUSDは1営業日以内に鋳造され、アプリ内のウォレットアドレスに転送されます。",
        redeem4_desc: "Q4. TrueUSDを償還するにはどうすればよいですか？",
        Visit_desc: "訪問先：",
        Polygon_desc1: "TrueUSDアプリ",
        receive_desc: "銀行情報を入力して、ユニークな償還アドレスを取得してください。法定通貨の送金は、銀行パートナーのKYCおよびAML要件に従います。",
        redemption_desc: "この償還アドレスは各TrueUSDユーザーに固有です。ユニークな償還アドレスを他人と共有しないでください。",
        simple_desc: "なぜ私の償還アドレスはこんなにシンプルなのですか？",
        notice_desc: "このアドレスが非常にシンプルで、多くの'0'が含まれていることに気付くでしょう。これは意図的です。このアドレスからは、すべてのウォレット（取引所ウォレットも含む）から償還することができ、このアドレスで受け取られたすべての償還はTrueUSDアカウントに関連付けられます。",
        unique_desc: "ユニークな償還アドレスにTrueUSDを送信（最低$1,000）。",
        partner_desc: "トークンが成功裏に償還アドレスに送信された後、銀行パートナーは通常、1営業日以内に銀行口座に電信送金を行います。",
        exchanges_desc: "Q5. TrueUSD（TUSD）はどの取引所に上場していますか？",
        Binance_desc: "TrueUSDは現在、Binance、Bittrex、Upbitを含む複数の取引所に上場しています。",
        here2_desc: "こちら",
        markets_desc: "TrueUSDのアクティブな取引所および市場の完全なリストを取得します。",
        contract_desc: "Q6. TrueUSDのスマートコントラクトアドレスは何ですか？",
        tokenSmart_desc: "当社のトークンのスマートコントラクトは、TUSDのネイティブデプロイメントネットワークおよびTUSDのブリッジネットワークまたはBinanceチェーンブラウザで見つけることができます。リンクは以下の通りです。スマートコントラクトおよびスマートコントラクトのアップグレードに関する詳細は、",
        blog_desc: "ブログ",
        related_desc: "に記載されています。",
        Natively_desc: "TUSDネイティブデプロイメントネットワーク",
        Ethererum2_desc: "イーサリアム",
        SmartChain_desc: "BNBスマートチェーン：",
       
        Avalanche2_desc: "アバランチ",
        Beacon_desc: "BNBビーコンチェーン：",
        Networks2_desc: "TUSDブリッジネットワーク",
        Polygon2_desc: "ポリゴン",
        Fantom2_desc: "ファントム",
       
        Polygon_desc2: "オプティミズム",
        ChainOld_desc: "旧BNBスマートチェーン：",
        AboutMore_desc: "TUSDの詳細についてはこちら",
        Digital_desc: "新しいグローバル支払いシステム向けのデジタル通貨",
        first_desc: "TrueUSDは、独立した第三者機関による日々の準備金検証を行う最初のドルにペッグされたステーブルコインです。",
        Polygon_desc: "ポリゴン",
        Follow_desc: "フォローする",
        Resources_desc: "リソース",
        Stablecoin_desc: "TrueUSDは、'Proof of Reserves'を通じて鋳造を保証する最初のドル支持ステーブルコインとなりました",
        Engages_desc: "TUSDは、法定通貨準備金の検証サービスを提供するために、主要な会計事務所であるMooreHKと提携しています",
        Becomes_desc: "TUSD（TRC-20）はドミニカ共和国の法定通貨となります",
        Blog_desc: "ブログ",
        Support_desc: "サポート",
        TermsOf_desc: "利用規約",
        Privacy_desc: "プライバシーポリシー",
        TrueUsd_desc: "TrueUSD",
        Home_desc: "ホーム",
        Transparency_desc: "透明性",
        Ecosystem_desc: "エコシステム",
        About_desc: "約",
        Supported_desc: "サポートされているチェーン",
        Other_desc: "その他のチェーン",
        rights_desc: "© 2023 TrueUSD. 全著作権所有。",
        Back_desc: "トップに戻る"
      },
      project_desc: {
        Pool_desc: "資金プール",
        participating_desc: "トークンに参加することで、",
        rewards_desc: "報酬として獲得します。",
        EndTime_desc: "プロジェクト終了時間",
        duration_desc: "ステーキング期間",
        days_desc: "日",
        calculation_desc: "定期的な計算",
        Invite_desc: "招待",
        level_desc: "レベルコミッション",
        withdrawal_desc: "最低引き出し額",
        supply_desc: "最低供給額",
        period_desc: "最低引き出し期間",
        Invitations_desc: "招待レベル",
        People_desc: "人数"
      },
      capitaInfoView_desc: {
        Please_desc: "アドレスをリンクしてください",
        Pool_desc: "資金プール",
        receive_desc: "トークンに参加することで、",
        rewards_desc: "報酬として獲得します。",
        level_desc: "レベル資金プール",
        Expired_desc: "期限切れ",
        Unexpired_desc: "未期限切れ",
        Participated_desc: "参加済み",
        uncommitted_desc: "未コミット",
        Withdraw_desc: "引き出し",
        Redeem_desc: "償還",
        Status_desc: "ステータス",
        Principal_desc: "元本",
        Redeemed_desc: "償還済み",
        subaccounts_desc: "サブアカウント",
        Supplied_desc: "供給済み",
        Redeemable_desc: "償還可能",
        Commission_desc: "コミッション",
        Available_desc: "引き出し可能",
        Withdrawed_desc: "引き出し済み",
        Lock_desc: "コミッションのロック",
        All_desc: "すべてのコミッション",
        interest_desc: "利息",
        Settled_desc: "決済済み",
        Loading_desc: "読み込み中",
        amount_desc: "引き出し可能金額"
      },
      elModal_desc: {
        Please_desc: "入金額を入力してください",
        Recharge_desc: "入金",
        Cancel_desc: "キャンセル",
        withdrawal_desc: "引き出し額を入力してください",
        Withdraw_desc: "引き出し",
        redemption_desc: "償還額を入力してください",
        Redeem_desc: "償還",
        Withdrawable_desc: "引き出し可能金額（コミッション＋利息）：",
        balance_desc: "償還可能残高：",
        input_desc: "金額を入力してください",
        Transaction_desc: "取引成功！",
        failed_desc: "取引失敗",
        funds_desc: "アカウントの資金が不足しています",
        Loading_desc: "読み込み中"
      },
    modal: {
        hold_desc: '保有している場合、',
        Smart_desc: 'BNB Smart Chainの場合、ボーナスリワードが付与されます。',
        Rewards_desc: 'リワードは、',
        address_desc: 'そのアドレスには',
        hours_desc: '24時間保持し、最小保有額はシステムのランダムスナップショットに基づいています。金額は24時間ごとに割り当てられます。実際のアカウントへの割り当てが優先されます。'
    },
    UserInfo: {
        Wallets_desc: 'ウォレット',
        Reward_desc: '報酬',
        half_month: '15日間',
        one_month: '1か月',
        three_month: '3か月',
        Invitees: '招待者',
        no_data: 'データなし',
        walletNo_support: 'お使いのウォレットはサポートされていません。別のウォレットアプリを使用してください。',
        network_wrong: '正しいネットワークを選択してください',
      },
}
export default ja_JP

// Datajson:{
//     language_json:{
//         zh_json:'簡体字中国語',
//         zh_FT_json: '繁体字中国語',
//         eh_json:'英語です',
//         ja_JP_json:'日本語.',  
//         ko_KR_json:'韓国語です' ,
//         en_TH_json:'タイ語です',
//         fr_FR_json:'フランス語です',
//         ru_RU_json:'ロシア語です',
//         en_IN_json:'ヒンディー語',
//         en_ID_json:'インドネシア語',
//         en_BS_json:'ペルシャ語',
//         en_YN_json:'ベトナム語です',
//         en_ALB_json:'アラビア語です',
//         en_TEQ_json:'トルコ語',
//         en_ES_json:'スペイン語です',
//         en_PTY_json:'ポルトガル語',
//         en_YDL_json:'イタリア語です'
//     },
// },