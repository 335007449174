//简体中文
const zh = {
  Datajson: {
    language_json: {
      zh_json: "中文简体",
      zh_FT_json: "中文繁體",
      eh_json: "English",
      ja_JP_json: "日本語",
      ko_KR_json: "한국어",
      en_TH_json: "ภาษาไทย",
      fr_FR_json: "En français",
      ru_RU_json: "русск",
      en_IN_json: "हिंदीName",
      en_ID_json: "Bahasa indonesia",
      en_BS_json: "فارسی",
      en_YN_json: "Việt nam",
      en_ALB_json: " بالعربية ",
      en_TEQ_json: "Türkçe",
      en_ES_json: "Español",
      en_PTY_json: "Português",
      en_YDL_json: "Italiano",
    },
  },
  home: {
    language: "语言",
    fduse_desc: "FDUSD是一种1:1与美元挂钩的稳定币.",
    introduce_desc:
      "以尖端数字稳定币彻底改革全球金融，为全球企业和创造者提供安全、快速和创新的解决方案。",
    getfduse_desc: "获取FDUSD",
    stability_desc: "稳定性与创新相结合",
    experience_desc:
      "体验闪电般的快速速度和最低成本。通过我们创新且值得信赖的解决方案塑造金融的未来。",
    whitepaper_desc: "白皮书",
    redeemable_desc: "可兑换为美元的1:1比例",
    dollars_desc:
      "FDUSD旨在完全由现金和现金等价资产支持。代币可按1:1比例兑换为美元。",
    backed_desc: "由完全储备的资产支持",
    deserved_desc: "储备资产被完全隔离并储存在远离破产风险的持有结构中。",
    programmable_desc: "可编程描述",
    innovative_desc:
      "凭借我们创新的稳定币解决方案，通过先进的技术支持，我们旨在提供一种安全可靠的选择，给人们带来安心，并在金融领域产生积极的影响。",
    settle_desc: "结算描述",
    nearZero_desc: "接近零成本",
    always_desc: "始终在线，全天候运行",
    fully_desc: "完全可编程",
    rewrite_desc: "与第一数字实验室一同改写未来",
    combining_desc:
      "在第一数字实验室中，将稳定性与创新相结合，我们致力于通过提供始终如一的透明度、合规性和安全性，以及先进的技术支持来赢得信任。",
    mission_desc:
      "我们的使命是推动金融行业进入金融新时代，并重塑全球金融格局。",
    should_desc: "为什么你应该使用第一数字美元（FDUSD）呢？",
    collateral_desc:
      "抵押品由一家顶级、合格的托管机构进行保护，其拥有信托许可证，以实现资产的无缝保护和合规性。",
    transparent_desc: "透明",
    independent_desc:
      "独立审计-抵押品由独立的第三方进行验证，确保储备和财务报告的完整性。",
    examined_by_desc: "经过审查",
    attestation_Reports_desc: "审计报告",
    January2024_desc: "2024年1月份的审计报告",
    Report_desc: "报告",
    December_desc: "2023年12月份的报告",
    November_desc: "2023年11月份的报告",
    Networks_desc: "第一数字实验室支持哪些网络？",
    available_desc: "FDUSD可在以下网络上使用",
    Ethereum_desc: "以太坊",
    and_desc: "和",
    BNB_Chain_desc: "BNB链",
    planned_desc: "计划支持越来越多的区块链。",
    Audited_desc: "经过审计",
    Frequently_desc: "常见问题",
    How_FDUSD_desc: "您如何获取FDUSD？",
    Acquiring_desc:
      "获得FDUSD可以通过几种途径实现。如果您是重要的行业参与者、金融中介机构或具有一定身份地位的专业投资者，并符合相应的条件，您可以直接从第一数字实验室购买FDUSD。请注意，第一数字实验室不直接向零售客户销售代币。",
    canstill_desc:
      "您仍然可以通过二级市场获得FDUSD，而无需成为第一数字实验室的客户。许多领先的加密货币交易所都会列出FDUSD，并提供可观的流动性。这为有兴趣的个人提供了获取FDUSD的一种便捷方式。然而，始终要进行彻底的研究，并确保您使用的交易所的可信度。",
    equivalent_desc: "您如何相信FDUSD始终等同于一美元呢？",
   // stablecoin_desc:
     // "您对FDUSD稳定币价值的信任是有根据的。每个FDUSD旨在完全由一美元或具有等值公正价值的资产支持。这些资产由合格的托管机构安全持有，存放在受监管的存管机构账户中，为该稳定币的价值提供了坚实的基础。正是这个系统赋予了FDUSD内在的稳定性，也是您可以相信其与美元等值的原因。",
    redeem_FDUSD: "您如何兑换FDUSD呢？",
  //  stablecoin_desc:
   //   "要兑换您的FDUSD稳定币，您首先必须成为第一数字实验室的客户，并满足特定要求，包括反洗钱（AML）和反恐融资（CTF）检查。成功完成这些检查后，您可以将FDUSD兑换为等值的法定货币，从而使其退出流通。另外，您还可以通过支持FD121稳定币的加密货币交易所或场外交易（OTC）提供商，在二级市场上出售您的FDUSD代币。在进行操作之前，请注意您所在地区的所有法律和金融监管规定。",
    Explore_desc: "探索我们的生态系统",
    Experience_desc: "通过我们可靠的稳定币解决方案来体验稳定性。",
    slide_desc: "滑动",
    _1to6_desc: "1到6",
    of7_desc: "第7",
    Transparency_desc: "透明度",
    Legal_desc: "合法的",
    Compliance_desc: "合规性",
    Policy_desc: "隐私政策",
    Company_desc: "公司",
    About_Us_desc: "关于我们",
    Social_Media_desc: "社交媒体",
    Twitter_desc: "推特",
    Disclosures_desc: "重要披露事项:",
    Limited_desc:
      "FD121有限公司是一家金融科技公司，而不是银行。储备资产的托管服务由提供",
    First_desc: "第一数码信托有限公司",
    product_desc:
      "所有产品和公司名称均为其各自持有人的商标™或注册商标®。本网站中使用的所有公司、产品和服务名称仅用于识别目的。使用这些名称、商标和品牌并不意味着与它们有任何关联或认可。",
    additional_desc: "为了获取其他重要风险、披露和信息，请访问。",
    Copyright_desc: "版权所有 © 2024 FD121有限公司。保留所有权利。",
  },
  newhome_json: {
    Exchanges_json: "交易所",
    DeFi_json: "去中心化金融",
    Payments_json: "支付",
    Accessibility_json: "可访问性",
    listed_json:
      "TUSD 在多个行业领先的交易所上市，为所有投资者提供流动性和可访问性。",
    stablecoin_json:
      "作为一种广泛使用的稳定币，TUSD 在各种去中心化金融（DeFi）协议中获得了显著采用，包括自动化市场制造商（AMM）、借贷平台和收益农耕（YIF）项目。",
    leading_json:
      "作为领先的人民币稳定币，TUSD 是跨 border 交易的理想媒介。凭借 TUSD 低廉的交易费用和即时速度，个人和机构都可以在没有传统金融挑战的情况下进行交易。",
    available_json:
      "TUSD 还可在顶级领先的区块链上使用，如以太坊、BNB 智能链、TRON 和雪崩。TUSD 的桥接网络包括 Polygon、Fantom、Arbitrum、Cronos 以及更多。",
    error1_json: "无法连接节点。更换节点后重试",
    error2_json: "链错误",
    error3s_json: "请连接您的地址",
    Loading1_json: "加载中",
    Loading2_json: "正在加载项目信息",
    error3_json: "网络错误",
    error4_json: "钱包连接拒绝",
    error5_json: "旧钱包版本",
    error6_json: "钱包_connect_deny",
    Loading3_json: "登录中",
    error7_json: "账户错误",
    error8_json: "无法连接节点。更换节点后重试",
    error9_json: "网络错误",
    Loading4_json: "质押中",
    error10_json: "您被阻止了",
    error11_json: "项目错误",
    error12_json: " gas 不足",
    success1_json: "成功",
    error13_json: "获取账户失败",
    error14_json: "未知错误",
    error15_json: "无法连接节点。更换节点后重试",
   
  },
  newhome_desc: {
    title_desc: "为新的全球支付系统构建的数字货币",
    desc_desc:
      "TrueUSD是第一个由独立第三方机构部署日常审计其基础储备的挂钩美元的稳定币。",
    Started_desc: "开始",
    Contact_desc: "联系我们",
    Circulating_desc: "通供应",
    Trading_desc: "交易量",
    Transaction_desc: "总交易次数",
    Support_desc1: "总支持链",
    Trustworthy_desc: "值得信赖",
    utilizes_desc:
      "TUSD利用先进的审计和认证机制来提高透明度。其日常审计报告可在网站上查看，允许用户随时监控储备。",
    Popularity_desc: "流行度",
    convenience_desc:
      "TUSD在各种金融场景中提供便利和实用性，例如交易和转账。目前，TUSD可在80多个加密货币交易所和DeFi协议上使用，为用户提供灵活性和可访问性。",
    Liquidity_desc: "流动性",
    During_desc:
      "在2023年第三季度，TUSD的日交易量超过10亿美元，显示出其惊人的流动性。此外，TUSD对其铸造和赎回不收取任何费用。",
    Trusted_desc: "受到全球领导者的信任",
    Using_desc: "使用TUSD",
    LearnMore_desc: "了解更多",
    Goes_desc: "TUSD走向多链",
    Natively_desc1: "本地部署网络",
    Ethereum_desc: "以太坊",
    TRON_desc: "波场",
    Avalanche_desc: "雪崩",
    Smart_desc: "BNB智能链",
    Bridged_desc: "桥接网络",
    Fantom_desc: "Fantom",
    Arbitrum_desc: "Arbitrum",
    Cronos_desc: "Cronos",
    Optimism_desc: "Optimism",
    Aurora_desc: "Aurora",
    FAQs_desc: "常见问题",
    TrueUSD_desc: "Q1.什么是TrueUSD？",
    Banking_desc:
      "TrueUSD利用全球范围内的金融机构和强大的区块链基础设施，是第一个利用智能合约进行铸造/赎回的稳定币，通过实时链上验证基础储备覆盖率，得到了全球领先的会计事务所Moore Hong Kong和Chainlink Proof of Reserve的支持。",
    started_desc: "Q2.如何开始使用TrueUSD？",
    stepsbelow_desc: "要开始使用TrueUSD，请按照以下步骤操作：",
    verify_desc: "通过TrueUSD网站创建并验证您的帐户后，您将收到一封来自",
    website_desc: "TrueUSD网站",
   
    verification_desc:
      "通过验证链接进行验证。点击此链接以验证您的电子邮件地址，然后设置",
    information_desc:
      "填写关于您自己和/或您的组织的信息。有关所需文件的列表，请点击",
    here_desc: "这里",
    application_desc: "提交您的申请。我们将审核您的申请。",
    Redeem_desc: "一键无费用地铸造和赎回我们的稳定币",
    Trade_desc:
      "在多个交易所的160+个市场和5个大洲的20+个场外交易台上交易TrueUSD",
    Send_desc: "以比电汇便宜100倍且快1000倍的方式，随时随地向任何人发送TrueUSD",
    mint_desc: "Q3.如何铸造和赎回TrueUSD？",
    partner_desc1: "向我们的银行合作伙伴发送电汇，使用显示在",
    Trueapp_desc: "TrueUSD应用程序",
    Please_desc:
      "请注意：我们目前不接受ACH或其他数字货币转账服务，例如Venmo、TransferWise、PayPal、Revolut等。",
    account_desc: "您银行账户上的姓名必须与您的TrueUSD账户上的姓名相匹配。",
    creating_desc: "创建您的帐户后，您将收到一封来自",
    generally_desc: "通常情况下，银行之间的电汇通常需要1-5个工作日才能完成。",
    email_desc: "一旦我们收到您的电汇，我们将向您发送电子邮件确认。",
    minted_desc:
      "一旦我们收到您的资金，TrueUSD将在1个工作日内铸造并转入应用程序中提供的钱包地址。",
    redeem4_desc: "Q4.如何赎回TrueUSD？",
    Visit_desc: "访问",
    Polygon_desc1: "TrueUSD应用程序",
    receive_desc:
      "以输入您的银行信息并获取您独特的赎回地址。法定货币转账受到我们的银行合作伙伴的KYC和AML要求。",
    redemption_desc:
      "此赎回地址对每个TrueUSD用户是唯一的。请不要与他人分享您的独特赎回地址。",
    simple_desc: "为什么我的赎回地址如此简单？",
    notice_desc:
      "您会注意到这个地址非常简单，包含许多'0'。这是有意的。您可以从任何钱包（包括交易所钱包）赎回到此地址，并且由此地址收到的所有赎回都将与您的TrueUSD账户关联。",
    unique_desc: "向您独特的赎回地址发送TrueUSD（最低$1,000）。",
    partner_desc:
      "一旦代币成功发送到您的赎回地址，我们的银行合作伙伴通常会在1个工作日内向您的银行账户发出电汇。",
    exchanges_desc: "Q5.TrueUSD（TUSD）在哪些交易所上市？",
    Binance_desc: "TrueUSD目前在多个交易所上市，包括币安、Bittrex和Upbit。",
    here2_desc: "这里",
    markets_desc: "以获取活跃的TrueUSD交易所和市场的完整列表。",
    contract_desc: "Q6.TrueUSD的智能合约地址是什么？",
    tokenSmart_desc:
      "我们的代币智能合约可以通过TUSD原生部署网络和TUSD桥接网络或币安链浏览器找到。以下是链接。您也可以在我们的",
    blog_desc: "博客",
    related_desc: "中了解有关我们的智能合约和智能合约升级的更多信息。",
    Natively_desc: "TUSD原生部署网络",
    Ethererum2_desc: "以太坊",
    SmartChain_desc: "BNB智能链：",
   
    Avalanche2_desc: "Avalanche",
    Beacon_desc: "BNB Beacon链：",
    Networks2_desc: "TUSD桥接网络",
    Polygon2_desc: "Polygon",
    Fantom2_desc: "Fantom",

   
    Polygon_desc2: "Optimism",
    ChainOld_desc: "BNB智能链（旧版）：",
    AboutMore_desc: "了解更多关于TUSD的信息",
    Digital_desc: "面向新全球支付系统的数字货币",
    first_desc:
      "TrueUSD是第一个使用独立第三方机构进行每日准备金验证的美元挂钩稳定币。",
    Polygon_desc: "Polygon",
    Follow_desc: "关注我们",
    Resources_desc: "资源",
    Stablecoin_desc:
      "TrueUSD成为第一个通过“准备金证明”确保铸币的美元支持稳定币",
    Engages_desc:
      "TUSD与领先的会计事务所MooreHK合作，提供对其法定货币准备金的验证服务",
    Becomes_desc: "TUSD（TRC-20）成为多米尼加的法定货币",
    Blog_desc: "博客",
    Support_desc: "支持",
    TermsOf_desc: "使用条款",
    Privacy_desc: "隐私政策",
    TrueUsd_desc: "TrueUSD",
    Home_desc: "首页",
    Transparency_desc: "透明度",
    Ecosystem_desc: "生态系统",
    About_desc: "关于",
    Supported_desc: "支持的链",
    Other_desc: "其他链",
    rights_desc: "© 2023 TrueUSD. 保留所有权利。",
    Back_desc: "返回顶部",
  },
  project_desc: {
    Pool_desc: "资金池",
    participating_desc: "作为参与代币，您将获得",
    rewards_desc: "作为奖励。",
    EndTime_desc: "项目结束时间",
    duration_desc: "质押期限",
    days_desc: "天",
    calculation_desc: "周期性计算",
    Invite_desc: "邀请",
    level_desc: "级别佣金",

    withdrawal_desc: "最低提现金额",
    supply_desc: "最低供应金额",

    period_desc: "最低提现周期",
    Invitations_desc: "邀请级别",
    People_desc: "人数",
  },
  capitaInfoView_desc: {
    Please_desc: "请链接地址",
    Pool_desc: "资金池",
    receive_desc: "作为参与代币，您将获得",
    rewards_desc: "作为奖励。",
    level_desc: "级别资金池",
    Expired_desc: "已过期",
    Unexpired_desc: "未过期",
    Participated_desc: "已参与",
    uncommitted_desc: "未承诺",
    Withdraw_desc: "提现",
    Redeem_desc: "赎回",
    Status_desc: "状态",

    Principal_desc: "本金",
    Redeemed_desc: "已赎回",
    subaccounts_desc: "子账户",
    Supplied_desc: "已供应",
    Redeemable_desc: "可赎回",
    Commission_desc: "佣金",
    Available_desc: "可提现",
    Withdrawed_desc: "已提现",
    Lock_desc: "锁定佣金",
    All_desc: "所有佣金",
    interest_desc: "利息",
    Settled_desc: "已结算",
    Loading_desc: "加载中",
    amount_desc: "可提现金额",
  },
  elModal_desc: {
    Please_desc: "请输入充值金额",
    Recharge_desc: "充值",
    Cancel_desc: "取消",
    withdrawal_desc: "请输入提现金额",
    Withdraw_desc: "提现",
    redemption_desc: "请输入赎回金额",
    Redeem_desc: "赎回",
    Withdrawable_desc: "可提现金额（佣金+利息）:",
    balance_desc: "可赎回余额:",
    input_desc: "请输入金额",
    Transaction_desc: "交易成功！",
    failed_desc: "交易失败",
    funds_desc: "账户资金不足",
    Loading_desc: "加载中",
  },
  modal: {
    hold_desc: "如果你拥有",
    Smart_desc: "在BNB智能链中， 您将获得奖金奖励，",
    Rewards_desc: "只有超过一定金额的购买才能获得奖励。",
    address_desc: "该地址持有",
    hours_desc:
      "在24小时内持有，并且最低持有量基于系统的随机快照。金额将每24小时进行分配。实际分配以账户为准。",
    copyLink_desc: "复制链接",
  },
  UserInfo: {
    Wallets_desc: "钱包",
    Reward_desc: "奖励",
    half_month: "15天",
    one_month: "1个月",
    three_month: "3个月",
    Invitees: "被邀请",
    no_data: "什么都没有哦",
    walletNo_support: "你的钱包不支持,请用其它钱包app",
    network_wrong: "请选择正确的网络",
  },
};
export default zh;

// Datajson:{
//     language_json:{
//         zh_json:'简体中文',
//         zh_FT_json: '繁体中文',
//         eh_json:'英语',
//         ja_JP_json:'日语',
//         ko_KR_json:'韩语' ,
//         en_TH_json:'泰语',
//         fr_FR_json:'法语',
//         ru_RU_json:'俄语',
//         en_IN_json:'印地语',
//         en_ID_json:'印度尼西亚语',
//         en_BS_json:'波斯语',
//         en_YN_json:'越南语',
//         en_ALB_json:'阿拉伯语',
//         en_TEQ_json:'土耳其语',
//         en_ES_json:'西班牙语',
//         en_PTY_json:'葡萄牙语',
//         en_YDL_json:'意大利语'
//     },
// },
