import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zh from './zh' //中文
import zh_FT from './zh_FT' //繁体中文
import en from './en' //英文
import ja_JP from './ja_JP' //日语
import ko_KR from './ko_KR' //  韩语
import en_TH from './en_TH' //泰语
import fr_FR from './fr_FR' //法语
import ru_RU from './ru_RU' //俄语
import en_IN from './en_IN' //印度
import en_ID from './en_ID' //印度尼西亚
import en_BS from './en_BS' //波斯
import en_YN from './en_YN' //越南
import en_ALB from './en_ALB' //阿拉伯
import en_TEQ from './en_TEQ' //土耳其
import en_ES from './en_ES' //西班牙
import en_PTY from './en_PTY' //葡萄牙
import en_YDL from './en_YDL' //意大利语
Vue.use(VueI18n)
const i18n = new VueI18n({
    locale: 'en', // 语言标识
    // 添加多语言（每一个语言标示对应一个语言文件）
    messages: {
        zh_FT,
        zh,
        en,
        ja_JP,
        ko_KR,
        en_TH,
        fr_FR,
        ru_RU,
        en_IN,
        en_ID,
        en_BS,
        en_YN,
        en_ALB,
        en_TEQ,
        en_ES,
        en_PTY,
        en_YDL
    }
})

export default i18n