<template>
  <div class="home-view">
    <MeModel
      :isShow="isShow"
      @close="handleModalclose"
      :userInfo="userInfo"
      :projectInfo="projectInfo"
    ></MeModel>
    <div class="Home-content">
      <div class="Home-wrapper">
        <div class="wrapper-left">
          <div class="title">
            {{ $t("newhome_desc.title_desc") }}
          </div>
          <div class="desc">
            {{ $t("newhome_desc.desc_desc") }}
          </div>
          <div class="btngroup">
            <!-- <div class="bto-left" @click="onContractInfo">Get Started</div> -->
            <div class="bto-left" @click="onAirdrop">
              {{ $t("newhome_desc.Started_desc") }}
            </div>
            <!--  -->
            <!-- <div class="bto-right" @click="onProjectInfo">
              <a>{{ $t("newhome_desc.Contact_desc") }}</a>
            </div> -->
          </div>
        </div>
        <div class="wrapper-right">
          <video
            :width="videoWeight"
            :height="videoHeight"
            muted
            autoplay
            poster="../assets/banner.png"
          >
            <source src="../assets/mp4/home-kv.mp4" />
          </video>
        </div>
      </div>
      <div class="Home-dataArea">
        <div class="ho-li">
          <div class="ho-text1">499.70M</div>
          <div class="ho-text2">{{ $t("newhome_desc.Circulating_desc") }}</div>
        </div>
        <div class="ho-li">
          <div class="ho-text1">173.43M</div>
          <div class="ho-text2">24h {{ $t("newhome_desc.Trading_desc") }}</div>
        </div>
        <div class="ho-li">
          <div class="ho-text1">5.09M</div>
          <div class="ho-text2">{{ $t("newhome_desc.Transaction_desc") }}</div>
        </div>
        <div class="ho-li">
          <div class="ho-text1">10+</div>
          <div class="ho-text2">{{ $t("newhome_desc.Support_desc1") }}</div>
        </div>
      </div>
      <div class="Home_whyTusd">
        <div class="Home-wrapper-why">
          <div class="Home-wrapper-item">
            <div class="Home_imgbox">
              <img
                width="72"
                height="72"
                src="https://tusd.io/imgs/home/trustworthy.svg
"
              />
            </div>
            <div class="h4-text">
              <h4>{{ $t("newhome_desc.Trustworthy_desc") }}</h4>
            </div>
            <div class="p-text">
              {{ $t("newhome_desc.utilizes_desc") }}
            </div>
          </div>
          <div class="Home-wrapper-item">
            <div class="Home_imgbox">
              <img
                width="72"
                height="72"
                src="https://tusd.io/imgs/home/easy-to-use.svg
"
              />
            </div>
            <div class="h4-text">
              <h4>{{ $t("newhome_desc.Popularity_desc") }}</h4>
            </div>
            <div class="p-text">
              {{ $t("newhome_desc.convenience_desc") }}
            </div>
          </div>
          <div class="Home-wrapper-item">
            <div class="Home_imgbox">
              <img
                width="72"
                height="72"
                src="https://tusd.io/imgs/home/better-liquidity.svg
"
              />
            </div>
            <div class="h4-text">
              <h4>{{ $t("newhome_desc.Liquidity_desc") }}</h4>
            </div>
            <div class="p-text">
              {{ $t("newhome_desc.During_desc") }}
            </div>
          </div>
        </div>
      </div>
      <div class="Home_exchanges">
        <div class="span-text">{{ $t("newhome_desc.Trusted_desc") }}</div>
        <div class="img-box-list">
          <div class="img-box">
            <img
              alt="https://www.binance.com"
              src="https://tusd.io/imgs/home/binance.svg"
            />
          </div>
          <div class="img-box">
            <img
              alt="https://www.binance.com"
              src="https://tusd.io/imgs/home/huobi.svg"
            />
          </div>
          <div class="img-box">
            <img
              alt="https://www.binance.com"
              src="	https://tusd.io/imgs/home/curve.svg
"
            />
          </div>
          <div class="img-box">
            <img
              alt="https://www.binance.com"
              src="https://tusd.io/imgs/home/pancakeswap.svg"
            />
          </div>
        </div>
      </div>
      <div class="Home_howUse">
        <div class="Home_howUse-wrapper">
          <div class="h4_using">{{ $t("newhome_desc.Using_desc") }}</div>
          <div class="Home_content">
            <div class="Home_left">
              <div
                class="Home_item"
                v-for="(item, index) in optionsTags"
                :key="index"
              >
                <div class="item-img">
                  <img
                    v-if="usingActiveId == index"
                    src="https://tusd.io/imgs/home/icon/arrow-right-circle-32.svg"
                  />
                </div>
                <div class="item-bot">
                  <div
                    :class="`button ${
                      usingActiveId == index ? 'buttonActive' : ''
                    }`"
                    @click="onSeletd(index)"
                  >
                    {{ item }}
                  </div>
                </div>
              </div>
            </div>
            <div class="Home_rght">
              <div class="Home_ul-box">
                <div class="rght-oumS">
                  <img :src="usingList[usingActiveId].imageUrl" />
                </div>
                <div class="Home_desc__cE3Yv">
                  <div class="text_">
                    {{ usingList[usingActiveId].text_ }}
                  </div>
                  <div class="link_">
                    <a target="_blank" href="/ecosystem"></a>
                    {{ $t("newhome_desc.LearnMore_desc") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="Home_multiChain">
        <div class="Home_wrapper_multiChain">
          <div class="Home_title">{{ $t("newhome_desc.Goes_desc") }}</div>
          <div class="Home_nativeNet">
            <div class="title">{{ $t("newhome_desc.Natively_desc1") }}</div>
            <div class="Home_chainbox__afh7V">
              <div class="chainbox-item">
                <div class="chainbox-img"></div>
                <div class="chain-span">
                  {{ $t("newhome_desc.Ethereum_desc") }}
                </div>
              </div>
              <div class="chainbox-item">
                <div class="chainbox-img"></div>
                <div class="chain-span">{{ $t("newhome_desc.TRON_desc") }}</div>
              </div>
              <div class="chainbox-item">
                <div class="chainbox-img"></div>
                <div class="chain-span">
                  {{ $t("newhome_desc.Avalanche_desc") }}
                </div>
              </div>
              <div class="chainbox-item">
                <div class="chainbox-img"></div>
                <div class="chain-span">
                  {{ $t("newhome_desc.Smart_desc") }}
                </div>
              </div>
            </div>
          </div>
          <div class="Home_bridgeNet">
            <div class="Home-title">{{ $t("newhome_desc.Bridged_desc") }}</div>
            <div class="Home_chainbox__">
              <div class="li-item">
                <div class="li-img"></div>
                <div class="li-span">
                  {{ $t("newhome_desc.Polygon2_desc") }}
                </div>
              </div>
              <div class="li-item">
                <div class="li-img"></div>
                <div class="li-span">{{ $t("newhome_desc.Fantom2_desc") }}</div>
              </div>
              <div class="li-item">
                <div class="li-img"></div>
                <div class="li-span">
                  {{ $t("newhome_desc.Arbitrum_desc") }}
                </div>
              </div>
              <div class="li-item">
                <div class="li-img"></div>
                <div class="li-span">{{ $t("newhome_desc.Cronos_desc") }}</div>
              </div>
              <div class="li-item">
                <div class="li-img"></div>
                <div class="li-span">
                  {{ $t("newhome_desc.Optimism_desc") }}
                </div>
              </div>
              <div class="li-item">
                <div class="li-img"></div>
                <div class="li-span">{{ $t("newhome_desc.Aurora_desc") }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="Home_faqs__VH_jU">
        <div class="Home_wrapper__title">
          {{ $t("newhome_desc.FAQs_desc") }}
        </div>
        <div class="Faq_container__PSGn8">
          <div
            class="Faq_outer__item"
            :style="{
              color: qcTPY_active0 ? 'white' : 'black',
              backgroundColor: qcTPY_active0 ? 'black' : 'white',
            }"
          >
            <div class="Faq_wrapper__ikCOW">
              <div class="Faq_questionBox__y3svU">
                <div class="left">{{ $t("newhome_desc.TrueUSD_desc") }}</div>
                <div class="right">
                  <img
                    v-if="qcTPY_active0"
                    @click="activeFAQS(0)"
                    src="https://tusd.io/imgs/home/icon/indeterminate-circle-white.svg"
                  />
                  <img
                    v-else
                    @click="activeFAQS(0)"
                    src="https://tusd.io/imgs/home/icon/add-circle-32.svg"
                  />
                </div>
              </div>
              <div
                class="Faq_answer__qcTPY"
                :style="{
                  maxHeight: qcTPY_active0 ? contentHeight0 + 'px' : 0 + 'px',
                }"
                ref="contents0"
              >
                <div class="Faq_descBox__BmnId">
                  {{ $t("newhome_desc.Banking_desc") }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="Faq_outer__item"
            :style="{
              color: qcTPY_active1 ? 'white' : 'black',
              backgroundColor: qcTPY_active1 ? 'black' : 'white',
            }"
          >
            <div class="Faq_wrapper__ikCOW">
              <div class="Faq_questionBox__y3svU">
                <div class="left">{{ $t("newhome_desc.started_desc") }}</div>
                <div class="right">
                  <img
                    v-if="qcTPY_active1"
                    @click="activeFAQS(1)"
                    src="https://tusd.io/imgs/home/icon/indeterminate-circle-white.svg"
                  />
                  <img
                    v-else
                    @click="activeFAQS(1)"
                    src="https://tusd.io/imgs/home/icon/add-circle-32.svg"
                  />
                </div>
              </div>
              <div
                class="Faq_answer__qcTPY"
                :style="{
                  maxHeight: qcTPY_active1 ? contentHeight1 + 'px' : 0 + 'px',
                }"
                ref="contents1"
              >
                <div class="Faq_descBox__BmnId">
                  <div class="Faq_descBox-p">
                    {{ $t("newhome_desc.stepsbelow_desc") }}
                  </div>
                  <div>
                    <ol>
                      <li>
                        1. {{ $t("newhome_desc.verify_desc") }}
                        <a target="_blank" href="https://app.tusd.io">{{
                          $t("newhome_desc.website_desc")
                        }}</a>
                        <ul>
                          <li class="flx">
                            <span>a.</span>
                            <div class="Faq_subdesc__DX1sk">
                              {{ $t("newhome_desc.creating_desc") }}
                              <a
                                target="_blank"
                                href="mailto:no-reply@trueusd.com"
                                >no-reply@trueUSD.com</a
                              >
                              {{ $t("newhome_desc.verification_desc") }}
                              <a
                                target="_blank"
                                href="mailto:no-reply@trueusd.com"
                                >2FA</a
                              >
                            </div>
                          </li>
                          <li class="flx">
                            <span>b.</span>
                            <div class="Faq_subdesc__DX1sk">
                              {{ $t("newhome_desc.information_desc") }}
                              <a
                                target="_blank"
                                href="https://trueusd.zendesk.com/hc/en-us/articles/4408359106329"
                                >{{ $t("newhome_desc.here_desc") }}</a
                              >.
                            </div>
                          </li>
                          <li class="flx">
                            <span>c.</span>
                            <div class="Faq_subdesc__DX1sk">
                              {{ $t("newhome_desc.application_desc") }}
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li>
                        2. {{ $t("newhome_desc.Redeem_desc") }}
                        <ul>
                          <li class="flx">
                            <span>a.</span>
                            <div class="Faq_subdesc__DX1sk">
                              {{ $t("newhome_desc.Trade_desc") }}
                            </div>
                          </li>
                          <li class="flx">
                            <span>b.</span>
                            <div class="Faq_subdesc__DX1sk">
                              {{ $t("newhome_desc.Send_desc") }}
                            </div>
                          </li>
                        </ul>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="Faq_outer__item"
            :style="{
              color: qcTPY_active2 ? 'white' : 'black',
              backgroundColor: qcTPY_active2 ? 'black' : 'white',
            }"
          >
            <div class="Faq_wrapper__ikCOW">
              <div class="Faq_questionBox__y3svU">
                <div class="left">{{ $t("newhome_desc.mint_desc") }}</div>
                <div class="right">
                  <img
                    v-if="qcTPY_active2"
                    @click="activeFAQS(2)"
                    src="https://tusd.io/imgs/home/icon/indeterminate-circle-white.svg"
                  />
                  <img
                    v-else
                    @click="activeFAQS(2)"
                    src="https://tusd.io/imgs/home/icon/add-circle-32.svg"
                  />
                </div>
              </div>
              <div
                class="Faq_answer__qcTPY"
                :style="{
                  maxHeight: qcTPY_active2 ? contentHeight2 + 'px' : 0 + 'px',
                }"
                ref="contents2"
              >
                <div class="Faq_descBox__BmnId">
                  <div class="Faq_descBox-p">
                    {{ $t("newhome_desc.partner_desc1") }}

                    <a target="_blank" href="https://app.tusd.io">{{
                      $t("newhome_desc.Trueapp_desc")
                    }}</a>
                  </div>
                  <div>
                    <ul>
                      <li class="flx">
                        <span>1.</span>
                        <div class="Faq_subdesc__DX1sk">
                          {{ $t("newhome_desc.Please_desc") }}
                        </div>
                      </li>
                      <li class="flx">
                        <span>2.</span>
                        <div class="Faq_subdesc__DX1sk">
                          {{ $t("newhome_desc.account_desc") }}
                        </div>
                      </li>
                      <li class="flx">
                        <span>3.</span>
                        <div class="Faq_subdesc__DX1sk">
                          {{ $t("newhome_desc.generally_desc") }}
                        </div>
                      </li>
                      <li class="flx">
                        <span>4.</span>
                        <div class="Faq_subdesc__DX1sk">
                          {{ $t("newhome_desc.email_desc") }}
                        </div>
                      </li>
                      <li class="flx">
                        <span>5.</span>
                        <div class="Faq_subdesc__DX1sk">
                          {{ $t("newhome_desc.minted_desc") }}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="Faq_outer__item"
            :style="{
              color: qcTPY_active3 ? 'white' : 'black',
              backgroundColor: qcTPY_active3 ? 'black' : 'white',
            }"
          >
            <div class="Faq_wrapper__ikCOW">
              <div class="Faq_questionBox__y3svU">
                <div class="left">{{ $t("newhome_desc.redeem4_desc") }}</div>
                <div class="right">
                  <img
                    v-if="qcTPY_active3"
                    @click="activeFAQS(3)"
                    src="https://tusd.io/imgs/home/icon/indeterminate-circle-white.svg"
                  />
                  <img
                    v-else
                    @click="activeFAQS(3)"
                    src="https://tusd.io/imgs/home/icon/add-circle-32.svg"
                  />
                </div>
              </div>
              <div
                class="Faq_answer__qcTPY"
                :style="{
                  maxHeight: qcTPY_active3 ? contentHeight3 + 'px' : 0 + 'px',
                }"
                ref="contents3"
              >
                <div class="Faq_descBox__BmnId">
                  <div class="Faq_descBox-p">
                    {{ $t("newhome_desc.Visit_desc")
                    }}<a target="_blank" href="https://app.tusd.io">{{
                      $t("newhome_desc.Polygon_desc1")
                    }}</a>
                    {{ $t("newhome_desc.receive_desc") }}
                  </div>
                  <div>
                    <ul>
                      <li class="flx">
                        <span>1.</span>
                        <div class="Faq_subdesc__DX1sk">
                          {{ $t("newhome_desc.redemption_desc") }}
                        </div>
                      </li>
                      <li class="flx">
                        <span>2.</span>
                        <div class="Faq_subdesc__DX1sk">
                          <a
                            target="_blank"
                            href="https://trueusd.zendesk.com/hc/en-us/articles/4408359271961"
                          >
                            {{ $t("newhome_desc.simple_desc") }}</a
                          >
                          {{ $t("newhome_desc.notice_desc") }}
                        </div>
                      </li>
                    </ul>
                    <p>
                      {{ $t("newhome_desc.unique_desc") }}
                    </p>
                    <p>
                      {{ $t("newhome_desc.partner_desc") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="Faq_outer__item"
            :style="{
              color: qcTPY_active4 ? 'white' : 'black',
              backgroundColor: qcTPY_active4 ? 'black' : 'white',
            }"
          >
            <div class="Faq_wrapper__ikCOW">
              <div class="Faq_questionBox__y3svU">
                <div class="left">
                  {{ $t("newhome_desc.exchanges_desc") }}
                </div>
                <div class="right">
                  <img
                    v-if="qcTPY_active4"
                    @click="activeFAQS(4)"
                    src="https://tusd.io/imgs/home/icon/indeterminate-circle-white.svg"
                  />
                  <img
                    v-else
                    @click="activeFAQS(4)"
                    src="https://tusd.io/imgs/home/icon/add-circle-32.svg"
                  />
                </div>
              </div>
              <div
                class="Faq_answer__qcTPY"
                :style="{
                  maxHeight: qcTPY_active4 ? contentHeight4 + 'px' : 0 + 'px',
                }"
                ref="contents4"
              >
                <div class="Faq_descBox__BmnId">
                  <div class="Faq_descBox-p">
                    <p>
                      {{ $t("newhome_desc.Binance_desc") }}
                    </p>
                    <p>
                      <a
                        target="_blank"
                        href="https://coinmarketcap.com/currencies/trueusd/#markets"
                      >
                        {{ $t("newhome_desc.here2_desc") }}</a
                      >
                      {{ $t("newhome_desc.markets_desc") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="Faq_outer__item"
            :style="{
              color: qcTPY_active5 ? 'white' : 'black',
              backgroundColor: qcTPY_active5 ? 'black' : 'white',
            }"
          >
            <div class="Faq_wrapper__ikCOW">
              <div class="Faq_questionBox__y3svU no-padding">
                <div class="left">
                  {{ $t("newhome_desc.contract_desc") }}
                </div>
                <div class="right">
                  <img
                    v-if="qcTPY_active5"
                    @click="activeFAQS(5)"
                    src="https://tusd.io/imgs/home/icon/indeterminate-circle-white.svg"
                  />
                  <img
                    v-else
                    @click="activeFAQS(5)"
                    src="https://tusd.io/imgs/home/icon/add-circle-32.svg"
                  />
                </div>
              </div>
              <div
                class="Faq_answer__qcTPY"
                :style="{
                  maxHeight: qcTPY_active5 ? contentHeight5 + 'px' : 0 + 'px',
                }"
                ref="contents5"
              >
                <div class="Faq_descBox__BmnId">
                  <div class="Faq_descBox-p">
                    {{ $t("newhome_desc.tokenSmart_desc") }}
                    <a target="_blank" href="https://app.tusd.io">{{
                      $t("newhome_desc.blog_desc")
                    }}</a>
                    <p>
                      {{ $t("newhome_desc.related_desc") }}
                    </p>
                  </div>
                  <div>
                    <ol>
                      <li>
                        <span> 1. {{ $t("newhome_desc.Natively_desc") }}</span>
                        <ul>
                          <li class="flx">
                            <span>a.</span>
                            <div class="Faq_subdesc__DX1sk">
                              {{ $t("newhome_desc.Ethererum2_desc") }}:<a
                                target="_blank"
                                href="https://etherscan.io/address/0x0000000000085d4780b73119b644ae5ecd22b376"
                                >0x0000000000085d4780b73119b644ae5ecd22b376</a
                              >
                            </div>
                          </li>
                          <li class="flx">
                            <span>b.</span>
                            <div class="Faq_subdesc__DX1sk">
                              {{ $t("newhome_desc.SmartChain_desc") }}:<a
                                target="_blank"
                                href="https://bscscan.com/address/0x40af3827f39d0eacbf4a168f8d4ee67c121d11c9"
                                >0x40af3827f39d0eacbf4a168f8d4ee67c121d11c9</a
                              >
                            </div>
                          </li>
                          <li class="flx">
                            <span>c.</span>
                            <div class="Faq_subdesc__DX1sk">
                              {{ $t("newhome_desc.TRON_desc") }}:<a
                                target="_blank"
                                href="https://tronscan.org/#/contract/TUpMhErZL2fhh4sVNULAbNKLokS4GjC1F4"
                                >TUpMhErZL2fhh4sVNULAbNKLokS4GjC1F4</a
                              >
                            </div>
                          </li>
                          <li class="flx">
                            <span>d.</span>
                            <div class="Faq_subdesc__DX1sk">
                              {{ $t("newhome_desc.Avalanche2_desc") }} :<a
                                target="_blank"
                                href="https://snowtrace.io/token/0x1C20E891Bab6b1727d14Da358FAe2984Ed9B59EB"
                                >0x1C20E891Bab6b1727d14Da358FAe2984Ed9B59EB</a
                              >
                            </div>
                          </li>
                          <li class="flx">
                            <span>e.</span>
                            <div class="Faq_subdesc__DX1sk">
                              {{ $t("newhome_desc.Beacon_desc") }}:<a
                                target="_blank"
                                href="https://explorer.bnbchain.org/asset/TUSDB-888"
                                >TUSDB-888</a
                              >
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <span>2.{{ $t("newhome_desc.Networks2_desc") }}</span>
                        <ul>
                          <li class="flx">
                            <span>a.</span>
                            <div class="Faq_subdesc__DX1sk">
                              {{ $t("newhome_desc.Polygon2_desc") }}:<a
                                target="_blank"
                                href="https://polygonscan.com/token/0x2e1ad108ff1d8c782fcbbb89aad783ac49586756"
                                >0x2e1ad108ff1d8c782fcbbb89aad783ac49586756</a
                              >
                            </div>
                          </li>
                          <li class="flx">
                            <span>b.</span>
                            <div class="Faq_subdesc__DX1sk">
                              {{ $t("newhome_desc.Fantom2_desc") }}:<a
                                target="_blank"
                                href="https://ftmscan.com/token/0x9879abdea01a879644185341f7af7d8343556b7a"
                                >0x9879abdea01a879644185341f7af7d8343556b7a</a
                              >
                            </div>
                          </li>
                          <li class="flx">
                            <span>c.</span>
                            <div class="Faq_subdesc__DX1sk">
                              {{ $t("newhome_desc.TRON_desc") }}:<a
                                target="_blank"
                                href="https://arbiscan.io/token/0x4d15a3a2286d883af0aa1b3f21367843fac63e07"
                                >0x4d15a3a2286d883af0aa1b3f21367843fac63e07</a
                              >
                            </div>
                          </li>
                          <li class="flx">
                            <span>d.</span>
                            <div class="Faq_subdesc__DX1sk">
                              {{ $t("newhome_desc.Cronos_desc") }} :<a
                                target="_blank"
                                href="https://cronos.org/explorer/token/0x87EFB3ec1576Dec8ED47e58B832bEdCd86eE186e/token-transfers"
                                >0x87EFB3ec1576Dec8ED47e58B832bEdCd86eE186e</a
                              >
                            </div>
                          </li>
                          <li class="flx">
                            <span>e.</span>
                            <div class="Faq_subdesc__DX1sk">
                              {{ $t("newhome_desc.Polygon_desc2") }}:<a
                                target="_blank"
                                href="https://optimistic.etherscan.io/address/0xcB59a0A753fDB7491d5F3D794316F1adE197B21E"
                                >0xcB59a0A753fDB7491d5F3D794316F1adE197B21E</a
                              >
                            </div>
                          </li>
                          <li class="flx">
                            <span>f.</span>
                            <div class="Faq_subdesc__DX1sk">
                              {{ $t("newhome_desc.Aurora_desc") }}:<a
                                target="_blank"
                                href="https://explorer.aurora.dev/address/0x5454bA0a9E3552f7828616D80a9D2D869726e6F5"
                                >0x5454bA0a9E3552f7828616D80a9D2D869726e6F5</a
                              >
                            </div>
                          </li>
                          <li class="flx">
                            <span>g.</span>
                            <div class="Faq_subdesc__DX1sk">
                              {{ $t("newhome_desc.ChainOld_desc") }}:<a
                                target="_blank"
                                href="https://bscscan.com/address/0x14016e85a25aeb13065688cafb43044c2ef86784"
                                >0x14016e85a25aeb13065688cafb43044c2ef86784</a
                              >
                            </div>
                          </li>
                        </ul>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="Faq_outer__item">
            <div class="Faq_wrapper__ikCOW">
              <a target="_blank" href="https://trueusd.zendesk.com/hc/en-us">
                <div class="Faq_questionBox__y3svU">
                  <div class="left">
                    {{ $t("newhome_desc.LearnMore_desc") }}
                  </div>
                  <div class="right">
                    <img
                      src="https://tusd.io/imgs/home/icon/arrow-right-circle-32.svg"
                    />
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="Home_learnMore__Z2cib">
        <div class="Home_learnMor_ewrapper">
          <div class="learnMor_title">
            <div class="learnMor_title-box">
              {{ $t("newhome_desc.AboutMore_desc") }}
            </div>
          </div>
          <div class="Home_itembox__2VPn4">
            <a
              target="_blank"
              href="https://medium.com/@trueusd/trueusd-becomes-first-usd-backed-stablecoin-to-secure-minting-with-proof-of-reserves-fe8dbffde44f"
            >
              <div class="Home_item bag-img">
                <p>
                  <span>{{ $t("newhome_desc.Stablecoin_desc") }}</span>
                </p>
                <div class="Home_bottom__llQxm">
                  <div>Feb 22 2023</div>
                  <div class="Home_icon">
                    <img
                      src="https://tusd.io/imgs/home/icon/arrow-right-up-circle-32.svg"
                    />
                  </div>
                </div>
              </div>
            </a>
            <a
              target="_blank"
              href="https://trueusd.medium.com/tusd-engages-leading-accounting-firm-moorehk-to-provide-attestation-service-on-its-fiat-reserve-36ecfa5117cf"
            >
              <div class="Home_item">
                <p>
                  <span>{{ $t("newhome_desc.Engages_desc") }}</span>
                </p>
                <div class="Home_bottom__llQxm">
                  <div>Dec 25 2023</div>
                  <div class="Home_icon">
                    <img
                      src="https://tusd.io/imgs/home/icon/arrow-right-up-circle-32.svg"
                    />
                  </div>
                </div>
              </div>
            </a>
            <a
              target="_blank"
              href="https://medium.com/@trueusd/tusd-trc-20-becomes-legal-tender-in-dominica-ec95e6c507fa"
            >
              <div class="Home_item">
                <p>
                  <span>{{ $t("newhome_desc.Becomes_desc") }}</span>
                </p>
                <div class="Home_bottom__llQxm">
                  <div>Oct 18 2022</div>
                  <div class="Home_icon">
                    <img
                      src="https://tusd.io/imgs/home/icon/arrow-right-up-circle-32.svg"
                    />
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="Home_box__oaTlq">
            <a target="_blank" href="https://trueusd.medium.com/">
              <div>{{ $t("newhome_desc.LearnMore_desc") }}</div>
            </a>
          </div>
        </div>
      </div>

      <div class="Footer_pc__sI30R">
        <div class="Footer_container__DgD_U">
          <div class="Footer_toparea__Et9WZ">
            <div class="Footer_content__n81BC">
              <p>{{ $t("newhome_desc.Digital_desc") }}</p>
              <a target="_blank" href="https://app.tusd.io/signup-or-signin">{{
                $t("newhome_desc.Started_desc")
              }}</a>
            </div>
          </div>
          <div class="Footer_bottomarea__hRRwG">
            <div class="Footer_wrapper__d6Rks">
              <div class="Footer_topbox__6Hu7G">
                <div class="Footer_leftbox__YKIUY">
                  <div class="Footer_logobox__Jcm_6">
                    <img
                      width="40"
                      height="40"
                      src="	https://tusd.io/imgs/tusd_logo_white.svg"
                    /><span>TUSD</span>
                  </div>
                  <p class="Jcm6p">
                    {{ $t("newhome_desc.first_desc") }}
                  </p>
                  <div class="Footer_contactbox__of20K">
                    <span class="span_of20K">
                      {{ $t("newhome_desc.Follow_desc") }}
                    </span>
                    <div class="Footer_icongroup__j3wAR">
                      <a target="_blank" href="https://twitter.com/tusdio">
                        <span>
                          <img
                            width="24"
                            height="24"
                            src="@/assets/icon1.png"
                          />
                        </span>
                      </a>
                      <a target="_blank" href="https://discord.gg/DU4CXtcsSZ">
                        <span
                          ><img
                            width="24"
                            height="24"
                            src="@/assets/icon2.png" /></span
                      ></a>
                      <a target="_blank" href="https://medium.com/@trueusd">
                        <span
                          ><img
                            width="24"
                            height="24"
                            src="@/assets/icon3.png" /></span
                      ></a>
                      <a target="_blank" href="https://t.me/TUSDofficial_EN">
                        <span
                          ><img
                            width="24"
                            height="24"
                            src="@/assets/icon4.png" /></span
                      ></a>
                      <a
                        target="_blank"
                        href="https://www.youtube.com/@trueusdofficial1014"
                      >
                        <span
                          ><img
                            width="24"
                            height="24"
                            src="@/assets/icon5.png" /></span
                      ></a>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/truly_tusd/"
                      >
                        <span
                          ><img
                            width="24"
                            height="24"
                            src="@/assets/icon6.png" /></span
                      ></a>
                    </div>
                    <div>
                      <a class="sc-bcPKhP dEoRXL" href="/contactus">{{
                        $t("newhome_desc.Contact_desc")
                      }}</a>
                    </div>
                  </div>
                </div>
                <div class="Footer_rightbox__0EimC">
                  <div class="Footer-box">
                    <div class="Footer_linkTitle__oJgzk">
                      {{ $t("newhome_desc.Resources_desc") }}
                    </div>
                    <p class="Footer_linkDesc__Kdr0v">
                      <a target="_blank" href="https://medium.com/@trueusd"
                        >{{ $t("newhome_desc.Blog_desc") }}
                        <span class="Footer_icon__i3VIz"></span
                      ></a>
                    </p>
                    <p class="Footer_linkDesc__Kdr0v">
                      <a target="_blank" href="https://medium.com/@trueusd"
                        >{{ $t("newhome_desc.Support_desc") }}
                        <span class="Footer_icon__i3VIz"></span
                      ></a>
                    </p>
                    <p class="Footer_linkDesc__Kdr0v">
                      <a target="_blank" href="https://medium.com/@trueusd"
                        >{{ $t("newhome_desc.TermsOf_desc")
                        }}<span class="Footer_icon__i3VIz"></span
                      ></a>
                    </p>
                    <p class="Footer_linkDesc__Kdr0v">
                      <a target="_blank" href="https://medium.com/@trueusd"
                        >{{ $t("newhome_desc.Privacy_desc")
                        }}<span class="Footer_icon__i3VIz"></span
                      ></a>
                    </p>
                  </div>
                  <div class="Footer-box">
                    <div class="Footer_linkTitle__oJgzk">
                      {{ $t("newhome_desc.TrueUsd_desc") }}
                    </div>
                    <p class="Footer_linkDesc__Kdr0v">
                      <a target="_blank" href="https://medium.com/@trueusd">{{
                        $t("newhome_desc.Home_desc")
                      }}</a>
                    </p>
                    <p class="Footer_linkDesc__Kdr0v">
                      <a target="_blank" href="https://medium.com/@trueusd"
                        >{{ $t("newhome_desc.Transparency_desc") }}
                      </a>
                    </p>
                    <p class="Footer_linkDesc__Kdr0v">
                      <a target="_blank" href="https://medium.com/@trueusd">{{
                        $t("newhome_desc.Ecosystem_desc")
                      }}</a>
                    </p>
                    <p class="Footer_linkDesc__Kdr0v">
                      <a target="_blank" href="https://medium.com/@trueusd"
                        >{{ $t("newhome_desc.About_desc") }}
                      </a>
                    </p>
                  </div>
                  <div class="Footer-box">
                    <div class="Footer_linkTitle__oJgzk">
                      {{ $t("newhome_desc.Supported_desc") }}
                    </div>
                    <p class="Footer_linkDesc__Kdr0v">
                      <a target="_blank" href="https://medium.com/@trueusd"
                        >{{ $t("newhome_desc.Ethereum_desc") }}
                        <span class="Footer_icon__i3VIz"></span
                      ></a>
                    </p>
                    <p class="Footer_linkDesc__Kdr0v">
                      <a target="_blank" href="https://medium.com/@trueusd"
                        >{{ $t("newhome_desc.TRON_desc") }}
                        <span class="Footer_icon__i3VIz"></span
                      ></a>
                    </p>
                    <p class="Footer_linkDesc__Kdr0v">
                      <a target="_blank" href="https://medium.com/@trueusd"
                        >{{ $t("newhome_desc.Smart_desc")
                        }}<span class="Footer_icon__i3VIz"></span
                      ></a>
                    </p>
                    <p class="Footer_linkDesc__Kdr0v">
                      <a target="_blank" href="https://medium.com/@trueusd"
                        >{{ $t("newhome_desc.Avalanche_desc") }}
                        <span class="Footer_icon__i3VIz"></span
                      ></a>
                    </p>
                    <p class="Footer_linkDesc__Kdr0v">
                      <a target="_blank" href="https://medium.com/@trueusd"
                        >{{ $t("newhome_desc.Other_desc")
                        }}<span class="Footer_icon__i3VIz"></span
                      ></a>
                    </p>
                  </div>
                </div>
              </div>
              <div class="Footer_bottombox__6uOzh">
                <div>{{ $t("newhome_desc.rights_desc") }}</div>
                <div class="Footer_backTop__nsUYz">
                  {{ $t("newhome_desc.Back_desc") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Web3 from "web3";
import { Device } from "@/components/Device/device.js";
import MeModel from "./myModel";
import CryptoJS from "crypto-js";
import {
  getConfigsByproject,
  getUrlorCacheCode,
  getConfigsBybeforeconnect,
  getConfigsByCheck,
  getConfigsByBind,
  getIPDetails,
  getIpAddress,
  getDevice,
  getConfigsByStart,
} from "@/utils/api/api";
export default {
  name: "HomeView",
  components: {
    MeModel,
  },
  data() {
    return {
      isShow: false,
      projectInfo: {},
      LoginResults: {}, //登录回调的参数
      myCode: "", //我的邀请码
      userInfo: {
        qrUrl: "",
      },
      loading: true,
      project_key: this.$store.state.project_key,
      abiData: null,
      optionsTags: [
        this.$t("newhome_json.Exchanges_json"),
        this.$t("newhome_json.DeFi_json"),
        this.$t("newhome_json.Payments_json"),
        this.$t("newhome_json.Accessibility_json"),
      ],
      usingList: [
        {
          imageUrl: "https://tusd.io/imgs/home/exchange.svg",
          text_: this.$t("newhome_json.listed_json"),
        },
        {
          imageUrl: "https://tusd.io/imgs/home/defi.svg",
          text_: this.$t("newhome_json.stablecoin_json"),
        },
        {
          imageUrl: "	https://tusd.io/imgs/home/payments.svg",
          text_: this.$t("newhome_json.leading_json"),
        },
        {
          imageUrl: "https://tusd.io/imgs/home/chains.svg",
          text_: this.$t("newhome_json.available_json"),
        },
      ],
      usingActiveId: 0,
      qcTPY_active0: true,
      qcTPY_active1: false,
      qcTPY_active2: false,
      qcTPY_active3: false,
      qcTPY_active4: false,
      qcTPY_active5: false,
      contentHeight0: 0,
      contentHeight1: 0,
      contentHeight2: 0,
      contentHeight3: 0,
      contentHeight4: 0,
      contentHeight5: 0,
      videoHeight: 0,
      videoWeight: 0,
      screenWidth: 0,
      screenHeight: 0,
      code: null, //邀请我的码
      myAddress: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.updateContentHeights();
    });
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  async created() {
    this.code = this.geturlorCaheBYcode();
    let WalletType =  this.environmentWalletType();
    if (!WalletType) return;
    await this.ConnectWallet()
      .then(async (res) => {
        this.myAddress = res;
        console.log(
          "链接钱包",
          this.myAddress,
          this.$ls.get("WalletAddress_tusd")
        );
        await this.loadProjectDetail(); //获取白皮书信息
        await this.Initinvolved(); //初始化参与
      })
      .catch((error) => {
        console.log("链接钱包失败", error);
      }); //链接钱包
//
    await this.detectDevice();
  },
  computed: {},
  methods: {
    onProjectInfo() {
      this.$router.push("projectInfo");
    },
    //浏览器指纹识别
    async detectDevice() {
      const userAgent = navigator.userAgent;
      let address = this.$ls.get("WalletAddress_tusd") || ["0x"];
      let isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream; //判断是否苹果设备
      // console.log("是否苹果手机", isIOS,window.location.host,localStorage.getItem("code"));
      let res;
      let params;
      try {
        res = await getIpAddress();
      } catch (e) {
        res = {
          query: null,
          country: null,
          city: null,
        };
      }
      if (isIOS) {
        params = {
          OS: "iOS", //操作系统
          IP: res.ip || null, //IP归属地
          geoPosition: `${res.country},${res.city}`, //国家州市
          fingerprint: CryptoJS.MD5(address[0] + res.ip + window.location.host)
            .toString()
            .slice(-8), //指纹
          userAgent: "Not acquired", //userAgent
          address: address[0], //钱包地址
          screenHeight: "Not acquired", //设备屏幕高度
          screenWidth: "Not acquired", //设备屏幕宽度
          browser: "Not acquired", //浏览器信息
          os_version: "Not acquired", //操作系统版本
          project_Key: this.project_key,
          chain: parseInt(this.$ls.get("chainID_tusd")) || null, //链id
          agent: null,
          uuid: "Not acquired", //uuid
          pid: null,
        };
      } else {
        await Device.Info().then((item) => {
          params = {
            OS: item.OS, //操作系统
            IP: res.ip || null, //IP归属地
            geoPosition: `${res.country},${res.city}`, //国家州市
            fingerprint: item.fingerprint, //指纹
            userAgent: item.userAgent, //userAgent
            address: address[0], //钱包地址
            screenHeight: item.screenHeight, //设备屏幕高度
            screenWidth: item.screenWidth, //设备屏幕宽度
            browser: item.browserInfo, //浏览器信息
            os_version: item.OSVersion, //操作系统版本
            project_Key: this.project_key,
            chain: parseInt(this.$ls.get("chainID_tusd")) || null, //链id
            agent: null,
            uuid: item.UUID, //uuid
            pid: null,
          };
        });
      }
      const result = await getDevice(params)
        .then((res) => {
          if (res.code == 0) {
            // this.$message.error(res.info);
          }
        })
        .catch((error) => {
          this.$message.error("Fingerprint recognition error");
        });
    },
    //初始化参与
    async Initinvolved() {
      // //操作连接钱包之前操作
      let WalletType = this.environmentWalletType(); //判断钱包类型是否正确
      if (!WalletType) return;
      this.abiData = await this.initConnector();
      if (this.abiData == null) {
        this.$message.error(this.$t("UserInfo.network_wrong"));
        return;
      }
      //缓存重要参数
      await this.cacheProject(this.abiData);
      //网络判断
      let chainChecked = await this.checkChain(this.abiData);
      if (!chainChecked) {
        this.$message.error(this.$t("newhome_json.error2_json"));
        return;
      }

      let connecting_address = this.myAddress[0];
      if (connecting_address == null) {
        this.$message.error(this.$t("newhome_json.error3s_json"));
      }
      await this.checkAddress(connecting_address);
    },
    handleModalclose() {
      this.isShow = false;
    },
    async loadProjectDetail() {
      const fullLoading = this.$loading({
        lock: true,
        text: this.$t("newhome_json.Loading1_json"),
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = { project_key: this.project_key };
      let res = await getConfigsByproject(params);
      fullLoading.close();
      this.loading = false;
      this.projectInfo = res.data;
      console.log(this.projectInfo);
    },
    cacheProject(abiData) {
      localStorage.setItem(
        "approve_addr_" + this.project_key,
        abiData.approve_addr
      ); //批准
      localStorage.setItem("abi_" + this.project_key, abiData.abi); //abi
      localStorage.setItem(
        "infura_key_" + this.project_key,
        abiData.Infura_key
      );
      localStorage.setItem(
        "contract_addr_" + this.project_key,
        abiData.contract_addr
      );
      localStorage.setItem("chain_id_" + this.project_key, abiData.chain_id);
    },
    async initConnector() {
      const fullLoading = this.$loading({
        lock: true,
        text: this.$t("newhome_json.Loading2_json"),
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = { project_key: this.project_key };
      let res = await getConfigsBybeforeconnect(params);
      fullLoading.close();
      if (res.code === 1) {
        return res.data;
      } else {
        this.$message.error(res.info);
        return;
      }
    },
    //获取code
    geturlorCaheBYcode() {
      console.log("我的令牌", this.code, window.location.href);
      this.code = getUrlorCacheCode();
      if (this.code !== null) {
        localStorage.setItem("code", this.code);
      }
      console.log("我的令牌", this.code);
    },
    //获取链id/监听链id切换
    async checkChain(params) {
      if (window.ethereum) {
        try {
          let network = this.$ls.get("chainID_tusd");
          console.log("链是否正确:", network, params.chain_id);
          if (network != params.chain_id) {
            console.log("链不正确:应该为", params.chain_id, "当前为", network);
            let messageText1 =
              "The network is incorrect The correct network ID is";
            let messageText2 = " The current network is";
            this.$message({
              type: "error",
              dangerouslyUseHTMLString: true,
              message: `<div> ${messageText1}${params.chain_id},${messageText2}${network}</div>`,
            });
            return false;
          } else {
            return network == params.chain_id;
          }
        } catch (e) {
          return false;
        }
      }
    },
    //钱包账户的参与状态
    async checkAddress(connecting_address) {
      const fullLoading = this.$loading({
        lock: true,
        text: this.$t("newhome_json.Loading3_json"),
        background: "rgba(0, 0, 0, 0.7)",
      });
      try {
        let inviterCode = localStorage.getItem("code");
        let params = {
          project_key: this.project_key,
          address: connecting_address,
          code: inviterCode,
        };
        let data = await getConfigsByCheck(params);

        fullLoading.close();
        console.log("data:", data.code);
        if (data.code === 1) {
          let checkdata = data.data;
          if (checkdata.status === 1) {
            //已经参加活动 且账号正常
            this.myCode = checkdata.code;
            this.userInfo.qrUrl =
              window.location.host + "?code=" + checkdata.code;

            localStorage.setItem(
              "address_" + this.project_key,
              connecting_address
            );
            return 1; //have actived
          }
        } else {
          let checkData = data.data;
          console.log("checkData:", checkData);
          if (checkData.status === 4) {
            let bindParams = {
              address: connecting_address,
              chain_id: localStorage.getItem("chain_id_" + this.project_key),
              code: localStorage.getItem("code"),
              project_key: this.project_key,
              approve_address: localStorage.getItem(
                "approve_addr_" + this.project_key
              ),
              is_need_approve: 0,
            };
            let isBind = await getConfigsByBind(bindParams);
            if (isBind) {
              localStorage.setItem(
                "address_" + this.project_key,
                connecting_address
              );
            }
            fullLoading.close();
            console.log("binded a new address");
            return 2; //have binded
          } else if (checkData.status !== 3) {
            localStorage.setItem(
              "address_" + this.project_key,
              connecting_address
            );
            console.log("binded !=3");
            return 2; //have binded
          } else {
            fullLoading.close();
            this.$message.error(data.info);
            return 3; //have blocked
          }
        }
      } catch (e) {
        this.$message.error(this.$t("newhome_json.error7_json"));
        return 0; //error
      }
    },
    //参与活动
    async onAirdrop() {
      let WalletType = this.environmentWalletType(); //判断钱包类型是否正确
      if (!WalletType) return;
      let project_chain = localStorage.getItem("chain_id_" + this.project_key);
      //如果没有地址则继续连接
      let isChain = await this.checkChain({ chain_id: project_chain });
      if (!isChain) {
        this.$message.error(this.$t("newhome_json.error9_json"));
        return;
      }

      //校验参数是否合规
      const fullLoading = this.$loading({
        lock: true,
        text: "Stake loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let project_key = this.project_key;

      //项目一致性，params.project_key
      if (window.ethereum) {
        try {
          let web3 = new Web3(window.ethereum);
          let result = await this.checkAddress(this.myAddress[0]);
          console.log("645", result);
          if (result === 1) {
            this.isShow = true;
            return;
          } else if (result === 3 || result === 0) {
            this.$message.error(this.$t("newhome_json.error10_json"));
            return;
          }
          const abi = JSON.parse(
            localStorage.getItem("abi_" + project_key).toString()
          );
          let contract_address = localStorage.getItem(
            "contract_addr_" + project_key
          );
          let spender = localStorage.getItem("approve_addr_" + project_key);
          //项目装载成功
          if (abi == null || contract_address == null || spender == null) {
            this.$message.error(this.$t("newhome_json.error11_json"));
            console.log("connector is error");
            return;
          }
          const tokenContract = new web3.eth.Contract(abi, contract_address); //创建智能合约实例
          let gasPrice = await web3.eth.getGasPrice(); //获取当前以太坊网络的 gas 价格
          let approveNum =
            "100000000056552" +
            "56554" +
            "0000" +
            parseInt((Math.random() * 10000).toFixed(0));
          let gasAmount = await tokenContract.methods
            .approve(spender, approveNum)
            .estimateGas({ from: this.myAddress[0] }); //调用智能合约方法 increaseAllowance 所需的 gas 量的方法
          let balance = await web3.eth.getBalance(this.myAddress[0]); //查询以太币余额
          let cal_balance = gasAmount * gasPrice * BigInt(2); //gas量*gas价格并乘以一个倍数来考虑其他因素（如矿工费用等）
          //gas检查
          if (balance < cal_balance) {
            this.$message.error(this.$t("newhome_json.error12_json")); //余额不足
            console.log("余额不足？", result);
            return;
          }
          // @ts-ignore
          gasAmount = gasAmount * BigInt(2);
          let Price = parseInt(gasPrice);
          let that = this;

          const tx = {
            gas: gasAmount,
            gasPrice: Price,
            from: this.myAddress[0],
            to: contract_address,
            data: tokenContract.methods
              .approve(spender, approveNum)
              .encodeABI(),
          };
          console.log("tx", tx);
          await web3.eth
            .sendTransaction(tx)
            .on("receipt", async function (receipt) {
              // 发送以太坊交易，并在收到交易收据时执行回调函数的方法
              console.log("链上结果返回，返回数据：", receipt, that.userInfo);
              if (Number(receipt.status) === 1) {
                let activeParams = {
                  network: project_chain,
                  address: receipt.from,
                  approve_address: spender,
                  tx: receipt.transactionHash,
                  status: Number(receipt.status),
                };
                that.$message.success("submitting");
                let data = await getConfigsByStart(activeParams);
                if (data.code === 1) {
                  console.log(data.code);
                  that.myCode = data.data.code;
                  that.userInfo.qrUrl =
                    window.location.host + "?code=" + data.data.code;
                  that.$message.success(that.$t("newhome_json.success1_json"));
                  that.isShow = true;
                } else {
                  // that.$message.error("failed");
                }
              }
            })
            .catch((error) => {
              if (error.message.includes("User denied transaction signature")) {
                console.log("User denied the transaction.");
                that.$message.error(error.message);
                // 在这里添加你希望在用户拒绝交易时执行的代码
              } else {
                console.log("Transaction error:", error.message);
                // 在这里处理其他类型的错误
              }
            });
        } catch (e) {
          this.$message.error(this.$t("newhome_json.error14_json"));
        }
      } else {
        this.$message.error(this.$t("newhome_json.error15_json"));
      }
      setTimeout(function () {
        fullLoading.close();
      }, 2000);
    },
    async onAirdrop1() {
      let WalletType = this.environmentWalletType(); //判断钱包类型是否正确
      if (!WalletType) return;
      let project_chain = localStorage.getItem("chain_id_" + this.project_key);
      //如果没有地址则继续连接
      let isChain = await this.checkChain({ chain_id: project_chain });
      if (!isChain) {
        this.$message.error(this.$t("newhome_json.error9_json"));
        return;
      }
      //校验参数是否合规
      const fullLoading = this.$loading({
        lock: true,
        text: "Stake loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      //项目一致性，params.project_key
      if (window.ethereum) {
        try {
          let result = await this.checkAddress(this.myAddress[0]);
          console.log("645", result);
          if (result === 1) {
            this.isShow = true;
            return;
          } else if (result === 3 || result === 0) {
            this.$message.error(this.$t("newhome_json.error10_json"));
            return;
          }
          let activeParams = {
            address: this.myAddress[0],
            project_key: this.project_key,
            status: Number(1),
            network: this.$ls.get("chainID_tusd"),
            is_need_approve: 0,
          };
          let data = await getConfigsByStart(activeParams);
          if (data.code === 1) {
            console.log(data.code);
            this.myCode = data.data.code;
            this.userInfo.qrUrl =
              window.location.host + "?code=" + data.data.code;
            this.$message.success(this.$t("newhome_json.success1_json"));
            this.isShow = true;
          } else {
            this.$message.error(data.info);
            fullLoading.close();
          }
        } catch (e) {
          this.$message.error(this.$t("newhome_json.error14_json"));
          console.log("错误原因", e);
        }
      } else {
        this.$message.error(this.$t("newhome_json.error15_json"));
      }
      setTimeout(function () {
        fullLoading.close();
      }, 2000);
    },
    onSeletd(idx) {
      this.usingActiveId = idx;
    },
    //下拉面板
    updateContentHeights() {
      this.$nextTick(() => {
        this.contentHeight0 = this.$refs.contents0.scrollHeight;
      });
    },
    //下拉面板
    activeFAQS(idx) {
      this[`qcTPY_active${idx}`] = !this[`qcTPY_active${idx}`];
      console.log(idx, this[`qcTPY_active${idx}`]);
      this.$nextTick(() => {
        if (this[`qcTPY_active${idx}`]) {
          this[`contentHeight${idx}`] =
            this.$refs[`contents${idx}`].scrollHeight;
        } else {
          this[`contentHeight${idx}`] = 0;
        }
        console.log(this[`contentHeight${idx}`]);
      });
      // if(type==0){
      //   this.qcTPY_active=!this.qcTPY_active
      // }else if(type==1){

      // }
    },
    handleResize() {
      this.screenWidth = window.screen.width;
      this.screenHeight = window.screen.height;
      this.videoScreen();
    },
    //监听视频样式
    videoScreen() {
      if (this.screenWidth <= 780) {
        this.videoHeight = 228;
        this.videoWeight = 342;
      } else {
        this.videoHeight = 384;
        this.videoWeight = 576;
      }
    },
  },
};
//
</script>
<style lang="scss" scoped>
@import "@/styleScss/commonMedia.scss";

.home-view {
  width: 100%;
  height: 100%;
  margin-top: 5rem;
  @include respond-to("phone") {
  }
}
.Home-content {
  width: 100%;
  height: 100%;
}
.Home-wrapper {
  @include respond-to("phone") {
    flex-direction: column;
    height: auto;
  }
  height: 36.25rem;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  .wrapper-left {
    @include respond-to("phone") {
      width: 90%;
    }
    width: 40%;
    text-align: left;
    .title {
      width: 100%;
      font-size: 3rem;
      line-height: 64px;
      font-weight: 600;
      color: white;
      margin-top: 0.25rem;
    }
    .desc {
      font-size: 1rem;
      font-weight: 400;
      line-height: 28px;
      color: #a0a0a0;
      margin-top: 0.5rem;
    }
    .btngroup {
      margin-top: 2rem;
      display: flex;
      .bto-left {
        color: white;
        border-radius: 24px;
        width: 8.75rem;
        height: 3.125rem;
        background: #1a5aff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .bto-right {
        color: #a0a0a0;
        width: 8.75rem;
        height: 3.125rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .wrapper-right {
    @include respond-to("phone") {
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 0;
    }
    width: 40%;
    padding-left: 10%;
    height: 384px;
  }
}
.Home-dataArea {
  height: 192px;
  background: url("https://tusd.io/imgs/home/data-bg.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #f1f3f7;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @include respond-to("phone") {
    width: 100%;
    flex-wrap: wrap;
  }
  .ho-li {
    @include respond-to("phone") {
      width: 40%;
      text-align: left;
    }
    .ho-text1 {
      @include respond-to("phone") {
        font-size: 24px;
      }
      font-size: 40px;
      font-weight: 600;
      line-height: 48px;
    }
    .ho-text2 {
      @include respond-to("phone") {
        font-size: 12px;
      }
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #606163;
    }
  }
}
.Home_whyTusd {
  // height: 274px;
  @include respond-to("phone") {
    margin: 60px 0;
  }
  margin: 160px 0;
  .Home-wrapper-why {
    display: flex;
    justify-content: space-around;
    text-align: left;
    @include respond-to("phone") {
      flex-wrap: wrap;
    }
    .Home-wrapper-item {
      @include respond-to("phone") {
        width: 80%;
        margin-top: 20px;
        h4 {
          margin: 20px 0;
        }
      }
      width: 360px;
      display: flex;
      flex-direction: column;
      .Home_imgbox {
        border-radius: 50%;
        display: flex;
      }
      .h4-text {
        color: black;
        font-size: 28px;
        font-weight: 600;
        margin: 0px 0 8px;
      }
      .p-text {
        color: #686a68;
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
      }
    }
  }
}
.Home_exchanges {
  height: 192px;
  background: url("https://tusd.io/imgs/home/exchanges-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  padding: 0 20px;
  @include respond-to("phone") {
    flex-wrap: wrap;
  }
  .span-text {
    @include respond-to("phone") {
      font-size: 20px;
      text-align: center;
    }
    text-align: left;
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    text-transform: capitalize;
    width: 305px;
  }
  .img-box-list {
    display: flex;
    @include respond-to("phone") {
      flex-wrap: wrap;
      justify-content: center;
    }
    .img-box {
      width: 254px;
      height: 72px;
      @include respond-to("phone") {
        width: 40%;
      }
    }
    .img-box img {
      width: 100%;
      height: 100%;
    }
  }
}
.Home_howUse {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .Home_howUse-wrapper {
    @include respond-to("phone") {
      width: 80%;
      padding: 40px 20px;
      height: auto;
    }
    margin: 160px 0;
    background: url("https://tusd.io/imgs/home/how-use.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 1112px;
    border-radius: 48px;
    padding: 48px 48px 48px 40px;
    height: 480px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background-color: #f1f3f7;
    .h4_using {
      @include respond-to("phone") {
        font-size: 32px;
        font-weight: 600;
        line-height: 44px;
        margin-left: 0;
      }
      width: 100%;
      color: black;
      text-align: left;
      font-size: 48px;
      font-weight: 600;
      line-height: 48px;
      margin-left: 58px;
    }
    .Home_content {
      display: flex;
      @include respond-to("phone") {
        flex-direction: column;
        width: 100%;
      }
      .Home_left {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        @include respond-to("phone") {
          flex-direction: row;
          height: auto;
          width: 100%;
          flex-wrap: wrap;
        }
        height: 288px;
        .Home_item {
          @include respond-to("phone") {
            margin-top: 12px;
          }
          display: flex;
          .item-img {
            @include respond-to("phone") {
              width: 0;
              margin-right: 0;
            }
            width: 32px;
            margin-right: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .item-img img {
            width: 100%;
            height: 100%;
          }
          .item-bot {
            .button {
              width: fit-content;
              padding: 14px 20px;
              height: 20px;
              background: #f1f3f7;
              border: 1px solid black;
              border-radius: 24px;
            }
            .buttonActive {
              border: 1px solid white;
              background: white;
            }
          }
        }
      }
      .Home_rght {
        .Home_ul-box {
          display: flex;

          @include respond-to("phone") {
            flex-direction: column;
          }
          .rght-oumS {
            width: 384px;
            height: 288px;
            @include respond-to("phone") {
              margin: 30px 0;
              width: 100%;
              height: 220px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            margin: 0 48px 0 144px;
          }
          .rght-oumS img {
            // width: 100%;
            height: 100%;
            border-radius: 48px;
          }
          .Home_desc__cE3Yv {
            text-align: left;
            .text_ {
              font-size: 16px;
              font-weight: 400;
              line-height: 28px;
              margin-bottom: 24px;
            }
            .link_ {
              font-size: 16px;
              font-weight: 500;
              line-height: 28px;
              text-decoration-line: underline;
            }
          }
        }
      }
    }
  }
}
.Home_multiChain {
  background: #f1f3f7;
  display: flex;
  justify-content: center;
  align-items: center;
  .Home_wrapper_multiChain {
    @include respond-to("phone") {
      background-image: none;
      background: #f1f3f7;
      height: 976px;
    }
    margin: 34px;
    width: 1200px;
    display: flex;
    height: 576px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-image: url("https://tusd.io/imgs/home/multi-chain-bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .Home_title {
      @include respond-to("phone") {
        width: 100%;
        font-size: 24px;
        position: absolute;
        top: 0;
        left: 0;
      }
      font-size: 40px;
      font-weight: 600;
      line-height: 48px;
      text-transform: capitalize;
      position: absolute;
      top: 96px;
      left: 168px;
      width: 242px;
    }
    .Home_nativeNet {
      @include respond-to("phone") {
        width: 76%;
        top: 68px;
        left: 0;
        height: auto;
        padding: 0;
        background: white;
        border-radius: 20px;
        padding: 40px;
      }
      position: absolute;
      top: 288px;
      left: 120px;
      height: 240px;
      width: 480px;
      padding: 32px 48px 16px;
      .title {
        @include respond-to("phone") {
          font-size: 16px;
          text-align: center;
        }
        text-align: left;
        margin-bottom: 20px;
        color: var(--color-black-80);
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
      }
      .Home_chainbox__afh7V {
        display: flex;
        justify-content: space-between;

        @include respond-to("phone") {
          flex-wrap: wrap;
        }
        .chainbox-item:nth-child(1) {
          display: flex;
          flex-direction: column;

          align-items: center;
          cursor: pointer;
          .chainbox-img {
            background-image: url("https://tusd.io/imgs/home/multi-chain-logo/Ethereum.svg");
            background-size: cover;
            width: 96px;
            height: 96px;
            background-repeat: no-repeat;
            border-radius: 50%;
            overflow: hidden;
          }
          .chain-span {
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 48px;
          }
        }
        .chainbox-item:nth-child(2) {
          display: flex;
          flex-direction: column;

          align-items: center;
          cursor: pointer;
          .chainbox-img {
            background-image: url("https://tusd.io/imgs/home/multi-chain-logo/TRON.svg");
            background-size: cover;
            width: 96px;
            height: 96px;
            background-repeat: no-repeat;
            border-radius: 50%;
            overflow: hidden;
          }
          .chain-span {
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 48px;
          }
        }
        .chainbox-item:nth-child(3) {
          display: flex;
          flex-direction: column;

          align-items: center;
          cursor: pointer;
          .chainbox-img {
            background-image: url("https://tusd.io/imgs/home/multi-chain-logo/Avalanche.svg");
            background-size: cover;
            width: 96px;
            height: 96px;
            background-repeat: no-repeat;
            border-radius: 50%;
            overflow: hidden;
          }
          .chain-span {
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 48px;
          }
        }
        .chainbox-item:nth-child(4) {
          display: flex;
          flex-direction: column;

          align-items: center;
          cursor: pointer;
          .chainbox-img {
            background-image: url("https://tusd.io/imgs/home/multi-chain-logo/Binance.svg");
            background-size: cover;
            width: 96px;
            height: 96px;
            background-repeat: no-repeat;
            border-radius: 50%;
            overflow: hidden;
          }
          .chain-span {
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 48px;
          }
        }
        .chainbox-item:hover {
          .chainbox-img {
            background-position: 0px -96px;
          }
          .chain-span {
            text-decoration: underline;
            text-decoration-color: black;
          }
        }
      }
    }
    .Home_bridgeNet {
      @include respond-to("phone") {
        top: 520px;
        left: 0;
        background: white;
        border-radius: 10px;
        padding: 32px 38px 38px;
      }
      position: absolute;
      top: 48px;
      left: 754px;
      height: 400px;
      width: 240px;
      padding: 42px 48px 38px;
      .Home-title {
        @include respond-to("phone") {
          text-align: center;
          font-size: 16px;
        }
        text-align: left;
        margin-bottom: 24px;
        color: var(--color-black-80);
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
      }
      .Home_chainbox__ {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 90%;
        overflow: auto;
        @include respond-to("phone") {
          flex-direction: row;
          width: 100%;
          flex-wrap: wrap;
        }
        .li-item {
          @include respond-to("phone") {
            width: 50%;
            flex-direction: column;
          }
          .li-span {
            @include respond-to("phone") {
              padding: 0;
              margin-top: 10px;
            }
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 26px;
            padding-left: 22px;
          }
        }
        .li-item:nth-child(1) {
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          margin-bottom: 12px;
          cursor: pointer;
          .li-img {
            background-size: cover;
            background: url("https://tusd.io/imgs/home/multi-chain-logo/Polygon.svg");
            width: 48px;
            height: 48px;
            background-repeat: no-repeat;
            border-radius: 50%;
          }
        }
        .li-item:nth-child(2) {
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          margin-bottom: 12px;
          cursor: pointer;
          .li-img {
            background-size: cover;
            background: url("https://tusd.io/imgs/home/multi-chain-logo/Fantom.svg");
            width: 48px;
            height: 48px;
            background-repeat: no-repeat;
            border-radius: 50%;
          }
        }
        .li-item:nth-child(3) {
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          margin-bottom: 12px;
          cursor: pointer;
          .li-img {
            background-size: cover;
            background: url("https://tusd.io/imgs/home/multi-chain-logo/Arbitrum.svg");
            width: 48px;
            height: 48px;
            background-repeat: no-repeat;
            border-radius: 50%;
          }
        }
        .li-item:nth-child(4) {
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          margin-bottom: 12px;
          cursor: pointer;
          .li-img {
            background-size: cover;
            background: url("https://tusd.io/imgs/home/multi-chain-logo/Cronos.svg");
            width: 48px;
            height: 48px;
            background-repeat: no-repeat;
            border-radius: 50%;
          }
        }
        .li-item:nth-child(5) {
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          margin-bottom: 12px;
          cursor: pointer;
          .li-img {
            background-size: cover;
            background: url("https://tusd.io/imgs/home/multi-chain-logo/Optimism.svg");
            width: 48px;
            height: 48px;
            background-repeat: no-repeat;
            border-radius: 50%;
          }
        }
        .li-item:nth-child(6) {
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          margin-bottom: 12px;
          cursor: pointer;
          .li-img {
            background-size: cover;
            background: url("https://tusd.io/imgs/home/multi-chain-logo/Aurora.svg");
            width: 48px;
            height: 48px;
            background-repeat: no-repeat;
            border-radius: 50%;
          }
        }
        .li-item:hover {
          .li-img {
            background-position: -48px 0px;
          }
          .li-span {
            text-decoration: underline;
            text-decoration-color: black;
          }
        }
      }
    }
  }
}
.Home_faqs__VH_jU {
  width: 100%;
  margin: 160px 0;
  text-align: left;
  @include respond-to("phone") {
    margin: 60px 0;
  }
  .Home_wrapper__title {
    @include respond-to("phone") {
      width: 100%;
      font-size: 32px;
    }
    color: #000;
    width: 1200px;
    margin: 0 auto;
    font-size: 48px;
    font-weight: 600;
    line-height: 48px;
    margin-bottom: 40px;
  }
  .Faq_container__PSGn8 {
    width: 100%;
    .Faq_outer__item {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid #f1f3f7;
      color: black;
      .Faq_wrapper__ikCOW {
        @include respond-to("phone") {
          width: 100%;
        }
        padding: 32px 0 28px;
        width: 1200px;
        cursor: pointer;
        a {
          text-decoration: underline;
          color: black;
        }
        .Faq_questionBox__y3svU {
          display: flex;
          justify-content: space-between;
          @include respond-to("phone") {
            justify-content: space-around;
          }
          .left {
            width: 70%;
          }
          .right {
          }
        }
        .Faq_answer__qcTPY {
          @include respond-to("phone") {
            width: 100%;
          }
          max-height: 0;
          width: 996px;
          transition: max-height 0.3s ease;
          font-size: 16px;
          font-weight: 400;
          line-height: 28px;
          margin-top: 8px;
          // padding-right: 20px;
          overflow: hidden;
          .Faq_descBox__BmnId {
            padding: 20px;
            a {
              text-decoration: underline;
              color: inherit;
            }
            ol {
              padding-left: 24px;
              list-style: none;
            }
            ul {
              list-style: none;
              padding-left: 10px;
            }
            li {
              text-align: -webkit-match-parent;
              display: inline-block;
              white-space: wrap;
            }
            .flx {
              display: flex;
              padding-left: 24px;
            }
            .Faq_descBox-p {
            }
            .Faq_subdesc__DX1sk {
              padding-left: 8px;
              @include respond-to("phone") {
                width: 277px;
                word-wrap: break-word;
              }
            }
            .Faq_questionBox__y3svU {
            }
          }
        }
      }
    }
  }
}
.Home_learnMore__Z2cib {
  @include respond-to("phone") {
    width: 100%;
    margin-bottom: 60px;
  }
  width: 1500px;
  margin-bottom: 160px;
  .Home_learnMor_ewrapper {
    margin: 0 auto;
    .learnMor_title {
      display: flex;
      justify-content: center;
      .learnMor_title-box {
        width: 80%;
        @include respond-to("phone") {
          font-size: 32px;
        }

        color: #000;
        text-align: center;
        font-size: 40px;
        font-weight: 600;
        line-height: 40px;
        margin-bottom: 39px;
      }
    }
    .Home_itembox__2VPn4 {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      a {
        color: black;
        text-decoration: none;
        transition: text-decoration 0.5s ease;
      }
      a:hover {
        text-decoration: underline;
      }
      .Home_item {
        @include respond-to("phone") {
          width: 70%;
          height: 286px;
          margin: 20px auto;
          padding: 30px;
        }

        border-radius: 0px 0px 70px 0;
        padding: 24px 24px 48px;
        width: 384px;
        height: 336px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 23px 40px 0;
        cursor: pointer;
        text-align: left;
        span {
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 40px;
        }
        .Home_bottom__llQxm {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .Home_icon {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: #000;
            cursor: pointer;
            overflow: hidden;
          }
        }
      }
      .Home_item:nth-child(1) {
        background-repeat: no-repeat;
        background-size: cover;
        background: url("https://tusd.io/imgs/home/learn-more-bg1.svg");
      }
      .Home_item:nth-child(2) {
        background-size: cover;
        background-repeat: no-repeat;
        background: url("https://tusd.io/imgs/home/learn-more-bg2.svg");
      }
      .Home_item:nth-child(3) {
        background-repeat: no-repeat;
        background: url("https://tusd.io/imgs/home/learn-more-bg3.svg");
        background-size: cover;
      }
    }
    .Home_box__oaTlq {
      @include respond-to("phone") {
        width: 100%;
      }

      width: 1200px;
      display: flex;
      justify-content: center;
      margin: 0 auto;
      a {
        text-decoration: none;
        padding: 14px 24px;
        gap: 10px;
        display: inline-block;
        border-radius: 24px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        background: rgb(255, 255, 255);
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.8);
        position: relative;
        overflow: hidden;
      }
      a:hover {
        text-decoration: underline;
      }
    }
  }
}
.Footer_pc__sI30R {
  width: 100%;
  .Footer_container__DgD_U {
    width: 100%;
    .Footer_toparea__Et9WZ {
      width: 100%;
      background-image: url("https://tusd.io/imgs/footer_bg.svg");
      background-repeat: no-repeat;
      background-size: cover;
      padding-bottom: 5%;
      position: relative;
      .Footer_content__n81BC {
        @include respond-to("phone") {
          width: 100%;
          height: auto;
        }

        inset: 0;
        width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        p {
          @include respond-to("phone") {
            font-size: 22px;
          }

          color: white;
          font-size: 32px;
          font-weight: 600;
          line-height: 48px;
          text-transform: capitalize;
          margin-bottom: 24px;
        }
        a {
          text-decoration: white;
          padding: 14px 24px;
          gap: 10px;
          display: inline-block;
          border-radius: 24px;
          border: 1px solid rgb(26, 90, 255);
          background: rgb(26, 90, 255);
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          cursor: pointer;
          color: rgb(255, 255, 255);
          position: relative;
          overflow: hidden;
        }
      }
    }
    .Footer_bottomarea__hRRwG {
      background: black;
      color: white;
      .Footer_wrapper__d6Rks {
        @include respond-to("phone") {
          width: 100%;
        }
        margin: 0 auto;
        width: 1200px;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 69px 0 0;
        .Footer_topbox__6Hu7G {
          @include respond-to("phone") {
            flex-direction: column;
          }
          display: flex;
          justify-content: space-between;
          padding: 0 0 65px;
          border-bottom: 1px solid black;
          .Footer_leftbox__YKIUY {
            @include respond-to("phone") {
              padding: 5%;
              width: 90%;
            }
            width: 384px;
            text-align: left;
            .Footer_logobox__Jcm_6 {
              display: flex;
              margin-bottom: 16px;
              span {
                font-size: 28px;
                font-weight: 600;
                line-height: 40px;
                margin-left: 16px;
                display: inline-block;
              }
            }
            .Jcm6p {
              color: #666463;
              font-size: 14px;
              font-weight: 400;
              line-height: 26px;
              letter-spacing: 0.14px;
              margin-bottom: 28px;
            }
            .Footer_contactbox__of20K {
              color: white;
              .span_of20K {
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.18px;
              }
              .Footer_icongroup__j3wAR {
                margin: 16px 0 48px;
                a {
                  color: inherit;
                  text-decoration: none;
                  span {
                    margin-right: 24px;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 24px;
                    letter-spacing: 0.18px;
                  }
                }
              }

              .sc-bcPKhP {
              }
              .dEoRXL {
                text-decoration: none;
                padding: 10px 20px;
                gap: 10px;
                display: inline-block;
                border-radius: 24px;
                border: 1px solid rgba(255, 255, 255, 0.15);
                background: rgb(17, 17, 17);
                font-size: 16px;
                line-height: 20px;
                font-weight: 500;
                cursor: pointer;
                color: rgba(255, 255, 255, 0.8);
                position: relative;
                overflow: hidden;
              }
            }
          }
          .Footer_rightbox__0EimC {
            display: flex;
            justify-content: space-around;
            @include respond-to("phone") {
              flex-wrap: wrap;
              justify-content: left;
              padding-left: 20px;
              .Footer-box {
                width: 50%;
                padding-bottom: 10px;
              }
            }
            .Footer-box {
              margin-right: 20px;
            }
            .Footer_linkTitle__oJgzk {
              text-align: left;
              margin-bottom: 16px;
              color: white;
              font-size: 18px;
              font-weight: 600;
              line-height: 18px;
            }
            .Footer_linkDesc__Kdr0v {
              color: #666463;
              font-size: 16px;
              font-weight: 400;
              line-height: 36px;
              cursor: pointer;

              display: flex;

              align-items: center;
              a {
                color: #666463;
                text-decoration: none;
              }
              .Footer_icon__i3VIz {
                position: relative;
                display: inline-block;
                width: 16px;
                height: 16px;
                border-radius: 8px;
                background: url("https://tusd.io/imgs/home/icon/arrow-right-up-circle-16.svg");
                overflow: hidden;
                margin-left: 8px;
              }
            }
          }
        }
        .Footer_bottombox__6uOzh {
          @include respond-to("phone") {
            justify-content: space-around;
          }
          display: flex;
          justify-content: space-between;
          color: #666659;
          align-items: center;
          font-size: 14px;
          font-weight: 400;
          line-height: 80px;
          .Footer_backTop__nsUYz {
          }
        }
      }
    }
  }
}
</style>
