const en_TEQ = {
    Datajson:{
        language_json:{
            zh_json:'中文简体',
            zh_FT_json: '中文繁體',
            eh_json:'English',
            ja_JP_json:'日本語',  
            ko_KR_json:'한국어' ,
            en_TH_json:'ภาษาไทย',
            fr_FR_json:'En français',
            ru_RU_json:'русск',
            en_IN_json:'हिंदीName',
            en_ID_json:'Bahasa indonesia',
            en_BS_json:'فارسی',
            en_YN_json:'Việt nam',
            en_ALB_json:' بالعربية ',
            en_TEQ_json:'Türkçe',
            en_ES_json:'Español',
            en_PTY_json:'Português',
            en_YDL_json:'Italiano'
        },
    },
    home: {
        language: 'dil',
        fduse_desc: "FDUSD, 1:1 ABD Doları destekli bir Stablecoin'dir.",
        introduce_desc: 'Küresel finansı son teknoloji dijital bir stablecoin ile devrimleştirerek, dünyadaki işletmelere ve geliştiricilere güvenlik, hız ve yenilik sunuyoruz.',
        getfduse_desc: 'FDUSD Edinin',
        stability_desc: 'Stabilite Yenilikle Buluşuyor',
        experience_desc: 'Hızlı işlem hızı ve minimum maliyetlerin keyfini çıkarın. Yenilikçi ve güvenilir çözümümüzle finansın geleceğini şekillendirin.',
        whitepaper_desc: 'Beyaz Kağıt',
        redeemable_desc: 'ABD doları karşılığında 1:1 olarak geri alınabilir',
        dollars_desc: "FDUSD, tamamen nakit ve nakit benzeri varlıklarla desteklenmesi amaçlanan bir stablecoin'dir. Jetonlar, ABD doları karşılığında 1:1 olarak geri alınabilir olması amaçlanmaktadır.",
        backed_desc: 'Tamamen rezerve dayalı',
        deserved_desc: 'Rezerve varlıklar, tamamen ayrılmış iflas dışı tutma yapılarında tutulmaktadır.',
        programmable_desc: 'Tamamen Programlanabilir',
        innovative_desc: 'Gelişmiş teknoloji tarafından desteklenen yenilikçi stablecoin çözümümüzle, finans dünyasında huzur ve güven sağlayan güvenli ve güvenilir bir alternatif sunmayı hedefliyoruz.',
        settle_desc: 'Dakikalar İçinde Hesaplaşma',
        nearZero_desc: 'Neredeyse Sıfır Maliyet',
        always_desc: 'Her Zaman Açık, 7/24',
        fully_desc: 'Tamamen Programlanabilir',
        rewrite_desc: 'First Digital Labs ile Geleceği Yeniden Yazın',
        combining_desc: "Stabiliteyi yenilikle birleştirerek, First Digital Labs'da, kesintisiz şeffaflık, uyumluluk ve güvenlik sunarak güven kazanmaya adanmışız.",
        mission_desc: 'Finans sektörünü finansal bir çağa taşımak ve küresel finansal manzarayı yeniden şekillendirmek için bir görevdeyiz.',
        should_desc: "Neden First Digital USD'yi Kullanmalısınız?",
        collateral_desc: 'Teminat, düzenlemeli bir depolama kuruluşunun güven lisansına sahip, en üst düzeyde nitelikli bir emanetçi tarafından korunmaktadır.',
        transparent_desc: 'Şeffaf',
        independent_desc: 'Bağımsız denetim - teminat, bağımsız bir üçüncü taraf tarafından doğrulanır, bu da rezervlerin ve mali raporlamanın bütünlüğünü sağlar.',
        examined_by_desc: 'Tarafından incelenmiştir',
        attestation_Reports_desc: 'Doğrulama Raporları',
        January2024_desc: 'Ocak 2024',
        Report_desc: 'Rapor',
        December_desc: 'Aralık 2023',
        November_desc: 'Kasım 2023',
        Networks_desc: 'Hangi Ağlar Desteklenmektedir?',
        available_desc: 'FDUSD,',
        Ethereum_desc: 'Ethereum',
        and_desc: 've',
        BNB_Chain_desc: 'BNB Chain',
        planned_desc: 'planned support for an increasing number of blockchains.',
        Audited_desc: 'Denetlenmiştir',
        Frequently_desc: 'Sıkça Sorulan Sorular',
        How_FDUSD_desc: 'FDUSD Nasıl Alabilirim?',
        Acquiring_desc: "FDUSD edinme işlemi birkaç farklı şekilde gerçekleştirilebilir. Eğer önemli bir endüstri oyuncusu, bir finansal aracı veya belirli bir statüye sahip bir profesyonel yatırımcıysanız ve gerekli kriterleri karşılıyayorsanız, FDUSD'yi doğrudan First Digital Labs'tan satın alabilirsiniz. Lütfen unutmayın ki, First Digital Labs doğrudan perakende müşterilere jeton satmamaktadır.",
        canstill_desc: "First Digital Labs müşterisi olmadan da FDUSD elde edebilirsiniz. Birçok önde gelen kripto para borsası FDUSD'yi listeleyerek ve büyük likidite sunarak erişilebilir ve uygun bir şekilde FDUSD elde etmek isteyen bireyler için kolay bir yol sağlar. Bununla birlikte, kullanacağınız borsanın güvenilirliğini sağlamak için detaylı araştırma yapmanız ve güvence sağlamanız önemlidir.",
        equivalent_desc: "FDUSD'nin her zaman bir dolara eşit olacağına nasıl güvenebilirim?",
     //   stablecoin_desc: "FDUSD stablecoin'inin değerine olan güveniniz sağlam temellere dayanmaktadır. Her bir FDUSD'nin tamamen bir ABD doları veya eşit değerde bir varlık tarafından desteklenmesi amaçlanmaktadır. Bu varlıklar, düzenlenmiş depolama kuruluşlarındaki hesaplarda güvenli bir şekilde saklanır ve nitelikli bir emanetçi tarafından sağlanır, bu da jetonun değerine sağlam bir temel oluşturur. Bu sistem, FDUSD'ye içsel istikrarını sağlar ve onun ABD dolarına eşit değerine güvenebileceğiniz anlamına gelir.",
        redeem_FDUSD: 'FDUSD Nasıl Geri Alabilirim?',
      //  stablecoin_desc: "FDUSD stablecoin'inizi geri almak için öncelikle First Digital Labs müşterisi olmanız ve Anti-Para Aklama (AML) ve Terörizmin Finansmanı (CTF) kontrolleri de dahil olmak üzere belirli gereksinimleri karşılamanız gerekmektedir. Bu kontrolleri başarıyla tamamladıktan sonra, FDUSD'nizi fiat para birimindeki karşılığıyla değiştirebilir ve böylece dolaşımdan çıkarabilirsiniz. Alternatif olarak, FDUSD jetonlarınızı FD121'in stablecoin'lerini destekleyen bir kripto para borsası veya tezgah üstü (OTC) sağlayıcı aracılığıyla ikincil piyasada satabilirsiniz. İşlem yapmadan önce yargı alanınızdaki tüm yasal ve finansal düzenlemelere dikkat edin.",
        Explore_desc: 'Ekosistemimizi Keşfedin',
        Experience_desc: 'Güvenilir stablecoin çözümümüzle istikrarı deneyimleyin.',
        slide_desc: 'sayfa',
        _1to6_desc: '1 ile 6',
        of7_desc: 'arasi',
        Transparency_desc: 'Şeffaflık',
        Legal_desc: 'Yasal',
        Compliance_desc: 'Uyumluluk',
        Policy_desc: 'Gizlilik Politikası',
        Company_desc: 'Şirket',
        About_Us_desc: 'Hakkımızda',
        Social_Media_desc: 'Sosyal Medya',
        Twitter_desc: 'Twitter',
        Disclosures_desc: 'Önemli Açıklamalar:',
        Limited_desc: 'FD121 Limited bir finansal teknoloji şirketidir, bir banka değildir. Rezervlerin saklanması hizmeti',
        First_desc: 'First Digital Trust Limited',
        product_desc: 'Tüm ürün ve şirket adları, sahiplerinin ticari markalarıdır veya tescilli ticari markalardır. Bu web sitesinde kullanılan tüm şirket, ürün ve hizmet adları yalnızca tanımlama amaçlıdır. Bu isimlerin, ticari markaların ve markaların herhangi biriyle bağlantı veya onay anlamına gelmez.',
        additional_desc: "Ek önemli riskler, açıklamalar ve bilgiler için lütfen ziy",
    },
    newhome_json: {
        Exchanges_json: "Borsalar",
        DeFi_json: "Merkeziyetsiz Finans",
        Payments_json: "Ödemeler",
        Accessibility_json: "Erişilebilirlik",
        listed_json: "TUSD, birden fazla endüstri lideri borsada listelenmiştir ve tüm yatırımcılara likidite ve erişilebilirlik sağlar.",
        stablecoin_json: "Geniş çapta kullanılan bir stabilcoin olarak, TUSD, otomatik piyasa yapıcılar (AMM), borç verme platformları ve getiri çiftçiliği (YIF) projeleri dahil olmak üzere çeşitli merkeziyetsiz finans (DeFi) protokollerinde önemli bir şekilde benimsenmiştir.",
        leading_json: "Önde gelen bir yuan stabilcoini olarak, TUSD sınır ötesi işlemler için ideal bir araçtır. TUSD'nin düşük işlem ücretleri ve anlık hızı sayesinde, bireyler ve kurumlar geleneksel finansal zorluklar olmadan işlem yapabilir.",
        available_json: "TUSD ayrıca Ethereum, BNB Smart Chain, TRON ve Avalanche gibi önde gelen blok zincirlerinde kullanılabilir. TUSD'nin köprü ağı Polygon, Fantom, Arbitrum, Cronos ve daha fazlasını içerir.",
        error1_json: "Node'a bağlanılamıyor. Node'u değiştirip tekrar deneyin",
        error2_json: "Zincir hatası",
        error3s_json: "Lütfen adresinizi bağlayın",
        Loading1_json: "Yükleniyor",
        Loading2_json: "Proje bilgileri yükleniyor",
        error3_json: "Ağ hatası",
        error4_json: "Cüzdan bağlantısı reddedildi",
        error5_json: "Eski cüzdan sürümü",
        error6_json: "Cüzdan bağlantısı reddedildi",
        Loading3_json: "Giriş yapılıyor",
        error7_json: "Hesap hatası",
        error8_json: "Node'a bağlanılamıyor. Node'u değiştirip tekrar deneyin",
        error9_json: "Ağ hatası",
        Loading4_json: "Stake ediliyor",
        error10_json: "Engellendiniz",
        error11_json: "Proje hatası",
        error12_json: "Yetersiz gaz",
        success1_json: "Başarılı",
        error13_json: "Hesap alınamadı",
        error14_json: "Bilinmeyen hata",
        error15_json: "Node'a bağlanılamıyor. Node'u değiştirip tekrar deneyin",
        
      },
      newhome_desc: {
        title_desc: "Yeni küresel ödeme sistemi için tasarlanmış dijital para birimi",
        desc_desc: "TrueUSD, bağımsız bir üçüncü taraf kurum tarafından günlük olarak denetlenen ilk ABD doları destekli stabilcoindir.",
        Started_desc: "Başla",
        Contact_desc: "Bize Ulaşın",
        Circulating_desc: "Dolaşımdaki Arz",
        Trading_desc: "Ticaret Hacmi",
        Transaction_desc: "Toplam İşlem Sayısı",
        Support_desc1: "Toplam Desteklenen Zincir",
        Trustworthy_desc: "Güvenilir",
        utilizes_desc: "TUSD, şeffaflığı artırmak için gelişmiş denetim ve sertifikasyon mekanizmalarını kullanır. Günlük denetim raporları web sitesinde görüntülenebilir ve kullanıcıların rezervleri her zaman izlemelerine olanak tanır.",
        Popularity_desc: "Popülerlik",
        convenience_desc: "TUSD, ticaret ve transfer gibi çeşitli finansal senaryolarda kolaylık ve fayda sağlar. Şu anda, TUSD 80'den fazla kripto para borsası ve DeFi protokolünde kullanılabilir ve kullanıcılara esneklik ve erişilebilirlik sağlar.",
        Liquidity_desc: "Likidite",
        During_desc: "2023'ün üçüncü çeyreğinde, TUSD'nin günlük ticaret hacmi 1 milyar doları aştı ve bu, olağanüstü likiditesini gösteriyor. Ayrıca, TUSD basım ve itfa için herhangi bir ücret talep etmez.",
        Trusted_desc: "Küresel liderler tarafından güveniliyor",
        Using_desc: "TUSD Kullanımı",
        LearnMore_desc: "Daha Fazla Bilgi",
        Goes_desc: "TUSD çoklu zincire gidiyor",
        Natively_desc1: "Yerel Dağıtım Ağı",
        Ethereum_desc: "Ethereum",
        TRON_desc: "TRON",
        Avalanche_desc: "Avalanche",
        Smart_desc: "BNB Smart Chain",
        Bridged_desc: "Köprü Ağı",
        Fantom_desc: "Fantom",
        Arbitrum_desc: "Arbitrum",
        Cronos_desc: "Cronos",
        Optimism_desc: "Optimism",
        Aurora_desc: "Aurora",
        FAQs_desc: "Sıkça Sorulan Sorular",
        TrueUSD_desc: "S1. TrueUSD nedir?",
        Banking_desc: "TrueUSD, küresel finansal kurumları ve güçlü blok zinciri altyapısını kullanır. Bu, zincir üzerinde gerçek zamanlı rezerv kapsamı doğrulaması ile basım/itfa için akıllı sözleşmeleri kullanan ilk stabilcoindir ve Moore Hong Kong ve Chainlink Proof of Reserve tarafından desteklenir.",
        started_desc: "S2. TrueUSD kullanmaya nasıl başlanır?",
        stepsbelow_desc: "TrueUSD kullanmaya başlamak için aşağıdaki adımları izleyin:",
        verify_desc: "TrueUSD web sitesi üzerinden hesabınızı oluşturup doğruladıktan sonra, bir e-posta alacaksınız",
        website_desc: "TrueUSD web sitesi",
       
        verification_desc: "Doğrulama bağlantısı üzerinden doğrulama yapın. Bu bağlantıya tıklayarak e-posta adresinizi doğrulayın, ardından ayarları yapın",
        information_desc: "Kendiniz ve/veya kuruluşunuz hakkında bilgileri doldurun. Gerekli belgelerin listesi için, tıklayın",
        here_desc: "burada",
        application_desc: "Başvurunuzu gönderin. Başvurunuzu inceleyeceğiz.",
        Redeem_desc: "Bir tıklamayla ücretsiz olarak stabilcoinimizi basın ve itfa edin",
        Trade_desc: "Birden fazla borsada ve 5 kıtada 20'den fazla OTC masasında 160'tan fazla piyasada TrueUSD ticareti yapın",
        Send_desc: "TrueUSD'yi herhangi birine, herhangi bir zamanda, banka havalesinden 100 kat daha ucuz ve 1000 kat daha hızlı bir şekilde gönderin",
        mint_desc: "S3. TrueUSD nasıl basılır ve itfa edilir?",
        partner_desc1: "Banka ortaklarımıza havale gönderin, gösterilen kullanarak",
        Trueapp_desc: "TrueUSD uygulaması",
        Please_desc: "Not: Şu anda ACH veya Venmo, TransferWise, PayPal, Revolut gibi diğer dijital para transfer hizmetlerini kabul etmiyoruz.",
        account_desc: "Banka hesabınızdaki isim, TrueUSD hesabınızdaki isimle eşleşmelidir.",
        creating_desc: "Hesabınızı oluşturduktan sonra, bir e-posta alacaksınız",
        generally_desc: "Genel olarak, bankalar arası havaleler genellikle 1-5 iş günü sürer.",
        email_desc: "Havalenizi aldıktan sonra, size bir onay e-postası göndereceğiz.",
        minted_desc: "Fonlarınızı aldıktan sonra, TrueUSD 1 iş günü içinde basılacak ve uygulamada sağlanan cüzdan adresine aktarılacaktır.",
        redeem4_desc: "S4. TrueUSD nasıl itfa edilir?",
        Visit_desc: "Ziyaret edin",
        Polygon_desc1: "TrueUSD uygulaması",
        receive_desc: "banka bilgilerinizi girmek ve benzersiz itfa adresinizi almak için. Fiat para transferleri, banka ortaklarımızın KYC ve AML gereksinimlerine tabidir.",
        redemption_desc: "Bu itfa adresi her TrueUSD kullanıcısı için benzersizdir. Benzersiz itfa adresinizi başkalarıyla paylaşmayın.",
        simple_desc: "Neden itfa adresim bu kadar basit?",
        notice_desc: "Bu adresin çok basit olduğunu ve birçok '0' içerdiğini fark edeceksiniz. Bu kasıtlıdır. Bu adrese herhangi bir cüzdandan (borsa cüzdanları dahil) itfa edebilirsiniz ve bu adrese alınan tüm itfalar TrueUSD hesabınıza bağlanacaktır.",
        unique_desc: "Benzersiz itfa adresinize TrueUSD gönderin (minimum $1,000).",
        partner_desc: "Tokenler benzersiz itfa adresinize başarıyla gönderildikten sonra, banka ortaklarımız genellikle 1 iş günü içinde banka hesabınıza havale gönderir.",
        exchanges_desc: "S5. TrueUSD (TUSD) hangi borsalarda listeleniyor?",
        Binance_desc: "TrueUSD şu anda Binance, Bittrex ve Upbit dahil olmak üzere çeşitli borsalarda listelenmektedir.",
        here2_desc: "burada",
        markets_desc: "aktif TrueUSD borsalarının ve piyasalarının tam listesini almak için.",
        contract_desc: "S6. TrueUSD'nin akıllı sözleşme adresi nedir?",
        tokenSmart_desc: "Token akıllı sözleşmelerimiz, TUSD'nin yerel dağıtım ağı ve TUSD köprü ağı veya Binance Chain tarayıcısı aracılığıyla bulunabilir. Aşağıdaki bağlantılar. Ayrıca akıllı sözleşmelerimiz ve akıllı sözleşme güncellemeleri hakkında daha fazla bilgiyi",
        blog_desc: "blogumuzda",
        related_desc: "bulabilirsiniz.",
        Natively_desc: "TUSD'nin Yerel Dağıtım Ağı",
        Ethererum2_desc: "Ethereum",
        SmartChain_desc: "BNB Smart Chain:",
       
        Avalanche2_desc: "Avalanche",
        Beacon_desc: "BNB Beacon Chain:",
        Networks2_desc: "TUSD Köprü Ağı",
        Polygon2_desc: "Polygon",
        Fantom2_desc: "Fantom",
      
        Polygon_desc2: "Optimism",
        ChainOld_desc: "BNB Smart Chain (eski):",
        AboutMore_desc: "TUSD hakkında daha fazla bilgi edinin",
        Digital_desc: "Yeni küresel ödeme sistemi için tasarlanmış dijital para birimi",
        first_desc: "TrueUSD, bağımsız bir üçüncü taraf kurum tarafından günlük olarak denetlenen ilk ABD doları destekli stabilcoindir.",
        Polygon_desc: "Polygon",
        Follow_desc: "Bizi Takip Edin",
        Resources_desc: "Kaynaklar",
        Stablecoin_desc: "TrueUSD, 'Rezerv Kanıtı' ile basımı garanti eden ilk ABD doları destekli stabilcoin oldu",
        Engages_desc: "TUSD, fiat para rezervlerinin doğrulama hizmetlerini sağlamak için önde gelen bir muhasebe firması olan MooreHK ile işbirliği yapar",
        Becomes_desc: "TUSD (TRC-20), Dominika'nın yasal para birimi olur",
        Blog_desc: "Blog",
        Support_desc: "Destek",
        TermsOf_desc: "Kullanım Şartları",
        Privacy_desc: "Gizlilik Politikası",
        TrueUsd_desc: "TrueUSD",
        Home_desc: "Ana Sayfa",
        Transparency_desc: "Şeffaflık",
        Ecosystem_desc: "Ekosistem",
        About_desc: "Hakkında",
        Supported_desc: "Desteklenen Zincirler",
        Other_desc: "Diğer Zincirler",
        rights_desc: "© 2023 TrueUSD. Tüm hakları saklıdır.",
        Back_desc: "Başa Dön"
      },
      project_desc: {
        Pool_desc: "Fon Havuzu",
        participating_desc: "Tokenlara katılarak,",
        rewards_desc: "ödül olarak alacaksınız.",
        EndTime_desc: "Proje Bitiş Zamanı",
        duration_desc: "Stake Süresi",
        days_desc: "gün",
        calculation_desc: "Dönemsel Hesaplama",
        Invite_desc: "Davet Et",
        level_desc: "Seviye Komisyonu",
        withdrawal_desc: "Minimum Çekim Miktarı",
        supply_desc: "Minimum Tedarik Miktarı",
        period_desc: "Minimum Çekim Süresi",
        Invitations_desc: "Davet Seviyesi",
        People_desc: "Kişi Sayısı"
      },
      capitaInfoView_desc: {
        Please_desc: "Lütfen adresi bağlayın",
        Pool_desc: "Fon Havuzu",
        receive_desc: "Tokenlara katılarak,",
        rewards_desc: "ödül olarak alacaksınız.",
        level_desc: "Seviye Fon Havuzu",
        Expired_desc: "Süresi Dolmuş",
        Unexpired_desc: "Süresi Dolmamış",
        Participated_desc: "Katıldı",
        uncommitted_desc: "Taahhütsüz",
        Withdraw_desc: "Çek",
        Redeem_desc: "İtfa Et",
        Status_desc: "Durum",
        Principal_desc: "Anapara",
        Redeemed_desc: "İtfa Edildi",
        subaccounts_desc: "Alt Hesaplar",
        Supplied_desc: "Sağlandı",
        Redeemable_desc: "İtfa Edilebilir",
        Commission_desc: "Komisyon",
        Available_desc: "Çekilebilir",
        Withdrawed_desc: "Çekildi",
        Lock_desc: "Komisyonu Kilitle",
        All_desc: "Tüm Komisyonlar",
        interest_desc: "Faiz",
        Settled_desc: "Hesaplandı",
        Loading_desc: "Yükleniyor",
        amount_desc: "Çekilebilir Miktar"
      },
      elModal_desc: {
        Please_desc: "Lütfen yatırılacak miktarı girin",
        Recharge_desc: "Şarj Et",
        Cancel_desc: "İptal Et",
        withdrawal_desc: "Lütfen çekilecek miktarı girin",
        Withdraw_desc: "Çek",
        redemption_desc: "Lütfen itfa edilecek miktarı girin",
        Redeem_desc: "İtfa Et",
        Withdrawable_desc: "Çekilebilir Miktar (komisyon + faiz):",
        balance_desc: "İtfa Edilebilir Bakiye:",
        input_desc: "Lütfen miktarı girin",
        Transaction_desc: "İşlem Başarılı!",
        failed_desc: "İşlem Başarısız",
        funds_desc: "Hesapta yeterli bakiye yok",
        Loading_desc: "Yükleniyor"
      },
    modal: {
        hold_desc: 'Eğer',
        Smart_desc: "BNB Smart Chain'de tutuyorsanız, bonus ödülünüz olacak",
        Rewards_desc: 'Ödüller,',
        address_desc: 'Adres',
        hours_desc: '24 saat boyunca toplamak için ve minimum tutma rastgele sistem anlık görüntüsüne dayanmaktadır. Miktar, her 24 saatte bir tahsis edilir. Gerçek tahsis hesaba geçerlidir.'
    },
    UserInfo: {
        Wallets_desc: 'Cüzdanlar',
        Reward_desc: 'Ödül',
        half_month: '15 Gün',
        one_month: '1 ay',
        three_month: '3 ay',
        Invitees: 'Davetliler',
        no_data: 'Veri Yok',
        walletNo_support: 'Cüzdanınız desteklenmiyor. Lütfen başka bir cüzdan uygulaması kullanın.',
        network_wrong: 'Lütfen doğru ağı seçin',
      },
}
export default en_TEQ

// Datajson:{
//     language_json:{
//         zh_json:'Basitleştirilmiş Çince',
//         zh_FT_json: 'Traditional Chinese',
//         eh_json:'İngilizce',
//         ja_JP_json:'Japonca',  
//         ko_KR_json:'Korece' ,
//         en_TH_json:'Tayland',
//         fr_FR_json:'Fransızca',
//         ru_RU_json:'Rusça',
//         en_IN_json:'Hindi',
//         en_ID_json:'Indonezi',
//         en_BS_json:'Farsça',
//         en_YN_json:'Vietnamca',
//         en_ALB_json:'Arapça',
//         en_TEQ_json:'Türkçe',
//         en_ES_json:'İspanyolca',
//         en_PTY_json:'Portekizce',
//         en_YDL_json:'İtalyanca'
//     },
// },