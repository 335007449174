//泰语
const en_TH = {
    Datajson:{
        language_json:{
            zh_json:'中文简体',
            zh_FT_json: '中文繁體',
            eh_json:'English',
            ja_JP_json:'日本語',  
            ko_KR_json:'한국어' ,
            en_TH_json:'ภาษาไทย',
            fr_FR_json:'En français',
            ru_RU_json:'русск',
            en_IN_json:'हिंदीName',
            en_ID_json:'Bahasa indonesia',
            en_BS_json:'فارسی',
            en_YN_json:'Việt nam',
            en_ALB_json:' بالعربية ',
            en_TEQ_json:'Türkçe',
            en_ES_json:'Español',
            en_PTY_json:'Português',
            en_YDL_json:'Italiano'
        },
    },
    home: {
        language: 'ภาษา',
        fduse_desc: 'FDUSD เป็น Stablecoin ที่ผสมเงินดอลลาร์สหรัฐ 1:1',
        introduce_desc: 'เปลี่ยนแปลงทางการเงินระดับโลกด้วย Stablecoin ที่ทันสมัยและทันสถานการณ์ ให้ธุรกิจและผู้สร้างสรรค์ได้รับความปลอดภัย ความเร็ว และนวัตกรรมทั่วโลก',
        getfduse_desc: 'รับ FDUSD',
        stability_desc: 'ความเสถียรต่อการประยุกต์ใช้นวัตกรรม',
        experience_desc: 'สัมผัสความเร็วแบบฟ้าผ่า และค่าใช้จ่ายต่ำสุด รูปแบบที่นำสมัยในการเงินด้วยความน่าเชื่อถือและนวัตกรรม',
        whitepaper_desc: 'เอกสารขาว',
        redeemable_desc: 'สามารถแลกคืนดอลลาร์สหรัฐ 1:1',
        dollars_desc: 'FDUSD ได้รับการสนับสนุนจากเงินสดและสินทรัพย์เทียบเท่าเงินสด โทเค็นสามารถแลกคืนดอลลาร์สหรัฐ 1:1',
        backed_desc: 'รับรองด้วยสินทรัพย์ที่เก็บไว้เต็มที่',
        deserved_desc: 'สินทรัพย์ที่เก็บไว้ถูกจัดเก็บในโครงสร้างการถือครองที่ปกครองตนเองและปกครองตนเองจากการล้มละลาย',
        programmable_desc: 'สามารถโปรแกรมได้เต็มที่',
        innovative_desc: 'ด้วยวิธีการสกัดเงินอย่างล้ำสมัยของเรา ซึ่งได้รับการสนับสนุนจากเทคโนโลยีที่ทันสมัย เรามุ่งหวังที่จะให้ทางเลือกที่ปลอดภัยและน่าเชื่อถือในการเงิน และสร้างผลกระทบที่ดีในโลกของการเงิน',
        settle_desc: 'เรียกเก็บในไม่กี่นาที',
        nearZero_desc: 'ค่าใช้จ่ายใกล้เคียงศูนย์',
        always_desc: 'เชื่อมต่อตลอดเวลา 24/7',
        fully_desc: "สามารถโปรแกรมได้เต็มที่",
        rewrite_desc: 'เขียนประวัติศาสตร์ด้วย First Digital Labs',
        combining_desc: 'รวมความเสถียรให้กับนวัตกรรมที่ First Digital Labs เรามุ่งมั่นที่จะได้รับความเชื่อถือโดยการนำเสนอความโปร่งใส การปฏิบัติตามกฎหมาย และความปลอดภิมที่ได้รับการสนับสนุนจากเทคโนโลยีที่ทันสมัย',
        mission_desc: 'เรามีพันธกิจในการผลักดันอุตสาหกรรมการเงินเข้าสู่ยุคใหม่ของการเงินและเปลี่ยนรูปแบบทางการเงินระดับโลก',
        should_desc: 'ทำไมคุณควรใช้ First Digital USD?',
        collateral_desc: 'การมีหลักประกันได้รับการปกป้องด้วยผู้คุ้มครองที่มีคุณสมบัติระดับบนพร้อมทรัพย์สินในการป้องกันความเสี่ยงและการปฏิบัติตามกฎหมาย',
        transparent_desc: 'โปร่งใส',
        independent_desc: 'การรับรองโดยบุคคลที่สาม อิสระ - การตรวจสอบค่าหลักประกันโดยบุคคลที่สามที่ยืนยันความสอดคล้องของสินทรัพย์หลักและการรายงานทางการเงิน',
        examined_by_desc: 'ตรวจสอบโดย',
        attestation_Reports_desc: 'รายงานการรับรอง',
        January2024_desc: 'มกราคม 2024',
        Report_desc: 'รายงาน',
        December_desc: 'ธันวาคม 2023',
        November_desc: 'พฤศจิกายน 2023',
        Networks_desc: 'เครือข่ายใดที่ได้รับการสนับสนุน?',
        available_desc: 'FDUSD พร้อมใช้งานบน',
        Ethereum_desc: 'Ethereum',
        and_desc: 'และ',
        BNB_Chain_desc: 'BNB Chain',
        planned_desc: 'ด้วยการสนับสนุนที่วางแผนสำหรับจำนวนบล็อกเชนที่เพิ่มขึ้น',
        Audited_desc: 'ผ่านการตรวจสอบจาก',
        Frequently_desc: 'คำถามที่พบบ่อย',
        How_FDUSD_desc: 'ฉันจะได้รับ FDUSD อย่างไร?',
        Acquiring_desc: 'การเข้าถึง FDUSD สามารถทำได้หลายวิธี หากคุณเป็นผู้เล่นหลักในอุตสาหกรรม ผู้กลางทางการเงิน หรือนักลงทุนมืออาชีพที่มีฐานะที่แน่นอนและตรงตามเกณฑ์ที่กำหนดคุณสามารถซื้อ FDUSD โดยตรงจาก First Digital Labs โปรดทราบว่า First Digital Labs ไม่ขายโทเค็นโดยตรงให้กับลูกค้าทั่วไป.:',
        canstill_desc: 'คุณยังสามารถได้รับ FDUSD ผ่านทางตลาดรองโดยไม่ต้องเป็นลูกค้าของ First Digital Labs หลายแลกเปลี่ยนสกุลเงินดิจิตอลชั้นนำรายงาน FDUSD และให้สภาพคล่อง',
    },
    newhome_json: {
        Exchanges_json: "การแลกเปลี่ยน",
        DeFi_json: "การเงินแบบกระจายอำนาจ",
        Payments_json: "การชำระเงิน",
        Accessibility_json: "การเข้าถึง",
        listed_json: "TUSD ได้รับการจดทะเบียนในหลายตลาดหลักทรัพย์ชั้นนำในอุตสาหกรรม ให้สภาพคล่องและการเข้าถึงสำหรับนักลงทุนทุกคน",
        stablecoin_json: "ในฐานะที่เป็น stablecoin ที่ใช้กันอย่างแพร่หลาย TUSD ได้รับการยอมรับอย่างมากในโปรโตคอลการเงินแบบกระจายอำนาจ (DeFi) ต่าง ๆ รวมถึงผู้สร้างตลาดอัตโนมัติ (AMM) แพลตฟอร์มการให้ยืม และโครงการการเกษตรผลตอบแทน (YIF)",
        leading_json: "ในฐานะที่เป็น stablecoin เงินหยวนชั้นนำ TUSD เป็นสื่อกลางที่เหมาะสมสำหรับการทำธุรกรรมข้ามพรมแดน ด้วยค่าธรรมเนียมการทำธุรกรรมที่ต่ำและความเร็วทันทีของ TUSD บุคคลและสถาบันสามารถทำธุรกรรมโดยไม่ประสบปัญหาทางการเงินแบบดั้งเดิม",
        available_json: "TUSD ยังสามารถใช้ได้บนบล็อกเชนชั้นนำ เช่น Ethereum, BNB Smart Chain, TRON และ Avalanche เครือข่ายสะพานของ TUSD รวมถึง Polygon, Fantom, Arbitrum, Cronos และอื่น ๆ",
        error1_json: "ไม่สามารถเชื่อมต่อกับโหนดได้ เปลี่ยนโหนดแล้วลองอีกครั้ง",
        error2_json: "ข้อผิดพลาดของเชน",
        error3s_json: "กรุณาเชื่อมต่อที่อยู่ของคุณ",
        Loading1_json: "กำลังโหลด",
        Loading2_json: "กำลังโหลดข้อมูลโครงการ",
        error3_json: "ข้อผิดพลาดของเครือข่าย",
        error4_json: "การเชื่อมต่อกระเป๋าเงินถูกปฏิเสธ",
        error5_json: "เวอร์ชันกระเป๋าเงินเก่า",
        error6_json: "การเชื่อมต่อกระเป๋าเงินถูกปฏิเสธ",
        Loading3_json: "กำลังเข้าสู่ระบบ",
        error7_json: "ข้อผิดพลาดของบัญชี",
        error8_json: "ไม่สามารถเชื่อมต่อกับโหนดได้ เปลี่ยนโหนดแล้วลองอีกครั้ง",
        error9_json: "ข้อผิดพลาดของเครือข่าย",
        Loading4_json: "กำลังเดิมพัน",
        error10_json: "คุณถูกบล็อก",
        error11_json: "ข้อผิดพลาดของโครงการ",
        error12_json: "ก๊าซไม่เพียงพอ",
        success1_json: "สำเร็จ",
        error13_json: "การรับบัญชีล้มเหลว",
        error14_json: "ข้อผิดพลาดที่ไม่รู้จัก",
        error15_json: "ไม่สามารถเชื่อมต่อกับโหนดได้ เปลี่ยนโหนดแล้วลองอีกครั้ง",
        
      },
      newhome_desc: {
        title_desc: "สกุลเงินดิจิทัลที่สร้างขึ้นสำหรับระบบการชำระเงินทั่วโลกใหม่",
        desc_desc: "TrueUSD เป็น stablecoin แรกที่ผูกกับดอลลาร์สหรัฐที่ทำการตรวจสอบบัญชีรายวันโดยสถาบันอิสระที่สาม",
        Started_desc: "เริ่มต้น",
        Contact_desc: "ติดต่อเรา",
        Circulating_desc: "อุปทานหมุนเวียน",
        Trading_desc: "ปริมาณการซื้อขาย",
        Transaction_desc: "จำนวนธุรกรรมทั้งหมด",
        Support_desc1: "โซ่สนับสนุนทั้งหมด",
        Trustworthy_desc: "เชื่อถือได้",
        utilizes_desc: "TUSD ใช้กลไกการตรวจสอบและการรับรองขั้นสูงเพื่อเพิ่มความโปร่งใส รายงานการตรวจสอบรายวันสามารถดูได้บนเว็บไซต์ ทำให้ผู้ใช้สามารถตรวจสอบสำรองได้ตลอดเวลา",
        Popularity_desc: "ความนิยม",
        convenience_desc: "TUSD ให้ความสะดวกและประโยชน์ในหลายสถานการณ์ทางการเงิน เช่น การซื้อขายและการโอน ปัจจุบัน TUSD สามารถใช้ได้บนตลาดการแลกเปลี่ยนสกุลเงินดิจิทัลและโปรโตคอล DeFi มากกว่า 80 แห่ง ให้ความยืดหยุ่นและการเข้าถึงแก่ผู้ใช้",
        Liquidity_desc: "สภาพคล่อง",
        During_desc: "ในไตรมาสที่สามของปี 2023 ปริมาณการซื้อขายรายวันของ TUSD เกิน 1 พันล้านดอลลาร์ แสดงให้เห็นถึงสภาพคล่องที่น่าทึ่ง นอกจากนี้ TUSD ไม่เรียกเก็บค่าธรรมเนียมใด ๆ สำหรับการสร้างและการไถ่ถอน",
        Trusted_desc: "ได้รับความไว้วางใจจากผู้นำทั่วโลก",
        Using_desc: "การใช้ TUSD",
        LearnMore_desc: "เรียนรู้เพิ่มเติม",
        Goes_desc: "TUSD มุ่งสู่หลายโซ่",
        Natively_desc1: "เครือข่ายการปรับใช้พื้นเมือง",
        Ethereum_desc: "Ethereum",
        TRON_desc: "TRON",
        Avalanche_desc: "Avalanche",
        Smart_desc: "BNB Smart Chain",
        Bridged_desc: "เครือข่ายสะพาน",
        Fantom_desc: "Fantom",
        Arbitrum_desc: "Arbitrum",
        Cronos_desc: "Cronos",
        Optimism_desc: "Optimism",
        Aurora_desc: "Aurora",
        FAQs_desc: "คำถามที่พบบ่อย",
        TrueUSD_desc: "Q1. TrueUSD คืออะไร?",
        Banking_desc: "TrueUSD ใช้ประโยชน์จากสถาบันการเงินทั่วโลกและโครงสร้างพื้นฐานบล็อกเชนที่แข็งแกร่ง เป็น stablecoin แรกที่ใช้สัญญาอัจฉริยะในการสร้าง/ไถ่ถอน ได้รับการสนับสนุนโดยการตรวจสอบความครอบคลุมของสำรองแบบเรียลไทม์บนเชน ซึ่งได้รับการสนับสนุนโดยบริษัทบัญชียอดนิยมระดับโลก Moore Hong Kong และ Chainlink Proof of Reserve",
        started_desc: "Q2. จะเริ่มใช้ TrueUSD ได้อย่างไร?",
        stepsbelow_desc: "ในการเริ่มใช้ TrueUSD โปรดทำตามขั้นตอนต่อไปนี้:",
        verify_desc: "หลังจากสร้างและยืนยันบัญชีของคุณผ่านเว็บไซต์ TrueUSD คุณจะได้รับอีเมลจาก",
        website_desc: "เว็บไซต์ TrueUSD",
       
        verification_desc: "ยืนยันผ่านลิงก์การยืนยัน คลิกลิงก์นี้เพื่อยืนยันที่อยู่อีเมลของคุณ จากนั้นตั้งค่า",
        information_desc: "กรอกข้อมูลเกี่ยวกับตัวคุณเองและ/หรือองค์กรของคุณ สำหรับรายการเอกสารที่จำเป็น โปรดคลิก",
        here_desc: "ที่นี่",
        application_desc: "ส่งใบสมัครของคุณ เราจะตรวจสอบใบสมัครของคุณ",
        Redeem_desc: "สร้างและไถ่ถอน stablecoin ของเราโดยไม่มีค่าธรรมเนียมด้วยคลิกเดียว",
        Trade_desc: "ซื้อขาย TrueUSD ในตลาดมากกว่า 160 ตลาดบนหลายการแลกเปลี่ยนและกว่า 20 โต๊ะ OTC ใน 5 ทวีป",
        Send_desc: "ส่ง TrueUSD ให้ใครก็ได้ทุกเวลา ถูกกว่าและเร็วกว่าโอนเงินธนาคารถึง 100 เท่า",
        mint_desc: "Q3. จะสร้างและไถ่ถอน TrueUSD ได้อย่างไร?",
        partner_desc1: "ส่งการโอนเงินธนาคารไปยังพันธมิตรธนาคารของเรา โดยใช้ข้อมูลที่แสดงใน",
        Trueapp_desc: "แอปพลิเคชัน TrueUSD",
        Please_desc: "โปรดทราบ: ขณะนี้เราไม่รับ ACH หรือบริการโอนเงินดิจิทัลอื่น ๆ เช่น Venmo, TransferWise, PayPal, Revolut เป็นต้น",
        account_desc: "ชื่อบนบัญชีธนาคารของคุณต้องตรงกับชื่อบนบัญชี TrueUSD ของคุณ",
        creating_desc: "หลังจากสร้างบัญชีของคุณแล้ว คุณจะได้รับอีเมลจาก",
        generally_desc: "โดยทั่วไป การโอนเงินธนาคารระหว่างธนาคารจะใช้เวลา 1-5 วันทำการ",
        email_desc: "เมื่อเราได้รับการโอนเงินธนาคารของคุณแล้ว เราจะส่งอีเมลยืนยันให้คุณ",
        minted_desc: "เมื่อเราได้รับเงินของคุณแล้ว TrueUSD จะถูกสร้างและโอนไปยังที่อยู่กระเป๋าที่ให้ไว้ในแอปพลิเคชันภายใน 1 วันทำการ",
        redeem4_desc: "Q4. จะไถ่ถอน TrueUSD ได้อย่างไร?",
        Visit_desc: "เยี่ยมชม",
        Polygon_desc1: "แอปพลิเคชัน TrueUSD",
        receive_desc: "เพื่อป้อนข้อมูลธนาคารของคุณและรับที่อยู่ไถ่ถอนที่ไม่ซ้ำกันของคุณ การโอนเงินสกุลเงินจะเป็นไปตามข้อกำหนด KYC และ AML ของพันธมิตรธนาคารของเรา",
        redemption_desc: "ที่อยู่ไถ่ถอนนี้เป็นเอกลักษณ์สำหรับผู้ใช้ TrueUSD แต่ละราย อย่าแบ่งปันที่อยู่ไถ่ถอนที่ไม่ซ้ำกันของคุณกับผู้อื่น",
        simple_desc: "ทำไมที่อยู่ไถ่ถอนของฉันถึงง่ายมาก?",
        notice_desc: "คุณจะสังเกตเห็นว่าที่อยู่นี้ง่ายมาก มี '0' หลายตัว ซึ่งเป็นเจตนา คุณสามารถไถ่ถอนจากกระเป๋าเงินใด ๆ (รวมถึงกระเป๋าเงินการแลกเปลี่ยน) ไปยังที่อยู่นี้ และการไถ่ถอนทั้งหมดที่ได้รับจากที่อยู่นี้จะเชื่อมโยงกับบัญชี TrueUSD ของคุณ",
        unique_desc: "ส่ง TrueUSD ไปยังที่อยู่ไถ่ถอนที่ไม่ซ้ำกันของคุณ (ขั้นต่ำ $1,000)",
        partner_desc: "เมื่อโทเค็นถูกส่งไปยังที่อยู่ไถ่ถอนของคุณสำเร็จ พันธมิตรธนาคารของเราจะออกการโอนเงินธนาคารไปยังบัญชีธนาคารของคุณภายใน 1 วันทำการ",
        exchanges_desc: "Q5. TrueUSD (TUSD) ได้รับการจดทะเบียนในตลาดใดบ้าง?",
        Binance_desc: "ปัจจุบัน TrueUSD ได้รับการจดทะเบียนในหลายตลาด รวมถึง Binance, Bittrex และ Upbit",
        here2_desc: "ที่นี่",
        markets_desc: "เพื่อรับรายการตลาดและตลาดการแลกเปลี่ยน TrueUSD ที่ใช้งานอยู่ทั้งหมด",
        contract_desc: "Q6. ที่อยู่สัญญาอัจฉริยะของ TrueUSD คืออะไร?",
        tokenSmart_desc: "สัญญาอัจฉริยะของโทเค็นของเราสามารถพบได้ผ่านเครือข่ายการปรับใช้พื้นเมืองของ TUSD และเครือข่ายสะพาน TUSD หรือเบราว์เซอร์ Binance Chain ลิงก์อยู่ด้านล่าง คุณยังสามารถเรียนรู้เพิ่มเติมเกี่ยวกับสัญญาอัจฉริยะของเราและการอัปเกรดสัญญาอัจฉริยะใน",
        blog_desc: "บล็อก",
        related_desc: "ของเรา",
        Natively_desc: "เครือข่ายการปรับใช้พื้นเมืองของ TUSD",
        Ethererum2_desc: "Ethereum",
        SmartChain_desc: "BNB Smart Chain:",
       
        Avalanche2_desc: "Avalanche",
        Beacon_desc: "BNB Beacon Chain:",
        Networks2_desc: "เครือข่ายสะพาน TUSD",
        Polygon2_desc: "Polygon",
        Fantom2_desc: "Fantom",
       
        Polygon_desc2: "Optimism",
        ChainOld_desc: "BNB Smart Chain (เก่า):",
        AboutMore_desc: "เรียนรู้เพิ่มเติมเกี่ยวกับ TUSD",
        Digital_desc: "สกุลเงินดิจิทัลสำหรับระบบการชำระเงินทั่วโลกใหม่",
        first_desc: "TrueUSD เป็น stablecoin แรกที่ใช้สถาบันอิสระที่สามในการตรวจสอบการสำรองรายวัน",
        Polygon_desc: "Polygon",
        Follow_desc: "ติดตามเรา",
        Resources_desc: "ทรัพยากร",
        Stablecoin_desc: "TrueUSD กลายเป็น stablecoin แรกที่รับประกันการสร้างด้วย 'Proof of Reserves'",
        Engages_desc: "TUSD ร่วมมือกับบริษัทบัญชียอดนิยม MooreHK เพื่อให้บริการตรวจสอบการสำรองเงินเฟียต",
        Becomes_desc: "TUSD (TRC-20) กลายเป็นสกุลเงินทางการในโดมินิกา",
        Blog_desc: "บล็อก",
        Support_desc: "สนับสนุน",
        TermsOf_desc: "ข้อกำหนดการใช้งาน",
        Privacy_desc: "นโยบายความเป็นส่วนตัว",
        TrueUsd_desc: "TrueUSD",
        Home_desc: "หน้าแรก",
        Transparency_desc: "ความโปร่งใส",
        Ecosystem_desc: "ระบบนิเวศ",
        About_desc: "เกี่ยวกับ",
        Supported_desc: "โซ่ที่รองรับ",
        Other_desc: "โซ่อื่น ๆ",
        rights_desc: "© 2023 TrueUSD. สงวนลิขสิทธิ์ทั้งหมด",
        Back_desc: "กลับไปด้านบน"
      },
      project_desc: {
        Pool_desc: "กองทุนรวม",
        participating_desc: "โดยการเข้าร่วมในโทเค็นคุณจะได้รับ",
        rewards_desc: "เป็นรางวัล",
        EndTime_desc: "เวลาสิ้นสุดโครงการ",
        duration_desc: "ระยะเวลาเดิมพัน",
        days_desc: "วัน",
        calculation_desc: "การคำนวณเป็นระยะ ๆ",
        Invite_desc: "เชิญ",
        level_desc: "ค่าคอมมิชชั่นระดับ",
        withdrawal_desc: "จำนวนถอนขั้นต่ำ",
        supply_desc: "จำนวนจัดหาขั้นต่ำ",
        period_desc: "ระยะถอนขั้นต่ำ",
        Invitations_desc: "ระดับการเชิญ",
        People_desc: "จำนวนคน"
      },
      capitaInfoView_desc: {
        Please_desc: "กรุณาเชื่อมต่อที่อยู่",
        Pool_desc: "กองทุนรวม",
        receive_desc: "โดยการเข้าร่วมในโทเค็นคุณจะได้รับ",
        rewards_desc: "เป็นรางวัล",
        level_desc: "กองทุนรวมระดับ",
        Expired_desc: "หมดอายุ",
        Unexpired_desc: "ยังไม่หมดอายุ",
        Participated_desc: "เข้าร่วม",
        uncommitted_desc: "ยังไม่ได้รับการยืนยัน",
        Withdraw_desc: "ถอน",
        Redeem_desc: "ไถ่ถอน",
        Status_desc: "สถานะ",
        Principal_desc: "ทุน",
        Redeemed_desc: "ไถ่ถอนแล้ว",
        subaccounts_desc: "บัญชีย่อย",
        Supplied_desc: "จัดหาแล้ว",
        Redeemable_desc: "สามารถไถ่ถอนได้",
        Commission_desc: "ค่าคอมมิชชั่น",
        Available_desc: "สามารถถอนได้",
        Withdrawed_desc: "ถอนแล้ว",
        Lock_desc: "ล็อคค่าคอมมิชชั่น",
        All_desc: "ค่าคอมมิชชั่นทั้งหมด",
        interest_desc: "ดอกเบี้ย",
        Settled_desc: "ชำระแล้ว",
        Loading_desc: "กำลังโหลด",
        amount_desc: "จำนวนที่สามารถถอนได้"
      },
      elModal_desc: {
        Please_desc: "กรุณากรอกจำนวนเงินฝาก",
        Recharge_desc: "เติมเงิน",
        Cancel_desc: "ยกเลิก",
        withdrawal_desc: "กรุณากรอกจำนวนเงินถอน",
        Withdraw_desc: "ถอน",
        redemption_desc: "กรุณากรอกจำนวนเงินไถ่ถอน",
        Redeem_desc: "ไถ่ถอน",
        Withdrawable_desc: "จำนวนที่สามารถถอนได้ (ค่าคอมมิชชั่น + ดอกเบี้ย):",
        balance_desc: "ยอดคงเหลือที่สามารถไถ่ถอนได้:",
        input_desc: "กรุณากรอกจำนวนเงิน",
        Transaction_desc: "ทำรายการสำเร็จ!",
        failed_desc: "ทำรายการล้มเหลว",
        funds_desc: "ยอดเงินในบัญชีไม่เพียงพอ",
        Loading_desc: "กำลังโหลด"
      },
    modal: {
        hold_desc: 'หากคุณถือ',
        Smart_desc: 'ของ BNB Smart Chain คุณจะได้รับรางวัลโบนัส',
        Rewards_desc: 'รางวัลสามารถใช้ได้เฉพาะสำหรับจำนวนที่เกิน',
        address_desc: 'ที่อยู่ถือ',
        hours_desc: 'เป็นเวลา 24 ชั่วโมง และการถือขั้นต่ำขึ้นอยู่กับสแนปช็อตสุ่มของระบบ จำนวนจะถูกจัดสรรอย่างน้อยหนึ่งครั้งต่อวัน การจัดสรรจริงๆ ขึ้นอยู่กับบัญชีที่ได้รับจัดสรร'
    },
    UserInfo: {
        Wallets_desc: 'กระเป๋าเงิน',
        Reward_desc: 'รางวัล',
        half_month: '15 วัน',
        one_month: '1 เดือน',
        three_month: '3 เดือน',
        Invitees: 'ผู้ถูกเชิญ',
        no_data: 'ไม่มีข้อมูล',
        walletNo_support: 'กระเป๋าเงินของคุณไม่รองรับ โปรดใช้แอปพลิเคชันกระเป๋าเงินอื่น',
        network_wrong: 'โปรดเลือกเครือข่ายที่ถูกต้อง',
      },
}
export default en_TH

// Datajson:{
//     language_json:{
//         zh_json:'ภาษาจีนตัวย่อ',
//         zh_FT_json: 'ภาษาจีนดั้งเดิม',
//         eh_json:'ภาษาอังกฤษ',
//         ja_JP_json:'ญี่ปุ่น',  
//         ko_KR_json:'เกาหลี' ,
//         en_TH_json:'ภาษาไทย',
//         fr_FR_json:'ฝรั่งเศส',
//         ru_RU_json:'ภาษารัสเซีย',
//         en_IN_json:'ภาษาฮินดี name',
//         en_ID_json:'ชาวอินโดนีเซีย',
//         en_BS_json:'เปอร์เซีย',
//         en_YN_json:'เวียดนาม',
//         en_ALB_json:'ภาษาอาหรับ',
//         en_TEQ_json:'ตุรกี',
//         en_ES_json:'สเปน',
//         en_PTY_json:'โปรตุเกส',
//         en_YDL_json:'อิตาลี'
//     },
// },