<template>
  <div class="nav-muen">
    <div class="nav-muen-nav">
      <div class="nav-muen-logo-box">
        <div class="nav-muen-logo">
          <img src="https://tusd.io/imgs/tusd_logo.svg" />
        </div>
        <div class="nav-muen-title">TrueUSD</div>
      </div>
      <div class="nav-user-right">
        <div class="nav-user-solid">
          <i class="el-icon-s-data" @click="onContractInfo"></i>
        </div>
        <div class="nav-user-solid" v-if="this.screenWidth > 768">
          <i
            class="el-icon-orange"
            @mouseenter="iconEnter"
            @mouseleave="iconLeave"
          ></i>
          <div
            class="language"
            v-show="isFormShow"
            @mouseenter="iconEnter"
            @mouseleave="iconLeave"
          >
            <div
              class="language-list"
              @mouseenter="iconEnter"
              @mouseleave="iconLeave"
            >
              <div class="language-prompt">{{ $t("home.language") }}</div>
              <div class="language-search">
                <el-input
                  v-model="inputValue"
                  placeholder="search"
                  icon="search"
                  @keyup.enter="handleEnter"
                ></el-input>
              </div>
              <div class="language-select">
                <ul>
                  <li
                    v-for="(item, index) in filteredItems"
                    :key="index"
                    :value="item.key"
                    @click="Selected(item, index)"
                    :class="{ activeColor: colorIndex === index }"
                  >
                    {{ item.nameVal }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="nav-user-solid" v-if="this.screenWidth < 768">
          <i class="el-icon-orange" @click="iconEnter"></i>
          <div class="language-icon-back">
            <div
              class="language-back"
              v-show="this.screenWidth <= 768 && MaxFormShow"
            >
              <transition name="slide-right">
                <div
                  class="language-maxlist-Modal animate__backInRight"
                  v-show="this.screenWidth <= 768 && MaxFormShow"
                >
                  <div class="language-Modal-close">
                    <div
                      class="el-icon-close"
                      style="font-size: 24px; margin-right: 3rem"
                      @click="iconLeave"
                    ></div>
                  </div>
                  <div class="language-prompt">{{ $t("home.language") }}</div>
                  <div class="language-search">
                    <el-input
                      v-model="inputValue"
                      placeholder="search"
                      icon="search"
                      @keyup.enter="handleEnter"
                    ></el-input>
                  </div>
                  <div class="language-select">
                    <ul>
                      <li
                        v-for="(item, index) in filteredItems"
                        :key="index"
                        :value="item.key"
                        @click="Selected(item, index)"
                        :class="{ activeColor: colorIndex === index }"
                      >
                        {{ item.nameVal }}
                      </li>
                    </ul>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
        <!-- <div class="nav-user-solid">
          <i class="el-icon-user-solid" @click="projectInfo"></i>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HomeView",
  data() {
    return {
      inputValue: "",
      colorIndex: 2,
      languageOption: [],
      screenWidth: 0,
      screenHeight: 0,
      isFormShow: false,
      MaxFormShow: false,
    };
  },
  computed: {
    filteredItems() {
      if (!this.inputValue) {
        return this.languageOption; // 如果搜索框为空，则返回所有数据
      }
      const searchTermLower = this.inputValue.toLowerCase();
      return this.languageOption.filter((item) =>
        item.nameVal.toLowerCase().includes(searchTermLower)
      );
    },
  },
  created() {
    this.init();
    window.addEventListener("resize", this.handleResize); //视口监听
    this.handleResize();
    //监听网络
    setTimeout(() => {
      this.onCheckChain();
    }, 500);
  },
  methods: {
    init() {
      //初始化数据
      this.languageOption = [
        {
          key: 0,
          nameVal: this.$t("Datajson.language_json.zh_json"),
          locale: "zh",
        },
        {
          key: 1,
          nameVal: this.$t("Datajson.language_json.zh_FT_json"),
          locale: "zh_FT",
        },
        {
          key: 2,
          nameVal: this.$t("Datajson.language_json.eh_json"),
          locale: "en",
        },

        {
          key: 3,
          nameVal: this.$t("Datajson.language_json.ko_KR_json"),
          locale: "ko_KR",
        },
        {
          key: 4,
          nameVal: this.$t("Datajson.language_json.en_TH_json"),
          locale: "en_TH",
        },
        {
          key: 5,
          nameVal: this.$t("Datajson.language_json.fr_FR_json"),
          locale: "fr_FR",
        },
        {
          key: 6,
          nameVal: this.$t("Datajson.language_json.ru_RU_json"),
          locale: "ru_RU",
        },
        {
          key: 7,
          nameVal: this.$t("Datajson.language_json.en_IN_json"),
          locale: "en_IN",
        },
        {
          key: 8,
          nameVal: this.$t("Datajson.language_json.en_ID_json"),
          locale: "en_ID",
        },
        {
          key: 9,
          nameVal: this.$t("Datajson.language_json.en_BS_json"),
          locale: "en_BS",
        },
        {
          key: 10,
          nameVal: this.$t("Datajson.language_json.en_YN_json"),
          locale: "en_YN",
        },
        {
          key: 11,
          nameVal: this.$t("Datajson.language_json.en_ALB_json"),
          locale: "en_ALB",
        },
        {
          key: 12,
          nameVal: this.$t("Datajson.language_json.en_TEQ_json"),
          locale: "en_TEQ",
        },
        {
          key: 13,
          nameVal: this.$t("Datajson.language_json.en_ES_json"),
          locale: "en_ES",
        },
        {
          key: 14,
          nameVal: this.$t("Datajson.language_json.en_PTY_json"),
          locale: "en_PTY",
        },
        {
          key: 15,
          nameVal: this.$t("Datajson.language_json.en_YDL_json"),
          locale: "en_YDL",
        },
        {
          key: 16,
          nameVal: this.$t("Datajson.language_json.ja_JP_json"),
          locale: "ja_JP",
        },
      ];
    },
    Selected(item, index) {
      this.$i18n.locale = item.locale;
      console.log("是否改变", this.languageOption);
      this.colorIndex = index;
      this.iconLeave();
    },
    iconEnter() {
      console.log("进");
      this.MaxFormShow = true;
      this.isFormShow = true;
    },
    iconLeave() {
      this.isFormShow = false;
      this.MaxFormShow = false;
      console.log("出");
    },
    handleEnter() {},
    handleResize() {
      this.screenWidth = window.screen.width;
      this.screenHeight = window.screen.height;
      console.log("计算屏幕", this.screenWidth);
    },

    onContractInfo() {
      console.log("合约信息");
      if (this.$route.name == "userDataInfo") return;
      this.$router.push("userDataInfo");
    },
    projectInfo() {
      if (this.$route.name == "projectInfo") return;
      this.$router.push("projectInfo");
    },
  },
};
</script>
<style lang="scss" scoped>
.nav-muen {
  width: 100vw;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 10;
  background: white;
}
.nav-muen-nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .nav-muen-logo-box {
    display: flex;
    .nav-muen-logo {
      margin: 0 0.5rem;
    }
    .nav-muen-title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 1.5rem;
    }
  }
  .nav-user-right {
    width: 22%;
    display: flex;
    justify-content: space-around;
    .nav-user-solid {
      font-size: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }
  }
}
.language {
  max-width: 200px;
  position: absolute;
  left: -4.5rem;
  top: 0rem;
  font-size: 1rem;
  width: 10rem;
  height: 20rem;
  padding-top: 3rem;
  .language-list {
    position: absolute;
    background-color: white;
    border-radius: 5px 5px 5px 5px;
    height: 20rem;
    padding: 16px;
    cursor: pointer;
    .language-prompt {
      margin-bottom: 8px;
      text-align: left;
    }

    .language-search {
      padding: 5px;
    }

    .language-select {
      height: 74%;
    }

    .language-select ul {
      padding-left: 10px;
      height: 100%;
      text-align: left;
      overflow-y: auto;
    }

    .language-select ul::-webkit-scrollbar {
      width: 10px;
    }

    .language-select ul::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }

    .activeColor {
      color: rgb(252, 213, 53);
    }
  }
}
.language-back {
  position: fixed;
  background-color: rgb(94, 102, 115, 0.3);
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  .language-maxlist-Modal {
    position: fixed;
    padding: 1.5rem;
    font-size: 14px;
    background-color: white;
    height: 100vh;
    width: 100%;

    .language-Modal-close {
      display: flex;
      justify-content: right;
    }

    .language-prompt {
      padding: 10px;
      text-align: left;
    }

    .language-search {
      padding: 5px;
    }

    .language-select {
      height: 74%;
    }

    .language-select ul {
      padding-left: 10px;
      height: 100%;
      text-align: left;
      overflow-y: auto;
    }

    .language-select ul li {
      margin-top: 16px;
    }

    .activeColor {
      color: rgb(252, 213, 53);
    }
  }
}

fade-enter-active,
.slide-right-enter-active {
  transition: transform 0.5s;
}

.slide-right-enter {
  transform: translateX(100%);
}

.slide-right-leave-active {
  transition: transform 0.5s;
}

.slide-right-leave-to {
  transform: translateX(100%);
}
</style>
