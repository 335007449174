import Vue from 'vue';

import {ConnectWbe3,ConnectWallet,onCheckChain,environmentWalletType} from "./comom/wallet"
Vue.mixin({
  methods: {
    ConnectWbe3,
    ConnectWallet,
    onCheckChain,
    environmentWalletType
  },
});