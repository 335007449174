const ru_RU = {
    Datajson: {
        language_json: {
            zh_json: '中文简体',
            zh_FT_json: '中文繁體',
            eh_json: 'English',
            ja_JP_json: '日本語',
            ko_KR_json: '한국어',
            en_TH_json: 'ภาษาไทย',
            fr_FR_json: 'En français',
            ru_RU_json: 'русск',
            en_IN_json: 'हिंदीName',
            en_ID_json: 'Bahasa indonesia',
            en_BS_json: 'فارسی',
            en_YN_json: 'Việt nam',
            en_ALB_json: ' بالعربية ',
            en_TEQ_json: 'Türkçe',
            en_ES_json: 'Español',
            en_PTY_json: 'Português',
            en_YDL_json: 'Italiano'
        },
    },
    home: {
        language: 'язык',
        fduse_desc: 'FDUSD - это стейблкоин, привязанный к доллару США в соотношении 1:1.',
        introduce_desc: 'Революционизируйте глобальную финансовую систему современным цифровым стейблкоином, обеспечивая предприятиям и разработчикам безопасность, скорость и инновации во всем мире.',
        getfduse_desc: 'Получите FDUSD',
        stability_desc: 'Стабильность встречает инновации',
        experience_desc: 'Получите мгновенную скорость и минимальные затраты. Сформируйте будущее финансовой системы с нашим инновационным и надежным решением.',
        whitepaper_desc: 'Белая бумага',
        redeemable_desc: 'Обменяемый на доллар США по курсу 1:1',
        dollars_desc: 'FDUSD должен быть полностью обеспечен наличными и эквивалентом наличных средств. Токены могут быть обменены на доллары США по курсу 1:1.',
        backed_desc: 'Обеспечен полностью резервированными активами',
        deserved_desc: 'Резервные активы хранятся в полностью отделенных от банкротства структурах хранения.',
        programmable_desc: 'Полностью программируемый',
        innovative_desc: 'С нашим инновационным стейблкоином, поддерживаемым передовыми технологиями, мы стремимся предоставить безопасную и надежную альтернативу, приносящую уверенность и положительное влияние в мире финансов.',
        settle_desc: 'Расчет в минуты',
        nearZero_desc: 'Минимальные затраты',
        always_desc: 'Всегда включено, 24/7',
        fully_desc: "Полностью программируемый",
        rewrite_desc: 'Перепишите будущее с First Digital Labs',
        combining_desc: 'Сочетая стабильность и инновации в First Digital Labs, мы стремимся завоевать доверие, предлагая непоколебимую прозрачность, соответствие требованиям и безопасность на основе передовых технологий.',
        mission_desc: 'Мы нацелены на привод финансовой индустрии в новую эру и изменение глобального финансового ландшафта.',
        should_desc: 'Почему стоит использовать First Digital USD?',
        collateral_desc: 'Обеспечение защищено высококлассным, квалифицированным хранителем с лицензией доверительного управления для безупречной защиты активов и соблюдения регулирующих требований.',
        transparent_desc: 'Прозрачность',
        independent_desc: 'Независимая проверка - обеспечение проверяется независимой стороной, что гарантирует целостность резервов и финансовой отчетности.',
        examined_by_desc: 'Проверено',
        attestation_Reports_desc: 'Отчеты о проверке',
        January2024_desc: 'Январь 2024',
        Report_desc: 'Отчет',
        December_desc: 'Декабрь 2023',
        November_desc: 'Ноябрь 2023',
        Networks_desc: 'Какие сети поддерживаются?',
        available_desc: 'FDUSD доступен на',
        Ethereum_desc: 'Ethereum',
        and_desc: 'и',
        BNB_Chain_desc: 'BNB Chain',
        planned_desc: 'Планы поддерживают все больше блокчейнов.',
        fduse_desc: 'FDUSD - это стейблкоин, привязанный к доллару США в соотношении 1:1.',
        introduce_desc: 'Революционизируйте глобальную финансовую систему современным цифровым стейблкоином, обеспечивая предприятиям и разработчикам безопасность, скорость и инновации во всем мире.',
        getfduse_desc: 'Получите FDUSD',
        stability_desc: 'Стабильность встречает инновации',
        experience_desc: 'Получите мгновенную скорость и минимальные затраты. Сформируйте будущее финансовой системы с нашим инновационным и надежным решением.',
        whitepaper_desc: 'Белая бумага',
        redeemable_desc: 'Обменяемый на доллар США по курсу 1:1',
        dollars_desc: 'FDUSD должен быть полностью обеспечен наличными и эквивалентом наличных средств. Токены могут быть обменены на доллары США по курсу 1:1.',
        backed_desc: 'Обеспечен полностью резервированными активами',
        deserved_desc: 'Резервные активы хранятся в полностью отделенных от банкротства структурах хранения.',
        programmable_desc: 'Полностью программируемый',
        innovative_desc: 'С нашим инновационным стейблкоином, поддерживаемым передовыми технологиями, мы стремимся предоставить безопасную и надежную альтернативу, приносящую уверенность и положительное влияние в мире финансов.',
        settle_desc: 'Расчет в минуты',
        nearZero_desc: 'Минимальные затраты',
        always_desc: 'Всегда включено, 24/7',
        fully_desc: "Полностью программируемый",
        rewrite_desc: 'Перепишите будущее с First Digital Labs',
        combining_desc: 'Сочетая стабильность и инновации в First Digital Labs, мы стремимся завоевать доверие, предлагая непоколебимую прозрачность, соответствие требованиям и безопасность на основе передовых технологий.',
        mission_desc: 'Мы нацелены на привод финансовой индустрии в новую эру и изменение глобального финансового ландшафта.',
        should_desc: 'Почему стоит использовать First Digital USD?',
        collateral_desc: 'Обеспечение защищено высококлассным, квалифицированным хранителем с лицензией доверительного управления для безупречной защиты активов и соблюдения регулирующих требований.',
        transparent_desc: 'Прозрачность',
        independent_desc: 'Независимая проверка - обеспечение проверяется независимой стороной, что гарантирует целостность резервов и финансовой отчетности.',
        examined_by_desc: 'Проверено',
        attestation_Reports_desc: 'Отчеты о проверке',
        January2024_desc: 'Январь 2024',
        Report_desc: 'Отчет',
        December_desc: 'Декабрь 2023',
        November_desc: 'Ноябрь 2023',
        Networks_desc: 'Какие сети поддерживаются?',
        available_desc: 'FDUSD доступен на',
        Ethereum_desc: 'Ethereum',
        and_desc: 'и',
        BNB_Chain_desc: 'BNB Chain',
        planned_desc: 'с планами поддержки все больш',
    },
    newhome_json: {
        Exchanges_json: "Биржи",
        DeFi_json: "Децентрализованные финансы",
        Payments_json: "Платежи",
        Accessibility_json: "Доступность",
        listed_json: "TUSD котируется на нескольких ведущих биржах отрасли, предоставляя ликвидность и доступность для всех инвесторов.",
        stablecoin_json: "Будучи широко используемым стейблкоином, TUSD получил значительное принятие в различных протоколах децентрализованных финансов (DeFi), включая автоматизированных маркет-мейкеров (AMM), платформы кредитования и проекты доходного фермерства (YIF).",
        leading_json: "Будучи ведущим юаневым стейблкоином, TUSD является идеальным средством для трансграничных транзакций. Благодаря низким транзакционным комиссиям и мгновенной скорости TUSD, как физические лица, так и учреждения могут осуществлять транзакции без традиционных финансовых проблем.",
        available_json: "TUSD также доступен на ведущих блокчейнах, таких как Ethereum, BNB Smart Chain, TRON и Avalanche. Сеть мостов TUSD включает Polygon, Fantom, Arbitrum, Cronos и другие.",
        error1_json: "Не удалось подключиться к узлу. Попробуйте сменить узел и повторите попытку.",
        error2_json: "Ошибка цепи",
        error3s_json: "Пожалуйста, подключите ваш адрес",
        Loading1_json: "Загрузка",
        Loading2_json: "Загрузка информации о проекте",
        error3_json: "Сетевая ошибка",
        error4_json: "Подключение к кошельку отклонено",
        error5_json: "Старая версия кошелька",
        error6_json: "Подключение к кошельку отклонено",
        Loading3_json: "Вход в систему",
        error7_json: "Ошибка аккаунта",
        error8_json: "Не удалось подключиться к узлу. Попробуйте сменить узел и повторите попытку.",
        error9_json: "Сетевая ошибка",
        Loading4_json: "Залог",
        error10_json: "Вы заблокированы",
        error11_json: "Ошибка проекта",
        error12_json: "Недостаточно газа",
        success1_json: "Успех",
        error13_json: "Не удалось получить аккаунт",
        error14_json: "Неизвестная ошибка",
        error15_json: "Не удалось подключиться к узлу. Попробуйте сменить узел и повторите попытку.",
       
      },
      newhome_desc: {
        title_desc: "Цифровая валюта, созданная для новой глобальной платежной системы",
        desc_desc: "TrueUSD - это первый стейблкоин, привязанный к доллару США, который проходит ежедневный аудит своих резервов независимым третьим лицом.",
        Started_desc: "Начать",
        Contact_desc: "Свяжитесь с нами",
        Circulating_desc: "Циркулирующее предложение",
        Trading_desc: "Объем торгов",
        Transaction_desc: "Общее количество транзакций",
        Support_desc1: "Общая поддержка цепей",
        Trustworthy_desc: "Надежный",
        utilizes_desc: "TUSD использует передовые механизмы аудита и сертификации для повышения прозрачности. Ежедневные аудиторские отчеты доступны на сайте, что позволяет пользователям в любое время контролировать резервы.",
        Popularity_desc: "Популярность",
        convenience_desc: "TUSD обеспечивает удобство и полезность в различных финансовых сценариях, таких как торговля и переводы. В настоящее время TUSD доступен на более чем 80 криптовалютных биржах и протоколах DeFi, предоставляя пользователям гибкость и доступность.",
        Liquidity_desc: "Ликвидность",
        During_desc: "В третьем квартале 2023 года дневной объем торгов TUSD превысил 1 миллиард долларов, что демонстрирует его удивительную ликвидность. Кроме того, TUSD не взимает никаких комиссий за выпуск и выкуп.",
        Trusted_desc: "Доверяют мировые лидеры",
        Using_desc: "Использование TUSD",
        LearnMore_desc: "Узнать больше",
        Goes_desc: "TUSD идет в мультицепочки",
        Natively_desc1: "Сеть нативного развертывания",
        Ethereum_desc: "Эфириум",
        TRON_desc: "Трон",
        Avalanche_desc: "Аваланч",
        Smart_desc: "BNB Смарт Чейн",
        Bridged_desc: "Сеть мостов",
        Fantom_desc: "Фантом",
        Arbitrum_desc: "Арбитрум",
        Cronos_desc: "Кронос",
        Optimism_desc: "Оптимизм",
        Aurora_desc: "Аврора",
        FAQs_desc: "Часто задаваемые вопросы",
        TrueUSD_desc: "В1. Что такое TrueUSD?",
        Banking_desc: "TrueUSD использует глобальные финансовые институты и мощную инфраструктуру блокчейна. Это первый стейблкоин, использующий смарт-контракты для выпуска и выкупа, с поддержкой верификации покрытия резервов в реальном времени на цепи, обеспеченной ведущей мировой аудиторской фирмой Moore Hong Kong и Chainlink Proof of Reserve.",
        started_desc: "В2. Как начать использовать TrueUSD?",
        stepsbelow_desc: "Чтобы начать использовать TrueUSD, следуйте следующим шагам:",
        verify_desc: "После создания и верификации вашего аккаунта через сайт TrueUSD вы получите электронное письмо от",
        website_desc: "сайта TrueUSD",
       
        verification_desc: "Проверьте через ссылку для верификации. Нажмите на эту ссылку, чтобы подтвердить свой адрес электронной почты, а затем настройте",
        information_desc: "Заполните информацию о себе и/или вашей организации. Для списка необходимых документов нажмите",
        here_desc: "здесь",
        application_desc: "Отправьте вашу заявку. Мы рассмотрим вашу заявку.",
        Redeem_desc: "Выпуск и выкуп нашего стейблкоина без комиссии в один клик",
        Trade_desc: "Торгуйте TrueUSD на более чем 160 рынках на нескольких биржах и более чем 20 OTC-столах на 5 континентах",
        Send_desc: "Отправляйте TrueUSD любому, в любое время, дешевле в 100 раз и быстрее в 1000 раз, чем банковский перевод",
        mint_desc: "В3. Как выпускать и выкупать TrueUSD?",
        partner_desc1: "Отправьте банковский перевод нашим банковским партнерам, используя данные, указанные в",
        Trueapp_desc: "приложении TrueUSD",
        Please_desc: "Обратите внимание: в настоящее время мы не принимаем ACH или другие услуги по переводу цифровых валют, такие как Venmo, TransferWise, PayPal, Revolut и т.д.",
        account_desc: "Имя на вашем банковском счете должно совпадать с именем на вашем аккаунте TrueUSD.",
        creating_desc: "После создания вашего аккаунта вы получите электронное письмо от",
        generally_desc: "Обычно банковские переводы между банками занимают 1-5 рабочих дней.",
        email_desc: "После получения вашего банковского перевода мы отправим вам подтверждающее электронное письмо.",
        minted_desc: "После получения ваших средств TrueUSD будет выпущен и переведен на указанный в приложении адрес кошелька в течение одного рабочего дня.",
        redeem4_desc: "В4. Как выкупить TrueUSD?",
        Visit_desc: "Посетите",
        Polygon_desc1: "приложение TrueUSD",
        receive_desc: "чтобы ввести свои банковские данные и получить уникальный адрес для выкупа. Переводы фиатной валюты подлежат требованиям KYC и AML наших банковских партнеров.",
        redemption_desc: "Этот адрес для выкупа уникален для каждого пользователя TrueUSD. Не делитесь своим уникальным адресом для выкупа с другими.",
        simple_desc: "Почему мой адрес для выкупа такой простой?",
        notice_desc: "Вы заметите, что этот адрес очень простой, с множеством '0'. Это сделано намеренно. Вы можете выкупить с любого кошелька (включая кошельки бирж) на этот адрес, и все выкупы, полученные на этот адрес, будут связаны с вашим аккаунтом TrueUSD.",
        unique_desc: "Отправьте TrueUSD на ваш уникальный адрес для выкупа (минимум $1,000).",
        partner_desc: "После успешного отправления токенов на ваш адрес для выкупа наши банковские партнеры обычно отправляют банковский перевод на ваш банковский счет в течение одного рабочего дня.",
        exchanges_desc: "В5. На каких биржах котируется TrueUSD (TUSD)?",
        Binance_desc: "TrueUSD в настоящее время котируется на нескольких биржах, включая Binance, Bittrex и Upbit.",
        here2_desc: "здесь",
        markets_desc: "для получения полного списка активных бирж и рынков TrueUSD.",
        contract_desc: "В6. Какой адрес смарт-контракта TrueUSD?",
        tokenSmart_desc: "Наши смарт-контракты можно найти через нативную сеть развертывания TUSD и сеть мостов TUSD или обозреватель Binance Chain. Ссылки ниже. Вы также можете узнать больше о наших смарт-контрактах и обновлениях смарт-контрактов в нашем",
        blog_desc: "блоге",
        related_desc: ".",
        Natively_desc: "Нативная сеть развертывания TUSD",
        Ethererum2_desc: "Эфириум",
        SmartChain_desc: "BNB Smart Chain:",
       
        Avalanche2_desc: "Avalanche",
        Beacon_desc: "BNB Beacon Chain:",
        Networks2_desc: "Сеть мостов TUSD",
        Polygon2_desc: "Polygon",
        Fantom2_desc: "Fantom",
       
        Polygon_desc2: "Optimism",
        ChainOld_desc: "BNB Smart Chain (старый):",
        AboutMore_desc: "Узнать больше о TUSD",
        Digital_desc: "Цифровая валюта для новой глобальной платежной системы",
        first_desc: "TrueUSD - это первый стейблкоин, привязанный к доллару США, который проходит ежедневную проверку резервов независимым третьим лицом.",
        Polygon_desc: "Polygon",
        Follow_desc: "Следите за нами",
        Resources_desc: "Ресурсы",
        Stablecoin_desc: "TrueUSD становится первым стейблкоином, поддерживаемым долларом, который обеспечивает выпуск благодаря 'Proof of Reserves'",
        Engages_desc: "TUSD сотрудничает с ведущей аудиторской фирмой MooreHK для предоставления услуг проверки своих фиатных резервов",
        Becomes_desc: "TUSD (TRC-20) становится законным платежным средством в Доминике",
        Blog_desc: "Блог",
        Support_desc: "Поддержка",
        TermsOf_desc: "Условия использования",
        Privacy_desc: "Политика конфиденциальности",
        TrueUsd_desc: "TrueUSD",
        Home_desc: "Главная",
        Transparency_desc: "Прозрачность",
        Ecosystem_desc: "Экосистема",
        About_desc: "О нас",
        Supported_desc: "Поддерживаемые цепочки",
        Other_desc: "Другие цепочки",
        rights_desc: "© 2023 TrueUSD. Все права защищены.",
        Back_desc: "Вернуться наверх"
      },
      project_desc: {
        Pool_desc: "Фондовый пул",
        participating_desc: "Участвуя в токенах, вы получите",
        rewards_desc: "в качестве награды.",
        EndTime_desc: "Время окончания проекта",
        duration_desc: "Период стейкинга",
        days_desc: "дней",
        calculation_desc: "Периодический расчет",
        Invite_desc: "Пригласить",
        level_desc: "Комиссия уровня",
        withdrawal_desc: "Минимальная сумма вывода",
        supply_desc: "Минимальная сумма поставки",
        period_desc: "Минимальный период вывода",
        Invitations_desc: "Уровень приглашений",
        People_desc: "Количество человек"
      },
      capitaInfoView_desc: {
        Please_desc: "Пожалуйста, подключите адрес",
        Pool_desc: "Фондовый пул",
        receive_desc: "Участвуя в токенах, вы получите",
        rewards_desc: "в качестве награды.",
        level_desc: "Фондовый пул уровня",
        Expired_desc: "Истекший",
        Unexpired_desc: "Не истекший",
        Participated_desc: "Участвовал",
        uncommitted_desc: "Не взятый на себя",
        Withdraw_desc: "Вывести",
        Redeem_desc: "Выкупить",
        Status_desc: "Статус",
        Principal_desc: "Основная сумма",
        Redeemed_desc: "Выкуплен",
        subaccounts_desc: "Субсчета",
        Supplied_desc: "Поставлено",
        Redeemable_desc: "Подлежащий выкупу",
        Commission_desc: "Комиссия",
        Available_desc: "Доступно для вывода",
        Withdrawed_desc: "Выведено",
        Lock_desc: "Заблокированная комиссия",
        All_desc: "Все комиссии",
        interest_desc: "Процент",
        Settled_desc: "Урегулирован",
        Loading_desc: "Загрузка",
        amount_desc: "Сумма, доступная для вывода"
      },
      elModal_desc: {
        Please_desc: "Пожалуйста, введите сумму депозита",
        Recharge_desc: "Пополнить",
        Cancel_desc: "Отменить",
        withdrawal_desc: "Пожалуйста, введите сумму вывода",
        Withdraw_desc: "Вывести",
        redemption_desc: "Пожалуйста, введите сумму выкупа",
        Redeem_desc: "Выкупить",
        Withdrawable_desc: "Сумма, доступная для вывода (комиссия + процент):",
        balance_desc: "Баланс, подлежащий выкупу:",
        input_desc: "Пожалуйста, введите сумму",
        Transaction_desc: "Сделка успешна!",
        failed_desc: "Сделка не удалась",
        funds_desc: "Недостаточно средств на счету",
        Loading_desc: "Загрузка"
      },
    modal: {
        hold_desc: 'Если у вас есть',
        Smart_desc: 'BNB Smart Chain, у вас будет бонусное вознаграждение,',
        Rewards_desc: 'Вознаграждение доступно только для сумм, превышающих',
        address_desc: 'Адрес содержит',
        hours_desc: 'в течение 24 часов, и минимальное удержание основывается на случайном снимке системы. Сумма распределяется один раз в сутки. Фактическое выделение на счет зависит от условий.',
    },
    UserInfo: {
        Wallets_desc: 'Кошельки',
        Reward_desc: 'Вознаграждение',
        half_month: '15 дней',
        one_month: '1 месяц',
        three_month: '3 месяца',
        Invitees: 'Приглашенные',
        no_data: 'Нет данных',
        walletNo_support: 'Ваш кошелек не поддерживается. Пожалуйста, используйте другое приложение для кошелька.',
        network_wrong: 'Пожалуйста, выберите правильную сеть',
      },
}
export default ru_RU

// Datajson:{
//     language_json:{
//         zh_json:'Упрощенный китайский язык',
//         zh_FT_json: 'Китайский стиль ',
//         eh_json:'По-английск',
//         ja_JP_json:'японск',  
//         ko_KR_json:'По-корейск' ,
//         en_TH_json:'тайск',
//         fr_FR_json:'французск',
//         ru_RU_json:'русск',
//         en_IN_json:'Хинди.',
//         en_ID_json:'Индонезийский язык',
//         en_BS_json:'Фарси.',
//         en_YN_json:'Вьетнамский.',
//         en_ALB_json:'Арабский.',
//         en_TEQ_json:'На турецком.',
//         en_ES_json:'По-испански.',
//         en_PTY_json:'Португальский язык',
//         en_YDL_json:'По-итальянски.'
//     },
// },