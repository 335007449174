const en_YDL = {
    Datajson: {
        language_json: {
            zh_json: '中文简体',
            zh_FT_json: '中文繁體',
            eh_json: 'English',
            ja_JP_json: '日本語',
            ko_KR_json: '한국어',
            en_TH_json: 'ภาษาไทย',
            fr_FR_json: 'En français',
            ru_RU_json: 'русск',
            en_IN_json: 'हिंदीName',
            en_ID_json: 'Bahasa indonesia',
            en_BS_json: 'فارسی',
            en_YN_json: 'Việt nam',
            en_ALB_json: ' بالعربية ',
            en_TEQ_json: 'Türkçe',
            en_ES_json: 'Español',
            en_PTY_json: 'Português',
            en_YDL_json: 'Italiano'
        },
    },
    home: {
        language: 'lingua',
        fduse_desc: 'FDUSD è una stablecoin 1:1 ancorata al dollaro USA.',
        introduce_desc: "Rivoluziona la finanza globale con una stablecoin digitale all'avanguardia, fornendo sicurezza, velocità e innovazione a livello mondiale per le aziende e i costruttori.",
        getfduse_desc: 'Ottieni FDUSD',
        stability_desc: "Stabilità incontra l'innovazione",
        experience_desc: 'Vivi velocità fulminea e costi minimi. Dà forma al futuro della finanza con la nostra soluzione innovativa e affidabile.',
        whitepaper_desc: 'Whitepaper',
        redeemable_desc: 'Riscattabile 1:1 per dollari statunitensi',
        dollars_desc: 'FDUSD è destinato ad essere completamente garantito da contanti e attività equivalenti in contanti. I token sono destinati a essere riscattabili 1:1 per dollari statunitensi.',
        backed_desc: 'Supportato da riserve completamente riservate',
        deserved_desc: 'Le riserve sono detenute in strutture di conservazione completamente separate e al riparo da fallimenti.',
        programmable_desc: 'Completamente programmabile',
        innovative_desc: "Con la nostra soluzione innovativa di stablecoin - supportata da tecnologia avanzata, miriamo a fornire un'alternativa sicura e affidabile che porti tranquillità, facendo un impatto positivo nel mondo della finanza.",
        settle_desc: 'Liquidazione in pochi minuti',
        nearZero_desc: 'Costo vicino allo zero',
        always_desc: 'Sempre attivo, 24/7',
        fully_desc: "Completamente programmabile",
        rewrite_desc: 'Riscrivi il futuro con First Digital Labs',
        combining_desc: "Unendo stabilità e innovazione presso First Digital Labs, ci impegniamo a guadagnare fiducia offrendo una trasparenza inossidabile, conformità e sicurezza supportate da tecnologia all'avanguardia.",
        mission_desc: "Siamo impegnati in una missione per spingere l'industria finanziaria verso una nuova era della finanza e ridisegnare il panorama finanziario globale.",
        should_desc: 'Perché dovresti usare First Digital USD?',
        collateral_desc: 'Il margine è protetto da un custode qualificato di alto livello con una licenza fiduciaria per una protezione agevole degli asset e la conformità alle normative.',
        transparent_desc: 'Trasparente',
        independent_desc: "Accertamento indipendente - il margine è convalidato da una terza parte indipendente, garantendo l'integrità delle riserve e la rendicontazione finanziaria.",
        examined_by_desc: 'Esaminato da',
        attestation_Reports_desc: 'Rapporti di attestazione',
        January2024_desc: 'Gennaio 2024',
        Report_desc: 'Rapporto',
        December_desc: 'Dicembre 2023',
        November_desc: 'Novembre 2023',
        Networks_desc: 'Quali reti sono supportate?',
        available_desc: 'FDUSD è disponibile su',
        Ethereum_desc: 'Ethereum',
        and_desc: 'e',
        BNB_Chain_desc: 'BNB Chain',
        planned_desc: 'con il supporto pianificato per un numero crescente di blockchain.',
        Audited_desc: 'Sottoposto a revisione da parte di',
        Frequently_desc: 'Domande frequenti',
        How_FDUSD_desc: 'Come posso ottenere FDUSD?',
        Acquiring_desc: "L'acquisizione di FDUSD può essere effettuata in diverse modalità. Se sei un attore chiave nel settore, un intermediario finanziario o un investitore professionale di determinato rango e soddisfi i criteri richiesti, puoi acquistare direttamente FDUSD da First Digital Labs. Si prega di notare che First Digital Labs non vende direttamente i token ai clienti al dettaglio.:",
        canstill_desc: "È comunque possibile ottenere FDUSD attraverso mercati secondari senza diventare un cliente di First Digital Labs. Molti importanti exchange di criptovalute elencano FDUSD e offrono una liquidità sostanziale. Questo offre un modo accessibile e conveniente per gli individui interessati di acquisire FDUSD. Tuttavia, è sempre essenziale condurre ricerche approfondite e garantire la credibilità dell'exchange che si sta utilizzando.",
        equivalent_desc: 'Come posso fidarmi che FDUSD sarà sempreequivalente a un dollaro?',
       // stablecoin_desc: 'La tua fiducia nel valore della stablecoin FDUSD è ben fondata. Ogni FDUSD è destinato ad essere completamente garantito da un dollaro statunitense o da un asset di valore equo equivalente. Questi asset sono custoditi in modo sicuro da un custode qualificato in conti presso istituti depositari regolamentati, fornendo una solida base per il valore della moneta. Questo sistema è ciò che conferisce a FDUSD la sua stabilità intrinseca e perché puoi fidarti del suo valore equivalente al dollaro statunitense.',
        redeem_FDUSD: 'Come posso riscattare FDUSD?',
      //  stablecoin_desc: 'Per riscattare la tua stablecoin FDUSD, devi prima diventare un cliente di First Digital Labs e soddisfare requisiti specifici, inclusi controlli antiriciclaggio (AML) e finanziamento del terrorismo (CTF). Al termine di questi controlli, puoi scambiare i tuoi FDUSD con il loro equivalente in valuta fiat, rimuovendoli così dalla circolazione. In alternativa, puoi vendere i tuoi token FDUSD sul mercato secondario tramite un exchange di criptovalute o un fornitore over-the-counter (OTC) che supporta le stablecoin FD121. Prima di procedere, ti preghiamo di essere consapevole di tutte le normative legali e finanziarie nella tua giurisdizione.',
        Explore_desc: "Esplora il nostro ecosistema",
        Experience_desc: 'Vivi la stabilità con la nostra affidabile soluzione di stablecoin.',
        slide_desc: 'diapositiva',
        _1to6_desc: 'da 1 a 6',
        of7_desc: 'di 7',
        Transparency_desc: 'Trasparenza',
        Legal_desc: 'Legale',
        Compliance_desc: 'Conformità',
        Policy_desc: 'Politica sulla privacy',
        Company_desc: 'Azienda',
        About_Us_desc: 'Chi siamo',
        Social_Media_desc: 'Social Media',
        Twitter_desc: 'Twitter',
        Disclosures_desc: 'Informazioni importanti:',
        Limited_desc: 'FD121 Limited è una società di tecnologia finanziaria, non una banca. I servizi di custodia delle riserve sono forniti da ',
        First_desc: "First Digital Trust Limited",
        product_desc: "Tutti i nomi di prodotti e aziende sono marchi ™ o marchi registrati® dei rispettivi titolari. Tutti i nomi di aziende, prodotti e servizi utilizzati in questo sito web sono solo a scopo di identificazione. L'uso di questi nomi, marchi e marchi commerciali non implica alcuna affiliazione o approvazione da parte loro.",
        additional_desc: 'Per ulteriori rischi, rivelazioni e informazioni importanti, visitare',
        Copyright_desc: 'Copyright © 2024 FD121 Limited. Tutti i diritti riservati.'
    },
    newhome_json: {
        Exchanges_json: "Borse",
        DeFi_json: "Finanza Decentralizzata",
        Payments_json: "Pagamenti",
        Accessibility_json: "Accessibilità",
        listed_json: "TUSD è quotato su diverse borse leader del settore, fornendo liquidità e accessibilità a tutti gli investitori.",
        stablecoin_json: "Come una stablecoin ampiamente utilizzata, TUSD è stata adottata in modo significativo in vari protocolli di finanza decentralizzata (DeFi), inclusi i formatori di mercato automatizzati (AMM), le piattaforme di prestito e i progetti di yield farming (YIF).",
        leading_json: "Come la principale stablecoin in yuan, TUSD è un mezzo ideale per le transazioni transfrontaliere. Con le basse commissioni di transazione e la velocità istantanea di TUSD, sia i privati che le istituzioni possono effettuare transazioni senza le sfide finanziarie tradizionali.",
        available_json: "TUSD è anche disponibile sulle principali blockchain come Ethereum, BNB Smart Chain, TRON e Avalanche. La rete di ponti di TUSD include Polygon, Fantom, Arbitrum, Cronos e altro.",
        error1_json: "Impossibile connettersi al nodo. Cambia nodo e riprova",
        error2_json: "Errore di catena",
        error3s_json: "Per favore, collega il tuo indirizzo",
        Loading1_json: "Caricamento",
        Loading2_json: "Caricamento informazioni sul progetto",
        error3_json: "Errore di rete",
        error4_json: "Connessione al portafoglio rifiutata",
        error5_json: "Versione del portafoglio obsoleta",
        error6_json: "Connessione al portafoglio rifiutata",
        Loading3_json: "Accesso in corso",
        error7_json: "Errore di account",
        error8_json: "Impossibile connettersi al nodo. Cambia nodo e riprova",
        error9_json: "Errore di rete",
        Loading4_json: "Staking in corso",
        error10_json: "Sei stato bloccato",
        error11_json: "Errore di progetto",
        error12_json: "Gas insufficiente",
        success1_json: "Successo",
        error13_json: "Impossibile ottenere l'account",
        error14_json: "Errore sconosciuto",
        error15_json: "Impossibile connettersi al nodo. Cambia nodo e riprova",
       
      },
      newhome_desc: {
        title_desc: "Moneta digitale costruita per il nuovo sistema di pagamento globale",
        desc_desc: "TrueUSD è la prima stablecoin ancorata al dollaro che effettua audit giornalieri delle sue riserve da parte di un'istituzione indipendente di terze parti.",
        Started_desc: "Inizia",
        Contact_desc: "Contattaci",
        Circulating_desc: "Offerta circolante",
        Trading_desc: "Volume di scambio",
        Transaction_desc: "Numero totale di transazioni",
        Support_desc1: "Supporto totale della catena",
        Trustworthy_desc: "Affidabile",
        utilizes_desc: "TUSD utilizza meccanismi avanzati di audit e certificazione per aumentare la trasparenza. I suoi rapporti di audit giornalieri sono disponibili sul sito web, permettendo agli utenti di monitorare le riserve in qualsiasi momento.",
        Popularity_desc: "Popolarità",
        convenience_desc: "TUSD offre convenienza e utilità in vari scenari finanziari come il trading e il trasferimento. Attualmente, TUSD può essere utilizzato su oltre 80 borse di criptovalute e protocolli DeFi, fornendo flessibilità e accessibilità agli utenti.",
        Liquidity_desc: "Liquidità",
        During_desc: "Nel terzo trimestre del 2023, il volume di scambio giornaliero di TUSD ha superato il miliardo di dollari, mostrando la sua incredibile liquidità. Inoltre, TUSD non addebita alcuna commissione per il minting e il riscatto.",
        Trusted_desc: "Fidato dai leader globali",
        Using_desc: "Usare TUSD",
        LearnMore_desc: "Scopri di più",
        Goes_desc: "TUSD si dirige verso il multi-chain",
        Natively_desc1: "Rete di distribuzione nativa",
        Ethereum_desc: "Ethereum",
        TRON_desc: "TRON",
        Avalanche_desc: "Avalanche",
        Smart_desc: "BNB Smart Chain",
        Bridged_desc: "Rete di ponti",
        Fantom_desc: "Fantom",
        Arbitrum_desc: "Arbitrum",
        Cronos_desc: "Cronos",
        Optimism_desc: "Optimism",
        Aurora_desc: "Aurora",
        FAQs_desc: "Domande frequenti",
        TrueUSD_desc: "Q1. Che cos'è TrueUSD?",
        Banking_desc: "TrueUSD utilizza istituzioni finanziarie globali e una robusta infrastruttura blockchain. È la prima stablecoin che utilizza contratti intelligenti per il minting/riscatto, con supporto per la verifica della copertura delle riserve in tempo reale on-chain, supportata dalla principale società di contabilità mondiale Moore Hong Kong e Chainlink Proof of Reserve.",
        started_desc: "Q2. Come iniziare a usare TrueUSD?",
        stepsbelow_desc: "Per iniziare a usare TrueUSD, segui i passaggi seguenti:",
        verify_desc: "Dopo aver creato e verificato il tuo account tramite il sito web TrueUSD, riceverai un'email da",
        website_desc: "sito web TrueUSD",
      
        verification_desc: "Verifica tramite il link di verifica. Fai clic su questo link per verificare il tuo indirizzo email, quindi configura",
        information_desc: "Compila le informazioni su di te e/o sulla tua organizzazione. Per un elenco dei documenti richiesti, fai clic",
        here_desc: "qui",
        application_desc: "Invia la tua candidatura. Esamineremo la tua candidatura.",
        Redeem_desc: "Minting e riscatto della nostra stablecoin senza commissioni con un clic",
        Trade_desc: "Scambia TrueUSD in oltre 160 mercati su più borse e oltre 20 desk OTC in 5 continenti",
        Send_desc: "Invia TrueUSD a chiunque, in qualsiasi momento, 100 volte più economico e 1000 volte più veloce di un bonifico bancario",
        mint_desc: "Q3. Come mintare e riscattare TrueUSD?",
        partner_desc1: "Invia un bonifico bancario ai nostri partner bancari, utilizzando le informazioni visualizzate nel",
        Trueapp_desc: "applicazione TrueUSD",
        Please_desc: "Nota: attualmente non accettiamo ACH o altri servizi di trasferimento di valuta digitale come Venmo, TransferWise, PayPal, Revolut, ecc.",
        account_desc: "Il nome sul tuo conto bancario deve corrispondere al nome sul tuo account TrueUSD.",
        creating_desc: "Dopo aver creato il tuo account, riceverai un'email da",
        generally_desc: "Generalmente, i bonifici bancari tra banche richiedono generalmente 1-5 giorni lavorativi per essere completati.",
        email_desc: "Una volta ricevuto il bonifico bancario, ti invieremo un'email di conferma.",
        minted_desc: "Una volta ricevuti i fondi, TrueUSD verrà mintato e trasferito all'indirizzo del portafoglio fornito nell'applicazione entro un giorno lavorativo.",
        redeem4_desc: "Q4. Come riscattare TrueUSD?",
        Visit_desc: "Visita",
        Polygon_desc1: "applicazione TrueUSD",
        receive_desc: "per inserire le tue informazioni bancarie e ottenere il tuo indirizzo di riscatto unico. I trasferimenti di valuta fiat sono soggetti ai requisiti KYC e AML dei nostri partner bancari.",
        redemption_desc: "Questo indirizzo di riscatto è unico per ogni utente TrueUSD. Non condividere il tuo indirizzo di riscatto unico con altri.",
        simple_desc: "Perché il mio indirizzo di riscatto è così semplice?",
        notice_desc: "Noterai che questo indirizzo è molto semplice, con molti '0'. Questo è intenzionale. Puoi riscattare da qualsiasi portafoglio (inclusi i portafogli di scambio) a questo indirizzo, e tutti i riscatti ricevuti a questo indirizzo saranno collegati al tuo account TrueUSD.",
        unique_desc: "Invia TrueUSD al tuo indirizzo di riscatto unico (minimo $1,000).",
        partner_desc: "Una volta che i token sono stati inviati con successo al tuo indirizzo di riscatto, i nostri partner bancari emetteranno generalmente un bonifico bancario al tuo conto bancario entro un giorno lavorativo.",
        exchanges_desc: "Q5. Dove è quotato TrueUSD (TUSD)?",
        Binance_desc: "Attualmente, TrueUSD è quotato su diverse borse, tra cui Binance, Bittrex e Upbit.",
        here2_desc: "qui",
        markets_desc: "per ottenere l'elenco completo delle borse e dei mercati attivi di TrueUSD.",
        contract_desc: "Q6. Qual è l'indirizzo del contratto intelligente di TrueUSD?",
        tokenSmart_desc: "I nostri contratti intelligenti possono essere trovati tramite la rete di distribuzione nativa di TUSD e la rete di ponti di TUSD o il browser della Binance Chain. I link sono sotto. Puoi anche saperne di più sui nostri contratti intelligenti e sugli aggiornamenti dei contratti intelligenti nel nostro",
        blog_desc: "blog",
        related_desc: ".",
        Natively_desc: "Rete di distribuzione nativa di TUSD",
        Ethererum2_desc: "Ethereum",
        SmartChain_desc: "BNB Smart Chain:",
       
        Avalanche2_desc: "Avalanche",
        Beacon_desc: "BNB Beacon Chain:",
        Networks2_desc: "Rete di ponti di TUSD",
        Polygon2_desc: "Polygon",
        Fantom2_desc: "Fantom",
      
        Polygon_desc2: "Optimism",
        ChainOld_desc: "BNB Smart Chain (vecchio):",
        AboutMore_desc: "Scopri di più su TUSD",
        Digital_desc: "Moneta digitale per il nuovo sistema di pagamento globale",
        first_desc: "TrueUSD è la prima stablecoin ancorata al dollaro ad effettuare verifiche giornaliere delle riserve da parte di un'istituzione indipendente di terze parti.",
        Polygon_desc: "Polygon",
        Follow_desc: "Seguici",
        Resources_desc: "Risorse",
        Stablecoin_desc: "TrueUSD diventa la prima stablecoin supportata dal dollaro che garantisce il minting tramite 'Proof of Reserves'",
        Engages_desc: "TUSD collabora con la principale società di contabilità MooreHK per fornire servizi di verifica delle riserve fiat",
        Becomes_desc: "TUSD (TRC-20) diventa la valuta legale nella Dominica",
        Blog_desc: "Blog",
        Support_desc: "Supporto",
        TermsOf_desc: "Termini di utilizzo",
        Privacy_desc: "Politica sulla privacy",
        TrueUsd_desc: "TrueUSD",
        Home_desc: "Home",
        Transparency_desc: "Trasparenza",
        Ecosystem_desc: "Ecosistema",
        About_desc: "Informazioni",
        Supported_desc: "Catene supportate",
        Other_desc: "Altre catene",
        rights_desc: "© 2023 TrueUSD. Tutti i diritti riservati.",
        Back_desc: "Torna in cima"
      },
      project_desc: {
        Pool_desc: "Fondo",
        participating_desc: "Partecipando ai token, riceverai",
        rewards_desc: "come ricompensa.",
        EndTime_desc: "Ora di fine del progetto",
        duration_desc: "Durata dello staking",
        days_desc: "giorni",
        calculation_desc: "Calcolo periodico",
        Invite_desc: "Invita",
        level_desc: "Commissione di livello",
        withdrawal_desc: "Importo minimo di prelievo",
        supply_desc: "Importo minimo di fornitura",
        period_desc: "Periodo minimo di prelievo",
        Invitations_desc: "Livello di invito",
        People_desc: "Numero di persone"
      },
      capitaInfoView_desc: {
        Please_desc: "Per favore, collega l'indirizzo",
        Pool_desc: "Fondo",
        receive_desc: "Partecipando ai token, riceverai",
        rewards_desc: "come ricompensa.",
        level_desc: "Fondo di livello",
        Expired_desc: "Scaduto",
        Unexpired_desc: "Non scaduto",
        Participated_desc: "Partecipato",
        uncommitted_desc: "Non impegnato",
        Withdraw_desc: "Preleva",
        Redeem_desc: "Riscatta",
        Status_desc: "Stato",
        Principal_desc: "Capitale",
        Redeemed_desc: "Riscattato",
        subaccounts_desc: "Sottoconti",
        Supplied_desc: "Fornito",
        Redeemable_desc: "Riscattabile",
        Commission_desc: "Commissione",
        Available_desc: "Disponibile per il prelievo",
        Withdrawed_desc: "Prelevato",
        Lock_desc: "Commissione bloccata",
        All_desc: "Tutte le commissioni",
        interest_desc: "Interesse",
        Settled_desc: "Liquidato",
        Loading_desc: "Caricamento",
        amount_desc: "Importo disponibile per il prelievo"
      },
      elModal_desc: {
        Please_desc: "Per favore, inserisci l'importo del deposito",
        Recharge_desc: "Ricarica",
        Cancel_desc: "Annulla",
        withdrawal_desc: "Per favore, inserisci l'importo del prelievo",
        Withdraw_desc: "Preleva",
        redemption_desc: "Per favore, inserisci l'importo del riscatto",
        Redeem_desc: "Riscatta",
        Withdrawable_desc: "Importo disponibile per il prelievo (commissione + interesse):",
        balance_desc: "Saldo riscattabile:",
        input_desc: "Per favore, inserisci l'importo",
        Transaction_desc: "Transazione riuscita!",
        failed_desc: "Transazione fallita",
        funds_desc: "Fondi insufficienti sul conto",
        Loading_desc: "Caricamento"
      },
    modal: {
        hold_desc: 'Se possiedi',
        Smart_desc: 'della BNB Smart Chain, avrai una ricompensa bonus,',
        Rewards_desc: 'Le ricompense sono disponibili solo per importi superiori a',
        address_desc: "L'indirizzo detiene",
        hours_desc: "per 24 ore, e il deposito minimo si basa sulla istantanea casuale del sistema. L'importo viene allocato, una volta ogni 24 ore. L'allocazione effettiva sul conto è quella che prevale."
    },
    UserInfo: {
        Wallets_desc: 'Portafogli',
        Reward_desc: 'Premio',
        half_month: '15 Giorni',
        one_month: '1 mese',
        three_month: '3 mesi',
        Invitees: 'Invitati',
        no_data: 'Nessun dato',
        walletNo_support: 'Il tuo portafoglio non è supportato. Si prega di utilizzare un\'altra app di portafoglio.',
        network_wrong: 'Seleziona la rete corretta',
      },
}
export default en_YDL
// Datajson:{
//     language_json:{
//         zh_json:'Cinese semplificato',
//         zh_FT_json: 'Cinese tradizionale',
//         eh_json:'Inglese',
//         ja_JP_json:'Giapponese',
//         ko_KR_json:'Coreano' ,
//         en_TH_json:'Thailandese',
//         fr_FR_json:'Francese',
//         ru_RU_json:'Russo',
//         en_IN_json:'Hindi',
//         en_ID_json:'Indonesiano',
//         en_BS_json:'Persiano',
//         en_YN_json:'Vietnamita',
//         en_ALB_json:'Arabo',
//         en_TEQ_json:'Turco',
//         en_ES_json:'Spagnolo',
//         en_PTY_json:'Portoghese',
//         en_YDL_json:'Italiano'
//     },
// },