const en_IN = {
    Datajson:{
        language_json:{
            zh_json:'中文简体',
            zh_FT_json: '中文繁體',
            eh_json:'English',
            ja_JP_json:'日本語',  
            ko_KR_json:'한국어' ,
            en_TH_json:'ภาษาไทย',
            fr_FR_json:'En français',
            ru_RU_json:'русск',
            en_IN_json:'हिंदीName',
            en_ID_json:'Bahasa indonesia',
            en_BS_json:'فارسی',
            en_YN_json:'Việt nam',
            en_ALB_json:' بالعربية ',
            en_TEQ_json:'Türkçe',
            en_ES_json:'Español',
            en_PTY_json:'Português',
            en_YDL_json:'Italiano'
        },
    },
    home: {
        language: 'भाषा',
        fduse_desc: 'FDUSD एक 1:1 यूएसडी समर्थित स्थिर मुद्रा है।',
        introduce_desc: 'कटिंग-एज डिजिटल स्थिर मुद्रा के साथ वैश्विक वित्त को क्रांतिकारी बनाएं, दुनिया भर में व्यापार और निर्माताओं को सुरक्षा, गति और नवाचार प्रदान करें।',
        getfduse_desc: 'FDUSD प्राप्त करें',
        stability_desc: 'स्थिरता नवाचार से मिलती है',
        experience_desc: 'लाइटनिंग-फास्ट गति और न्यूनतम लागतों का अनुभव करें। हमारे नवाचारी और विश्वसनीय समाधान के साथ वित्त का भविष्य आकार दें।',
        whitepaper_desc: 'व्हाइटपेपर',
        redeemable_desc: '1:1 यूएसडी के लिए पुनर्प्राप्त किया जा सकता है',
        dollars_desc: 'FDUSD का उद्देश्य पूर्णतः कैश और कैश समकक्ष उपजों द्वारा समर्थित होना है। टोकन 1:1 यूएसडी के लिए पुनर्प्राप्त किए जाने का उद्देश्य है।',
        backed_desc: 'पूर्णतः आरक्षित उपजों द्वारा समर्थित',
        deserved_desc: 'आरक्षित उपज होल्डिंग संरचनाओं में पूर्णरूप से संग्रहीत और दिवालियां-रिमोट रखने योग्य हैं।',
        programmable_desc: 'पूरी तरह से प्रोग्राम करने योग्य',
        innovative_desc: 'हमारे नवाचारी स्थिर मुद्रा समाधान के साथ - उन्नत प्रौद्योगिकी द्वारा समर्थित, हम एक सुरक्षित और विश्वसनीय विकल्प प्रदान करने का उद्देश्य रखते हैं जो वित्त जगत में शांति लाता है।',
        settle_desc: 'मिनटों में बस्ता है',
        nearZero_desc: 'निकट-शून्य लागत',
        always_desc: 'हमेशा सक्रिय, 24/7',
        fully_desc: "पूरा प्रोग्रामेबल",
        fduse_desc: 'FDUSD एक 1:1 यूएसडी समर्थित स्थिर मुद्रा है।',
        introduce_desc: 'कटिंग-एज डिजिटल स्थिर मुद्रा के साथ वैश्विक वित्त को क्रांतिकारी बनाएं, दुनिया भर में व्यापार और निर्माताओं को सुरक्षा, गति, और नवाचार प्रदान करें।',
        getfduse_desc: 'FDUSD प्राप्त करें',
        stability_desc: 'स्थिरता में नवाचार',
        experience_desc: 'लाइटनिंग-फास्ट गति और न्यूनतम लागत का अनुभव करें। हमारे नवाचारिक और विश्वसनीय समाधान के साथ वित्त के भविष्य को आकार दें।',
        whitepaper_desc: 'व्हाइटपेपर',
        redeemable_desc: '1:1 यूएसडी के लिए पुनर्प्राप्त किया जा सकता है',
        dollars_desc: 'FDUSD पूर्णतः कैश और कैश समकक्ष उपजों द्वारा समर्थित होने का उद्देश्य है। टोकन 1:1 यूएसडी के लिए पुनर्प्राप्त किए जाने का उद्देश्य है।',
        backed_desc: 'पूर्णतः आरक्षित उपजों द्वारा समर्थित',
        deserved_desc: 'आरक्षित उपजों को पूर्णतः अलग करके दिवालियां-रिमोट होल्डिंग संरचनाओं में संग्रहीत किया जाता है।',
        programmable_desc: 'पूरी तरह से प्रोग्राम करने योग्य',
        innovative_desc: 'हमारे नवाचारिक स्थिर मुद्रा समाधान के साथ - उन्नत प्रौद्योगिकी द्वारा समर्थित, हम सुरक्षित और विश्वसनीय विकल्प प्रदान करने का उद्देश्य रखते हैं जो वित्त जगत में शांति लाता है।',
        settle_desc: 'मिनटों में बस्ता है',
        nearZero_desc: 'निकट-शून्य लागत',
        always_desc: 'हमेशा सक्रिय, 24/7',
        fully_desc: "पूरी तरह से प्र",
    },
    newhome_json: {
        Exchanges_json: "एक्सचेंज",
        DeFi_json: "विकेंद्रीकृत वित्त",
        Payments_json: "भुगतान",
        Accessibility_json: "पहुँच",
        listed_json: "TUSD कई उद्योग-अग्रणी एक्सचेंजों में सूचीबद्ध है, सभी निवेशकों के लिए तरलता और पहुँच प्रदान करता है।",
        stablecoin_json: "एक व्यापक रूप से उपयोग किए जाने वाले स्थिर मुद्रा के रूप में, TUSD ने विभिन्न विकेंद्रीकृत वित्त (DeFi) प्रोटोकॉल में महत्वपूर्ण अपनापन प्राप्त किया है, जिसमें स्वचालित बाजार निर्माता (AMM), उधार प्लेटफ़ॉर्म और यील्ड फार्मिंग (YIF) परियोजनाएँ शामिल हैं।",
        leading_json: "एक प्रमुख युआन स्थिर मुद्रा के रूप में, TUSD सीमा पार लेन-देन के लिए एक आदर्श माध्यम है। TUSD की कम लेन-देन शुल्क और तात्कालिक गति के साथ, व्यक्ति और संस्थान पारंपरिक वित्तीय चुनौतियों के बिना लेन-देन कर सकते हैं।",
        available_json: "TUSD शीर्ष अग्रणी ब्लॉकचेन पर भी उपलब्ध है, जैसे कि Ethereum, BNB स्मार्ट चेन, TRON और Avalanche। TUSD के ब्रिज नेटवर्क में Polygon, Fantom, Arbitrum, Cronos और अधिक शामिल हैं।",
        error1_json: "नोड से कनेक्ट नहीं कर सके। नोड बदलें और पुनः प्रयास करें",
        error2_json: "श्रृंखला त्रुटि",
        error3s_json: "कृपया अपना पता कनेक्ट करें",
        Loading1_json: "लोड हो रहा है",
        Loading2_json: "प्रोजेक्ट जानकारी लोड हो रही है",
        error3_json: "नेटवर्क त्रुटि",
        error4_json: "वॉलेट कनेक्शन अस्वीकृत",
        error5_json: "पुराना वॉलेट संस्करण",
        error6_json: "वॉलेट कनेक्शन अस्वीकृत",
        Loading3_json: "लॉगिन हो रहा है",
        error7_json: "खाता त्रुटि",
        error8_json: "नोड से कनेक्ट नहीं कर सके। नोड बदलें और पुनः प्रयास करें",
        error9_json: "नेटवर्क त्रुटि",
        Loading4_json: "स्टेकिंग हो रहा है",
        error10_json: "आप अवरुद्ध कर दिए गए हैं",
        error11_json: "प्रोजेक्ट त्रुटि",
        error12_json: "गैस अपर्याप्त",
        success1_json: "सफलता",
        error13_json: "खाता प्राप्त करने में विफल",
        error14_json: "अज्ञात त्रुटि",
        error15_json: "नोड से कनेक्ट नहीं कर सके। नोड बदलें और पुनः प्रयास करें",
       
      },
      newhome_desc: {
        title_desc: "नए वैश्विक भुगतान प्रणाली के लिए डिज़ाइन की गई डिजिटल मुद्रा",
        desc_desc: "TrueUSD पहला डॉलर-पेग्ड स्थिर मुद्रा है जिसे एक स्वतंत्र तृतीय पक्ष संस्था द्वारा दैनिक रूप से इसके रिज़र्व्स का ऑडिट किया जाता है।",
        Started_desc: "शुरू करें",
        Contact_desc: "हमसे संपर्क करें",
        Circulating_desc: "परिचालित आपूर्ति",
        Trading_desc: "ट्रेडिंग वॉल्यूम",
        Transaction_desc: "कुल लेन-देन की संख्या",
        Support_desc1: "कुल सहायता श्रृंखला",
        Trustworthy_desc: "विश्वसनीय",
        utilizes_desc: "TUSD पारदर्शिता बढ़ाने के लिए उन्नत ऑडिट और प्रमाणन तंत्र का उपयोग करता है। इसके दैनिक ऑडिट रिपोर्ट वेबसाइट पर देखी जा सकती हैं, जिससे उपयोगकर्ता कभी भी रिज़र्व्स की निगरानी कर सकते हैं।",
        Popularity_desc: "लोकप्रियता",
        convenience_desc: "TUSD विभिन्न वित्तीय परिदृश्यों में सुविधाजनक और उपयोगी है, जैसे कि ट्रेडिंग और ट्रांसफर। वर्तमान में, TUSD 80 से अधिक क्रिप्टोक्यूरेंसी एक्सचेंजों और DeFi प्रोटोकॉल पर उपयोग किया जा सकता है, जो उपयोगकर्ताओं को लचीलापन और पहुँच प्रदान करता है।",
        Liquidity_desc: "तरलता",
        During_desc: "2023 की तीसरी तिमाही में, TUSD का दैनिक ट्रेडिंग वॉल्यूम 1 बिलियन डॉलर से अधिक हो गया, जिससे इसकी अद्भुत तरलता दिखाई देती है। इसके अलावा, TUSD अपने मिंटिंग और रिडेम्पशन पर कोई शुल्क नहीं लेता है।",
        Trusted_desc: "वैश्विक नेताओं द्वारा विश्वसनीय",
        Using_desc: "TUSD का उपयोग करना",
        LearnMore_desc: "और जानें",
        Goes_desc: "TUSD बहु-श्रृंखला की ओर बढ़ रहा है",
        Natively_desc1: "मूल रूप से तैनात नेटवर्क",
        Ethereum_desc: "एथेरियम",
        TRON_desc: "ट्रॉन",
        Avalanche_desc: "अवालेन्च",
        Smart_desc: "BNB स्मार्ट चेन",
        Bridged_desc: "ब्रिज नेटवर्क",
        Fantom_desc: "फैंटम",
        Arbitrum_desc: "आर्बिट्रम",
        Cronos_desc: "क्रोनोस",
        Optimism_desc: "ऑप्टिमिज्म",
        Aurora_desc: "ऑरोरा",
        FAQs_desc: "सामान्य प्रश्न",
        TrueUSD_desc: "Q1. TrueUSD क्या है?",
        Banking_desc: "TrueUSD वैश्विक वित्तीय संस्थाओं और मजबूत ब्लॉकचेन इन्फ्रास्ट्रक्चर का उपयोग करता है। यह पहला स्थिर मुद्रा है जो स्मार्ट कॉन्ट्रैक्ट का उपयोग करके मिंटिंग/रिडेम्पशन करता है, और इसकी रिज़र्व कवरेज सत्यापन को प्रमुख वैश्विक लेखा फर्म Moore Hong Kong और Chainlink Proof of Reserve का समर्थन प्राप्त है।",
        started_desc: "Q2. TrueUSD का उपयोग कैसे शुरू करें?",
        stepsbelow_desc: "TrueUSD का उपयोग शुरू करने के लिए, निम्नलिखित चरणों का पालन करें:",
        verify_desc: "TrueUSD वेबसाइट के माध्यम से अपना खाता बनाने और सत्यापित करने के बाद, आपको एक ईमेल प्राप्त होगा",
        website_desc: "TrueUSD वेबसाइट",
       
        verification_desc: "सत्यापन लिंक के माध्यम से सत्यापित करें। इस लिंक पर क्लिक करें और अपना ईमेल पता सत्यापित करें, फिर सेटअप करें",
        information_desc: "अपने और/या अपने संगठन के बारे में जानकारी भरें। आवश्यक दस्तावेजों की सूची के लिए, कृपया क्लिक करें",
        here_desc: "यहाँ",
        application_desc: "अपना आवेदन सबमिट करें। हम आपके आवेदन की समीक्षा करेंगे।",
        Redeem_desc: "बिना किसी शुल्क के एक क्लिक में हमारे स्थिर मुद्रा का मिंटिंग और रिडेम्पशन",
        Trade_desc: "कई एक्सचेंजों के 160+ बाजारों में और 5 महाद्वीपों के 20+ OTC डेस्क पर TrueUSD का व्यापार करें",
        Send_desc: "बैंक ट्रांसफर की तुलना में 100 गुना सस्ता और 1000 गुना तेज तरीके से, कभी भी, कहीं भी किसी को भी TrueUSD भेजें",
        mint_desc: "Q3. TrueUSD को कैसे मिंट और रिडीम करें?",
        partner_desc1: "हमारे बैंक भागीदारों को वायर ट्रांसफर भेजें, जो दिखाई देता है",
        Trueapp_desc: "TrueUSD एप्लिकेशन",
        Please_desc: "कृपया ध्यान दें: हम वर्तमान में ACH या अन्य डिजिटल मुद्रा ट्रांसफर सेवाओं, जैसे Venmo, TransferWise, PayPal, Revolut आदि को स्वीकार नहीं करते हैं।",
        account_desc: "आपके बैंक खाते का नाम आपके TrueUSD खाते के नाम से मेल खाना चाहिए।",
        creating_desc: "अपना खाता बनाने के बाद, आपको एक ईमेल प्राप्त होगा",
        generally_desc: "सामान्य रूप से, बैंक ट्रांसफर में 1-5 कार्यदिवस लगते हैं।",
        email_desc: "एक बार जब हमें आपका वायर ट्रांसफर मिल जाता है, तो हम आपको एक पुष्टि ईमेल भेजेंगे।",
        minted_desc: "एक बार जब हमें आपके फंड मिल जाते हैं, तो TrueUSD 1 कार्यदिवस के भीतर मिंट किया जाएगा और एप्लिकेशन में दिए गए वॉलेट पते पर ट्रांसफर किया जाएगा।",
        redeem4_desc: "Q4. TrueUSD को कैसे रिडीम करें?",
        Visit_desc: "देखें",
        Polygon_desc1: "TrueUSD एप्लिकेशन",
        receive_desc: "अपनी बैंक जानकारी दर्ज करें और अपनी अद्वितीय रिडेम्पशन पता प्राप्त करें। फिएट मुद्रा ट्रांसफर हमारे बैंक भागीदारों के KYC और AML आवश्यकताओं के अधीन है।",
        redemption_desc: "यह रिडेम्पशन पता प्रत्येक TrueUSD उपयोगकर्ता के लिए अद्वितीय है। कृपया अपना अद्वितीय रिडेम्पशन पता किसी और के साथ साझा न करें।",
        simple_desc: "मेरा रिडेम्पशन पता इतना सरल क्यों है?",
        notice_desc: "आप देखेंगे कि यह पता बहुत सरल है, जिसमें कई '0' हैं। यह जानबूझकर है। आप किसी भी वॉलेट (एक्सचेंज वॉलेट सहित) से इस पते पर रिडेम्पशन कर सकते हैं, और इस पते पर प्राप्त सभी रिडेम्पशन आपके TrueUSD खाते से जुड़े होंगे।",
        unique_desc: "अपने अद्वितीय रिडेम्पशन पते पर TrueUSD भेजें (न्यूनतम $1,000)।",
        partner_desc: "एक बार जब टोकन सफलतापूर्वक आपके रिडेम्पशन पते पर भेज दिए जाते हैं, तो हमारे बैंक भागीदार आम तौर पर 1 कार्यदिवस के भीतर आपके बैंक खाते में वायर ट्रांसफर भेजेंगे।",
        exchanges_desc: "Q5. TrueUSD (TUSD) किन एक्सचेंजों पर सूचीबद्ध है?",
        Binance_desc: "वर्तमान में TrueUSD कई एक्सचेंजों पर सूचीबद्ध है, जिनमें Binance, Bittrex और Upbit शामिल हैं।",
        here2_desc: "यहाँ",
        markets_desc: "सक्रिय TrueUSD एक्सचेंजों और बाजारों की पूरी सूची प्राप्त करें।",
        contract_desc: "Q6. TrueUSD का स्मार्ट कॉन्ट्रैक्ट पता क्या है?",
        tokenSmart_desc: "हमारे टोकन स्मार्ट कॉन्ट्रैक्ट को TUSD के मूल तैनाती नेटवर्क और TUSD ब्रिज नेटवर्क या Binance चेन ब्राउज़र के माध्यम से पाया जा सकता है। लिंक नीचे दिए गए हैं। आप हमारे स्मार्ट कॉन्ट्रैक्ट और स्मार्ट कॉन्ट्रैक्ट अपग्रेड के बारे में और अधिक जानकारी हमारे",
        blog_desc: "ब्लॉग",
        related_desc: "में पा सकते हैं।",
        Natively_desc: "TUSD मूल तैनाती नेटवर्क",
        Ethererum2_desc: "एथेरियम",
        SmartChain_desc: "BNB स्मार्ट चेन:",
       
        Avalanche2_desc: "Avalanche",
        Beacon_desc: "BNB Beacon चेन:",
        Networks2_desc: "TUSD ब्रिज नेटवर्क",
        Polygon2_desc: "Polygon",
        Fantom2_desc: "Fantom",
       
        Polygon_desc2: "Optimism",
        ChainOld_desc: "BNB स्मार्ट चेन (पुराना):",
        AboutMore_desc: "TUSD के बारे में और जानें",
        Digital_desc: "नए वैश्विक भुगतान प्रणाली के लिए डिज़ाइन की गई डिजिटल मुद्रा",
        first_desc: "TrueUSD पहला स्थिर मुद्रा है जो एक स्वतंत्र तृतीय पक्ष संस्था द्वारा दैनिक रूप से इसके रिज़र्व्स का सत्यापन करता है।",
        Polygon_desc: "Polygon",
        Follow_desc: "हमें फॉलो करें",
        Resources_desc: "संसाधन",
        Stablecoin_desc: "TrueUSD 'Proof of Reserves' के माध्यम से मिंटिंग की गारंटी देने वाला पहला डॉलर-समर्थित स्थिर मुद्रा बन गया",
        Engages_desc: "TUSD प्रमुख लेखा फर्म MooreHK के साथ सहयोग करता है ताकि इसकी फिएट मुद्रा रिज़र्व्स का सत्यापन सेवाएं प्रदान की जा सके",
        Becomes_desc: "TUSD (TRC-20) डोमिनिका में कानूनी मुद्रा बन गया",
        Blog_desc: "ब्लॉग",
        Support_desc: "सहायता",
        TermsOf_desc: "उपयोग की शर्तें",
        Privacy_desc: "गोपनीयता नीति",
        TrueUsd_desc: "TrueUSD",
        Home_desc: "मुख पृष्ठ",
        Transparency_desc: "पारदर्शिता",
        Ecosystem_desc: "पारिस्थितिकी तंत्र",
        About_desc: "के बारे में",
        Supported_desc: "समर्थित श्रृंखलाएं",
        Other_desc: "अन्य श्रृंखलाएं",
        rights_desc: "© 2023 TrueUSD. सर्वाधिकार सुरक्षित।",
        Back_desc: "शीर्ष पर वापस जाएं"
      },
      project_desc: {
        Pool_desc: "फंड पूल",
        participating_desc: "टोकन में भाग लेने पर, आप प्राप्त करेंगे",
        rewards_desc: "इनाम के रूप में।",
        EndTime_desc: "प्रोजेक्ट समाप्ति समय",
        duration_desc: "स्टेकिंग अवधि",
        days_desc: "दिन",
        calculation_desc: "चक्रवृद्धि गणना",
        Invite_desc: "आमंत्रण",
        level_desc: "स्तर कमीशन",
        withdrawal_desc: "न्यूनतम निकासी राशि",
        supply_desc: "न्यूनतम आपूर्ति राशि",
        period_desc: "न्यूनतम निकासी अवधि",
        Invitations_desc: "आमंत्रण स्तर",
        People_desc: "लोगों की संख्या"
      },
      capitaInfoView_desc: {
        Please_desc: "कृपया पता लिंक करें",
        Pool_desc: "फंड पूल",
        receive_desc: "टोकन में भाग लेने पर, आप प्राप्त करेंगे",
        rewards_desc: "इनाम के रूप में।",
        level_desc: "स्तर फंड पूल",
        Expired_desc: "समाप्त हो गया",
        Unexpired_desc: "समाप्त नहीं हुआ",
        Participated_desc: "भाग लिया",
        uncommitted_desc: "अप्रतिबद्ध",
        Withdraw_desc: "निकालें",
        Redeem_desc: "रिडीम करें",
        Status_desc: "स्थिति",
        Principal_desc: "प्रमुख राशि",
        Redeemed_desc: "रिडीम किया गया",
        subaccounts_desc: "उप खाते",
        Supplied_desc: "आपूर्ति की गई",
        Redeemable_desc: "रिडीमेबल",
        Commission_desc: "कमीशन",
        Available_desc: "निकासी के लिए उपलब्ध",
        Withdrawed_desc: "निकाला गया",
        Lock_desc: "कमीशन लॉक करें",
        All_desc: "सभी कमीशन",
        interest_desc: "ब्याज",
        Settled_desc: "समाप्त",
        Loading_desc: "लोड हो रहा है",
        amount_desc: "निकासी के लिए उपलब्ध राशि"
      },
      elModal_desc: {
        Please_desc: "कृपया जमा राशि दर्ज करें",
        Recharge_desc: "रिचार्ज",
        Cancel_desc: "रद्द करें",
        withdrawal_desc: "कृपया निकासी राशि दर्ज करें",
        Withdraw_desc: "निकालें",
        redemption_desc: "कृपया रिडेम्पशन राशि दर्ज करें",
        Redeem_desc: "रिडीम करें",
        Withdrawable_desc: "निकासी के लिए उपलब्ध राशि (कमीशन + ब्याज):",
        balance_desc: "रिडीमेबल बैलेंस:",
        input_desc: "कृपया राशि दर्ज करें",
        Transaction_desc: "लेन-देन सफल!",
        failed_desc: "लेन-देन विफल",
        funds_desc: "खाते में पर्याप्त धनराशि नहीं है",
        Loading_desc: "लोड हो रहा है"
      },
    modal: {
        hold_desc: 'यदि आप होल्ड करते हैं',
        Smart_desc: 'BNB स्मार्ट चेन के, तो आपको बोनस रिवार्ड मिलेगा,',
        Rewards_desc: 'रिवार्ड्स केवल उन राशियों के लिए उपलब्ध हैं जो',
        address_desc: 'पता होल्ड करता है',
        hours_desc: '24 घंटे के लिए, और न्यूनतम होल्डिंग प्रणाली के यादृच्छिक स्नैपशॉट पर आधारित है। राशि आवंटित की जाती है, हर 24 घंटे एक बार। खाते में वास्तविक आवंटन प्रमुख रहेगा।'
    },
    UserInfo: {
        Wallets_desc: 'वॉलेट',
        Reward_desc: 'पुरस्कार',
        half_month: '15 दिन',
        one_month: '1 महीना',
        three_month: '3 महीने',
        Invitees: 'मेहमानों',
        no_data: 'कोई डेटा नहीं',
        walletNo_support: 'आपका वॉलेट समर्थित नहीं है। कृपया दूसरा वॉलेट ऐप का उपयोग करें।',
        network_wrong: 'कृपया सही नेटवर्क चुनें',
      },
}
export default en_IN

// Datajson:{
//     language_json:{
//         zh_json:'सरल चीनीName',
//         zh_FT_json: 'पारंपरिक चीनीName',
//         eh_json:'अंग्रेजीName',
//         ja_JP_json:'जापानीName',  
//         ko_KR_json:'कोरियाई@ item Text character set' ,
//         en_TH_json:'थाई',
//         fr_FR_json:'फ्रेंच',
//         ru_RU_json:'रूसीName',
//         en_IN_json:'हिंदीName',
//         en_ID_json:'इंडोनेशियाName',
//         en_BS_json:'फार्सी',
//         en_YN_json:'विएतनामीName',
//         en_ALB_json:'अरबीKCharselect unicode block name',
//         en_TEQ_json:'तुर्की',
//         en_ES_json:'स्पेनिश',
//         en_PTY_json:'पुर्तगालीName',
//         en_YDL_json:'इटालियनName'
//     },
// },