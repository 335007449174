//简体中文
const zh_FT = {
  Datajson: {
    language_json: {
      zh_json: "中文简体",
      zh_FT_json: "中文繁體",
      eh_json: "English",
      ja_JP_json: "日本語",
      ko_KR_json: "한국어",
      en_TH_json: "ภาษาไทย",
      fr_FR_json: "En français",
      ru_RU_json: "русск",
      en_IN_json: "हिंदीName",
      en_ID_json: "Bahasa indonesia",
      en_BS_json: "فارسی",
      en_YN_json: "Việt nam",
      en_ALB_json: " بالعربية ",
      en_TEQ_json: "Türkçe",
      en_ES_json: "Español",
      en_PTY_json: "Português",
      en_YDL_json: "Italiano",
    },
  },
  home: {
    language: "語言",
    fduse_desc: "FDUSD是一種1:1與美元掛鉤的穩定幣.",
    introduce_desc:
      "以尖端數位穩定幣徹底改革全球金融，為全球企業和創造者提供安全、快速和創新的解決方案。",
    getfduse_desc: "獲取FDUSD",
    stability_desc: "穩定性與創新相結合",
    experience_desc:
      "體驗閃電般的快速速度和最低成本。 通過我們創新且值得信賴的解決方案塑造金融的未來。",
    whitepaper_desc: "白皮書",
    redeemable_desc: "可兌換為美元的1:1比例",
    dollars_desc:
      "FDUSD旨在完全由現金和現金等價資產支持。 代幣可按1:1比例兌換為美元。",
    backed_desc: "由完全儲備的資產支持",
    deserved_desc: "儲備資產被完全隔離並儲存在遠離破產風險的持有結構中。",
    programmable_desc: "可編程描述",
    innovative_desc:
      "憑藉我們創新的穩定幣解決方案，通過先進的技術支持，我們旨在提供一種安全可靠的選擇，給人們帶來安心，並在金融領域產生積極的影響。",
    settle_desc: "結算描述",
    nearZero_desc: "接近零成本",
    always_desc: "始終在線，全天候運行",
    fully_desc: "完全可編程",
    rewrite_desc: "與第一數字實驗室一同改寫未來",
    combining_desc:
      "在第一數字實驗室中，將穩定性與創新相結合，我們致力於通過提供始終如一的透明度、合規性和安全性，以及先進的技術支持來贏得信任。",
    mission_desc:
      "我們的使命是推動金融行業進入金融新時代，並重塑全球金融格局。",
    should_desc: "為什麼你應該使用第一數字美元（FDUSD）呢？",
    collateral_desc:
      "抵押品由一家頂級、合格的托管機構進行保護，其擁有信託許可證，以實現資產的無縫保護和合規性。",
    transparent_desc: "透明",
    independent_desc:
      "獨立審計-抵押品由獨立的第三方進行驗證，確保儲備和財務報告的完整性。",
    examined_by_desc: "經過審查",
    attestation_Reports_desc: "審計報告",
    January2024_desc: "2024年1月份的審計報告",
    Report_desc: "報告",
    December_desc: "2023年12月份的報告",
    November_desc: "2023年11月份的報告",
    Networks_desc: "第一數字實驗室支持哪些網絡？",
    available_desc: "FDUSD可在以下網絡上使用",
    Ethereum_desc: "以太坊",
    and_desc: "和",
    BNB_Chain_desc: "BNB鏈",
    planned_desc: "計劃支持越來越多的區塊鏈。",
    Audited_desc: "經過審計",
    Frequently_desc: "常見問題",
    How_FDUSD_desc: "您如何獲取FDUSD？",
    Acquiring_desc:
      "獲得FDUSD可以通過幾種途徑實現。如果您是重要的行業參與者、金融中介機構或具有一定身份地位的專業投資者，並符合相應的條件，您可以直接從第一數字實驗室購買FDUSD。請注意，第一數字實驗室不直接向零售客戶銷售代幣。",
    canstill_desc:
      "您仍然可以通過二級市場獲得FDUSD，而無需成為第一數字實驗室的客戶。許多領先的加密貨幣交易所都會列出FDUSD，並提供可觀的流動性。這為有興趣的個人提供了獲取FDUSD的一種便捷方式。然而，始終要進行徹底的研究，並確保您使用的交易所的可信度。",
    equivalent_desc: "您如何相信FDUSD始終等同於一美元呢？",
   // stablecoin_desc:
    //  "您對FDUSD穩定幣價值的信任是有根據的。每個FDUSD旨在完全由一美元或具有等值公正價值的資產支持。這些資產由合格的托管機構安全持有，存放在受監管的存管機構帳戶中，為該穩定幣的價值提供了堅實的基礎。正是這個系統賦予了FDUSD內在的穩定性，也是您可以相信其與美元等值的原因。",
    redeem_FDUSD: "您如何兌換FDUSD呢？",
  //  stablecoin_desc:
    //  "要兌換您的FDUSD穩定幣，您首先必須成為第一數字實驗室的客戶，並滿足特定要求，包括反洗錢（AML）和反恐融資（CTF）檢查。成功完成這些檢查後，您可以將FDUSD兌換為等值的法定貨幣，從而使其退出流通。另外，您還可以通過支持FD121穩定幣的加密貨幣交易所或場外交易（OTC）提供商，在二級市場上出售您的FDUSD代幣。在進行操作之前，請注意您所在地區的所有法律和金融監管規定。",
    Explore_desc: "探索我們的生態系統",
    Experience_desc: "通過我們可靠的穩定幣解決方案來體驗穩定性。",
    slide_desc: "滑動",
    _1to6_desc: "1到6",
    of7_desc: "第7",
    Transparency_desc: "透明度",
    Legal_desc: "合法的",
    Compliance_desc: "合規性",
    Policy_desc: "隱私政策",
    Company_desc: "公司",
    About_Us_desc: "關於我們",
    Social_Media_desc: "社交媒體",
    Twitter_desc: "推特",
    Disclosures_desc: "重要披露事項:",
    Limited_desc:
      "FD121有限公司是一家金融科技公司，而不是銀行。儲備資產的托管服務由提供",
    First_desc: "第一數碼信託有限公司",
    product_desc:
      "所有產品和公司名稱均為其各自持有人的商標™或註冊商標®。本網站中使用的所有公司、產品和服務名稱僅用於識別目的。使用這些名稱、商標和品牌並不意味著與它們有任何關聯或認可。",
    additional_desc: "為了獲取其他重要風險、披露和信息，請訪問。",
    Copyright_desc: "版權所有 © 2024 FD121有限公司。保留所有權利。",
  },
  newhome_json: {
    Exchanges_json: "交易所",
    DeFi_json: "DeFi",
    Payments_json: "支付",
    Accessibility_json: "可訪問性",
    listed_json:
      "TUSD在多個行業領先的交易所上市，為全球所有投資者提供流動性和可訪問性。",
    stablecoin_json:
      "作為被廣泛使用的穩定幣，TUSD在各種DeFi協議中獲得了顯著的採用，包括自動做市商（AMM）、借貸平台和收益農場項目。",
    leading_json:
      "作為領先的美元挂鉤穩定幣，TUSD是跨境交易的理想媒介。憑借TUSD的低交易費用和即時速度，個人和機構都可以在沒有傳統金融所面臨挑戰的情況下進行交易。",
    available_json:
      "TUSD還可在以太坊、BNB智能鏈、波場和Avalanche等頂級區塊鏈上使用。TUSD的橋接網絡包括Polygon、Fantom、Arbitrum、Cronos等。",
    error1_json: "無法連接節點。請更換節點並重試。",
    error2_json: "鏈錯誤",
    error3s_json: "請連接您的地址",
    Loading1_json: "加載中",
    Loading2_json: "加載項目信息",
    error3_json: "網絡錯誤",
    error4_json: "錢包連接被拒絕",
    error5_json: "舊版錢包",
    error6_json: "錢包連接被拒絕",
    Loading3_json: "登錄加載中",
    error7_json: "賬戶錯誤",
    error8_json: "無法連接節點。請更換節點並重試",
    error9_json: "網絡錯誤",
    Loading4_json: "質押加載中",
    error10_json: "您已被阻止",
    error11_json: "項目錯誤",
    error12_json: "燃氣不足",
    success1_json: "成功",
    error13_json: "賬戶獲取失敗",
    error14_json: "未知錯誤",
    error15_json: "無法連接節點。請更換節點並重試",
   
  },
  newhome_desc: {
    title_desc: "為新全球支付系統建立的數字貨幣",
    desc_desc:
      "TrueUSD是第一個由獨立第三方機構每日驗證其基礎儲備金的美元挂鉤穩定幣。",
    Started_desc: "開始使用",
    Contact_desc: "聯繫我們",
    Circulating_desc: "流通供應量",
    Trading_desc: "交易量",
    Transaction_desc: "總交易次數",
    Support_desc1: "支持鏈總數",
    Trustworthy_desc: "值得信賴",
    utilizes_desc:
      "TUSD採用先進的審計和驗證機制，提高了透明度。其每日審計報告可在網站上查看，用戶可以隨時監控儲備金情況。",
    Popularity_desc: "受歡迎程度",
    convenience_desc:
      "TUSD在各種金融場景中提供便利和實用性，如交易和轉賬。目前，TUSD在80多家加密貨幣交易所和DeFi協議上可用，為用戶提供了靈活性和可訪問性。",
    Liquidity_desc: "流動性",
    During_desc:
      "2023年第三季度，TUSD的每日交易量超過10億美元，顯示出其令人印象深刻的流動性。此外，TUSD的鑄造和贖回不收取任何費用。",
    Trusted_desc: "全球領導者信任",
    Using_desc: "使用TUSD",
    LearnMore_desc: "了解更多",
    Goes_desc: "TUSD多鏈部署",
    Natively_desc1: "原生部署網絡",
    Ethereum_desc: "以太坊",
    TRON_desc: "波場",
    Avalanche_desc: "Avalanche",
    Smart_desc: "BNB智能鏈",
    Bridged_desc: "橋接網絡",
    Fantom_desc: "Fantom",
    Arbitrum_desc: "Arbitrum",
    Cronos_desc: "Cronos",
    Optimism_desc: "Optimism",
    Aurora_desc: "Aurora",
    FAQs_desc: "常見問題",
    TrueUSD_desc: "Q1. 什麼是TrueUSD？",
    Banking_desc:
      "在一個成熟的全球金融機構網絡和強大的區塊鏈基礎設施上，TUSD是第一個利用智能合約進行鑄造/贖回的穩定幣，通過實時區塊鏈驗證基礎儲備金覆蓋，由全球領先的會計事務所Moore Hong Kong和Chainlink Proof of Reserve提供支持。",
    started_desc: "Q2. 如何開始使用TrueUSD？",
    stepsbelow_desc: "要開始使用TrueUSD，請按照以下步驟操作：",
    verify_desc: "通過",
    website_desc: "TrueUSD網站",
    creating_desc: "創建並驗證您的帳戶後，您將收到一封來自",
    verification_desc:
      "利用驗證鏈接進行驗證。點擊此鏈接驗證您的電子郵件地址，然後設置",
    information_desc:
      "填寫有關您自己和/或您的組織的信息。有關所需文件清單，請點擊",
    here_desc: "此處",
    application_desc: "提交申請。我們將審核您的申請。",
    Redeem_desc: "一鍵無費用地鑄造和贖回我們的穩定幣",
    Trade_desc: "在多個交易所的160多個市場和5個大陸上交易TrueUSD",
    Send_desc: "隨時隨地向任何人發送TrueUSD，比電匯便宜100倍，快1000倍",
    mint_desc: "Q3. 如何鑄造和贖回TrueUSD？",
    partner_desc1: "根據應用程序中顯示的鑄造說明向我們的銀行合作夥伴發送電匯",
    Trueapp_desc: "TrueUSD應用程序",
    Please_desc:
      "請注意：我們目前不接受ACH或其他數字貨幣轉賬服務，例如Venmo、TransferWise、PayPal、Revolut等。",
    account_desc: "您銀行帳戶上的姓名必須與您的TrueUSD帳戶上的姓名相匹配。",
    generally_desc: "在銀行之間進行電匯通常需要1-5個工作日才能完成。",
    email_desc: "一旦我們收到您的電匯，我們將向您發送電子郵件確認。",
    minted_desc:
      "在收到您的資金後的1個工作日內，TrueUSD將鑄造並轉入應用程序中提供的錢包地址。",
    redeem4_desc: "Q4. 如何贖回TrueUSD？",
    Visit_desc: "訪問",
    Polygon_desc1: "TrueUSD應用程序",
    receive_desc:
      "輸入您的銀行信息並接收您的唯一贖回地址。法定貨幣轉賬需符合我們銀行合作夥伴的KYC和AML要求。",
    redemption_desc:
      "此贖回地址是每個TrueUSD用戶獨有的。請不要與他人共享您的唯一贖回地址。",
    simple_desc: "為什麼我的贖回地址這麼簡單？",
    notice_desc:
      "您會注意到這個地址非常簡單，包含許多'0'。這是有意為之的。您可以從任何錢包（包括交易所錢包）贖回到這個地址，所有通過這個地址收到的贖回將與您的TrueUSD帳戶關聯。",
    unique_desc: "向您獨特的贖回地址發送TrueUSD（最低金額為1,000美元）。",
    partner_desc:
      "通常情況下，當代幣成功發送到您的贖回地址後，我們的銀行合作夥伴會在1個工作日內向您的銀行帳戶發起電匯。",
    exchanges_desc: "Q5. TrueUSD（TUSD）在哪些交易所上市？",
    Binance_desc:
      "TrueUSD目前在多個交易所上市，包括幣安（Binance）、Bittrex和Upbit。",
    here2_desc: "這裡",
    markets_desc: "獲取活躍的TrueUSD交易所和市場的完整列表。",
    contract_desc: "Q6. TrueUSD的智能合約地址是什麼？",
    tokenSmart_desc:
      "我們的代幣智能合約可以通過TUSD本地部署網絡和TUSD橋接網絡或幣安鏈瀏覽器找到。以下是鏈接。您還可以在我們的",
    blog_desc: "博客",
    related_desc:
      "中了解更多關於我們的智能合約和智能合約升級的信息。以下是ERC-20智能合約地址和與TUSD相關的智能合約鏈接：",
    Natively_desc: "TUSD本地部署網絡",
    Ethererum2_desc: "以太坊",
    SmartChain_desc: "BNB智能鏈：",
   
    Avalanche2_desc: "Avalanche",
    Beacon_desc: "BNB信標鏈：",
    Networks2_desc: "TUSD橋接網絡",
    Polygon2_desc: "Polygon",
    Fantom2_desc: "Fantom",
   
    Polygon_desc2: "Optimism",
    ChainOld_desc: "BNB智能鏈 (舊版):",
    AboutMore_desc: "了解更多關於TUSD",
    Digital_desc: "為新型全球支付系統打造的數字貨幣",
    first_desc:
      "TrueUSD是第一個通過獨立第三方機構每日確認其基礎儲備的美元錨定穩定幣。",
    Polygon_desc: "Polygon",
    Follow_desc: "關注我們",
    Resources_desc: "資源",
    Stablecoin_desc: "TrueUSD成為首個通過「儲備證明」確保鑄幣的美元支持穩定幣",
    Engages_desc:
      "TUSD與領先的會計事務所MooreHK合作，提供對其法幣儲備的確認服務",
    Becomes_desc: "TUSD（TRC-20）成為多米尼加的法定貨幣",
    Blog_desc: "博客",
    Support_desc: "支援",
    TermsOf_desc: "使用條款",
    Privacy_desc: "隱私政策",
    TrueUsd_desc: "TrueUSD",
    Home_desc: "首頁",
    Transparency_desc: "透明度",
    Ecosystem_desc: "生態系統",
  },
  project_desc: {
    Pool_desc: "資金池",
    participating_desc: "作為參與代幣，您將獲得",
    rewards_desc: "作為獎勵。",
    EndTime_desc: "項目結束時間",
    duration_desc: "質押期限",
    days_desc: "天",
    calculation_desc: "周期性計算",
    Invite_desc: "邀請",
    level_desc: "級別佣金",
    withdrawal_desc: "最低提現金額",
    supply_desc: "最低供應金額",
    period_desc: "最低提現周期",
    Invitations_desc: "邀請級別",
    People_desc: "人數",
  },
  capitaInfoView_desc: {
    Please_desc: "請鏈接地址",
    Pool_desc: "資金池",
    receive_desc: "作為參與代幣，您將獲得",
    rewards_desc: "作為獎勵。",
    level_desc: "級別資金池",
    Expired_desc: "已過期",
    Unexpired_desc: "未過期",
    Participated_desc: "已參與",
    uncommitted_desc: "未承諾",
    Withdraw_desc: "提現",
    Redeem_desc: "贖回",
    Status_desc: "狀態",
    Principal_desc: "本金",
    Redeemed_desc: "已贖回",
    subaccounts_desc: "子帳戶",
    Supplied_desc: "已供應",
    Redeemable_desc: "可贖回",
    Commission_desc: "佣金",
    Available_desc: "可提現",
    Withdrawed_desc: "已提現",
    Lock_desc: "鎖定佣金",
    All_desc: "所有佣金",
    interest_desc: "利息",
    Settled_desc: "已結算",
    Loading_desc: "加載中",
    amount_desc: "可提現金額",
  },
  elModal_desc: {
    Please_desc: "請輸入充值金額",
    Recharge_desc: "充值",
    Cancel_desc: "取消",
    withdrawal_desc: "請輸入提現金額",
    Withdraw_desc: "提現",
    redemption_desc: "請輸入贖回金額",
    Redeem_desc: "贖回",
    Withdrawable_desc: "可提現金額（佣金+利息）:",
    balance_desc: "可贖回餘額:",
    input_desc: "請輸入金額",
    Transaction_desc: "交易成功！",
    failed_desc: "交易失敗",
    funds_desc: "賬戶資金不足",
    Loading_desc: "加載中",
  },
  modal: {
    hold_desc: "如果你擁有",
    Smart_desc: "在BNB智能鏈中，您將獲得獎金獎勵，",
    Rewards_desc: "只有超過一定金額的購買才能獲得獎勵。",
    address_desc: "該地址持有",
    hours_desc:
      "在24小時內持有，並且最低持有量基於系統的隨機快照。金額將每24小時進行分配。實際分配以賬戶為準。",
  },
  UserInfo: {
    Wallets_desc: "錢包",
    Reward_desc: "獎勵",
    half_month: "15天",
    one_month: "1個月",
    three_month: "3個月",
    Invitees: "被邀請",
    no_data: "什麼都沒有哦",
    walletNo_support: "您的钱包不受支持。请使用其他钱包应用程序。",
    network_wrong: "請選擇正確的網路",
  },
};
export default zh_FT;

// Datajson: {
//     language_json: {
//         zh_json: '簡體中文',
//         zh_FT_json: '繁體中文',
//         eh_json: '英語',
//         ja_JP_json: '日語',
//         ko_KR_json: '韓語',
//         en_TH_json: '泰語',
//         fr_FR_json: '法語',
//         ru_RU_json: '俄語',
//         en_IN_json: '印地語',
//         en_ID_json: '印尼語',
//         en_BS_json: '波斯語',
//         en_YN_json: '越南語',
//         en_ALB_json: '阿拉伯語',
//         en_TEQ_json: '土耳其語',
//         en_ES_json: '西班牙語',
//         en_PTY_json: '葡萄牙語',
//         en_YDL_json: '義大利語'
//     },
// },
