import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from './i18n-vue/index'
import Storage from "vue-ls";
import "@/mixin/index";
import "@/components/index";
// 加载 element 组件库
import ElementUI from "element-ui";
// 加载 element 组件库的样式
import "element-ui/lib/theme-chalk/index.css";
// vue-ls 的配置
const storageOptions = {
  namespace: "vue_", // key 键的前缀（随便起）
  name: "ls", // 变量名称（随便起） 使用方式：Vue.变量名称 或 this.$变量名称
  storage: "local", // 作用范围：local、session、memory
};
Vue.use(Storage, storageOptions);
// 全局注册 element 组件库
Vue.use(ElementUI);
Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
