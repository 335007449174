const en_ID = {
    Datajson:{
        language_json:{
            zh_json:'中文简体',
            zh_FT_json: '中文繁體',
            eh_json:'English',
            ja_JP_json:'日本語',  
            ko_KR_json:'한국어' ,
            en_TH_json:'ภาษาไทย',
            fr_FR_json:'En français',
            ru_RU_json:'русск',
            en_IN_json:'हिंदीName',
            en_ID_json:'Bahasa indonesia',
            en_BS_json:'فارسی',
            en_YN_json:'Việt nam',
            en_ALB_json:' بالعربية ',
            en_TEQ_json:'Türkçe',
            en_ES_json:'Español',
            en_PTY_json:'Português',
            en_YDL_json:'Italiano'
        },
    },
    home: {
        language: 'bahasa',
        fduse_desc: 'FDUSD adalah Stablecoin yang didukung oleh USD dalam perbandingan 1:1.',
        introduce_desc: 'Revolutionize keuangan global dengan stablecoin digital terdepan, memberikan keamanan, kecepatan, dan inovasi bagi bisnis dan pengembang di seluruh dunia.',
        getfduse_desc: 'Dapatkan FDUSD',
        stability_desc: 'Stabilitas Bertemu Inovasi',
        experience_desc: 'Nikmati kecepatan yang sangat cepat dan biaya minimal. Bentuk masa depan keuangan dengan solusi inovatif dan terpercaya kami.',
        whitepaper_desc: 'Whitepaper',
        redeemable_desc: 'Dapat Ditukarkan 1:1 dengan dolar AS',
        dollars_desc: 'FDUSD dimaksudkan untuk sepenuhnya didukung oleh uang tunai dan aset setara uang tunai. Token dimaksudkan dapat ditukarkan 1:1 dengan dolar AS.',
        backed_desc: 'Didukung oleh aset yang sepenuhnya direservasi',
        deserved_desc: 'Aset yang direservasi disimpan dalam struktur kepemilikan yang terpisah dari kebangkrutan.',
        programmable_desc: 'Sepenuhnya Dapat Diprogram',
        innovative_desc: 'Dengan solusi stablecoin inovatif kami - didukung oleh teknologi canggih, kami bertujuan untuk menyediakan alternatif yang aman dan dapat diandalkan yang memberikan ketenangan pikiran dan memberikan dampak positif di dunia keuangan.',
        settle_desc: 'Selesaikan dalam hitungan menit',
        nearZero_desc: 'Biaya Dekat Nol',
        always_desc: 'Selalu Aktif, 24/7',
        fully_desc: "Sepenuhnya Dapat Diprogram",
        rewrite_desc: 'Menulis Ulang Masa Depan dengan First Digital Labs',
        combining_desc: 'Dengan menggabungkan stabilitas dengan inovasi di First Digital Labs, kami berkomitmen untuk mendapatkan kepercayaan dengan menawarkan transparansi, kepatuhan, dan keamanan yang teguh didukung oleh teknologi terkini.',
        mission_desc: 'Kami berada dalam misi untuk mendorong industri keuangan ke era keuangan baru dan membentuk lanskap keuangan global.',
        should_desc: 'Mengapa Anda Harus Menggunakan First Digital USD?',
        collateral_desc: 'Jaminan dijaga oleh kustodian berperingkat tinggi yang memiliki lisensi kepercayaan untuk perlindungan aset yang mulus dan kepatuhan peraturan.',
        transparent_desc: 'Transparan',
        independent_desc: 'Attestasi independen - jaminan divalidasi oleh pihak ketiga independen, memastikan integritas cadangan dan pelaporan keuangan.',
        examined_by_desc: 'Diperiksa oleh',
        attestation_Reports_desc: 'Laporan Attestasi',
        January2024_desc: 'Januari 2024',
        Report_desc: 'Laporan',
        December_desc: 'Desember 2023',
        November_desc: 'November 2023',
        Networks_desc: 'Jaringan Mana yang Didukung?',
        available_desc: 'FDUSD tersedia di',
        Ethereum_desc: 'Ethereum',
        and_desc: 'dan',
        BNB_Chain_desc: 'BNB Chain',
        planned_desc: 'dengan dukungan yang direncanakan untuk jumlah yang semakin banyak dari blockchain.',
        Audited_desc: 'Diaudit oleh',
        Frequently_desc: 'Pertanyaan yang Sering Diajukan',
        How_FDUSD_desc: 'Bagaimana cara mendapatkan FDUSD?',
        Acquiring_desc: 'Mendapatkan FDUSD dapat dicapai dengan beberapa cara. Jika Anda adalah pemain industri kunci, perantara keuangan, atau investor profesional dengan status tertentu dan memenuhi kriteria yang diperlukan, Anda dapat membeli FDUSD langsung dari First Digital Labs. Harap dicatat bahwa First Digital Labs tidak menjual token secara langsung kepada pelanggan eceran.:',
        canstill_desc: 'Anda masih dapat memperoleh FDUSD melalui pasar sekunder tanpa menjadi klien First Digital Labs. Banyak bursa mata uang kripto terkemuka mencantumkan FDUSD dan menawarkan likuiditas yang substansial. Ini memberikan cara yang mudah dan nyaman bagi individu yang tertarik untuk memperoleh FDUSD. Namun, selalu penting untuk melakukan riset menyeluruh dan memastikan kredibilitas pertukaran yang Anda gunakan.',
        equivalent_desc: 'Bagaimana saya bisa percaya bahwa FDUSD selalu setara dengan satu dolar?',
       // stablecoin_desc: 'Kepercayaan Anda pada nilai stablecoin FDUSD sangatlah kuat. Setiap FDUSD dimaksudkan untuk sepenuhnya didukung oleh satu dolar AS atau aset dengan nilai wajar yang setara. Aset-aset ini aman disimpan oleh kustodian yang berkualifikasi dalam rekening dengan lembaga penyimpan teratur, memberikan dasar yang kokoh bagi nilai koin tersebut. Sistem ini adalah yang memberikan stabilitas inheren pada FDUSD dan alasan mengapa Anda dapat mempercayai nilai setara dengan dolar AS.',
        redeem_FDUSD: 'Bagaimana cara menukarkan FDUSD?',
       // stablecoin_desc: 'Untuk menukarkan stablecoin FDUSD Anda, Anda harus menjadi klien First Digital Labs terlebih dahulu dan memenuhi persyaratan tertentu, termasuk pemeriksaan Anti-Money Laundering (AML) dan Counter-Terrorism Financing (CTF). Setelah berhasil menyelesaikan pemeriksaan ini, Anda dapat menukar FDUSD Anda dengan mata uang fiat yang setara, sehingga mengeluarkannya dari peredaran. Alternatifnya, Anda dapat menjual token FDUSD Anda di pasar sekunder melalui bursa mata uang kripto atau penyedia Over-the-Counter (OTC) yang mendukung stablecoin FDUSD. Harap perhatikan semua regulasi hukum dan keuangan di yurisdiksi Anda sebelum melanjutkan.',
        Explore_desc: "Jelajahi Ekosistem Kami",
        Experience_desc: 'Rasakan stabilitas dengan solusi stablecoin yang dapat diandalkan kami.',
        slide_desc: 'slide',
        _1to6_desc: '1 hingga 6',
        of7_desc: 'dari 7',
        Transparency_desc: 'Transparansi',
        Legal_desc: 'Hukum',
        Compliance_desc: 'Kepatuhan',
        Policy_desc: 'Kebijakan Privasi',
        Company_desc: 'Perusahaan',
        About_Us_desc: 'Tentang Kami',
        Social_Media_desc: 'Media Sosial',
        Twitter_desc: 'Twitter',
        Disclosures_desc: 'Pernyataan Penting:',
        Limited_desc: 'FD121 Limited adalah perusahaan teknologi keuangan, bukan bank. Layanan penitipan cadangan disediakan oleh ',
        First_desc: "First Digital Trust Limited",
        product_desc: 'Semua nama produk dan perusahaan adalah merek dagang™ atau merek dagang terdaftar® dari pemegangnya masing-masing. Semua nama perusahaan, produk, dan layanan yang digunakan dalam situs web ini hanya untuk tujuan identifikasi. Penggunaan nama, merek dagang, dan merek dagang tersebut tidak menyiratkan adanya afiliasi atau dukungan oleh mereka.',
        additional_desc: 'Untuk risiko, pengungkapan, dan informasi penting tambahan, silakan kunjungi',
        Copyright_desc: 'Hak Cipta © 2024 FD121 Limited. Seluruh Hak Dilindungi.'
    },
    newhome_json: {
        Exchanges_json: "Bursa",
        DeFi_json: "Keuangan Terdesentralisasi",
        Payments_json: "Pembayaran",
        Accessibility_json: "Aksesibilitas",
        listed_json: "TUSD terdaftar di berbagai bursa terkemuka di industri, menyediakan likuiditas dan aksesibilitas untuk semua investor.",
        stablecoin_json: "Sebagai stablecoin yang banyak digunakan, TUSD telah diadopsi secara signifikan dalam berbagai protokol keuangan terdesentralisasi (DeFi), termasuk pembuat pasar otomatis (AMM), platform peminjaman, dan proyek pertanian hasil (YIF).",
        leading_json: "Sebagai stablecoin yuan terkemuka, TUSD adalah media ideal untuk transaksi lintas batas. Dengan biaya transaksi yang rendah dan kecepatan instan dari TUSD, individu dan institusi dapat melakukan transaksi tanpa tantangan keuangan tradisional.",
        available_json: "TUSD juga tersedia di blockchain terkemuka seperti Ethereum, BNB Smart Chain, TRON, dan Avalanche. Jaringan jembatan TUSD mencakup Polygon, Fantom, Arbitrum, Cronos, dan lainnya.",
        error1_json: "Tidak dapat terhubung ke node. Ganti node dan coba lagi",
        error2_json: "Kesalahan rantai",
        error3s_json: "Harap hubungkan alamat Anda",
        Loading1_json: "Memuat",
        Loading2_json: "Memuat informasi proyek",
        error3_json: "Kesalahan jaringan",
        error4_json: "Koneksi dompet ditolak",
        error5_json: "Versi dompet lama",
        error6_json: "Koneksi dompet ditolak",
        Loading3_json: "Masuk",
        error7_json: "Kesalahan akun",
        error8_json: "Tidak dapat terhubung ke node. Ganti node dan coba lagi",
        error9_json: "Kesalahan jaringan",
        Loading4_json: "Staking",
        error10_json: "Anda telah diblokir",
        error11_json: "Kesalahan proyek",
        error12_json: "Gas tidak cukup",
        success1_json: "Berhasil",
        error13_json: "Gagal mendapatkan akun",
        error14_json: "Kesalahan tidak dikenal",
        error15_json: "Tidak dapat terhubung ke node. Ganti node dan coba lagi",
       
      },
      newhome_desc: {
        title_desc: "Mata uang digital yang dibangun untuk sistem pembayaran global baru",
        desc_desc: "TrueUSD adalah stablecoin pertama yang dipatok dolar yang melakukan audit harian pada cadangannya oleh lembaga pihak ketiga independen.",
        Started_desc: "Mulai",
        Contact_desc: "Hubungi Kami",
        Circulating_desc: "Pasokan Beredar",
        Trading_desc: "Volume Perdagangan",
        Transaction_desc: "Jumlah Transaksi Total",
        Support_desc1: "Total Dukungan Rantai",
        Trustworthy_desc: "Terpercaya",
        utilizes_desc: "TUSD memanfaatkan mekanisme audit dan sertifikasi canggih untuk meningkatkan transparansi. Laporan audit harian dapat dilihat di situs web, memungkinkan pengguna untuk memantau cadangan setiap saat.",
        Popularity_desc: "Popularitas",
        convenience_desc: "TUSD menawarkan kenyamanan dan kegunaan dalam berbagai skenario keuangan seperti perdagangan dan transfer. Saat ini, TUSD dapat digunakan di lebih dari 80 bursa cryptocurrency dan protokol DeFi, memberikan fleksibilitas dan aksesibilitas kepada pengguna.",
        Liquidity_desc: "Likuiditas",
        During_desc: "Pada kuartal ketiga tahun 2023, volume perdagangan harian TUSD melebihi 1 miliar dolar, menunjukkan likuiditas yang luar biasa. Selain itu, TUSD tidak mengenakan biaya untuk pencetakan dan penebusan.",
        Trusted_desc: "Dipercaya oleh para pemimpin global",
        Using_desc: "Menggunakan TUSD",
        LearnMore_desc: "Pelajari Lebih Lanjut",
        Goes_desc: "TUSD menuju multi-rantai",
        Natively_desc1: "Jaringan Penyebaran Asli",
        Ethereum_desc: "Ethereum",
        TRON_desc: "TRON",
        Avalanche_desc: "Avalanche",
        Smart_desc: "BNB Smart Chain",
        Bridged_desc: "Jaringan Jembatan",
        Fantom_desc: "Fantom",
        Arbitrum_desc: "Arbitrum",
        Cronos_desc: "Cronos",
        Optimism_desc: "Optimism",
        Aurora_desc: "Aurora",
        FAQs_desc: "Pertanyaan Umum",
        TrueUSD_desc: "Q1. Apa itu TrueUSD?",
        Banking_desc: "TrueUSD memanfaatkan lembaga keuangan global dan infrastruktur blockchain yang kuat. Ini adalah stablecoin pertama yang menggunakan kontrak pintar untuk pencetakan/penebusan, dengan dukungan verifikasi cakupan cadangan secara real-time on-chain, didukung oleh firma akuntansi global terkemuka Moore Hong Kong dan Chainlink Proof of Reserve.",
        started_desc: "Q2. Bagaimana cara mulai menggunakan TrueUSD?",
        stepsbelow_desc: "Untuk mulai menggunakan TrueUSD, ikuti langkah-langkah di bawah ini:",
        verify_desc: "Setelah membuat dan memverifikasi akun Anda melalui situs web TrueUSD, Anda akan menerima email dari",
        website_desc: "situs web TrueUSD",
      
        verification_desc: "Verifikasi melalui tautan verifikasi. Klik pada tautan ini untuk memverifikasi alamat email Anda, lalu atur",
        information_desc: "Isi informasi tentang diri Anda dan/atau organisasi Anda. Untuk daftar dokumen yang diperlukan, klik",
        here_desc: "di sini",
        application_desc: "Kirim aplikasi Anda. Kami akan meninjau aplikasi Anda.",
        Redeem_desc: "Pencetakan dan penebusan stablecoin kami tanpa biaya dalam satu klik",
        Trade_desc: "Perdagangkan TrueUSD di lebih dari 160 pasar di beberapa bursa dan lebih dari 20 meja OTC di 5 benua",
        Send_desc: "Kirim TrueUSD kepada siapa pun, kapan saja, di mana saja dengan biaya 100 kali lebih murah dan kecepatan 1000 kali lebih cepat daripada transfer bank",
        mint_desc: "Q3. Bagaimana cara mencetak dan menebus TrueUSD?",
        partner_desc1: "Kirim transfer kawat ke mitra bank kami, menggunakan informasi yang ditampilkan di",
        Trueapp_desc: "aplikasi TrueUSD",
        Please_desc: "Perhatikan: saat ini kami tidak menerima ACH atau layanan transfer mata uang digital lainnya seperti Venmo, TransferWise, PayPal, Revolut, dll.",
        account_desc: "Nama pada akun bank Anda harus sesuai dengan nama pada akun TrueUSD Anda.",
        creating_desc: "Setelah membuat akun Anda, Anda akan menerima email dari",
        generally_desc: "Biasanya, transfer kawat antar bank memakan waktu 1-5 hari kerja.",
        email_desc: "Setelah kami menerima transfer kawat Anda, kami akan mengirimkan email konfirmasi kepada Anda.",
        minted_desc: "Setelah kami menerima dana Anda, TrueUSD akan dicetak dan ditransfer ke alamat dompet yang disediakan dalam aplikasi dalam waktu satu hari kerja.",
        redeem4_desc: "Q4. Bagaimana cara menebus TrueUSD?",
        Visit_desc: "Kunjungi",
        Polygon_desc1: "aplikasi TrueUSD",
        receive_desc: "untuk memasukkan informasi bank Anda dan mendapatkan alamat penebusan unik Anda. Transfer mata uang fiat tunduk pada persyaratan KYC dan AML dari mitra bank kami.",
        redemption_desc: "Alamat penebusan ini unik untuk setiap pengguna TrueUSD. Jangan bagikan alamat penebusan unik Anda dengan orang lain.",
        simple_desc: "Mengapa alamat penebusan saya sangat sederhana?",
        notice_desc: "Anda akan melihat bahwa alamat ini sangat sederhana, dengan banyak '0'. Hal ini disengaja. Anda dapat menebus dari dompet apa pun (termasuk dompet bursa) ke alamat ini, dan semua penebusan yang diterima di alamat ini akan dikaitkan dengan akun TrueUSD Anda.",
        unique_desc: "Kirim TrueUSD ke alamat penebusan unik Anda (minimal $1,000).",
        partner_desc: "Setelah token berhasil dikirim ke alamat penebusan Anda, mitra bank kami biasanya akan mengirimkan transfer kawat ke akun bank Anda dalam waktu satu hari kerja.",
        exchanges_desc: "Q5. Di mana TrueUSD (TUSD) terdaftar?",
        Binance_desc: "Saat ini TrueUSD terdaftar di beberapa bursa, termasuk Binance, Bittrex, dan Upbit.",
        here2_desc: "di sini",
        markets_desc: "untuk daftar lengkap bursa dan pasar TrueUSD yang aktif.",
        contract_desc: "Q6. Apa alamat kontrak pintar TrueUSD?",
        tokenSmart_desc: "Kontrak pintar kami dapat ditemukan melalui jaringan penyebaran asli TUSD dan jaringan jembatan TUSD atau penjelajah Binance Chain. Tautan ada di bawah. Anda juga dapat mempelajari lebih lanjut tentang kontrak pintar kami dan pembaruan kontrak pintar di",
        blog_desc: "blog",
        related_desc: "kami.",
        Natively_desc: "Jaringan Penyebaran Asli TUSD",
        Ethererum2_desc: "Ethereum",
        SmartChain_desc: "BNB Smart Chain:",
       
        Avalanche2_desc: "Avalanche",
        Beacon_desc: "BNB Beacon Chain:",
        Networks2_desc: "Jaringan Jembatan TUSD",
        Polygon2_desc: "Polygon",
        Fantom2_desc: "Fantom",
       
        Polygon_desc2: "Optimism",
        ChainOld_desc: "BNB Smart Chain (lama):",
        AboutMore_desc: "Pelajari lebih lanjut tentang TUSD",
        Digital_desc: "Mata uang digital untuk sistem pembayaran global baru",
        first_desc: "TrueUSD adalah stablecoin pertama yang menggunakan lembaga pihak ketiga independen untuk memverifikasi cadangan hariannya.",
        Polygon_desc: "Polygon",
        Follow_desc: "Ikuti Kami",
        Resources_desc: "Sumber Daya",
        Stablecoin_desc: "TrueUSD menjadi stablecoin pertama yang didukung oleh dolar yang menjamin pencetakan melalui 'Proof of Reserves'",
        Engages_desc: "TUSD bekerja sama dengan firma akuntansi terkemuka MooreHK untuk menyediakan layanan verifikasi cadangan fiatnya",
        Becomes_desc: "TUSD (TRC-20) menjadi mata uang resmi di Dominika",
        Blog_desc: "Blog",
        Support_desc: "Dukungan",
        TermsOf_desc: "Syarat Penggunaan",
        Privacy_desc: "Kebijakan Privasi",
        TrueUsd_desc: "TrueUSD",
        Home_desc: "Beranda",
        Transparency_desc: "Transparansi",
        Ecosystem_desc: "Ekosistem",
        About_desc: "Tentang",
        Supported_desc: "Rantai yang Didukung",
        Other_desc: "Rantai lainnya",
        rights_desc: "© 2023 TrueUSD. Hak cipta dilindungi.",
        Back_desc: "Kembali ke atas"
      },
      project_desc: {
        Pool_desc: "Kolam Dana",
        participating_desc: "Dengan berpartisipasi dalam token, Anda akan menerima",
        rewards_desc: "sebagai hadiah.",
        EndTime_desc: "Waktu Akhir Proyek",
        duration_desc: "Durasi Staking",
        days_desc: "hari",
        calculation_desc: "Perhitungan Berkala",
        Invite_desc: "Undang",
        level_desc: "Komisi Tingkat",
        withdrawal_desc: "Jumlah Penarikan Minimum",
        supply_desc: "Jumlah Pasokan Minimum",
        period_desc: "Periode Penarikan Minimum",
        Invitations_desc: "Tingkat Undangan",
        People_desc: "Jumlah Orang"
      },
      capitaInfoView_desc: {
        Please_desc: "Harap hubungkan alamat",
        Pool_desc: "Kolam Dana",
        receive_desc: "Dengan berpartisipasi dalam token, Anda akan menerima",
        rewards_desc: "sebagai hadiah.",
        level_desc: "Kolam Dana Tingkat",
        Expired_desc: "Kadaluarsa",
        Unexpired_desc: "Belum Kadaluarsa",
        Participated_desc: "Berpartisipasi",
        uncommitted_desc: "Tidak Ditetapkan",
        Withdraw_desc: "Tarik",
        Redeem_desc: "Tukarkan",
        Status_desc: "Status",
        Principal_desc: "Modal",
        Redeemed_desc: "Ditukarkan",
        subaccounts_desc: "Sub Akun",
        Supplied_desc: "Disediakan",
        Redeemable_desc: "Dapat Ditukarkan",
        Commission_desc: "Komisi",
        Available_desc: "Tersedia untuk Penarikan",
        Withdrawed_desc: "Ditari",
        Lock_desc: "Kunci Komisi",
        All_desc: "Semua Komisi",
        interest_desc: "Bunga",
        Settled_desc: "Diselesaikan",
        Loading_desc: "Memuat",
        amount_desc: "Jumlah yang Dapat Ditarik"
      },
      elModal_desc: {
        Please_desc: "Harap masukkan jumlah deposit",
        Recharge_desc: "Isi Ulang",
        Cancel_desc: "Batalkan",
        withdrawal_desc: "Harap masukkan jumlah penarikan",
        Withdraw_desc: "Tarik",
        redemption_desc: "Harap masukkan jumlah penukaran",
        Redeem_desc: "Tukarkan",
        Withdrawable_desc: "Jumlah yang Dapat Ditarik (komisi + bunga):",
        balance_desc: "Saldo yang Dapat Ditukarkan:",
        input_desc: "Harap masukkan jumlah",
        Transaction_desc: "Transaksi Berhasil!",
        failed_desc: "Transaksi Gagal",
        funds_desc: "Saldo akun tidak mencukupi",
        Loading_desc: "Memuat"
      },
    modal: {
        hold_desc: 'Jika Anda memegang',
        Smart_desc: 'dari BNB Smart Chain, Anda akan mendapatkan bonus hadiah,',
        Rewards_desc: 'Hadiah hanya tersedia untuk jumlah yang melebihi',
        address_desc: 'Alamat ini memegang',
        hours_desc: 'selama 24 jam, dan jumlah minimum yang harus dipertahankan didasarkan pada snapshot acak sistem. Jumlah dialokasikan setiap 24 jam. Penetapan aktual ke akun yang bersangkutan akan berlaku.'
    },
    UserInfo: {
        Wallets_desc: 'Dompet',
        Reward_desc: 'Hadiah',
        half_month: '15 Hari',
        one_month: '1 bulan',
        three_month: '3 bulan',
        Invitees: 'Undangan',
        no_data: 'Tidak Ada Data',
        walletNo_support: 'Dompet Anda tidak didukung. Silakan gunakan aplikasi dompet lain.',
        network_wrong: 'Harap pilih jaringan yang benar',
      },
}
export default en_ID

// Datajson:{
//     language_json:{
//         zh_json:'China disederhanakan',
//         zh_FT_json: 'Cina Tradisional',
//         eh_json:'Bahasa inggris',
//         ja_JP_json:'Bahasa jepang.',  
//         ko_KR_json:'Korea.' ,
//         en_TH_json:'Bahasa thailand',
//         fr_FR_json:'perancis',
//         ru_RU_json:'rusia',
//         en_IN_json:'Hindi berbicara',
//         en_ID_json:'Bahasa indonesia',
//         en_BS_json:'farsiname',
//         en_YN_json:'Vietnam (bahasa)',
//         en_ALB_json:'Arab.',
//         en_TEQ_json:'Turki (bahasa)',
//         en_ES_json:'spanyol',
//         en_PTY_json:'portugis',
//         en_YDL_json:'Italia.'
//     },
// },