const en_BS = {
    Datajson:{
        language_json:{
            zh_json:'中文简体',
            zh_FT_json: '中文繁體',
            eh_json:'English',
            ja_JP_json:'日本語',  
            ko_KR_json:'한국어' ,
            en_TH_json:'ภาษาไทย',
            fr_FR_json:'En français',
            ru_RU_json:'русск',
            en_IN_json:'हिंदीName',
            en_ID_json:'Bahasa indonesia',
            en_BS_json:'فارسی',
            en_YN_json:'Việt nam',
            en_ALB_json:' بالعربية ',
            en_TEQ_json:'Türkçe',
            en_ES_json:'Español',
            en_PTY_json:'Português',
            en_YDL_json:'Italiano'
        },
    },
    home: {
        language: 'زبان',
        fduse_desc: 'FDUSD یک Stablecoin با پشتوانه یک به یک دلار آمریکا است.',
        introduce_desc: 'با استفاده از یک استیبل کوین دیجیتال پیشرفته، ما در حال آشوب زدایی در مالی جهانی هستیم و برای شرکت‌ها و سازندگان سرعت و نوآوری را در سراسر جهان فراهم می‌کنیم.',
        getfduse_desc: 'FDUSD دریافت کنید',
        stability_desc: 'استحکام با نوآوری همراه است',
        experience_desc: 'سرعت بسیار بالا و هزینه‌های کم. با راهکار نوآورانه و قابل اعتماد خود، آینده مالی را شکل دهید.',
        whitepaper_desc: 'کاغذ سفید',
        redeemable_desc: 'امکان تبدیل یک به یک به دلار آمریکا',
        dollars_desc: 'FDUSD به طور کامل بر اساس پشتوانه نقدی و دارایی‌های معادل نقدی است. توکن‌ها قابل تبدیل یک به یک به دلار آمریکا هستند.',
        backed_desc: 'با پشتوانه دارایی‌های کامل',
        deserved_desc: 'دارایی‌های پشتوانه در ساختارهای نگهداری مستقل و دور از ورشکستگی نگهداری می‌شوند.',
        programmable_desc: 'کاملا قابل برنامه‌ریزی',
        innovative_desc: 'با استفاده از راه‌حل استیبل کوین نوآورانه ما که با فناوری پیشرفته پشتوانه‌گذاری شده است، هدف ما ارائه یک جایگزین امن و قابل اعتماد است که آرامش را به همراه دارد و در جهان مالی تأثیر مثبتی می‌گذارد.',
        settle_desc: 'در عرض چند دقیقه تسویه شوید',
        nearZero_desc: 'هزینه نزدیک به صفر',
        always_desc: 'همیشه فعال، ۲۴/۷',
        fully_desc: "کاملا قابل برنامه‌ریزی",
        rewrite_desc: 'نویسی آینده با First Digital Labs',
        combining_desc: 'ترکیب استحکام با نوآوری در First Digital Labs، ما متعهد به بهبود اعتماد از طریق ارائه شفافیت، پایبندی به قوانین و امنیت مدرن به وسیله فناوری پیشرفته هستیم.',
        mission_desc: 'ما در یک مأموریت هستیم تا صنعت مالی را به دوران جدیدی از مالی پیشبرد کنیم و منظره مالی جهانی را تغییر دهیم.',
        should_desc: 'چرا باید از First Digital USD استفاده کنید؟',
        collateral_desc: 'تضمینات بازپرداخت توسط یک نگهدارنده برتر و صلاحیت‌دار با مجوز اعتماد برای حفاظت سریعاز دارایی‌ها تضمین می‌شود و با رعایت قوانین و مقررات کنترل می‌شود.',
        transparent_desc: 'شفاف',
        independent_desc: 'تایید مستقل - تضمینات توسط یک شخص ثالث مستقل تأیید می‌شود تا سلامت محفظه‌ها و گزارش‌های مالی تأیید شود.',
        examined_by_desc: 'تحت بازرسی',
        attestation_Reports_desc: 'گزارش‌های تأیید',
        January2024_desc: 'ژانویه ۲۰۲۴',
        Report_desc: 'گزارش',
        December_desc: 'دسامبر ۲۰۲۳',
        November_desc: 'نوامبر ۲۰۲۳',
        Networks_desc: 'کدام شبکه‌ها پشتیبانی می‌شوند؟',
        available_desc: 'FDUSD در دسترس است در',
        Ethereum_desc: 'Ethereum',
        and_desc: 'و',
        BNB_Chain_desc: 'BNB Chain',
        planned_desc: 'با پشتیبانی برای تعدادی از بلاکچین‌ها در دست بررسی.',
        Audited_desc: 'مورد بازرسی قرار گرفته است توسط',
        Frequently_desc: 'سوالات متداول',
        How_FDUSD_desc: 'چگونه می‌توانم FDUSD دریافت کنم؟',
        Acquiring_desc: 'می‌توان به دو روش FDUSD را دریافت کرد. اگر یک بازیگر کلیدی در صنعت، یک واسطه‌گر مالی یا یک سرمایه‌گذار حرفه‌ای باشید و شرایط مورد نیاز را داشته باشید، می‌توانید مستقیماً FDUSD را از First Digital Labs خریداری کنید. لطفاً توجه داشته باشید که First Digital Labs توکن‌ها را به صورت مستقیم به مشتریان خرده‌فروشی نمی‌فروشد.:',
        canstill_desc: 'هنوز می‌توانید FDUSD را از طریق بازارهای ثانویه بدست آورید بدون اینکه مشتری First Digital Labs شوید. بسیاری از صرافی‌های برتر رمزارز FDUSD را لیست می‌کنند و پایداری قابل توجهی را ارائه می‌دهند. این یک راه دسترسی آسان و مناسب برای افراد علاقه‌مند به دریافت FDUSD است. با این حال، همیشه ضروری است تحقیقات دقیقی انجام دهید و معتبر بودن صرافی که استفاده می‌کنید را تأیید کنید.',
        equivalent_desc: 'چگونه می‌توانم اعتماد کنم که FDUSD همیشه معادل یک دلار خواهد بود؟',
        stablecoin_desc: 'اعتماد شما به ارزش استیبل کوین FDUSD مبنایی قوی دارد. هر FDUSD قرار است به طور کامل توسط یک دلار آمریکا یا یک دارایی با ارزش عادلانه معادل پشتوانه گ',
    },
    newhome_json: {
        Exchanges_json: "صرافی‌ها",
        DeFi_json: "مالی غیرمتمرکز",
        Payments_json: "پرداخت‌ها",
        Accessibility_json: "دسترسی",
        listed_json: "TUSD در صرافی‌های برتر صنعت فهرست شده است و نقدینگی و دسترسی را برای همه سرمایه‌گذاران فراهم می‌کند.",
        stablecoin_json: "به عنوان یک استیبل‌کوین به طور گسترده استفاده شده، TUSD به طور قابل توجهی در پروتکل‌های مالی غیرمتمرکز (DeFi) مختلف مانند سازندگان بازار خودکار (AMM)، پلتفرم‌های وام‌دهی و پروژه‌های کشاورزی بازده (YIF) پذیرفته شده است.",
        leading_json: "به عنوان پیشروترین استیبل‌کوین یوان، TUSD یک واسطه ایده‌آل برای معاملات مرزی است. با هزینه‌های معامله کم و سرعت فوری TUSD، افراد و مؤسسات می‌توانند بدون چالش‌های مالی سنتی معامله کنند.",
        available_json: "TUSD همچنین در بلاکچین‌های برتر مانند اتریوم، زنجیره هوشمند BNB، TRON و Avalanche در دسترس است. شبکه پل‌های TUSD شامل Polygon، Fantom، Arbitrum، Cronos و بیشتر می‌شود.",
        error1_json: "اتصال به نود ممکن نیست. نود را تغییر دهید و دوباره تلاش کنید",
        error2_json: "خطای زنجیره",
        error3s_json: "لطفاً آدرس خود را متصل کنید",
        Loading1_json: "در حال بارگذاری",
        Loading2_json: "در حال بارگذاری اطلاعات پروژه",
        error3_json: "خطای شبکه",
        error4_json: "اتصال کیف پول رد شد",
        error5_json: "نسخه قدیمی کیف پول",
        error6_json: "اتصال کیف پول رد شد",
        Loading3_json: "در حال ورود",
        error7_json: "خطای حساب",
        error8_json: "اتصال به نود ممکن نیست. نود را تغییر دهید و دوباره تلاش کنید",
        error9_json: "خطای شبکه",
        Loading4_json: "در حال استیکینگ",
        error10_json: "شما مسدود شده‌اید",
        error11_json: "خطای پروژه",
        error12_json: "گاز کافی نیست",
        success1_json: "موفقیت",
        error13_json: "دریافت حساب ناموفق بود",
        error14_json: "خطای ناشناخته",
        error15_json: "اتصال به نود ممکن نیست. نود را تغییر دهید و دوباره تلاش کنید",
       
      },
      newhome_desc: {
        title_desc: "یک ارز دیجیتال ساخته شده برای سیستم پرداخت جهانی جدید",
        desc_desc: "TrueUSD اولین استیبل‌کوین پیوسته به دلار است که توسط یک مؤسسه مستقل ثالث ذخایر پایه خود را به صورت روزانه حسابرسی می‌کند.",
        Started_desc: "شروع",
        Contact_desc: "تماس با ما",
        Circulating_desc: "عرضه در گردش",
        Trading_desc: "حجم معاملات",
        Transaction_desc: "تعداد کل معاملات",
        Support_desc1: "کل زنجیره‌های پشتیبانی‌شده",
        Trustworthy_desc: "قابل اعتماد",
        utilizes_desc: "TUSD از مکانیزم‌های پیشرفته حسابرسی و گواهی برای افزایش شفافیت استفاده می‌کند. گزارش‌های حسابرسی روزانه آن در وب‌سایت قابل مشاهده است، که به کاربران اجازه می‌دهد ذخایر را در هر زمان نظارت کنند.",
        Popularity_desc: "محبوبیت",
        convenience_desc: "TUSD در سناریوهای مالی مختلف مانند معاملات و انتقالات راحتی و کاربردی ارائه می‌دهد. در حال حاضر، TUSD در بیش از 80 صرافی ارز دیجیتال و پروتکل DeFi قابل استفاده است، که به کاربران انعطاف‌پذیری و دسترسی می‌دهد.",
        Liquidity_desc: "نقدینگی",
        During_desc: "در سه‌ماهه سوم سال 2023، حجم معاملات روزانه TUSD بیش از 1 میلیارد دلار بود که نقدینگی فوق‌العاده آن را نشان می‌دهد. علاوه بر این، TUSD هیچ هزینه‌ای برای ماینینگ و بازخرید دریافت نمی‌کند.",
        Trusted_desc: "مورد اعتماد رهبران جهانی",
        Using_desc: "استفاده از TUSD",
        LearnMore_desc: "بیشتر بدانید",
        Goes_desc: "TUSD به سمت چند زنجیره‌ای می‌رود",
        Natively_desc1: "شبکه استقرار محلی",
        Ethereum_desc: "اتریوم",
        TRON_desc: "ترون",
        Avalanche_desc: "آوالانچ",
        Smart_desc: "زنجیره هوشمند BNB",
        Bridged_desc: "شبکه پل",
        Fantom_desc: "فانتوم",
        Arbitrum_desc: "آربیتروم",
        Cronos_desc: "کرونوس",
        Optimism_desc: "آپتیمیزم",
        Aurora_desc: "آئورا",
        FAQs_desc: "سؤالات متداول",
        TrueUSD_desc: "س1. TrueUSD چیست؟",
        Banking_desc: "TrueUSD از مؤسسات مالی جهانی و زیرساخت قوی بلاکچین استفاده می‌کند. این اولین استیبل‌کوین است که از قراردادهای هوشمند برای ماینینگ/بازخرید استفاده می‌کند، با پشتیبانی از تأیید پوشش ذخایر در زمان واقعی روی زنجیره، که توسط مؤسسه حسابرسی جهانی Moore Hong Kong و Chainlink Proof of Reserve پشتیبانی می‌شود.",
        started_desc: "س2. چگونه استفاده از TrueUSD را شروع کنم؟",
        stepsbelow_desc: "برای شروع استفاده از TrueUSD، مراحل زیر را دنبال کنید:",
        verify_desc: "پس از ایجاد و تأیید حساب خود از طریق وب‌سایت TrueUSD، یک ایمیل از",
        website_desc: "وب‌سایت TrueUSD",
       
        verification_desc: "از طریق لینک تأیید صحت، تأیید کنید. روی این لینک کلیک کنید تا آدرس ایمیل خود را تأیید کنید، سپس تنظیم کنید",
        information_desc: "اطلاعات خود و/یا سازمان خود را پر کنید. برای لیست اسناد مورد نیاز، کلیک کنید",
        here_desc: "اینجا",
        application_desc: "درخواست خود را ارسال کنید. ما درخواست شما را بررسی خواهیم کرد.",
        Redeem_desc: "ماینینگ و بازخرید استیبل‌کوین ما بدون هزینه در یک کلیک",
        Trade_desc: "TrueUSD را در بیش از 160 بازار در چندین صرافی و بیش از 20 میز OTC در 5 قاره معامله کنید",
        Send_desc: "TrueUSD را به هر کسی، در هر زمان و هر مکان ارسال کنید، 100 برابر ارزان‌تر و 1000 برابر سریع‌تر از انتقال بانکی",
        mint_desc: "س3. چگونه TrueUSD را ماین و بازخرید کنم؟",
        partner_desc1: "یک انتقال بانکی به شرکای بانکی ما ارسال کنید، با استفاده از اطلاعات نمایش داده شده در",
        Trueapp_desc: "اپلیکیشن TrueUSD",
        Please_desc: "توجه: در حال حاضر ما ACH یا خدمات انتقال ارز دیجیتال دیگر مانند Venmo، TransferWise، PayPal، Revolut و غیره را نمی‌پذیریم.",
        account_desc: "نام روی حساب بانکی شما باید با نام روی حساب TrueUSD شما مطابقت داشته باشد.",
        creating_desc: "پس از ایجاد حساب خود، یک ایمیل از",
        generally_desc: "به طور کلی، انتقالات بانکی بین بانک‌ها معمولاً 1-5 روز کاری طول می‌کشد.",
        email_desc: "پس از دریافت انتقال بانکی شما، یک ایمیل تأیید به شما ارسال خواهیم کرد.",
        minted_desc: "پس از دریافت وجوه شما، TrueUSD در عرض 1 روز کاری ماین و به آدرس کیف پول ارائه شده در اپلیکیشن منتقل خواهد شد.",
        redeem4_desc: "س4. چگونه TrueUSD را بازخرید کنم؟",
        Visit_desc: "بازدید از",
        Polygon_desc1: "اپلیکیشن TrueUSD",
        receive_desc: "برای وارد کردن اطلاعات بانکی خود و دریافت آدرس بازخرید منحصر به فرد خود. انتقالات ارز فیات تحت الزامات KYC و AML شرکای بانکی ما است.",
        redemption_desc: "این آدرس بازخرید برای هر کاربر TrueUSD منحصر به فرد است. آدرس بازخرید منحصر به فرد خود را با دیگران به اشتراک نگذارید.",
        simple_desc: "چرا آدرس بازخرید من اینقدر ساده است؟",
        notice_desc: "خواهید دید که این آدرس بسیار ساده است، با تعداد زیادی '0'. این عمدی است. شما می‌توانید از هر کیف پولی (از جمله کیف پول‌های صرافی) به این آدرس بازخرید کنید، و تمام بازخریدهایی که به این آدرس دریافت می‌شود با حساب TrueUSD شما مرتبط خواهد بود.",
        unique_desc: "TrueUSD را به آدرس بازخرید منحصر به فرد خود ارسال کنید (حداقل $1,000).",
        partner_desc: "پس از ارسال موفقیت‌آمیز توکن‌ها به آدرس بازخرید شما، شرکای بانکی ما معمولاً در عرض 1 روز کاری یک انتقال بانکی به حساب بانکی شما ارسال می‌کنند.",
        exchanges_desc: "س5. TrueUSD (TUSD) در کدام صرافی‌ها فهرست شده است؟",
        Binance_desc: "در حال حاضر TrueUSD در چندین صرافی، از جمله Binance، Bittrex و Upbit فهرست شده است.",
        here2_desc: "اینجا",
        markets_desc: "برای دریافت لیست کامل صرافی‌ها و بازارهای فعال TrueUSD.",
        contract_desc: "س6. آدرس قرارداد هوشمند TrueUSD چیست؟",
        tokenSmart_desc: "قراردادهای هوشمند ما را می‌توان از طریق شبکه استقرار محلی TUSD و شبکه پل TUSD یا مرورگر زنجیره Binance پیدا کرد. لینک‌ها در زیر آمده است. همچنین می‌توانید در مورد قراردادهای هوشمند ما و به‌روزرسانی‌های قرارداد هوشمند در",
        blog_desc: "وبلاگ",
        related_desc: "ما بیشتر بدانید.",
        Natively_desc: "شبکه استقرار محلی TUSD",
        Ethererum2_desc: "اتریوم",
        SmartChain_desc: "زنجیره هوشمند BNB:",
       
        Avalanche2_desc: "Avalanche",
        Beacon_desc: "زنجیره Beacon BNB:",
        Networks2_desc: "شبکه پل TUSD",
        Polygon2_desc: "Polygon",
        Fantom2_desc: "Fantom",
       
        Polygon_desc2: "آپتیمیزم",
        ChainOld_desc: "زنجیره هوشمند BNB (قدیمی):",
        AboutMore_desc: "بیشتر درباره TUSD بدانید",
        Digital_desc: "ارز دیجیتال برای سیستم پرداخت جهانی جدید",
        first_desc: "TrueUSD اولین استیبل‌کوین پیوسته به دلار است که توسط یک مؤسسه مستقل ثالث ذخایر پایه خود را به صورت روزانه حسابرسی می‌کند.",
        Polygon_desc: "Polygon",
        Follow_desc: "ما را دنبال کنید",
        Resources_desc: "منابع",
        Stablecoin_desc: "TrueUSD اولین استیبل‌کوین پشتیبانی شده توسط دلار شد که از طریق 'Proof of Reserves' ماینینگ را تضمین می‌کند",
        Engages_desc: "TUSD با مؤسسه حسابرسی برتر MooreHK همکاری می‌کند تا خدمات تأیید ذخایر فیات خود را فراهم کند",
        Becomes_desc: "TUSD (TRC-20) به ارز قانونی در دومینیکا تبدیل می‌شود",
        Blog_desc: "وبلاگ",
        Support_desc: "پشتیبانی",
        TermsOf_desc: "شرایط استفاده",
        Privacy_desc: "سیاست حفظ حریم خصوصی",
        TrueUsd_desc: "TrueUSD",
        Home_desc: "صفحه اصلی",
        Transparency_desc: "شفافیت",
        Ecosystem_desc: "اکوسیستم",
        About_desc: "درباره",
        Supported_desc: "زنجیره‌های پشتیبانی‌شده",
        Other_desc: "زنجیره‌های دیگر",
        rights_desc: "© 2023 TrueUSD. همه حقوق محفوظ است.",
        Back_desc: "بازگشت به بالا"
      },
      project_desc: {
        Pool_desc: "استخر سرمایه",
        participating_desc: "با شرکت در توکن‌ها، شما دریافت خواهید کرد",
        rewards_desc: "به عنوان پاداش.",
        EndTime_desc: "زمان پایان پروژه",
        duration_desc: "مدت زمان استیکینگ",
        days_desc: "روزها",
        calculation_desc: "محاسبه دوره‌ای",
        Invite_desc: "دعوت",
        level_desc: "کمیسیون سطح",
        withdrawal_desc: "حداقل مبلغ برداشت",
        supply_desc: "حداقل مبلغ عرضه",
        period_desc: "حداقل دوره برداشت",
        Invitations_desc: "سطح دعوت",
        People_desc: "تعداد افراد"
      },
      capitaInfoView_desc: {
        Please_desc: "لطفاً آدرس را متصل کنید",
        Pool_desc: "استخر سرمایه",
        receive_desc: "با شرکت در توکن‌ها، شما دریافت خواهید کرد",
        rewards_desc: "به عنوان پاداش.",
        level_desc: "استخر سرمایه سطح",
        Expired_desc: "منقضی شده",
        Unexpired_desc: "منقضی نشده",
        Participated_desc: "شرکت کرده",
        uncommitted_desc: "متعهد نشده",
        Withdraw_desc: "برداشت",
        Redeem_desc: "بازخرید",
        Status_desc: "وضعیت",
        Principal_desc: "اصل سرمایه",
        Redeemed_desc: "بازخرید شده",
        subaccounts_desc: "حساب‌های فرعی",
        Supplied_desc: "عرضه شده",
        Redeemable_desc: "قابل بازخرید",
        Commission_desc: "کمیسیون",
        Available_desc: "قابل برداشت",
        Withdrawed_desc: "برداشت شده",
        Lock_desc: "قفل کمیسیون",
        All_desc: "تمام کمیسیون‌ها",
        interest_desc: "بهره",
        Settled_desc: "تسویه شده",
        Loading_desc: "در حال بارگذاری",
        amount_desc: "مبلغ قابل برداشت"
      },
      elModal_desc: {
        Please_desc: "لطفاً مبلغ واریز را وارد کنید",
        Recharge_desc: "شارژ مجدد",
        Cancel_desc: "لغو",
        withdrawal_desc: "لطفاً مبلغ برداشت را وارد کنید",
        Withdraw_desc: "برداشت",
        redemption_desc: "لطفاً مبلغ بازخرید را وارد کنید",
        Redeem_desc: "بازخرید",
        Withdrawable_desc: "مبلغ قابل برداشت (کمیسیون + بهره):",
        balance_desc: "موجودی قابل بازخرید:",
        input_desc: "لطفاً مبلغ را وارد کنید",
        Transaction_desc: "تراکنش موفق!",
        failed_desc: "تراکنش ناموفق",
        funds_desc: "موجودی حساب کافی نیست",
        Loading_desc: "در حال بارگذاری"
      },
    modal: {
        hold_desc: 'اگر شما مقداری از BNB Smart Chain را نگه دارید، شما یک پاداش اضافی خواهید داشت.',
        Smart_desc: 'پاداش‌ها تنها برای مقادیری بیشتر از',
        Rewards_desc: 'موجود است.',
        address_desc: 'آدرس',
        hours_desc: '24 ساعت نگه‌داری می‌کند و حداقل نگهداری بر اساس لحظه‌ای تصادفی از سیستم است. مقدار هر 24 ساعت یکبار تخصیص داده می‌شود. تخصیص واقعی به حساب اعمال می‌شود.'
    },
    UserInfo: {
        Wallets_desc: 'کیف پول',
        Reward_desc: 'پاداش',
        half_month: '۱۵ روز',
        one_month: '۱ ماه',
        three_month: '۳ ماه',
        Invitees: 'مهمانان',
        no_data: 'هیچ داده‌ای وجود ندارد',
        walletNo_support: 'کیف پول شما پشتیبانی نمی‌شود. لطفاً از برنامه کیف پول دیگری استفاده کنید.',
        network_wrong: 'لطفاً شبکه صحیح را انتخاب کنید',
      },
}
export default en_BS
// Datajson:{
//     language_json:{
//         zh_json:'چینی ساده',
//         zh_FT_json: 'چینی سنتی',
//         eh_json:'انگلیسی',
//         ja_JP_json:'ژاپنی',  
//         ko_KR_json:'کوریه' ,
//         en_TH_json:'یName',
//         fr_FR_json:'فرانسوی',
//         ru_RU_json:'روسی',
//         en_IN_json:'هندي',
//         en_ID_json:'IndonesiaName',
//         en_BS_json:'فارسی',
//         en_YN_json:'ویتنامیName',
//         en_ALB_json:'عربی',
//         en_TEQ_json:'ترکیName',
//         en_ES_json:'اسپانیایName',
//         en_PTY_json:'پرتغالی',
//         en_YDL_json:'ایتالیایName'
//     },
// },