const en_PTY = {
    Datajson:{
        language_json:{
            zh_json:'中文简体',
            zh_FT_json: '中文繁體',
            eh_json:'English',
            ja_JP_json:'日本語',  
            ko_KR_json:'한국어' ,
            en_TH_json:'ภาษาไทย',
            fr_FR_json:'En français',
            ru_RU_json:'русск',
            en_IN_json:'हिंदीName',
            en_ID_json:'Bahasa indonesia',
            en_BS_json:'فارسی',
            en_YN_json:'Việt nam',
            en_ALB_json:' بالعربية ',
            en_TEQ_json:'Türkçe',
            en_ES_json:'Español',
            en_PTY_json:'Português',
            en_YDL_json:'Italiano'
        },
    },
    home: {
        language: 'idioma',
        fduse_desc: 'FDUSD é uma Stablecoin respaldada 1:1 pelo dólar americano.',
        introduce_desc: 'Revolutionize as finanças globais com uma Stablecoin digital de ponta, proporcionando segurança, velocidade e inovação a empresas e desenvolvedores em todo o mundo.',
        getfduse_desc: 'Obter FDUSD',
        stability_desc: 'Estabilidade Encontra Inovação',
        experience_desc: 'Experimente velocidades ultrarrápidas e custos mínimos. Molde o futuro das finanças com nossa solução inovadora e confiável.',
        whitepaper_desc: 'Whitepaper',
        redeemable_desc: 'Resgatável 1:1 por dólares americanos',
        dollars_desc: 'O FDUSD destina-se a ser totalmente respaldado por dinheiro e ativos equivalentes em dinheiro. Os tokens destinam-se a serem resgatados 1:1 por dólares americanos.',
        backed_desc: 'Respaldado por ativos totalmente reservados',
        deserved_desc: 'Os ativos reservados são mantidos em estruturas de custódia totalmente segregadas e à prova de falências.',
        programmable_desc: 'Totalmente Programável',
        innovative_desc: 'Com nossa solução inovadora de stablecoin - respaldada por tecnologia avançada, nosso objetivo é fornecer uma alternativa segura e confiável que traga tranquilidade, causando um impacto positivo no mundo das finanças.',
        settle_desc: 'Liquidar em minutos',
        nearZero_desc: 'Custo próximo de zero',
        always_desc: 'Sempre ativo, 24/7',
        fully_desc: 'Totalmente programável',
        rewrite_desc: 'Reescreva o Futuro com a First Digital Labs',
        combining_desc: 'Combinando estabilidade com inovação na First Digital Labs, estamos comprometidos em conquistar confiança oferecendo transparência, conformidade e segurança inabaláveis, respaldadas por tecnologia de ponta.',
        mission_desc: 'Estamos em uma missão para impulsionar a indústria financeira para uma nova era das finanças e remodelar o cenário financeiro global.',
        should_desc: 'Por que você deve usar o First Digital USD?',
        collateral_desc: 'A garantia é protegida por um custodiante qualificado de alto nível com uma licença fiduciária para proteção contínua dos ativos e conformidade regulatória.',
        transparent_desc: 'Transparente',
        independent_desc: 'Atestação independente - a garantia é validada por uma terceira parte independente, garantindo a integridade das reservas e relatórios financeiros.',
        examined_by_desc: 'Examinado por',
        attestation_Reports_desc: 'Relatórios de Atestação',
        January2024_desc: 'Janeiro de 2024',
        Report_desc: 'Relatório',
        December_desc: 'Dezembro de 2023',
        November_desc: 'Novembro de 2023',
        Networks_desc: 'Quais redes são suportadas?',
        available_desc: 'O FDUSD está disponível em',
        Ethereum_desc: 'Ethereum',
        and_desc: 'e',
        BNB_Chain_desc: 'BNB Chain',
        planned_desc: 'com suporte planejado para um número crescente de blockchains.',
        Audited_desc: 'Auditado por',
        Frequently_desc: 'Perguntas Frequentes',
        How_FDUSD_desc: 'Como posso obter FDUSD?',
        Acquiring_desc: 'Aquisição de FDUSD pode ser feita de várias maneiras. Se você é um jogador-chave da indústria, um intermediário financeiro ou um investidor profissional de determinado estatuto e atende aos critérios necessários, você pode comprar diretamente FDUSD da First Digital Labs. Observe que a First Digital Labs não vende tokens diretamente para clientes de varejo.:',
        canstill_desc: 'Você ainda pode obter FDUSD através de mercados secundários sem se tornar um cliente da First Digital Labs. Muitas das principais exchanges de criptomoedas listam FDUSD e oferecem liquidez substancial. Isso proporciona uma maneira acessível e conveniente para que pessoas interessadas adquiram FDUSD. No entanto, é sempre essencial realizar pesquisas minuciosas e garantir a credibilidade da exchange que você está usando.',
        equivalent_desc: 'Como posso confiar que o FDUSD sempre terá o valor equivalente a um dólar?',
       // stablecoin_desc: 'Sua confiança no valor estável do FDUSD é bem fundamentada. Cada FDUSD destina-se a ser totalmente respaldadopor um dólar dos Estados Unidos ou um ativo de valor justo equivalente. Esses ativos são mantidos com segurança por um custodiante qualificado em contas de instituições depositárias regulamentadas, proporcionando uma base sólida para o valor da moeda. Esse sistema é o que garante a estabilidade intrínseca do FDUSD e por isso você pode confiar em seu valor equivalente ao dólar dos Estados Unidos.',
        redeem_FDUSD: 'Como posso resgatar o FDUSD?',
       // stablecoin_desc: 'Para resgatar sua stablecoin FDUSD, você primeiro precisa se tornar cliente da First Digital Labs e atender a requisitos específicos, incluindo verificações de Anti-Lavagem de Dinheiro (AML) e de Combate ao Financiamento do Terrorismo (CFT). Após a conclusão bem-sucedida dessas verificações, você pode trocar seus FDUSD por seu equivalente em moeda fiduciária, retirando-o de circulação. Alternativamente, você pode vender seus tokens FDUSD no mercado secundário por meio de uma exchange de criptomoedas ou um provedor Over-the-Counter (OTC) que ofereça suporte às stablecoins FD121. Esteja ciente de todas as regulamentações legais e financeiras em sua jurisdição antes de prosseguir.',
        Explore_desc: "Explore nosso Ecossistema",
        Experience_desc: 'Experimente a estabilidade com nossa solução confiável de stablecoin.',
        slide_desc: 'slide',
        _1to6_desc: '1 a 6',
        of7_desc: 'de 7',
        Transparency_desc: 'Transparência',
        Legal_desc: 'Legal',
        Compliance_desc: 'Conformidade',
        Policy_desc: 'Política de Privacidade',
        Company_desc: 'Empresa',
        About_Us_desc: 'Sobre Nós',
        Social_Media_desc: 'Mídias Sociais',
        Twitter_desc: 'Twitter',
        Disclosures_desc: 'Divulgações Importantes:',
        Limited_desc: 'FD121 Limited é uma empresa de tecnologia financeira, não um banco. Os serviços de custódia dos ativos são fornecidos por',
        First_desc: "First Digital Trust Limited",
        product_desc: 'Todos os nomes de produtos e empresas são marcas comerciais™ ou marcas comerciais registradas® de seus respectivos titulares. Todos os nomes de empresas, produtos e serviços usados neste site são apenas para fins de identificação. O uso desses nomes, marcas comerciais e marcas não implica nenhuma afiliação ou endosso por parte deles.',
        additional_desc: 'Para riscos, divulgações e informações importantes adicionais, visite',
        Copyright_desc: 'Direitos Autorais © 2024 FD121 Limited. Todos os direitos reservados.'
    },
    newhome_json: {
        Exchanges_json: "Bolsas",
        DeFi_json: "Finanças Descentralizadas",
        Payments_json: "Pagamentos",
        Accessibility_json: "Acessibilidade",
        listed_json: "TUSD está listado em várias bolsas líderes da indústria, fornecendo liquidez e acessibilidade para todos os investidores.",
        stablecoin_json: "Como uma stablecoin amplamente utilizada, o TUSD foi adotado de forma significativa em vários protocolos de finanças descentralizadas (DeFi), incluindo formadores de mercado automatizados (AMM), plataformas de empréstimo e projetos de yield farming (YIF).",
        leading_json: "Como a principal stablecoin em yuan, o TUSD é um meio ideal para transações transfronteiriças. Com as baixas taxas de transação e a velocidade instantânea do TUSD, tanto indivíduos quanto instituições podem realizar transações sem os desafios financeiros tradicionais.",
        available_json: "O TUSD também está disponível nas principais blockchains, como Ethereum, BNB Smart Chain, TRON e Avalanche. A rede de pontes do TUSD inclui Polygon, Fantom, Arbitrum, Cronos e mais.",
        error1_json: "Não foi possível conectar ao nó. Altere o nó e tente novamente",
        error2_json: "Erro de cadeia",
        error3s_json: "Por favor, conecte seu endereço",
        Loading1_json: "Carregando",
        Loading2_json: "Carregando informações do projeto",
        error3_json: "Erro de rede",
        error4_json: "Conexão da carteira recusada",
        error5_json: "Versão antiga da carteira",
        error6_json: "Conexão da carteira recusada",
        Loading3_json: "Entrando",
        error7_json: "Erro de conta",
        error8_json: "Não foi possível conectar ao nó. Altere o nó e tente novamente",
        error9_json: "Erro de rede",
        Loading4_json: "Fazendo staking",
        error10_json: "Você foi bloqueado",
        error11_json: "Erro de projeto",
        error12_json: "Gás insuficiente",
        success1_json: "Sucesso",
        error13_json: "Falha ao obter conta",
        error14_json: "Erro desconhecido",
        error15_json: "Não foi possível conectar ao nó. Altere o nó e tente novamente",
        
      },
      newhome_desc: {
        title_desc: "Moeda digital criada para o novo sistema de pagamentos global",
        desc_desc: "TrueUSD é a primeira stablecoin atrelada ao dólar que realiza auditorias diárias de suas reservas por uma instituição terceirizada independente.",
        Started_desc: "Começar",
        Contact_desc: "Contate-Nos",
        Circulating_desc: "Oferta em circulação",
        Trading_desc: "Volume de negociação",
        Transaction_desc: "Número total de transações",
        Support_desc1: "Total de cadeias suportadas",
        Trustworthy_desc: "Confiável",
        utilizes_desc: "O TUSD utiliza mecanismos avançados de auditoria e certificação para aumentar a transparência. Seus relatórios de auditoria diários estão disponíveis no site, permitindo que os usuários monitorem as reservas a qualquer momento.",
        Popularity_desc: "Popularidade",
        convenience_desc: "O TUSD oferece conveniência e utilidade em vários cenários financeiros, como negociações e transferências. Atualmente, o TUSD pode ser utilizado em mais de 80 bolsas de criptomoedas e protocolos DeFi, proporcionando flexibilidade e acessibilidade aos usuários.",
        Liquidity_desc: "Liquidez",
        During_desc: "No terceiro trimestre de 2023, o volume de negociação diário do TUSD superou 1 bilhão de dólares, mostrando sua incrível liquidez. Além disso, o TUSD não cobra nenhuma taxa para minting e resgate.",
        Trusted_desc: "Confiado por líderes globais",
        Using_desc: "Usando TUSD",
        LearnMore_desc: "Saiba Mais",
        Goes_desc: "O TUSD está indo para multi-chain",
        Natively_desc1: "Rede de implantação nativa",
        Ethereum_desc: "Ethereum",
        TRON_desc: "TRON",
        Avalanche_desc: "Avalanche",
        Smart_desc: "BNB Smart Chain",
        Bridged_desc: "Rede de pontes",
        Fantom_desc: "Fantom",
        Arbitrum_desc: "Arbitrum",
        Cronos_desc: "Cronos",
        Optimism_desc: "Optimism",
        Aurora_desc: "Aurora",
        FAQs_desc: "Perguntas Frequentes",
        TrueUSD_desc: "Q1. O que é TrueUSD?",
        Banking_desc: "TrueUSD utiliza instituições financeiras globais e uma infraestrutura blockchain robusta. É a primeira stablecoin que utiliza contratos inteligentes para minting/resgate, com suporte para verificação de cobertura de reservas em tempo real on-chain, apoiada pela empresa de contabilidade líder mundial Moore Hong Kong e Chainlink Proof of Reserve.",
        started_desc: "Q2. Como começar a usar TrueUSD?",
        stepsbelow_desc: "Para começar a usar TrueUSD, siga os passos abaixo:",
        verify_desc: "Após criar e verificar sua conta através do site TrueUSD, você receberá um e-mail de",
        website_desc: "site TrueUSD",
       
        verification_desc: "Verifique através do link de verificação. Clique neste link para verificar seu endereço de e-mail, depois configure",
        information_desc: "Preencha as informações sobre você e/ou sua organização. Para uma lista dos documentos necessários, clique em",
        here_desc: "aqui",
        application_desc: "Envie sua aplicação. Revisaremos sua aplicação.",
        Redeem_desc: "Minting e resgate de nossa stablecoin sem taxas com um clique",
        Trade_desc: "Negocie TrueUSD em mais de 160 mercados em várias bolsas e mais de 20 mesas OTC em 5 continentes",
        Send_desc: "Envie TrueUSD para qualquer pessoa, a qualquer momento, de forma 100 vezes mais barata e 1000 vezes mais rápida que uma transferência bancária",
        mint_desc: "Q3. Como mintar e resgatar TrueUSD?",
        partner_desc1: "Envie uma transferência bancária para nossos parceiros bancários, usando as informações exibidas no",
        Trueapp_desc: "aplicativo TrueUSD",
        Please_desc: "Observe: atualmente não aceitamos ACH ou outros serviços de transferência de moeda digital, como Venmo, TransferWise, PayPal, Revolut, etc.",
        account_desc: "O nome na sua conta bancária deve corresponder ao nome na sua conta TrueUSD.",
        creating_desc: "Após criar sua conta, você receberá um e-mail de",
        generally_desc: "Geralmente, transferências bancárias entre bancos levam de 1 a 5 dias úteis para serem concluídas.",
        email_desc: "Assim que recebermos sua transferência bancária, enviaremos um e-mail de confirmação.",
        minted_desc: "Assim que recebermos seus fundos, o TrueUSD será mintado e transferido para o endereço da carteira fornecido no aplicativo dentro de um dia útil.",
        redeem4_desc: "Q4. Como resgatar TrueUSD?",
        Visit_desc: "Visite o",
        Polygon_desc1: "aplicativo TrueUSD",
        receive_desc: "para inserir suas informações bancárias e obter seu endereço de resgate único. Transferências de moeda fiduciária estão sujeitas aos requisitos de KYC e AML de nossos parceiros bancários.",
        redemption_desc: "Este endereço de resgate é único para cada usuário TrueUSD. Não compartilhe seu endereço de resgate único com outros.",
        simple_desc: "Por que meu endereço de resgate é tão simples?",
        notice_desc: "Você notará que este endereço é muito simples, com muitos '0'. Isso é intencional. Você pode resgatar de qualquer carteira (incluindo carteiras de bolsa) para este endereço, e todos os resgates recebidos neste endereço serão vinculados à sua conta TrueUSD.",
        unique_desc: "Envie TrueUSD para seu endereço de resgate único (mínimo $1,000).",
        partner_desc: "Uma vez que os tokens sejam enviados com sucesso para seu endereço de resgate, nossos parceiros bancários geralmente emitem uma transferência bancária para sua conta bancária dentro de um dia útil.",
        exchanges_desc: "Q5. Onde TrueUSD (TUSD) está listado?",
        Binance_desc: "Atualmente, TrueUSD está listado em várias bolsas, incluindo Binance, Bittrex e Upbit.",
        here2_desc: "aqui",
        markets_desc: "para obter a lista completa de bolsas e mercados ativos de TrueUSD.",
        contract_desc: "Q6. Qual é o endereço do contrato inteligente de TrueUSD?",
        tokenSmart_desc: "Nossos contratos inteligentes podem ser encontrados através da rede de implantação nativa do TUSD e da rede de pontes do TUSD ou do explorador da Binance Chain. Os links estão abaixo. Você também pode saber mais sobre nossos contratos inteligentes e atualizações de contratos inteligentes em nosso",
        blog_desc: "blog",
        related_desc: ".",
        Natively_desc: "Rede de implantação nativa do TUSD",
        Ethererum2_desc: "Ethereum",
        SmartChain_desc: "BNB Smart Chain:",
      
        Avalanche2_desc: "Avalanche",
        Beacon_desc: "BNB Beacon Chain:",
        Networks2_desc: "Rede de pontes do TUSD",
        Polygon2_desc: "Polygon",
        Fantom2_desc: "Fantom",
       
        Polygon_desc2: "Optimism",
        ChainOld_desc: "BNB Smart Chain (antigo):",
        AboutMore_desc: "Saiba mais sobre TUSD",
        Digital_desc: "Moeda digital para o novo sistema de pagamentos global",
        first_desc: "TrueUSD é a primeira stablecoin atrelada ao dólar que realiza verificações diárias das reservas por uma instituição terceirizada independente.",
        Polygon_desc: "Polygon",
        Follow_desc: "Siga-nos",
        Resources_desc: "Recursos",
        Stablecoin_desc: "TrueUSD se torna a primeira stablecoin garantida pelo dólar que assegura a mintagem através da 'Proof of Reserves'",
        Engages_desc: "TUSD colabora com a firma de contabilidade líder MooreHK para fornecer serviços de verificação de suas reservas fiduciárias",
        Becomes_desc: "TUSD (TRC-20) se torna a moeda oficial em Dominica",
        Blog_desc: "Blog",
        Support_desc: "Suporte",
        TermsOf_desc: "Termos de Uso",
        Privacy_desc: "Política de Privacidade",
        TrueUsd_desc: "TrueUSD",
        Home_desc: "Início",
        Transparency_desc: "Transparência",
        Ecosystem_desc: "Ecossistema",
        About_desc: "Sobre",
        Supported_desc: "Cadeias Suportadas",
        Other_desc: "Outras Cadeias",
        rights_desc: "© 2023 TrueUSD. Todos os direitos reservados.",
        Back_desc: "Voltar ao Topo"
      },
      project_desc: {
        Pool_desc: "Fundo",
        participating_desc: "Ao participar dos tokens, você receberá",
        rewards_desc: "como recompensa.",
        EndTime_desc: "Hora de Término do Projeto",
        duration_desc: "Duração do Staking",
        days_desc: "dias",
        calculation_desc: "Cálculo Periódico",
        Invite_desc: "Convidar",
        level_desc: "Comissão de Nível",
        withdrawal_desc: "Montante Mínimo de Retirada",
        supply_desc: "Montante Mínimo de Fornecimento",
        period_desc: "Período Mínimo de Retirada",
        Invitations_desc: "Nível de Convites",
        People_desc: "Número de Pessoas"
      },
      capitaInfoView_desc: {
        Please_desc: "Por favor, conecte o endereço",
        Pool_desc: "Fundo",
        receive_desc: "Ao participar dos tokens, você receberá",
        rewards_desc: "como recompensa.",
        level_desc: "Fundo de Nível",
        Expired_desc: "Expirado",
        Unexpired_desc: "Não Expirado",
        Participated_desc: "Participado",
        uncommitted_desc: "Não Comprometido",
        Withdraw_desc: "Retirar",
        Redeem_desc: "Resgatar",
        Status_desc: "Status",
        Principal_desc: "Principal",
        Redeemed_desc: "Resgatado",
        subaccounts_desc: "Subcontas",
        Supplied_desc: "Fornecido",
        Redeemable_desc: "Resgatável",
        Commission_desc: "Comissão",
        Available_desc: "Disponível para Retirada",
        Withdrawed_desc: "Retirado",
        Lock_desc: "Comissão Bloqueada",
        All_desc: "Todas as Comissões",
        interest_desc: "Juros",
        Settled_desc: "Liquidado",
        Loading_desc: "Carregando",
        amount_desc: "Montante Disponível para Retirada"
      },
      elModal_desc: {
        Please_desc: "Por favor, insira o valor do depósito",
        Recharge_desc: "Recarregar",
        Cancel_desc: "Cancelar",
        withdrawal_desc: "Por favor, insira o valor da retirada",
        Withdraw_desc: "Retirar",
        redemption_desc: "Por favor, insira o valor do resgate",
        Redeem_desc: "Resgatar",
        Withdrawable_desc: "Montante Disponível para Retirada (comissão + juros):",
        balance_desc: "Saldo Resgatável:",
        input_desc: "Por favor, insira o valor",
        Transaction_desc: "Transação bem-sucedida!",
        failed_desc: "Transação falhou",
        funds_desc: "Saldo insuficiente na conta",
        Loading_desc: "Carregando"
      },
    modal: {
        hold_desc: 'Se você possuir',
        Smart_desc: 'da BNB Smart Chain, você receberá uma recompensa de bônus,',
        Rewards_desc: 'As recompensas estão disponíveis apenas para valores superiores a',
        address_desc: 'O endereço possui',
        hours_desc: 'por 24 horas, e a quantidade mínima é baseada no snapshot aleatório do sistema. O valor é alocado, uma vez a cada 24 horas. A alocação real para a conta prevalecerá.'
    },
    UserInfo: {
        Wallets_desc: 'Carteiras',
        Reward_desc: 'Recompensa',
        half_month: '15 Dias',
        one_month: '1 mês',
        three_month: '3 meses',
        Invitees: 'Convidados',
        no_data: 'Sem Dados',
        walletNo_support: 'Sua carteira não é suportada. Por favor, use outro aplicativo de carteira.',
        network_wrong: 'Por favor, selecione a rede correta',
      },
}
export default en_PTY

// Datajson:{
//     language_json:{
//         zh_json:'Chinês simplificado',
//         zh_FT_json: 'Chinês tradicional',
//         eh_json:'Inglês',
//         ja_JP_json:'Japonês',  
//         ko_KR_json:'Coreano' ,
//         en_TH_json:'Tailandês',
//         fr_FR_json:'Francês',
//         ru_RU_json:'Russo',
//         en_IN_json:'Hindi',
//         en_ID_json:'Indonésio',
//         en_BS_json:'Persa',
//         en_YN_json:'Vietnamita',
//         en_ALB_json:'Árabe',
//         en_TEQ_json:'Turco',
//         en_ES_json:'Espanhol',
//         en_PTY_json:'Português',
//         en_YDL_json:'Italiano'
//     },
// },