const en_ALB = {
  Datajson: {
    language_json: {
      zh_json: "中文简体",
      zh_FT_json: "中文繁體",
      eh_json: "English",
      ja_JP_json: "日本語",
      ko_KR_json: "한국어",
      en_TH_json: "ภาษาไทย",
      fr_FR_json: "En français",
      ru_RU_json: "русск",
      en_IN_json: "हिंदीName",
      en_ID_json: "Bahasa indonesia",
      en_BS_json: "فارسی",
      en_YN_json: "Việt nam",
      en_ALB_json: " بالعربية ",
      en_TEQ_json: "Türkçe",
      en_ES_json: "Español",
      en_PTY_json: "Português",
      en_YDL_json: "Italiano",
    },
  },
  home: {
    language: "اللغة",
    fduse_desc: "FDUSD هو عملة مستقرة مرتبطة بالدولار الأمريكي بنسبة 1:1.",
    introduce_desc:
      "قم بثورة في الأمور المالية العالمية مع عملة مستقرة رقمية مبتكرة توفر الأمان والسرعة والابتكار في جميع أنحاء العالم.",
    getfduse_desc: "احصل على FDUSD",
    stability_desc: "الاستقرار يلتقي بالابتكار",
    experience_desc:
      "استمتع بسرعة فائقة وتكاليف محدودة. شكل مستقبل الأمور المالية مع حلنا المبتكر والموثوق.",
    whitepaper_desc: "الورقة البيضاء",
    redeemable_desc: "قابلة للتحويل 1:1 إلى الدولار الأمريكي",
    dollars_desc:
      "تم تصميم FDUSD لتكون مدعومة بالكامل بالنقد وأصول مكافئة للنقد. يتم تصميم الرموز لتكون قابلة للتحويل 1:1 إلى الدولار الأمريكي.",
    backed_desc: "مدعومة بأصول محجوزة بالكامل",
    deserved_desc:
      "تحتفظ الأصول المحجوزة في هياكل محجوزة بشكل كامل ومنعزلة عن الإفلاس.",
    programmable_desc: "قابلة للبرمجة بالكامل",
    innovative_desc:
      "مع حلنا المستقر المبتكر - الذي يدعمه تكنولوجيا متقدمة - نهدف إلى توفير بديل آمن وموثوق يوفر الطمأنينة ويحقق تأثيرًا إيجابيًا في عالم المال.",
    settle_desc: "استوطن في دقائق",
    nearZero_desc: "تكلفة قريبة من الصفر",
    always_desc: "على مدار الساعة طوال أيام الأسبوع",
    fully_desc: "قابل للبرمجة بالكامل",
    rewrite_desc: "إعادة كتابة المستقبل مع First Digital Labs",
    combining_desc:
      "من خلال الجمع بين الاستقرار والابتكار في First Digital Labs ، نحن ملتزمون بكسب الثقة من خلال توفير شفافية لا تتزعزع وامتثال وأمان يدعمه التكنولوجيا المتقدمة.",
    mission_desc:
      "نحن في مهمة لدفع صناعة المال إلى عصر مالي جديد وإعادة تشكيل المشهد المالي العالمي.",
    should_desc: "لماذا يجب عليك استخدام First Digital USD؟",
    collateral_desc:
      "تتم حماية الرهن العقاري بواسطة حارس أمان مؤهل من الدرجة الأولى يحمل ترخيص الثقة لحماية الأصول بسلاسة والامتثال للتنظيم.",
    transparent_desc: "شفاف",
    independent_desc:
      "الشهادة المستقلة - يتم التحقق من صحة الرهن العقاري من قبلجهة خارجية مستقلة، مما يضمن سلامة الاحتياطيات وتقارير المالية.",
    examined_by_desc: "فحص بواسطة",
    attestation_Reports_desc: "تقارير التصديق",
    January2024_desc: "يناير 2024",
    Report_desc: "تقرير",
    December_desc: "ديسمبر 2023",
    November_desc: "نوفمبر 2023",
    Networks_desc: "ما هي الشبكات المدعومة؟",
    available_desc: "يتوفر FDUSD على",
    Ethereum_desc: "إيثيريوم",
    and_desc: "و",
    BNB_Chain_desc: "BNB Chain",
    planned_desc: "مع دعم مخطط لعدد متزايد من سلاسل الكتل.",
    Audited_desc: "تمت مراجعته من قبل",
    Frequently_desc: "الأسئلة المتكررة",
    How_FDUSD_desc: "كيف يمكنني الحصول على FDUSD؟",
    Acquiring_desc:
      "يمكن الحصول على FDUSD بعدة طرق. إذا كنت لاعبًا رئيسيًا في الصناعة أو وسيطًا ماليًا أو مستثمرًا محترفًا ذو مركزية معينة وتلبي الشروط المطلوبة ، يمكنك شراء FDUSD مباشرة من First Digital Labs. يرجى ملاحظة أن First Digital Labs لا تبيع الرموز مباشرة للعملاء التجزئة.:",
    canstill_desc:
      "لا يزال بإمكانك الحصول على FDUSD من خلال الأسواق الثانوية دون أن تصبح عميلًا لدى First Digital Labs. تقوم العديد من بورصات العملات المشفرة الرائدة بقائمة FDUSD وتقديم سيولة كبيرة. يوفر هذا وسيلة سهلة ومريحة للأفراد المهتمين بالحصول على FDUSD. ومع ذلك ، فمن الضروري دائمًا إجراء بحث شامل وضمان مصداقية البورصة التي تستخدمها.",
    equivalent_desc: "كيف يمكنني الثقة بأن FDUSD ستكون دائمًا معادلة للدولار؟",
    // stablecoin_desc:
    //   "ثقتك في قيمة عملة FDUSD المستقرة متينة. يتم تصميم كل FDUSD ليكون مدعومًا بالكامل بدولار أمريكي واحد أو أصل قيمته العادلة المعادلة. تحتفظ هذه الأصول بأمان بواسطة حارس أمان مؤهل في حسابات لديها مؤسسات إيداع منظمة، مما يوفر أساسًا قويًا لقيمة العملة. هذا هو النظام الذي يوفر لـ FDUSD استقراره الأساسي وسبب ثقتك في قيمتها المعادلة للدولار الأمريكي.",
    redeem_FDUSD: "كيف يمكنني استرداد FDUSD؟",
    // stablecoin_desc: "لاسترداد عملة FDUSD المستقرة الخاص",
  },
  newhome_json: {
    Exchanges_json: "البورصات",
    DeFi_json: "التمويل اللامركزي",
    Payments_json: "المدفوعات",
    Accessibility_json: "إمكانية الوصول",
    listed_json:
      "TUSD مدرج في العديد من البورصات الرائدة في الصناعة، مما يوفر السيولة وإمكانية الوصول لجميع المستثمرين.",
    stablecoin_json:
      "باعتبارها عملة مستقرة مستخدمة على نطاق واسع، تم اعتماد TUSD بشكل كبير في مختلف بروتوكولات التمويل اللامركزي (DeFi)، بما في ذلك صانعي السوق الآليين (AMM)، ومنصات الإقراض، ومشاريع الزراعة المربحة (YIF).",
    leading_json:
      "باعتبارها العملة المستقرة الرائدة للرنمينبي، تعد TUSD وسيلة مثالية للمعاملات عبر الحدود. بفضل رسوم المعاملات المنخفضة وسرعة التنفيذ الفوري لـ TUSD، يمكن للأفراد والمؤسسات إجراء المعاملات دون تحديات التمويل التقليدي.",
    available_json:
      "TUSD متاح أيضًا على سلاسل الكتل الرائدة مثل إيثريوم وسلسلة BNB الذكية وتيرون وأفالانش. تشمل شبكة الجسور الخاصة بـ TUSD بوليجون وفانتوم وأربتروم وكروتوس والمزيد.",
    error1_json: "لا يمكن الاتصال بالعقدة. حاول تغيير العقدة وإعادة المحاولة",
    error2_json: "خطأ في السلسلة",
    error3s_json: "يرجى توصيل عنوانك",
    Loading1_json: "جارٍ التحميل",
    Loading2_json: "جارٍ تحميل معلومات المشروع",
    error3_json: "خطأ في الشبكة",
    error4_json: "تم رفض الاتصال بالمحفظة",
    error5_json: "إصدار قديم من المحفظة",
    error6_json: "رفض_الاتصال_بالمحفظة",
    Loading3_json: "جارٍ تسجيل الدخول",
    error7_json: "خطأ في الحساب",
    error8_json: "لا يمكن الاتصال بالعقدة. حاول تغيير العقدة وإعادة المحاولة",
    error9_json: "خطأ في الشبكة",
    Loading4_json: "جارٍ الرهن",
    error10_json: "تم حظرك",
    error11_json: "خطأ في المشروع",
    error12_json: "غاز غير كافٍ",
    success1_json: "تم بنجاح",
    error13_json: "فشل في الحصول على الحساب",
    error14_json: "خطأ غير معروف",
    error15_json: "لا يمكن الاتصال بالعقدة. حاول تغيير العقدة وإعادة المحاولة",
   
  },
  newhome_desc: {
    title_desc: "عملة رقمية مبنية لنظام الدفع العالمي الجديد",
    desc_desc:
      "TrueUSD هي أول عملة مستقرة مربوطة بالدولار الأمريكي يتم نشرها بواسطة طرف ثالث مستقل يقوم بمراجعة احتياطياتها الأساسية يوميًا.",
    Started_desc: "ابدأ",
    Contact_desc: "اتصل بنا",
    Circulating_desc: "الإمداد المتداول",
    Trading_desc: "حجم التداول",
    Transaction_desc: "إجمالي عدد المعاملات",
    Support_desc1: "إجمالي دعم السلسلة",
    Trustworthy_desc: "موثوق",
    utilizes_desc:
      "TUSD يستخدم آليات التدقيق والتصديق المتقدمة لزيادة الشفافية. تقارير التدقيق اليومية متاحة على الموقع الإلكتروني، مما يسمح للمستخدمين بمراقبة الاحتياطيات في أي وقت.",
    Popularity_desc: "الشعبية",
    convenience_desc:
      "يوفر TUSD الراحة والفائدة في مختلف السيناريوهات المالية مثل التداول والتحويل. حاليًا، يمكن استخدام TUSD في أكثر من 80 بورصة للعملات المشفرة وبروتوكولات DeFi، مما يوفر للمستخدمين المرونة وإمكانية الوصول.",
    Liquidity_desc: "السيولة",
    During_desc:
      "في الربع الثالث من عام 2023، تجاوز حجم التداول اليومي لـ TUSD مليار دولار، مما يظهر سيولته المذهلة. بالإضافة إلى ذلك، لا تفرض TUSD أي رسوم على سك العملات أو الاسترداد.",
    Trusted_desc: "موثوق به من قبل القادة العالميين",
    Using_desc: "استخدام TUSD",
    LearnMore_desc: "تعرف على المزيد",
    Goes_desc: "TUSD يتجه نحو السلاسل المتعددة",
    Natively_desc1: "شبكة النشر الأصلية",
    Ethereum_desc: "إيثريوم",
    TRON_desc: "تيرون",
    Avalanche_desc: "أفالانش",
    Smart_desc: "سلسلة BNB الذكية",
    Bridged_desc: "شبكة الجسور",
    Fantom_desc: "فانتوم",
    Arbitrum_desc: "أربتروم",
    Cronos_desc: "كروتوس",
    Optimism_desc: "أوبتيميزم",
    Aurora_desc: "أورورا",
    FAQs_desc: "الأسئلة الشائعة",
    TrueUSD_desc: "س1. ما هو TrueUSD؟",
    Banking_desc:
      "يستفيد TrueUSD من المؤسسات المالية العالمية والبنية التحتية القوية لسلسلة الكتل، وهو أول عملة مستقرة تستخدم العقود الذكية للسك والاسترداد، بدعم من التحقق في الوقت الفعلي من تغطية الاحتياطيات على السلسلة، بدعم من شركة المحاسبة الرائدة عالميًا Moore Hong Kong وChainlink Proof of Reserve.",
    started_desc: "س2. كيف أبدأ باستخدام TrueUSD؟",
    stepsbelow_desc: "لبدء استخدام TrueUSD، يرجى اتباع الخطوات التالية:",
    verify_desc:
      "بعد إنشاء حسابك والتحقق منه عبر موقع TrueUSD، ستتلقى بريدًا إلكترونيًا من",
    website_desc: "موقع TrueUSD",
    verification_desc:
      "تحقق عبر الرابط المرسل. انقر على هذا الرابط للتحقق من عنوان بريدك الإلكتروني، ثم قم بإعداد",
    information_desc:
      "املأ المعلومات الخاصة بك و/أو بمؤسستك. للحصول على قائمة المستندات المطلوبة، انقر",
    here_desc: "هنا",
    application_desc: "قدم طلبك. سنقوم بمراجعة طلبك.",
    Redeem_desc: "سك واسترداد عملتنا المستقرة بدون رسوم بنقرة واحدة",
    Trade_desc:
      "تداول TrueUSD في أكثر من 160 سوقًا على عدة بورصات في خمسة قارات وعلى أكثر من 20 مكتبًا للتداول خارج البورصة",
    Send_desc:
      "أرسل TrueUSD لأي شخص في أي وقت، بطريقة أرخص بـ 100 مرة وأسرع بـ 1000 مرة من الحوالات المصرفية",
    mint_desc: "س3. كيف يمكنني سك واسترداد TrueUSD؟",
    partner_desc1:
      "أرسل حوالة مصرفية إلى شركائنا المصرفيين، باستخدام العنوان المعروض في",
    Trueapp_desc: "تطبيق TrueUSD",
    Please_desc:
      "يرجى ملاحظة: نحن لا نقبل حاليًا تحويلات ACH أو خدمات تحويل العملات الرقمية الأخرى مثل Venmo أو TransferWise أو PayPal أو Revolut.",
    account_desc:
      "يجب أن يتطابق الاسم الموجود في حسابك المصرفي مع الاسم الموجود في حسابك على TrueUSD.",
    creating_desc: "بعد إنشاء حسابك، ستتلقى بريدًا إلكترونيًا من",
    generally_desc:
      "بشكل عام، تستغرق الحوالات المصرفية بين البنوك عادة 1-5 أيام عمل لإكمالها.",
    email_desc:
      "بمجرد استلام الحوالة المصرفية، سنرسل لك تأكيدًا بالبريد الإلكتروني.",
    minted_desc:
      "بمجرد استلام الأموال، سيتم سك TrueUSD وتحويله إلى عنوان المحفظة الخاص بك في التطبيق في غضون يوم عمل واحد.",
    redeem4_desc: "س4. كيف يمكنني استرداد TrueUSD؟",
    Visit_desc: "قم بزيارة",
    Polygon_desc1: "تطبيق TrueUSD",
    receive_desc:
      "لإدخال معلوماتك المصرفية والحصول على عنوان الاسترداد الفريد الخاص بك. تخضع تحويلات العملات الورقية لمتطلبات KYC وAML الخاصة بشركائنا المصرفيين.",
    redemption_desc:
      "هذا العنوان الفريد للاسترداد مخصص لكل مستخدم TrueUSD. يرجى عدم مشاركة عنوان الاسترداد الفريد الخاص بك مع الآخرين.",
    simple_desc: "لماذا عنوان الاسترداد الخاص بي بسيط جدًا؟",
    notice_desc:
      "ستلاحظ أن هذا العنوان بسيط للغاية، ويحتوي على العديد من '0'. هذا متعمد. يمكنك استرداد العملات من أي محفظة (بما في ذلك محافظ البورصات) إلى هذا العنوان، وجميع الاستردادات التي يتم استلامها عبر هذا العنوان سيتم ربطها بحساب TrueUSD الخاص بك.",
    unique_desc:
      "أرسل TrueUSD إلى عنوان الاسترداد الفريد الخاص بك (بحد أدنى 1000 دولار).",
    partner_desc:
      "بمجرد إرسال الرموز بنجاح إلى عنوان الاسترداد الخاص بك، سيقوم شركاؤنا المصرفيون عادةً بإصدار حوالة مصرفية إلى حسابك المصرفي في غضون يوم عمل واحد.",
    exchanges_desc: "س5. ما هي البورصات التي تم إدراج TrueUSD (TUSD) عليها؟",
    Binance_desc:
      "يتم إدراج TrueUSD حاليًا في العديد من البورصات، بما في ذلك بينانس وBittrex وUpbit.",
    here2_desc: "هنا",
    markets_desc:
      "للحصول على قائمة كاملة بالبورصات والأسواق النشطة لـ TrueUSD.",
    contract_desc: "س6. ما هو عنوان العقد الذكي لـ TrueUSD؟",
    tokenSmart_desc:
      "يمكن العثور على عقدنا الذكي عبر شبكة نشر TUSD الأصلية وشبكة جسر TUSD أو متصفح سلسلة بينانس. الروابط أدناه. يمكنك أيضًا معرفة المزيد عن عقودنا الذكية وترقيات العقود الذكية على",
    blog_desc: "مدونتنا",
    related_desc: "المزيد.",
    Natively_desc: "شبكة نشر TUSD الأصلية",
    Ethererum2_desc: "إيثريوم",
    SmartChain_desc: "سلسلة BNB الذكية:",
    
    Avalanche2_desc: "أفالانش",
    Beacon_desc: "سلسلة BNB بيكون:",
    Networks2_desc: "شبكة جسر TUSD",
    Polygon2_desc: "بوليجون",
    Fantom2_desc: "فانتوم",
    Polygon_desc2: "أوبتيميزم",
    ChainOld_desc: "سلسلة BNB الذكية (الإصدار القديم):",
    AboutMore_desc: "تعرف على المزيد عن TUSD",
    Digital_desc: "عملة رقمية موجهة لنظام الدفع العالمي الجديد",
    first_desc:
      "TrueUSD هو أول عملة مستقرة مربوطة بالدولار الأمريكي تستخدم طرف ثالث مستقل للتحقق من الاحتياطيات يوميًا.",
    Polygon_desc: "بوليجون",
    Follow_desc: "تابعنا",
    Resources_desc: "الموارد",
    Stablecoin_desc:
      "أصبح TrueUSD أول عملة مستقرة مربوطة بالدولار الأمريكي تضمن سك العملات بفضل 'إثبات الاحتياطيات'",
    Engages_desc:
      "يتعاون TUSD مع شركة المحاسبة الرائدة MooreHK لتقديم خدمات التحقق من احتياطيات العملات الورقية",
    Becomes_desc: "أصبح TUSD (TRC-20) العملة القانونية في الدومينيكان",
    Blog_desc: "المدونة",
    Support_desc: "الدعم",
    TermsOf_desc: "شروط الاستخدام",
    Privacy_desc: "سياسة الخصوصية",
    TrueUsd_desc: "TrueUSD",
    Home_desc: "الصفحة الرئيسية",
    Transparency_desc: "الشفافية",
    Ecosystem_desc: "النظام البيئي",
    About_desc: "حول",
    Supported_desc: "السلاسل المدعومة",
    Other_desc: "سلاسل أخرى",
    rights_desc: "© 2023 TrueUSD. جميع الحقوق محفوظة.",
    Back_desc: "العودة إلى الأعلى",
  },
  project_desc: {
    Pool_desc: "مجمع الأموال",
    participating_desc: "بصفتك حاملًا للرموز، ستحصل على",
    rewards_desc: "كمكافأة.",
    EndTime_desc: "وقت انتهاء المشروع",
    duration_desc: "فترة الرهن",
    days_desc: "أيام",
    calculation_desc: "حساب دوري",
    Invite_desc: "دعوة",
    level_desc: "مستوى العمولة",
    withdrawal_desc: "الحد الأدنى لمبلغ السحب",
    supply_desc: "الحد الأدنى لمبلغ التوريد",
    period_desc: "الحد الأدنى لفترة السحب",
    Invitations_desc: "مستوى الدعوات",
    People_desc: "عدد الأشخاص",
  },
  capitaInfoView_desc: {
    Please_desc: "يرجى ربط العنوان",
    Pool_desc: "مجمع الأموال",
    receive_desc: "بصفتك حاملًا للرموز، ستحصل على",
    rewards_desc: "كمكافأة.",
    level_desc: "مستوى مجمع الأموال",
    Expired_desc: "منتهي الصلاحية",
    Unexpired_desc: "غير منتهي الصلاحية",
    Participated_desc: "مشارك",
    uncommitted_desc: "غير ملتزم",
    Withdraw_desc: "سحب",
    Redeem_desc: "استرداد",
    Status_desc: "الحالة",
    Principal_desc: "الرأس المال",
    Redeemed_desc: "تم الاسترداد",
    subaccounts_desc: "الحسابات الفرعية",
    Supplied_desc: "تم التوريد",
    Redeemable_desc: "قابل للاسترداد",
    Commission_desc: "العمولة",
    Available_desc: "قابل للسحب",
    Withdrawed_desc: "تم السحب",
    Lock_desc: "تأمين العمولة",
    All_desc: "جميع العمولات",
    interest_desc: "الفائدة",
    Settled_desc: "تم التسوية",
    Loading_desc: "جارٍ التحميل",
    amount_desc: "المبلغ القابل للسحب",
  },
  elModal_desc: {
    Please_desc: "يرجى إدخال مبلغ الإيداع",
    Recharge_desc: "إيداع",
    Cancel_desc: "إلغاء",
    withdrawal_desc: "يرجى إدخال مبلغ السحب",
    Withdraw_desc: "سحب",
    redemption_desc: "يرجى إدخال مبلغ الاسترداد",
    Redeem_desc: "استرداد",
    Withdrawable_desc: "المبلغ القابل للسحب (العمولة + الفائدة):",
    balance_desc: "الرصيد القابل للاسترداد:",
    input_desc: "يرجى إدخال المبلغ",
    Transaction_desc: "تمت المعاملة بنجاح!",
    failed_desc: "فشلت المعاملة",
    funds_desc: "رصيد الحساب غير كافٍ",
    Loading_desc: "جارٍ التحميل",
  },

  modal: {
    hold_desc: "إذا كنت تحتفظ بـ",
    Smart_desc: "من سلسلة BNB الذكية ، ستحصل على مكافأة إضافية",
    Rewards_desc: "المكافآت متاحة فقط للمبالغ التي تتجاوز",
    address_desc: "العنوان يحمل",
    hours_desc:
      "لمدة 24 ساعة ، والحد الأدنى للحصول يعتمد على لقطة عشوائية للنظام. يتم تخصيص المبلغ مرة كل 24 ساعة. يسود التوزيع الفعلي إلى الحساب.",
  },
  UserInfo: {
    Wallets_desc: "المحافظ",
    Reward_desc: "المكافآت",
    half_month: "15 يومًا",
    one_month: "شهر واحد",
    three_month: "3 أشهر",
    Invitees: "المدعوين",
    no_data: "لا توجد بيانات",
    walletNo_support:
      "المحفظة الخاصة بك غير مدعومة. يُرجى استخدام تطبيق محفظة آخر.",
    network_wrong: "يرجى اختيار الشبكة الصحيحة",
  },
};
export default en_ALB;

// Datajson:{
//     language_json:{
//         zh_json:' الصينية المبسطة ',
//         zh_FT_json: 'مليئة الصينية',
//         eh_json:' إنجليزي ',
//         ja_JP_json:' اليابانية . ',
//         ko_KR_json:' كوري ' ,
//         en_TH_json:'التايلاندية ',
//         fr_FR_json:'الفرنسية . ',
//         ru_RU_json:' روسي ',
//         en_IN_json:'هندي ',
//         en_ID_json:' اللغة الأندونيسية ',
//         en_BS_json:' فارسي ',
//         en_YN_json:' الفيتنامية ',
//         en_ALB_json:' بالعربية ',
//         en_TEQ_json:' اللغة التركية ',
//         en_ES_json:' إسبانية ',
//         en_PTY_json:' البرتغالية ',
//         en_YDL_json:' الايطالية '
//     },
// },
