import Web3 from "web3";
import Vue from "vue";
import { Message } from "element-ui";
import BEP20USDT from "@/components/Abi/BEP20USDT";
/**
 *
 * @param {*}  //创建wb3实例
 * @returns
 */
function ConnectWbe3() {
  return new Promise((resolve, reject) => {
    let web3;
    if (window.ethereum) {
      web3 = new Web3(window.ethereum);
    } else if (web3) {
      web3 = new Web3(window.web3.currentProvider);
    } else {
      // 如果没有metamask，则使用infura
      const provider = new Web3.providers.HttpProvider("YOUR_INFURA_ENDPOINT");
      web3 = new Web3(provider);
    }
    resolve(web3);
  });
}
//链接钱包以及监听钱包
function ConnectWallet() {
  return new Promise((resolve, reject) => {
    if (window.ethereum) {
      window.ethereum
        .request({
          method: "eth_requestAccounts",
        })
        .then((res) => {
          this.$ls.set("WalletAddress_tusd", res);

          // 在这里可以执行任何你需要在账户变化时进行的操作
          window.ethereum.on("accountsChanged", (accounts) => {
            this.$ls.set("WalletAddress_tusd", accounts);
          });
          resolve(res);
        })
        .catch((error) => {
          this.$message.error(error.message);
          console.log("钱包类型结果错误", error.message);
          reject([]);
        });
    } else {
      this.$message.error("Please install your wallet");
      reject([]);
    }
  });
}
//检查钱包类型
function environmentWalletType() {
  // //操作连接钱包之前操作
  console.log("钱包类型", window.ethereum);
  if (!window.ethereum) {
    Message.error(this.$t("newhome_json.error1_json"));
    console.log("MetaMask1");
    return false;
  } else if (
    (window.ethereum.isMetaMask && window.ethereum.isTrustWallet) ||
    window.ethereum.isTrustWallet
  ) {
    console.log("MetaMask2");
    return true;
  } else if (window.ethereum.isMetaMask) {
    console.log("MetaMask3");
    Message.error(this.$t("UserInfo.walletNo_support"));
    return false;
  } else {
    return true;
  }
}
//监听网络切换
async function onCheckChain(params) {
  if (window.ethereum) {
    try {
      let network = await window.ethereum.request({
        method: "eth_chainId",
      }); //获得以太网链ID
      network = Number(network);
      console.log("当前网络id", network);
      this.$ls.set("chainID_tusd", network);
      window.ethereum.on("chainChanged", (isChainId) => {
        //监听链ID
        let ChainId = isChainId;
        console.log("未解码的十六进制id", isChainId);
        ChainId = Number(ChainId);
        console.log("监听当前网络id:", ChainId, window.ethereum.isTrust);
        this.$ls.set("chainID_tusd", ChainId);
        if (window.ethereum.isTrust) {
          //防止Trust 钱包刷新会触发两次
          this.$message.success(
            "Chain changed successfully,The current network is:" + ChainId
          );
          window.location.reload();
        } else {
          console.log("切换链提示:", ChainId);
          this.$message.success(
            "Chain changed successfully,The current network is:" + ChainId
          );
          window.location.reload();
        }
      });
    } catch (e) {}
  }
}
export { ConnectWbe3, ConnectWallet, onCheckChain, environmentWalletType };
