const en_YN = {
    Datajson: {
        language_json: {
            zh_json: '中文简体',
            zh_FT_json: '中文繁體',
            eh_json: 'English',
            ja_JP_json: '日本語',
            ko_KR_json: '한국어',
            en_TH_json: 'ภาษาไทย',
            fr_FR_json: 'En français',
            ru_RU_json: 'русск',
            en_IN_json: 'हिंदीName',
            en_ID_json: 'Bahasa indonesia',
            en_BS_json: 'فارسی',
            en_YN_json: 'Việt nam',
            en_ALB_json: ' بالعربية ',
            en_TEQ_json: 'Türkçe',
            en_ES_json: 'Español',
            en_PTY_json: 'Português',
            en_YDL_json: 'Italiano'
        },
    },
    home: {
        language: 'ngôn ngữ',
        fduse_desc: 'FDUSD là một Stablecoin được hỗ trợ bằng USD tỷ lệ 1:1.',
        introduce_desc: 'Cách mạng hóa tài chính toàn cầu với một stablecoin kỹ thuật số tiên tiến, cung cấp an ninh, tốc độ và sự đổi mới trên toàn thế giới cho doanh nghiệp và nhà xây dựng.',
        getfduse_desc: 'Nhận FDUSD',
        stability_desc: 'Sự ổn định gặp gỡ sự đổi mới',
        experience_desc: 'Trải nghiệm tốc độ nhanh chóng và chi phí tối thiểu. Hình thành tương lai tài chính với giải pháp đổi mới và đáng tin cậy của chúng tôi.',
        whitepaper_desc: 'Bản giấy trắng',
        redeemable_desc: 'Đổi 1:1 thành đô la Mỹ',
        dollars_desc: 'FDUSD được thiết kế để được hỗ trợ đầy đủ bằng tiền mặt và tài sản tương đương tiền mặt. Token được thiết kế để có thể đổi 1:1 thành đô la Mỹ.',
        backed_desc: 'Được hỗ trợ bởi tài sản dự trữ đầy đủ',
        deserved_desc: 'Các tài sản dự trữ được giữ trong cơ cấu nắm giữ riêng biệt và không bị ảnh hưởng bởi phá sản.',
        programmable_desc: 'Hoàn toàn có thể lập trình',
        innovative_desc: 'Với giải pháp stablecoin đổi mới của chúng tôi - được hỗ trợ bởi công nghệ tiên tiến, chúng tôi nhằm mục tiêu cung cấp một lựa chọn an toàn và đáng tin cậy mang lại sự yên tâm, tạo ra một tác động tích cực trong thế giới tài chính.',
        settle_desc: 'Thanh toán trong vài phút',
        nearZero_desc: 'Chi phí gần như bằng không',
        always_desc: 'Luôn sẵn sàng, 24/7',
        fully_desc: "Hoàn toàn có thể lập trình",
        rewrite_desc: 'Viết lại Tương lai với First Digital Labs',
        combining_desc: 'Kết hợp sự ổn định với sự đổi mới tại First Digital Labs, chúng tôi cam kết xây dựng niềm tin bằng cách cung cấp sự minh bạch, tuân thủ và an ninh không dao động được hỗ trợ bằng công nghệ tiên tiến.',
        mission_desc: 'Chúng tôi đang trên một nhiệm vụ thúc đẩy ngành tài chính vào một kỷ nguyên tài chính mới và tái hình thành cảnh quan tài chính toàn cầu.',
        should_desc: 'Tại sao bạn nên sử dụng First Digital USD?',
        collateral_desc: 'Tài sản thế chấp được bảo vệ bởi một ngân hàng giữ tầng hàng đầu, có giấy phép đáng tin cậy để bảo vệ tài sản một cách liền mạch và tuân thủ quy định.',
        transparent_desc: 'Minh bạch',
        independent_desc: 'Xác nhận độc lập - tài sản thế chấp được xác nhận bởi bên thứ ba độc lập, đảm bảo tính toàn vẹn của dự trữ và báo cáo tài chính.',
        examined_by_desc: 'Được kiểm tra bởi',
        attestation_Reports_desc: 'Báo cáo xác nhận',
        January2024_desc: 'Tháng 1 năm 2024',
        Report_desc: 'Báo cáo',
        December_desc: 'Tháng 12 năm 2023',
        November_desc: 'Tháng 11 năm 2023',
        Networks_desc: 'Các Mạng được Hỗ trợ?',
        available_desc: 'FDUSD có sẵn trên',
        Ethereum_desc: 'Ethereum',
        and_desc: 'và',
        BNB_Chain_desc: 'BNB Chain',
        planned_desc: 'với kế hoạch hỗ trợ cho một số lượng ngày càng tăng của các blockchain.',
        Audited_desc: 'Được kiểm toán bởi',
        Frequently_desc: 'Câu hỏi thường gặp',
        How_FDUSD_desc: 'Làm thế nào để tôi có thể có được FDUSD?',
        Acquiring_desc: 'Việc sở hữu FDUSD có thể được thực hiện theo một vài cách. Nếu bạn là một người chơi quan trọng trong ngành, một trung gian tài chính hoặc một nhà đầu tư chuyên nghiệp có địa vị nhất định và đáp ứng các tiêu chí cần thiết, bạn có thể mua FDUSD trực tiếp từ First Digital Labs. Vui lòng lưu ý rằng First Digital Labs không bán token trực tiếp cho khách hàng bán lẻ.:',
        canstill_desc: 'Bạn vẫn có thể có được FDUSD thông qua thị trường phụ không trở thành khách hàng của First Digital Labs. Nhiều sàn giao dịch tiền điện tử hàng đầu liệt kê FDUSD và cung cấp thanh khoản đáng kể. Điều này cung cấp một cách tiếp cận và thuận tiện cho những người quan tâm để sở hữu FDUSD. Tuy nhiên, luôn luôn cần thực hiện nghiên cứu kỹ lưỡng và đảm bảo sự đáng tin cậy của sàn giao dịch mà bạn đang sử dụng.',
        equivalent_desc: 'Làm thế nào để tôi có thể tin rằng FDUSD luôn luôn tương đương với đô la?',
        //stablecoin_desc: 'Sự tin tưởng của bạn vào giá trị stablecoin FDUSD là có căn cứ vững chắc. Mỗi FDUSD được thiết đặt để được đảm bảo đầy đủ bằng một đô la Mỹ hoặc một tài sản có giá trị công bằng tương đương. Những tài sản này được giữ an toàn bởi một ngân hàng giữ tầng được chấp thuận trong tài khoản với các cơ sở lưu ký được quy định, tạo nền tảng vững chắc cho giá trị của đồng tiền này. Hệ thống này là điều cung cấp cho FDUSD tính ổn định bẩm sinh và tại sao bạn có thể tin tưởng giá trị tương đương của nó với đô la Mỹ.',
        redeem_FDUSD: 'Làm thế nào để tôi có thể đổi FDUSD?',
       // stablecoin_desc: 'Để đổi FDUSD stablecoin của bạn, bạn phải trở thành khách hàng của First Digital Labs và đáp ứng các yêu cầu cụ thể, bao gồm kiểm tra chống rửa tiền (AML) và chống tài chính khủng bố (CTF). Sau khi hoàn thành thành công các kiểm tra này, bạn có thể đổi FDUSD của mình thành tiền tệ tương đương, do đó, lấy nó ra khỏi lưu thông. Hoặc bạn có thể bán các token FDUSD của mình trên thị trường phụ qua sàn giao dịch tiền điện tử hoặc nhà cung cấp Over-the-Counter (OTC) hỗ trợ stablecoin FD121',
    },
    newhome_json: {
        Exchanges_json: "Sàn giao dịch",
        DeFi_json: "Tài chính phi tập trung",
        Payments_json: "Thanh toán",
        Accessibility_json: "Khả năng tiếp cận",
        listed_json: "TUSD được niêm yết trên nhiều sàn giao dịch hàng đầu trong ngành, cung cấp thanh khoản và khả năng tiếp cận cho tất cả các nhà đầu tư.",
        stablecoin_json: "Là một loại tiền ổn định được sử dụng rộng rãi, TUSD đã được áp dụng đáng kể trong các giao thức tài chính phi tập trung (DeFi) khác nhau, bao gồm các nhà tạo lập thị trường tự động (AMM), nền tảng cho vay và dự án canh tác lợi nhuận (YIF).",
        leading_json: "Là một loại tiền ổn định hàng đầu bằng nhân dân tệ, TUSD là phương tiện lý tưởng cho các giao dịch xuyên biên giới. Với phí giao dịch thấp và tốc độ tức thì của TUSD, cả cá nhân và tổ chức đều có thể thực hiện giao dịch mà không gặp phải những thách thức tài chính truyền thống.",
        available_json: "TUSD cũng có sẵn trên các blockchain hàng đầu như Ethereum, BNB Smart Chain, TRON và Avalanche. Mạng lưới cầu nối của TUSD bao gồm Polygon, Fantom, Arbitrum, Cronos và nhiều hơn nữa.",
        error1_json: "Không thể kết nối với nút. Thay đổi nút và thử lại",
        error2_json: "Lỗi chuỗi",
        error3s_json: "Vui lòng kết nối địa chỉ của bạn",
        Loading1_json: "Đang tải",
        Loading2_json: "Đang tải thông tin dự án",
        error3_json: "Lỗi mạng",
        error4_json: "Kết nối ví bị từ chối",
        error5_json: "Phiên bản ví cũ",
        error6_json: "Kết nối ví bị từ chối",
        Loading3_json: "Đang đăng nhập",
        error7_json: "Lỗi tài khoản",
        error8_json: "Không thể kết nối với nút. Thay đổi nút và thử lại",
        error9_json: "Lỗi mạng",
        Loading4_json: "Đang đặt cược",
        error10_json: "Bạn đã bị chặn",
        error11_json: "Lỗi dự án",
        error12_json: "Không đủ gas",
        success1_json: "Thành công",
        error13_json: "Không thể lấy tài khoản",
        error14_json: "Lỗi không xác định",
        error15_json: "Không thể kết nối với nút. Thay đổi nút và thử lại",
       
      },
      newhome_desc: {
        title_desc: "Tiền tệ kỹ thuật số được xây dựng cho hệ thống thanh toán toàn cầu mới",
        desc_desc: "TrueUSD là stablecoin đầu tiên được gắn với đô la Mỹ được kiểm toán hàng ngày bởi một tổ chức bên thứ ba độc lập.",
        Started_desc: "Bắt đầu",
        Contact_desc: "Liên hệ với chúng tôi",
        Circulating_desc: "Cung cấp lưu thông",
        Trading_desc: "Khối lượng giao dịch",
        Transaction_desc: "Tổng số giao dịch",
        Support_desc1: "Tổng số chuỗi hỗ trợ",
        Trustworthy_desc: "Đáng tin cậy",
        utilizes_desc: "TUSD sử dụng các cơ chế kiểm toán và chứng nhận tiên tiến để tăng cường độ minh bạch. Báo cáo kiểm toán hàng ngày của nó có sẵn trên trang web, cho phép người dùng giám sát dự trữ bất cứ lúc nào.",
        Popularity_desc: "Độ phổ biến",
        convenience_desc: "TUSD cung cấp sự thuận tiện và hữu ích trong các kịch bản tài chính khác nhau như giao dịch và chuyển tiền. Hiện tại, TUSD có thể được sử dụng trên hơn 80 sàn giao dịch tiền điện tử và các giao thức DeFi, cung cấp sự linh hoạt và khả năng tiếp cận cho người dùng.",
        Liquidity_desc: "Thanh khoản",
        During_desc: "Trong quý 3 năm 2023, khối lượng giao dịch hàng ngày của TUSD vượt quá 1 tỷ đô la, cho thấy tính thanh khoản tuyệt vời của nó. Ngoài ra, TUSD không tính phí cho việc đúc và mua lại.",
        Trusted_desc: "Được tin cậy bởi các nhà lãnh đạo toàn cầu",
        Using_desc: "Sử dụng TUSD",
        LearnMore_desc: "Tìm hiểu thêm",
        Goes_desc: "TUSD hướng tới đa chuỗi",
        Natively_desc1: "Mạng triển khai bản địa",
        Ethereum_desc: "Ethereum",
        TRON_desc: "TRON",
        Avalanche_desc: "Avalanche",
        Smart_desc: "BNB Smart Chain",
        Bridged_desc: "Mạng lưới cầu nối",
        Fantom_desc: "Fantom",
        Arbitrum_desc: "Arbitrum",
        Cronos_desc: "Cronos",
        Optimism_desc: "Optimism",
        Aurora_desc: "Aurora",
        FAQs_desc: "Câu hỏi thường gặp",
        TrueUSD_desc: "Q1. TrueUSD là gì?",
        Banking_desc: "TrueUSD sử dụng các tổ chức tài chính toàn cầu và cơ sở hạ tầng blockchain mạnh mẽ. Đây là stablecoin đầu tiên sử dụng hợp đồng thông minh để đúc/xóa, với sự hỗ trợ của việc xác minh độ bao phủ dự trữ theo thời gian thực trên chuỗi, được hỗ trợ bởi công ty kiểm toán hàng đầu thế giới Moore Hong Kong và Chainlink Proof of Reserve.",
        started_desc: "Q2. Làm thế nào để bắt đầu sử dụng TrueUSD?",
        stepsbelow_desc: "Để bắt đầu sử dụng TrueUSD, hãy làm theo các bước dưới đây:",
        verify_desc: "Sau khi tạo và xác minh tài khoản của bạn thông qua trang web TrueUSD, bạn sẽ nhận được một email từ",
        website_desc: "trang web TrueUSD",
       
        verification_desc: "Xác minh qua liên kết xác minh. Nhấp vào liên kết này để xác minh địa chỉ email của bạn, sau đó thiết lập",
        information_desc: "Điền thông tin về bản thân và/hoặc tổ chức của bạn. Để biết danh sách các tài liệu cần thiết, bấm vào",
        here_desc: "đây",
        application_desc: "Gửi đơn đăng ký của bạn. Chúng tôi sẽ xem xét đơn đăng ký của bạn.",
        Redeem_desc: "Đúc và mua lại stablecoin của chúng tôi không mất phí chỉ với một cú nhấp chuột",
        Trade_desc: "Giao dịch TrueUSD trên hơn 160 thị trường trên nhiều sàn giao dịch và hơn 20 bàn OTC ở 5 châu lục",
        Send_desc: "Gửi TrueUSD cho bất kỳ ai, bất cứ lúc nào, với chi phí rẻ hơn 100 lần và nhanh hơn 1000 lần so với chuyển khoản ngân hàng",
        mint_desc: "Q3. Làm thế nào để đúc và mua lại TrueUSD?",
        partner_desc1: "Gửi chuyển khoản ngân hàng cho các đối tác ngân hàng của chúng tôi, sử dụng thông tin hiển thị trong",
        Trueapp_desc: "ứng dụng TrueUSD",
        Please_desc: "Lưu ý: hiện tại chúng tôi không chấp nhận ACH hoặc các dịch vụ chuyển tiền kỹ thuật số khác như Venmo, TransferWise, PayPal, Revolut, v.v.",
        account_desc: "Tên trên tài khoản ngân hàng của bạn phải khớp với tên trên tài khoản TrueUSD của bạn.",
        creating_desc: "Sau khi tạo tài khoản của bạn, bạn sẽ nhận được một email từ",
        generally_desc: "Thông thường, các chuyển khoản ngân hàng giữa các ngân hàng thường mất 1-5 ngày làm việc để hoàn thành.",
        email_desc: "Sau khi chúng tôi nhận được chuyển khoản ngân hàng của bạn, chúng tôi sẽ gửi cho bạn một email xác nhận.",
        minted_desc: "Sau khi chúng tôi nhận được tiền của bạn, TrueUSD sẽ được đúc và chuyển đến địa chỉ ví được cung cấp trong ứng dụng trong vòng 1 ngày làm việc.",
        redeem4_desc: "Q4. Làm thế nào để mua lại TrueUSD?",
        Visit_desc: "Truy cập",
        Polygon_desc1: "ứng dụng TrueUSD",
        receive_desc: "để nhập thông tin ngân hàng của bạn và nhận địa chỉ mua lại duy nhất của bạn. Chuyển tiền fiat phải tuân theo các yêu cầu KYC và AML của các đối tác ngân hàng của chúng tôi.",
        redemption_desc: "Địa chỉ mua lại này là duy nhất cho mỗi người dùng TrueUSD. Đừng chia sẻ địa chỉ mua lại duy nhất của bạn với người khác.",
        simple_desc: "Tại sao địa chỉ mua lại của tôi lại đơn giản như vậy?",
        notice_desc: "Bạn sẽ nhận thấy rằng địa chỉ này rất đơn giản, với nhiều '0'. Điều này là cố ý. Bạn có thể mua lại từ bất kỳ ví nào (bao gồm cả ví sàn giao dịch) đến địa chỉ này, và tất cả các giao dịch mua lại được nhận tại địa chỉ này sẽ được liên kết với tài khoản TrueUSD của bạn.",
        unique_desc: "Gửi TrueUSD đến địa chỉ mua lại duy nhất của bạn (tối thiểu $1,000).",
        partner_desc: "Sau khi token được gửi thành công đến địa chỉ mua lại của bạn, các đối tác ngân hàng của chúng tôi thường sẽ gửi chuyển khoản ngân hàng đến tài khoản ngân hàng của bạn trong vòng 1 ngày làm việc.",
        exchanges_desc: "Q5. TrueUSD (TUSD) được niêm yết trên những sàn giao dịch nào?",
        Binance_desc: "Hiện tại TrueUSD được niêm yết trên nhiều sàn giao dịch, bao gồm Binance, Bittrex và Upbit.",
        here2_desc: "đây",
        markets_desc: "để có danh sách đầy đủ các sàn giao dịch và thị trường TrueUSD đang hoạt động.",
        contract_desc: "Q6. Địa chỉ hợp đồng thông minh của TrueUSD là gì?",
        tokenSmart_desc: "Hợp đồng thông minh của chúng tôi có thể được tìm thấy qua mạng triển khai bản địa TUSD và mạng cầu nối TUSD hoặc trình duyệt Binance Chain. Các liên kết dưới đây. Bạn cũng có thể tìm hiểu thêm về các hợp đồng thông minh của chúng tôi và các bản nâng cấp hợp đồng thông minh trên",
        blog_desc: "blog",
        related_desc: "của chúng tôi.",
        Natively_desc: "Mạng triển khai bản địa TUSD",
        Ethererum2_desc: "Ethereum",
        SmartChain_desc: "BNB Smart Chain:",
        TRON_desc: "TRON:",
        Avalanche2_desc: "Avalanche",
        Beacon_desc: "BNB Beacon Chain:",
        Networks2_desc: "Mạng cầu nối TUSD",
        Polygon2_desc: "Polygon",
        Fantom2_desc: "Fantom",
       
        Polygon_desc2: "Optimism",
        ChainOld_desc: "BNB Smart Chain (cũ):",
        AboutMore_desc: "Tìm hiểu thêm về TUSD",
        Digital_desc: "Tiền tệ kỹ thuật số cho hệ thống thanh toán toàn cầu mới",
        first_desc: "TrueUSD là stablecoin đầu tiên được gắn với đô la Mỹ được xác minh dự trữ hàng ngày bởi một tổ chức bên thứ ba độc lập.",
        Polygon_desc: "Polygon",
        Follow_desc: "Theo dõi chúng tôi",
        Resources_desc: "Tài nguyên",
        Stablecoin_desc: "TrueUSD trở thành stablecoin đầu tiên được hỗ trợ bởi đô la Mỹ đảm bảo việc đúc tiền thông qua 'Proof of Reserves'",
        Engages_desc: "TUSD hợp tác với công ty kiểm toán hàng đầu MooreHK để cung cấp dịch vụ xác minh dự trữ fiat của mình",
        Becomes_desc: "TUSD (TRC-20) trở thành tiền tệ hợp pháp ở Dominica",
        Blog_desc: "Blog",
        Support_desc: "Hỗ trợ",
        TermsOf_desc: "Điều khoản sử dụng",
        Privacy_desc: "Chính sách bảo mật",
        TrueUsd_desc: "TrueUSD",
        Home_desc: "Trang chủ",
        Transparency_desc: "Minh bạch",
        Ecosystem_desc: "Hệ sinh thái",
        About_desc: "Về chúng tôi",
        Supported_desc: "Chuỗi được hỗ trợ",
        Other_desc: "Chuỗi khác",
        rights_desc: "© 2023 TrueUSD. Bảo lưu mọi quyền.",
        Back_desc: "Quay lại đầu trang"
      },
      project_desc: {
        Pool_desc: "Quỹ",
        participating_desc: "Bằng cách tham gia vào token, bạn sẽ nhận được",
        rewards_desc: "như phần thưởng.",
        EndTime_desc: "Thời gian kết thúc dự án",
        duration_desc: "Thời gian staking",
        days_desc: "ngày",
        calculation_desc: "Tính toán định kỳ",
        Invite_desc: "Mời",
        level_desc: "Hoa hồng cấp độ",
        withdrawal_desc: "Số tiền rút tối thiểu",
        supply_desc: "Số lượng cung cấp tối thiểu",
        period_desc: "Thời gian rút tối thiểu",
        Invitations_desc: "Cấp độ mời",
        People_desc: "Số người"
      },
      capitaInfoView_desc: {
        Please_desc: "Vui lòng kết nối địa chỉ",
        Pool_desc: "Quỹ",
        receive_desc: "Bằng cách tham gia vào token, bạn sẽ nhận được",
        rewards_desc: "như phần thưởng.",
        level_desc: "Quỹ cấp độ",
        Expired_desc: "Đã hết hạn",
        Unexpired_desc: "Chưa hết hạn",
        Participated_desc: "Đã tham gia",
        uncommitted_desc: "Chưa cam kết",
        Withdraw_desc: "Rút",
        Redeem_desc: "Đổi",
        Status_desc: "Trạng thái",
        Principal_desc: "Số tiền gốc",
        Redeemed_desc: "Đã đổi",
        subaccounts_desc: "Tài khoản phụ",
        Supplied_desc: "Đã cung cấp",
        Redeemable_desc: "Có thể đổi",
        Commission_desc: "Hoa hồng",
        Available_desc: "Có thể rút",
        Withdrawed_desc: "Đã rút",
        Lock_desc: "Khóa hoa hồng",
        All_desc: "Tất cả hoa hồng",
        interest_desc: "Lãi suất",
        Settled_desc: "Đã thanh toán",
        Loading_desc: "Đang tải",
        amount_desc: "Số tiền có thể rút"
      },
      elModal_desc: {
        Please_desc: "Vui lòng nhập số tiền gửi",
        Recharge_desc: "Nạp tiền",
        Cancel_desc: "Hủy",
        withdrawal_desc: "Vui lòng nhập số tiền rút",
        Withdraw_desc: "Rút",
        redemption_desc: "Vui lòng nhập số tiền đổi",
        Redeem_desc: "Đổi",
        Withdrawable_desc: "Số tiền có thể rút (hoa hồng + lãi suất):",
        balance_desc: "Số dư có thể đổi:",
        input_desc: "Vui lòng nhập số tiền",
        Transaction_desc: "Giao dịch thành công!",
        failed_desc: "Giao dịch thất bại",
        funds_desc: "Số dư tài khoản không đủ",
        Loading_desc: "Đang tải"
      },
    modal: {
        hold_desc: 'Nếu bạn nắm giữ',
        Smart_desc: 'của BNB Smart Chain, bạn sẽ nhận được phần thưởng thêm,',
        Rewards_desc: 'Phần thưởng chỉ có sẵn cho số lượng vượt quá',
        address_desc: 'Địa chỉ nắm giữ',
        hours_desc: 'trong vòng 24 giờ, và số lượng tối thiểu được dựa trên chụp ảnh ngẫu nhiên của hệ thống. Số tiền được phân bổ, mỗi 24 giờ một lần. Số tiền phân bổ thực tế cho tài khoản sẽ được ưu tiên.',
    },
    UserInfo: {
        Wallets_desc: 'Ví',
        Reward_desc: 'Phần thưởng',
        half_month: '15 Ngày',
        one_month: '1 tháng',
        three_month: '3 tháng',
        Invitees: 'Người được mời',
        no_data: 'Không có dữ liệu',
        walletNo_support: 'Ví của bạn không được hỗ trợ. Vui lòng sử dụng ứng dụng ví khác.',
        network_wrong: 'Vui lòng chọn đúng mạng',
      },
}
export default en_YN

// Datajson:{
//     language_json:{
//         zh_json:'Trung Quốc giản thể',
//         zh_FT_json: 'Trung Quốc truyền thống',
//         eh_json:'Tiếng anh',
//         ja_JP_json:'Tiếng nhật',  
//         ko_KR_json:'Tiếng hàn' ,
//         en_TH_json:'Quận taita tiếng',
//         fr_FR_json:'Tiếng pháp',
//         ru_RU_json:'Tiếng nga',
//         en_IN_json:'Hindi',
//         en_ID_json:'Tiếng Indonesia',
//         en_BS_json:'Ba tư',
//         en_YN_json:'Việt nam',
//         en_ALB_json:'Ả rập',
//         en_TEQ_json:'Thổ nhĩ kỳ',
//         en_ES_json:'Tiếng tây ban nha',
//         en_PTY_json:'Tiếng bồ đào nha',
//         en_YDL_json:'Tiếng ý.'
//     },
// },