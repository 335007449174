<template>
  <div id="app">
    <nav-muen></nav-muen>
    <router-view />
  </div>
</template>
<script>
import NavMuen from '@/components/nav-muen/nav-muen'
export default {
  components:{
    'nav-muen':NavMuen
  },
  data() {
    return{

    }
  }, created(){
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100vw;
  
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
